import React from "react";

// Customizable Area Start
import {
    Box,
    Input,
    InputAdornment,
    IconButton,
    Grid
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { crossIcon, errorIcon, greenTickIcon, logo, logoMini, mainImage2 } from "./assets";
import CircularProgress from '@material-ui/core/CircularProgress';

const gradientBackground = "linear-gradient(to right bottom, #2B3D7D, #39C6B3)";
type ObjectFitValue = 'cover';
type OverflowXProperty = 'hidden';
type TextAlignProperty = 'center'
const webStyle = {
    container: {
        // height: "100%",   
        alignItems: 'start',
        overflowX: "hidden" as OverflowXProperty,
    },
    successMsg: {
        height: "50px",
        borderRadius: "14px",
        fontFamily: "Poppins",
        background: 'rgba(131, 254, 87, 0.15)',
        width: "350px",
        color: 'rgb(93, 176, 86)',
        display: "flex",
        alignItems: "center",
        gap: "10px",
        fontSize: "14px",
        fontWeight: 700,
        padding: "10px 15px",
        position: "relative",
    },
    cards: {
        marginTop: '50px',
        display: 'flex',
        height: '100%',
    },

    mainWrapper: {
        margin: "0 auto",
        flexDirection: "column",
        padding: "10px 35px",
        maxWidth: "65%",
        display: "flex",
        gap: "15px",
        alignItems: "start",
    },
    loginimg: {
        width: "100%",
        overflow: "hidden",
        borderRadius: "10px 10px 0 0",
        height: "550px",
    },
    innerimg: {
        objectPosition: '0px 40%',
        objectFit: "cover" as ObjectFitValue,
        width: "100%",
        borderRadius: "10px 10px 0px 0px",
        height: "100%",
    },
    customH2: {
        lineHeight: "32px",
        fontWeight: "600",
        fontFamily: "Poppins",
        fontSize: "20px",
    },
    ptext: {
        color: "#000",
        fontWeight: "500",
        fontSize: "12px",
        fontFamily: "Poppins",
    },
    contentWrapper: {
        background: gradientBackground,
        alignItems: "start",
        padding: "25px",
        gap: "10px",
        borderRadius: "0 0 10px 10px",
        display: "flex",
        flexDirection: "column",
    },
    contentWrappertext: {
        letterSpacing: "0.18px",
        fontFamily: "poppins",
        fontWeight: 500,
        color: "#fff",
        fontSize: "16px",
    },
    logoImageMini: {
        maxWidth: "150px",
    },
    logoImage: {
        cursor: "pointer",
        minWidth: "250px",
    },
    formWrapper: { paddingTop: "20px", width: "100%", },
    inputStyle: {
        flexDirection: "column",
        gap: "8px",
        display: "flex",
        'label': {
            fontSize: "14px",
            fontFamily: "Roboto",
        },
    },
    linkStyle: {
        textAlign: "right",
        fontFamily: "Poppins",
        color: "#000",
        fontWeight: "400",
        fontSize: "14px",
    },
    rememberMeStyle: {
        alignItems: "center",
        fontSize: "14px",
        fontWeight: "400",
        display: "flex",
        fontFamily: "Poppins",
        color: "#000",
    },
    loginbtn: {
        color: "#fff",
        fontSize: "16px",
        background: "#2b3d7d",
        width: "100%",
        padding: "10px",
        borderRadius: "5px",
        border: "none",
        fontFamily: "Poppins",
        cursor: "pointer",
        marginTop: "15px",
    },
    labelStyle: {
        fontSize: "12px",
        display: "flex",
        color: "#2B3D7D",
        padding: "10px 0px",
        gap: "10px",
        alignItems: "center",
        fontFamily: "Poppins",
    },
    lineLeft: {
        border: "1px solid",
        borderColor: gradientBackground,
        flex: "30%",
    },
    googlebtn: {
        alignItems: "center",
        width: "100%",
        padding: "10px",
        cursor: "pointer",
        color: "#000",
        border: "1px solid #2b3d7d",
        display: "flex",
        justifyContent: "center",
        borderRadius: "8px",
        background: "#fff",
    },
    lineRight: {
        flex: "30%",
        border: "1px solid",
        borderColor: gradientBackground,
    },
    googlebtnText: {
        marginLeft: "8px",
        fontWeight: 500,
        fontFamily: "Poppins",
        fontSize: "16px",
        color: "#2b3d7d",
    },
    customInput: {
        border: "1px solid #C0C0C0",
        padding: "8px 15px",
        borderRadius: "8px",
        '.MuiInputBase-input': {
            background: 'red'
        },
    },
    bottomtext: {
        width: "100%",
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
    },
    startbox: {
        flexDirection: "column",
        display: "flex",
    },
    arrowsimg: {
        gap: "10px",
        display: "flex",
    },
    bgcolor: {
        padding: "30px",
        height: "auto",
        background: "#EFFAFF",
    },
    errormsg: {
        gap: "10px",
        display: "flex",
        borderRadius: "14px",
        fontFamily: "Poppins",
        background: "rgba(254,87,90,0.15)",
        fontWeight: 700,
        width: "90%",
        position: "relative",
        color: "#fe575a",
        alignItems: "center",
        fontSize: "14px",
        height: "50px",
        padding: "10px 15px",
    },
    inputlabel: {
        fontFamily: "Poppins",
    },
    signupLink: {
        cursor: "pointer",
        color: "#2B3D7D",
    },
    signupLabel: {
        textAlign: "center" as TextAlignProperty,
        letterSpacing: "0.16px",
        fontSize: "16px",
        fontWeight: 500,
        fontFamily: "Poppins",
    },
    signupCard: {
        display: 'flex',
        height: '100%',
    },
};

import Signup from "../../email-account-login/src/components/Signup.web";
import ForgotPasswordController, {
    Props, configJSON,
} from "./ForgotPasswordController";
import EmailAccountLogin from "../../email-account-login/src/EmailAccountLogin.web";
import UserSelectionModal from "../../../components/src/UserSelectionModal.web";

const theme = createTheme({
    palette: {
        primary: {
            contrastText: "#fff",
            main: "#0000ff",
        },
    },
});

 // Customizable Area End

export default class NewPassword extends ForgotPasswordController {
    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        // Customizable Area End
    }

     // Customizable Area Start
    renderDefaultView = () => {
        return (
            <>
                {this.state.updatePassword && (
                    <Box data-test-id="test8" sx={webStyle.errormsg}>
                    <img data-testid="loginErrorMsg" src={errorIcon} onClick={()=>{this.setState({updatePassword: ''})}} style={{cursor:"pointer"}}/>
                    {this.state.updatePassword}
                    </Box>
                )}

            {this.state.loginSuccess && (
                <Box data-test-id="test8" sx={webStyle.successMsg}>
                  <img src={crossIcon} data-testid="crossTick"  onClick={this.handleUpdatePasswordSuccessRemoved} style={{width:'35px',cursor:"pointer",position:"absolute", top:'0px', right:"6px"}}/>
                  <img src={greenTickIcon}  style={{width:'30px'}}/>
                  {this.state.loginSuccess}
                </Box>
              )}
                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <Box sx={webStyle.inputStyle}>
                            <label style={webStyle.inputlabel}>Password*</label>
                            <Input
                                data-test-id="txtInputPassword"
                                type={this.state.enablePasswordField ? "password" : "text"}
                                placeholder={"Enter your password"}
                                fullWidth={true}
                                style={this.state.passwordError ? { ...webStyle.customInput, border: "1px solid #fe575a" }
                                    : webStyle.customInput}
                                value={this.state.password}
                                onChange={(e) => this.setPassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            data-test-id="passwordIcon"
                                            aria-label="toggle password visibility"
                                            onClick={this.handleClickShowPassword}
                                            edge="end"
                                        >
                                            {this.state.enablePasswordField ? (
                                                <VisibilityOffOutlinedIcon />
                                            ) : (
                                                <VisibilityOutlinedIcon />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {this.state.passwordError && <label data-test-id="passwordError"  dangerouslySetInnerHTML={{ __html: this.state.passwordError }}></label>}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={webStyle.inputStyle}>
                            <label style={webStyle.inputlabel}>Confirm Password*</label>
                            <Input
                                data-test-id="txtInputConfirmPassword"
                                type={this.state.enableConfirmPasswordField ? "password" : "text"}
                                placeholder={"Confirm password"}
                                fullWidth={true}
                                style={this.state.confirmPasswordError ? { ...webStyle.customInput, border: "1px solid #fe575a" }
                                    : webStyle.customInput}
                                value={this.state.confirmPassword}
                                onChange={(e) => this.setConfirmPassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            data-test-id="showCnfirmPass"
                                            aria-label="toggle password visibility"
                                            onClick={this.handleClickShowConfirmPassword}
                                            edge="end"
                                        >
                                            {!this.state.enableConfirmPasswordField ? (
                                                <VisibilityOutlinedIcon />
                                            ) : (
                                                <VisibilityOffOutlinedIcon />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {this.state.confirmPasswordError && <label data-test-id="passwordError" >{this.state.confirmPasswordError}</label>}
                        </Box>
                    </Grid>

                    <Grid item xs={12} >
                        <button
                            onClick={this.goToConfirmationAfterPasswordChange.bind(this,{password:this.state.password,confirmPassword:this.state.confirmPassword})}
                            data-test-id={"btnEmailLogIn"}
                            style={webStyle.loginbtn}>
                            {this.state.loading ? <CircularProgress style={{ color: 'white' }} /> : 'Save'}
                        </button>
                    </Grid>

                    <Grid item xs={12} >
                        <p>Don't have an account yet? <span data-test-id="signLink" style={webStyle.signupLink} onClick={() => this.showSelectRoleModal('signup')}>Sign Up</span></p>
                    </Grid>

                </Grid>

            </>
        )
    }
     // Customizable Area End

    render() {
        // Customizable Area Start
        let labelTitle = configJSON.labelTitle;
        let subTitle = configJSON.subTitle;

        const location = window.location.pathname
        let componentToRender;
        if (location === "/login/signup") {
            componentToRender = <Signup navigation={""} id="" selectedRole={this.state.selectedRole} />
        }
        else if (location === "/login") {
            componentToRender = <EmailAccountLogin navigation={""} id="" selectedRole={""} />
        } else {
            componentToRender = this.renderDefaultView();
        }
        return (
            <ThemeProvider theme={theme}>
                <Grid container alignItems="center" style={webStyle.container}>

                    <Grid item xs={12} md={6} style={webStyle.cards}>
                        <Box sx={webStyle.mainWrapper}>
                            <Box sx={webStyle.customH2}>
                                {labelTitle}
                            </Box>
                            <Box>
                                <img src={logo} style={webStyle.logoImage} onClick={() => this.handleNavigation("HomePage")} />
                            </Box>
                            <Box sx={webStyle.ptext}>
                                {subTitle}
                            </Box>
                            {this.state.loginError && (

                                <Box sx={webStyle.errormsg}>
                                    <img src={errorIcon} />
                                    {this.state.loginError === "Password is require" && "Password is required!"}
                                    {this.state.loginError === "Password is required!" && "Password is required!"}
                                </Box>
                            )}

                            {componentToRender}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6} style={webStyle.cards}>
                        <Box sx={webStyle.bgcolor}>
                            <Box sx={webStyle.loginimg}>
                                <img src={mainImage2} style={webStyle.innerimg} />
                            </Box>
                            <Box sx={webStyle.contentWrapper}>
                                <img src={logoMini} style={webStyle.logoImageMini} />
                                <p style={webStyle.contentWrappertext}>
                                Empower Learning with Engagemo: Engage, Inspire, Succeed!
                                </p>
                            </Box>
                        </Box>
                    </Grid>

                </Grid>
                <UserSelectionModal isVisibleModal={this.state.isVisibleModal} hideModal={this.hideModal} handleSelectRole={this.handleSelectRole} selectedRole={this.state.selectedRole} handleSubmitRole={this.handleSubmitRole} source={this.state.source} />
            </ThemeProvider>
        );
        // Customizable Area End
    }
}

