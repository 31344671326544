import React from "react";

import {
    // Customizable Area Start
    Box,
    Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled } from "@material-ui/core/styles";
import Sidebar from "../../../components/src/Sidebar.web";
import AppHeaderWeb from "../../../components/src/AppHeader.web";
import SearchInput from "../../../components/src/SearchInput.web";
import { withStyles } from "@material-ui/styles";
import { back } from "./assets";
import BasicPagination from "../../../components/src/BasicPagination.web";
import Loader from "../../../components/src/Loader.web";

// Customizable Area End

import TaskPagesListDetailsController, {
  Props,
  configJSON,
} from "./TaskPagesListDetailsController";

export default class TaskPagesListDetails extends TaskPagesListDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  tableHead1 = () => {
    return (
      <TableRow>
        <StyledTableCell align="left">Student Name</StyledTableCell>
        <StyledTableCell align="left">Student Email</StyledTableCell>
        <StyledTableCell align="left">{this.state.isPendingReviewPage ? 'Status' : ''}</StyledTableCell>
        <StyledTableCell align="left"></StyledTableCell>
      </TableRow>
    );
  };
  
  tableBody = () => {
    return this.state.taskDetailList?.map((element) => (
      <> <StyledTableRow key={element.id}>
        <StyledTableCell align="left" >
          <Box style={webStyle.assignmentContainer}>
            <Typography style={webStyle.assignmentName}>{element.attributes.full_name}</Typography>
          </Box>
        </StyledTableCell>
        <StyledTableCell align="left">{element.attributes.email}</StyledTableCell>
        <StyledTableCell align="left">{(this.state.isPendingReviewPage || this.state.isQuizPendingReviewPage) ? 'Pending' : ''}</StyledTableCell>
        <StyledTableCell align="left">
          <Typography data-test-id='btnViewAnswer' onClick={()=> this.viewAnswers(element.id)} style={(this.state.isPendingReviewPage || this.state.isQuizPendingReviewPage) ? webStyle.openBtn : webStyle.viewBtn} >{this.state.isPendingReviewPage || this.state.isQuizPendingReviewPage ? 'Open' : 'View'}</Typography>
        </StyledTableCell>
      </StyledTableRow>
      </>
    ));
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isPendingReviewPage, isQuizPendingReviewPage, daysLeft, className, assignmentName, searchValue,isQuizPage } = this.state
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box>
        <Grid container style={{ overflowX: "hidden", height: "100%" }}>
        {!this.state.isSmallScreen && (
              <Grid item xs={2}>
                <Sidebar />
              </Grid>
            )}
          <Grid item xs={10} style={{ overflowY: "scroll", height: "100%" }}>
            <Grid item md={12}>
              <AppHeaderWeb title={ (isPendingReviewPage || isQuizPendingReviewPage) ? "Pending Review" : "Feedback Sent"} />
            </Grid>
            <Grid item md={12} data-testid="renderSwitch">
              <Box sx={{ px: "35px", mt: "30px" }}>
                <HeaderBox >
                  <Box style={{ display: "flex", alignItems: 'center', }}>
                    <img data-test-id='goToAssignTask' onClick={this.goToAssignTask} style={{width: '24px', height: '24px', marginRight: '4px', cursor: 'pointer'}} src={back} />
                    <Typography style={{ fontSize: "24px", fontFamily: "Poppins", color: '#282829' }}>{assignmentName}</Typography>
                  </Box>

                  <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", gap: "60px" }}>
                    <div style={{ display: "flex", flexDirection: "column", }}>
                      <div style={{ color: "#2c2c2e", fontSize: "12px", fontFamily: "Poppins" }}>Class</div>
                      <div style={{ fontSize: "14px", fontFamily: "Poppins" }}>{className}</div></div>
                    <div style={{ display: "flex", flexDirection: "column", }}>
                      <div style={{ color: "#2c2c2e", fontSize: "12px", fontFamily: "Poppins" }}>{isQuizPage?"Quiz": "Assignment"} Due Date</div>
                      <div style={{ fontSize: "14px", fontFamily: "Poppins" }}>{daysLeft} Days Left</div></div>
                      {
                        !isPendingReviewPage && <div style={{ display: "flex", flexDirection: "column", }}>
                        <div style={{ color: "#2c2c2e", fontSize: "12px", fontFamily: "Poppins" }}>Submitted</div>
                        <div style={{ fontSize: "14px", fontFamily: "Poppins" }}>{this.state.taskDetailList && this.state.taskDetailList.length}</div></div>
                      }
                  </Box>
                </HeaderBox>
                <Box style={webStyle.addButtonLayout} sx={{  }}>
                  <Box style={webStyle.searchBarLayout}>
                    <SearchInput
                      placeholder={"Search by name"}
                      handleSearchInput={this.handleSearch}
                      searchInput={searchValue}
                    />
                  </Box>
                </Box>
                { this.state.loading ? <Loader loading /> :
                  this.state.taskDetailList?.length === 0 ? <Typography style={webStyle.noRecords}>No records found</Typography>
                  : <>
                    <TableContainer component={Paper}>
                      <Table style={webStyle.table} aria-label="customized table">
                        <TableHead>
                          {this.tableHead1()}
                        </TableHead>
                        <TableBody>{this.tableBody()}</TableBody>
                      </Table>
                    </TableContainer>
                    <Box sx={{ px: "35px", py: "50px" }}>
                      <BasicPagination
                        handlePageChange={this.handlePageChange}
                        pageLimit={this.state.totalPages}
                        pageNo={this.state.currentPage}
                      />
                    </Box>
                  </>   
                }
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  assignmentContainer: {
    display: "flex",
    alignItems: "center",
  },
  assignmentName: {
    marginLeft: "10px"
  },
  addButtonLayout: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    marginBottom: "20px",
  },
  searchBarLayout: {
    display: "flex",
    gap: "5%"
  },
  table: {
    minWidth: 700,
  },
  viewBtn: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 500,
    color: '#2B3D7D',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  openBtn: {
    width: 'fit-content',
    border: '1.5px solid #2B3D7D',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 500,
    color: '#2B3D7D',
    padding: '12px 24px 12px 24px',
    margin: 'auto',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  noRecords: {
    fontFamily: "Poppins",
    textAlign: "center" as "center",
    paddingTop: "35px",
  },
};
const HeaderBox=styled(Box)({
  display: "flex",  justifyContent: "space-between",
  '@media (max-width: 700px)':{
    flexDirection: "column",
    gap:"10px"
  }
})
const StyledTableRow = withStyles(() => ({}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#FDF4DC",
    color: "#202020",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
  },
  body: {
    fontSize: "14px",
    color: "#3F526D",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
}))(TableCell);
// Customizable Area End
