import React from "react";
import { Menu, MenuItem, ListItemText, Box, Divider, Modal, Typography,CircularProgress } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  searchIcon,
  folderIcon
} from "../../../assets";
import GenericButton from "../../../../../../components/src/GenericButton.web";
import MoveConfirmationPopup from "./MoveConfirmationPopUp.web";
import MoveSuccessPopup from "./MoveSuccessPopup.web";
const StyledFolderListItem = withStyles((theme) => ({
  root: {
    "& .MuiTypography-body1":{
      fontFamily: "Poppins"
    },
    "&:hover": {
      backgroundImage:
        "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
        fontFamily: "Poppins",
        fontSize: "16px",
      },
    },
  },
}))(MenuItem);
const StyledFolderList = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    borderRadius: "6px",
  },
})((props: any) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));
const useStyles = makeStyles((theme) => ({
  buttonLayout: {
    display: "flex",
    justifyContent: "space-around",
    height: "45px",
    padding: "2px",
    marginTop: "8px"
  },
  list: {
    maxHeight: "150px",
    overflowY: "auto",
    fontFamily:"Poppins"
  },
  searchLayout: {
    display: "flex",
    paddingLeft: "8px",
    paddingRight: "8px",
    alignItems: "center",
    width: "203px"
  },
  searchInput: {
    flexGrow: 1,
    padding: "10px",
    border: "none",
    fontFamily:"Poppins"
  },
  noRecords: {
    fontFamily: "Poppins",
    textAlign: "center",
    paddingTop: "20px",
    width: "100%",
    paddingBottom: "20px"
  },
  loaderColor: {
    color: "#2B3D7D",
  },
  loaderBox:{
    paddingBottom: "20px",
    paddingTop:"20px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  }
}))
const FolderList = (props: any) => {
  const {
    anchorEl,
    open,
    onClose,
    folderList,
    handleOpenMoveConfirmationPopUp,
    handleCloseMoveConfirmationPopUp,
    openMovePopup,
    contextMenuData,
    moveLessonToFolder,
    handleSetFolderId,
    folderId,
    openMoveSuccessPopup,
    handleCloseSuccessPopup,
    handleFolderNameSearchInput,
    folderSearchInput,
    loading
  } = props;
  const classes = useStyles();
  const imageRef = React.useRef<any>();
  const [selectedFolderId, setSelectedFolderId] = React.useState(null);
  const [folderName, setFolderName] = React.useState("")
  
  const handleMove = () => {
    if (selectedFolderId !== null) {
      handleOpenMoveConfirmationPopUp()
    }
  }
  return (
    <>
      <StyledFolderList
        id="customized-menu"
        data-testid="customized-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={()=>{
          setSelectedFolderId(null)
          onClose()}}
      >
        <Box className={classes.searchLayout}>
          <img src={searchIcon} alt="Search" style={{ marginRight: "8px" }} />
          <input
            data-test-id="search-folder"
            type="text"
            value={folderSearchInput}
            placeholder="Search Folder"
            className={classes.searchInput}
            onChange={(e) => handleFolderNameSearchInput(e)}
          />
        </Box>
        <Divider />
        <Box className={classes.list}>
          {loading ? (
            <Box className={classes.loaderBox}>
              <CircularProgress className={classes.loaderColor} />
            </Box>
          ) : (
            <>
            {folderList?.length > 0 ? (<>
              {folderList?.map((obj: any) => (
                <StyledFolderListItem
                data-test-id="folder-list"
                key={obj.id}
                  onMouseEnter={(e) =>
                    imageRef.current && (imageRef.current.src = folderIcon)
                  }
                  onMouseLeave={(e) => imageRef.current && (imageRef.current.src = folderIcon)}
                  onClick={() => {
                    handleSetFolderId(obj.id)
                    setSelectedFolderId(obj.id)
                    setFolderName(obj.name)
                  }}
                  style={{backgroundColor:selectedFolderId===obj.id?"#d4f7fc":"transparent"}}
                >
                  <img
                    src={folderIcon}
                    ref={imageRef}
                    style={{ marginRight: "10px" }}
                  />
                  <ListItemText primary={obj.name} color="#48484A" />
                </StyledFolderListItem>
              ))}
              </> ) : <Typography className={classes.noRecords}>
              No records found
            </Typography>}
         </> )}

        </Box>
        <Divider />
        <Box className={classes.buttonLayout}>
          <GenericButton
            type="NORMAL"
            label="Cancel"
            handleClick={()=>{
            onClose()
            setSelectedFolderId(null)
            }}
            customStyle={{ width: "90px" }}
          />
          <GenericButton
            data-test-id="move-btn"
            type="COLORED"
            label="Move"
            handleClick={handleMove}
            customStyle={{ width: "90px" }}
          />
        </Box>
      </StyledFolderList>
      <Modal
        data-test-id="move-confirmPopup"
        open={openMovePopup}
        onClose={()=>{
        setSelectedFolderId(null)
        handleCloseMoveConfirmationPopUp()
        }}>
        <MoveConfirmationPopup
          contextMenuData={contextMenuData}
          handleClose={handleCloseMoveConfirmationPopUp}
          moveLessonToFolder={moveLessonToFolder}
          setSelectedFolderId={setSelectedFolderId}
          folderName={folderName}
        />
      </Modal>
      <Modal open={openMoveSuccessPopup} onClose={handleCloseSuccessPopup}>
        <MoveSuccessPopup
          handleClose={handleCloseSuccessPopup}
          type={contextMenuData.itemType}
        />
      </Modal>
    </>
  );
};

export default FolderList;
