import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Button } from "@material-ui/core";
import { blueTick, greenTick } from "../../assets";
import { redCross } from "../../../../analytics/src/assets";
import { incorrect } from "../../assets";
import LinearProgress from '@material-ui/core/LinearProgress';
import StaticCanvas from "../../../../../components/src/StaticCanvas.web";

interface OptionSelected {
    optionSelected:number[],
    setOptionSelected:React.Dispatch<React.SetStateAction<number[]>>
}

interface Height {
    setHeight:React.Dispatch<React.SetStateAction<number>>,
    height:number
}

const useStyles = makeStyles(() => ({
    title: {
        marginBottom: "7px",
        fontSize: "14px",
        fontFamily: "Poppins",
        color: "#282829",
        fontWeight: 400,
    },
    addTextLayout: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        height: "100%",
        width: "100%",
        padding:"25px"
    },
    mcqLayout: {
        background: "white",
        width: "30%",
        borderRadius: "20px",
        padding: "30px 50px",
        position:"relative"
    },
    addTextLayoutSub: {
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
    },
    mcqLayoutSub: {
        padding: "80px 30px 30px",
        background: "white",
        borderRadius: "20px",
        height: "100%",
        display: "flex",
        flexDirection: "column",  
        position:"relative"      
    },
    mcqLayoutSubAnswer:{
        justifyContent: "space-between",
    },
    addTextLayoutAnswer: {
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        paddingTop: "50px"
    },
    mcqLayoutAnswer: {
        background: "white",
        borderRadius: "20px",
        padding: "30px",
        position:"relative"
    },
    addTextLayoutPopup: {
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
    },
    mcqLayoutPopup: {
        background: "white",
        borderRadius: "20px",
        position:"relative"
    },
    questionTitle: {
        marginBottom: "25px",
        fontSize: "21px",
        color: "#282829",
        fontFamily: "Poppins",
        fontWeight: 600,
    },
    option: {
        display: "flex",
        justifyContent: "space-between",
        width: "initial",
        border: "1px solid rgba(43, 61, 125, 24%)",
        borderRadius: "50px",
        fontSize: "14px",
        fontFamily: "Poppins",
        padding: "5px 10px",
        marginBottom: "15px",
        alignItems:"center",
        "& img":{
            width:"28px",
            height:"28px",
            borderRadius:"50%",
        },
        cursor:"pointer"
    },
    optionTitle: {
        padding: "5px 10px"
    },
    optionTitleAnswer: {
        padding: "5px 10px",
        overflowX: "clip",
        textOverflow: "ellipsis",
        maxWidth:"80%"
    },
    submit: {
        color: " #FFFFFF",
        padding: "10px 25px",
        fontSize: "14px",
        fontFamily: "Poppins",
        fontWeight: 500,
        borderRadius: "50px",
        textTransform: "none",
        border: "none",
        backgroundColor: "#2B3D7D !important",
        cursor:"pointer"
    },
    disabledBtn: {
        color: " #FFFFFF",
        padding: "10px 25px",
        fontSize: "14px",
        fontFamily: "Poppins",
        fontWeight: 500,
        borderRadius: "50px",
        textTransform: "none",
        border: "none",
        cursor:"pointer",
        backgroundColor: "#2B3D7D",
        opacity:"0.5",
      },
    optionLayout: {
        marginBottom: "10px",
        width: "100%"
    },
    Container: {
        display: "flex",
    },
    greenTickContainer: {
        width: "20px",
        height: "20px"
    },
    imgContainer: {
        width: "100%",
        height: "100%",
        marginBottom:"20px"
    },
    img: {
        maxWidth: "100%",
        maxHeight: "100%"
    },
    answer: {
        color: "#3F526D",
        fontSize: "14px",
        fontFamily: "Poppins",
        fontWeight: 500,
        marginLeft: "10px"
    },
    rightAnswer: {
        backgroundColor: "#34C759",
        color: "#FFF",
        border:"0",
    },
    inCorrectOption: {
        border:"1px solid blue"
    },
    correctOption: {
        border:"2px solid #34C759"
    },
    pollResultWrapper:{
        display:"flex",
        alignItems:"flex-start",
        "& p":{
          fontSize:"14px",
          fontWeight:"400",
          color:"#282829",
          marginBottom:"6px",
          fontFamily: 'Poppins',
        },
        "&:not(:last-child)":{
          marginBottom:"24px",
        },
        "& .poll-result-left":{
          flex:"0 0 50px",
          maxWidth:"50px",
          "& p":{
            fontSize:"14px",
            fontWeight:"600",
            color:"#282829",
            fontFamily: 'Poppins',
          },
        },
        "& .poll-result-right":{
          flex:"1",
          "& .progressbar-wrapper":{
            "&:not(:last-child)":{
              marginBottom:"24px",
            },
          }
        }
      },
    LinearProgressMain:{
        "& .MuiLinearProgress-root":{
          height:"6",
          borderRadius:"22px",
          backgroundColor:"rgb(43, 61, 125,0.12)",
        },
        "& .MuiLinearProgress-barColorPrimary":{
          backgroundColor: "#34C759", 
          borderRadius: "22px",
        }
    },
    submitBtnLayout: {
        marginTop: "25px",
        cursor:"pointer"
      },
      loginBtnLayout: {
        alignItems:"center",
        marginTop: "25px",
        display:"flex",
        flexDirection:"column",
        gap:"10px"
      },
      submitStyle:{
        color:"#818181"
      },
}));
const getBorderStyle = (isSelected:boolean) => {
    if (isSelected) {
        return "1px solid #2B3D7D";
    }
    else {
        return "1px solid #e9eaee";
    }
};
const getTick = (option:any,{showResult,isPolling}:any) => {
    if(showResult){
        return option.marked_as_right_answer || isPolling ? <img src={greenTick} /> : <img src={redCross} />
    }
    else{
        return <img src={blueTick} />
    }
}
const handleOptionClick = (props:any,canAnswer:boolean,option:{id:number,answer:string},optionSelected:number[],setOptionSelected:React.Dispatch<React.SetStateAction<number[]>>,isSelected:boolean,isMultiple:boolean) => {
    const { showResult} = props;
    
    if(showResult || !canAnswer) return
    if(isMultiple){
        let currentOptions:number[] = [];
        let currentOptionNames:string[] = props.selectedOption ?? [];
        setOptionSelected((options)=>{
            currentOptions = options;
            if(isSelected){
                currentOptions = currentOptions.filter((x:any)=>x!=option.id)
                currentOptionNames = currentOptionNames.filter((x:any)=>x!=option.answer)
            }
            else{
                currentOptions = [...currentOptions,option.id]
                currentOptionNames = [...currentOptionNames,option.answer]
            }
            props.setSelectedOption(currentOptionNames)
            props.setSelectedOptionId(currentOptions)
            return currentOptions
        })
    }
    else{
        props.setSelectedOption([option.answer])
        props.setSelectedOptionId([option.id])
        setOptionSelected([option.id])
    }
};

const renderOptions = (props:any,canAnswer:boolean,optionSelected:number[],setOptionSelected:React.Dispatch<React.SetStateAction<number[]>>,classes:any) => {
    const { stage, isPolling,showResult} = props;
    const { attributes } = stage
    const isMultiple = isPolling ? attributes.multiple : false;
    const selOption = optionSelected.length > 0 ? optionSelected[0]:"";

    return attributes?.questions[0]?.options.map((option: any) => {  
        let isSelected = false;
        isSelected = optionSelected?.includes(option?.id)
        let optionStyle = {
            border: getBorderStyle(isSelected)
        };
        if(isSelected && showResult){               
            if(option.marked_as_right_answer || isPolling){
                optionStyle= {border: "2px solid #34C759"}
            }
            else{
                optionStyle= {border: "2px solid #FD5F5A"}
            }
        }
        
        return (
            <Box
                data-testID='valuiie'
                data-test-id={`option-${option.id}`}
                key={option.answer}
                style={optionStyle}
                className={classes.option}
                onClick={()=>handleOptionClick(props,canAnswer,option,optionSelected,setOptionSelected,isSelected,isMultiple)}
            >
                <Typography className={classes.optionTitleAnswer}>{option?.answer}</Typography>
                 {isSelected && getTick(option,props)}
            </Box>
        );
    });
};
const showAnswerResult = (props:any,optionSelected:number[],classes:any) => {
    const { stage} = props;
    const { attributes } = stage
    let isCorrect = false;
    let correctOption = attributes?.questions[0]?.options.find((option:any)=>option.marked_as_right_answer==true)
    isCorrect = correctOption && correctOption.id == optionSelected;
   
    let msg;
    if (optionSelected.length == 0) {
        msg = "Not answered";
    } else {
        if (isCorrect) {
            msg = "Your answer is Correct";
        } else {
            msg = "Your answer is Incorrect";
        }
    }
    return (
        <Box className={classes.Container}>
            <Box className={classes.greenTickContainer}>
                <img src={isCorrect?greenTick:redCross} className={classes.img} />
            </Box>
            <Typography className={classes.answer}>{msg}</Typography>
        </Box>
    )
}
const renderAnswerOptions = (props:any,classes:any,optionSelected:number[]) => {
    const { stage, isPolling,pollingResult} = props;
    const { attributes } = stage
    const isAnswered = (optionSelected ?? []).length>0
    return !isPolling?attributes?.questions[0]?.options.map((option: any) => {
        return (
            <Box
                data-testID='valuiie'
                key={option.answer}
                className={`${classes.option} ${option.marked_as_right_answer && isAnswered && classes.rightAnswer}`}
            >
                <Typography className={classes.optionTitle}>{option?.answer}</Typography>
            </Box>
        );
    }):pollingResult.map((poll:{
        "options_id": number,
        "option": string,
        "percentage": number
      })=>{
        const pollPercentage = Math.round(poll.percentage)
        return (
            <Box className={classes.pollResultWrapper}>
                <Box className="poll-result-left">
                    <Typography>{pollPercentage}%</Typography>
                </Box>
                <Box className="poll-result-right">
                    <Box className="progressbar-wrapper">
                        <Typography>{poll.option}</Typography>
                        <Box className={classes.LinearProgressMain}>
                        <LinearProgress variant="determinate" value={pollPercentage} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    });
}
const getFirstBox = (props:any,classes:any,setHeight:React.Dispatch<React.SetStateAction<number>>,height:number,optionSelected:number[]) => {
    const { stage, isPolling,isPopup } = props;
    const { attributes } = stage
    const hasCanvasData = attributes.canvas_data;
    let jsonCanvas2: object | undefined;
    let canvasSize2: string | undefined;
    if (hasCanvasData) {

        const parsedData = JSON.parse(hasCanvasData);
        jsonCanvas2 = parsedData.canvas;
        canvasSize2 = parsedData.canvasSize;
      
    }
    return (
        <Box className={isPopup?classes.addTextLayoutPopup:classes.addTextLayoutAnswer}>
            <Box className={isPopup?classes.mcqLayoutPopup:classes.mcqLayoutAnswer}>
                <Typography className={classes.title}>
                   {isPolling?"Polling Question Results":"Multiple Choice Question Results"} 
                    </Typography>
                {  jsonCanvas2 &&
                <Box style={{position:"absolute",width:"90%",height:"max-content"}}>
                    <StaticCanvas canvasData={jsonCanvas2} id="canvasId" canvasSize={canvasSize2?JSON.parse(canvasSize2):undefined}  callbackCanvas={setHeight}/>
                    </Box>}
                <Typography className={classes.questionTitle}  dangerouslySetInnerHTML={{
                    __html: attributes?.questions[0]?.question?.question,
                    }} style={{marginBottom:`${height}px`}}/>
                <Box className={classes.optionLayout}>
                    {renderAnswerOptions(props,classes,optionSelected)}
                </Box>
            </Box>
        </Box>
    )
}
const getSecondBox = (props:any,classes:any,canAnswer:boolean,option:OptionSelected,getSubmitBtn:()=>boolean|JSX.Element,height:Height) => {
    const { stage,isSubStage, isPolling,showResult} = props;
    const { attributes } = stage
    const hasCanvasData = attributes.canvas_data;
    const backgroundImage=stage.attributes.background_image?.url;
    let jsonCanvas: object | undefined;
    let canvasSize: string | undefined;
    if (hasCanvasData) {

        const parsedData = JSON.parse(hasCanvasData);
        canvasSize = parsedData.canvasSize;
        jsonCanvas = parsedData.canvas;
      
    }
    return (
        <Box className={isSubStage?classes.addTextLayoutSub:classes.addTextLayout} style={backgroundImage && { 
            backgroundImage: `url("${backgroundImage}")`,
            height: '100vh',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
           }}>
            <Box className={isSubStage?`${classes.mcqLayoutSub} ${showResult && classes.mcqLayoutSubAnswer}`:classes.mcqLayout}>
                <Box className="mcqLayoutSubTop">
                    <Typography className={classes.title}>
                    {isPolling?"Polling Question":"Multiple Choice Question"} 
                        </Typography>
                    {  jsonCanvas &&
       <Box style={{position:"absolute",width:"90%",height:"max-content",}}>
        <StaticCanvas canvasData={jsonCanvas} id="canvasId" canvasSize={canvasSize?JSON.parse(canvasSize):undefined}  callbackCanvas={height.setHeight}/>
        </Box>}
                    <Typography className={classes.questionTitle}  dangerouslySetInnerHTML={{__html: attributes?.questions[0]?.question?.question,}}  style={{marginBottom:`${height.height}px`}}/>
                    <Box className={classes.optionLayout}>
                        {renderOptions(props,canAnswer,option.optionSelected,option.setOptionSelected,classes)}
                    </Box>
                </Box>
                {!showResult?getSubmitBtn():!isPolling && showAnswerResult(props,option.optionSelected,classes)}
            </Box>
        </Box>
    )
}
const redirectToLogin=()=>{
    window.location.href= "/login"
  }
const StudentMcqLive = (props: any) => {
    const { isSubStage,showQuestion, isPolling,showAnswers,selectedOptionId,stageAnswers,isQuizTimedOut,isGuest } = props;
    const classes = useStyles();
    const [optionSelected, setOptionSelected] = useState<number[]>([])
    const [height,setHeight] = useState(25);
    const canAnswer = (stageAnswers ?? []).length<=0 && !isQuizTimedOut && !isGuest ;

    const handleSubmit =()=>{
        props.handleSubmit(isPolling)
    }
    const getSubmitBtn = () => {
        if(isSubStage && isGuest){
            return (
            <Box className={classes.loginBtnLayout}>
              <Typography className={classes.submitStyle}>Please Login to submit your answers</Typography>
              <Button 
                className={classes.submit}
                onClick={redirectToLogin}
                data-testID='loginMCQ'
              >
                Login
              </Button>
            </Box>
            )
        }
        return canAnswer && <button data-testID='submit' onClick={showQuestion && handleSubmit} className={showQuestion ? classes.submit : classes.disabledBtn} disabled={!showQuestion}><Typography>Submit</Typography></button>
    }
    useEffect(()=>{
        let selectedOption = typeof selectedOptionId == "number" ? [selectedOptionId] : selectedOptionId
        if(selectedOptionId==0) selectedOption=[]
        setOptionSelected(selectedOption)
    },[selectedOptionId])
    return (
        (showAnswers && !isSubStage) ? 
         getFirstBox(props,classes,setHeight,height,optionSelected)
        :
        getSecondBox(props,classes,canAnswer,{optionSelected:optionSelected,setOptionSelected:setOptionSelected},getSubmitBtn,{setHeight:setHeight,height:height})
    );
};

export default StudentMcqLive;