import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box,Typography } from "@material-ui/core";
import SearchInput from "../../../../components/src/SearchInput.web";
import BasicPagination from "../../../../components/src/BasicPagination.web";
import GenericButton from "../../../../components/src/GenericButton.web";
import { filterIcon } from "../assets";
import StudentTable from "./StudentTable.web";
import FilterContextMenu from "./FilterContextMenu.web";
import Loader from "../../../../components/src/Loader.web";
const useStyles = makeStyles(() => ({
  searchBarLayout: {
    display: "flex",
  },
  addButtonLayout: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    marginTop: "20px",
    marginBottom: "20px",
    "@media (max-width: 600px)": {
      flexDirection: "column",
      alignItems:"center",
      gap:"10px"
    },
  },
  noRecords: {
    fontFamily: "Poppins",
    textAlign: "center",
    paddingTop: "35px",
  },
  filterImg: { 
    marginLeft: "10px",
    cursor:"pointer" 
  },
  paginationLyt: {
    padding: "50px 35px",
    "@media (max-width: 600px)": {
      padding: "50px 10px"
  },
  }
}));
const StudentList = (props:any) => {
  const {
    showAddStudentForm,
    loading,
    students,
    pageNoStudent,
    pageLimitStudent,
    handlePageChange,
    handleSearchInput,
    searchInput,
    deleteStudentProfileApi,
    isDeleteSuccess,
    handleCloseDeleteSuccess,
    handleOpenDeleteSuccessModal,
    handleOpenDeleteProfileModal,
    handleCloseDeleteProfileModal,
    openDeleteStudentModal,
    handleDateFilter,
    handleStatusFilter
  }=props
  const [anchorEl,setAnchorEl]=useState(null)
  const [openFilterContextMenuList,setOpenFilterContextMenuList]=useState(false)
  const classes = useStyles();
  const handleOpenContextMenuList = (
    event:any
  ) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setOpenFilterContextMenuList(true);
  };
  const handleCloseContextMenuList = (event: any) => {
    event.preventDefault();
    setAnchorEl(null);
    setOpenFilterContextMenuList(false);
  };
  return (
    <>
      <Box sx={{ px: "35px" }}>
        <Box className={classes.addButtonLayout}>
          <Box className={classes.searchBarLayout}>
            <SearchInput
              searchInput={searchInput}
              handleSearchInput={handleSearchInput}
              placeholder={"Search by name"}
            />
            <img 
             data-test-id="open-filter"
             src={filterIcon} 
             className={classes.filterImg} 
             onClick={(e)=>handleOpenContextMenuList(e)}/>
          </Box>
          <GenericButton
            data-test-id="add-student"
            type="COLORED"
            label="Add new student"
            handleClick={showAddStudentForm}
            customStyle={{ width: "190px", height: "50px" }}
          />
        </Box>
        {loading ? (
          <Loader loading />
        ) : (<>
          {!students.length ?
            (
              <Typography className={classes.noRecords}>
                No records found
              </Typography>
            ) : (
              <Box>
          <>
            <StudentTable 
            students={students}
            pageNo={pageNoStudent}
            deleteStudentProfileApi={deleteStudentProfileApi}
            isDeleteSuccess={isDeleteSuccess}
            handleCloseDeleteSuccess={handleCloseDeleteSuccess}
            handleOpenDeleteSuccessModal={handleOpenDeleteSuccessModal}
            handleOpenDeleteProfileModal={handleOpenDeleteProfileModal}
            handleCloseDeleteProfileModal={handleCloseDeleteProfileModal}
            openDeleteStudentModal={openDeleteStudentModal}
            showAddStudentForm={showAddStudentForm}
            />
            <Box className={classes.paginationLyt}>
              <BasicPagination
                handlePageChange={handlePageChange}
                pageLimit={pageLimitStudent}
                pageNo={pageNoStudent}
              />
            </Box>
          </>
        </Box>
            )
          }
        </>)}
        
      </Box>
      <FilterContextMenu
      data-test-id="context-menu"
      open={openFilterContextMenuList}
      onClose={handleCloseContextMenuList}
      anchorEl={anchorEl}
      handleDateFilter={handleDateFilter}
      handleStatusFilter={handleStatusFilter}
      />
    </>
  )
}

export default StudentList;