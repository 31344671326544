import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import moment from "moment";
import { EventType } from "../../SchoolAdmin/TeacherCalendar/types";

export const configJSON = require("../../config.js");
import momentTz from "moment-timezone"
export interface Props {
  navigation: any;
  id: string;
  classes: any;
}
interface StudentCalendar {
  name: string;
  events: any[];
}
interface EventPropsObj {
  "event": {
    "endTime": string;
    "startTime": string;
    "id": string;
    "start": string;
    "end": string;
    "endDate": string;
    "type": string;
    "startDate": string;
    "title": string;
    "multiDayEvent": boolean;
    "overlap": boolean;
    "lessonCode": string;
    "quizCode": string;
    lessonLive:boolean;
    quizLive: boolean;
    "assignment":{
      data:{
        id:string;
        attributes:{
          class_name:string;
          details: string;
        }
      }
    },
    status:string;
  };
  "title": string
}
interface S {
  loading: boolean;
  selectedDate: Date;
  setOpen: boolean;
  calendarData: StudentCalendar;
  eventPropsObj: EventPropsObj;
  openAssignment: boolean;
  openNoMobileModal: boolean;
  isSmallScreen:boolean;
  isLiveModalActive: boolean;
  sessonLive: any;
}

interface SS {
  id: any;
}

export default class TeacherCalendarController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiDeleteEventCallId: string = "";
  apiCalendarEventsCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      calendarData: {
        name: "",
        events: [],
      },
      selectedDate: new Date(),
      loading: false,
      setOpen: false,
      isLiveModalActive: false,
      eventPropsObj: {
        "event": {
          "endTime": '',
          "startTime": '',
          "id": "",
          "start": "",
          "end": "",
          "endDate": "",
          "type": "",
          "startDate": "",
          "title": "",
          "multiDayEvent": false,
          "overlap": false,
          lessonCode:"",
          quizCode:"",
          lessonLive: false,
          quizLive: false,
          assignment:{
            data:{
              id:"",
              attributes:{
                class_name:'',
                details:"",
              }
            }
          },
          status:""
        },
        "title": "",
      },
      sessonLive: {},
      openAssignment: false,
      isSmallScreen: window.innerWidth <= 600,
      openNoMobileModal: window.innerWidth <= 1200,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.setState({
        loading: false,
      });
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    this.handleGetStudentEventsData(apiRequestCallId, responseJson, errorReponse)
  }

  handleCloseNoMobileModal = () => {
    this.setState({
      openNoMobileModal:false
    })
  };
  handleOpenNoMobileModal = () => {
    this.setState({
      openNoMobileModal:true
    })
  };


  handleGetTimeUtc = (timeUtc:string)=>{
    const timeDt = timeUtc?.split("+")[0]
    return timeDt
  }

  handleGetStudentEventsData = (apiRequestCallId: any, responseJson: any, errorReponse: any) => {
    if (
      apiRequestCallId != null &&
      apiRequestCallId === this.apiCalendarEventsCallId
    ) {
      const lessonsData1: any[] = responseJson?.calendar_events?.data;
      const assignmentsData: any[] = responseJson?.assignment_events?.data;

      const lessonsEvents1: any[] = lessonsData1
        ?.filter((lesson: any) =>  EventType.LESSON ===lesson?.attributes?.event_type)
        ?.map((lesson: any) => {
          return {
            endDate: momentTz(new Date(this.handleGetTimeUtc(lesson?.attributes?.due_time_utc))).format('YYYY-MM-DD'),
            lessonId: lesson?.attributes?.lesson_id,
            end: momentTz(new Date(this.handleGetTimeUtc(lesson?.attributes?.due_time_utc))).toDate(),
            start: momentTz(new Date(this.handleGetTimeUtc(lesson?.attributes?.start_time_utc))).toDate(),
            lessonFolderId: lesson?.attributes?.lesson_folder_id,
            type: EventType.LESSON,
            title: lesson.attributes?.lesson?.lesson_name,
            id: lesson.id,
            description: lesson?.attributes?.lesson?.lesson_objective,
            startTime: momentTz(new Date(this.handleGetTimeUtc(lesson?.attributes?.start_time_utc))).format('hh:mm A'),
            teacherClass: lesson?.attributes?.teacher_class_id,
            endTime: momentTz(new Date(this.handleGetTimeUtc(lesson?.attributes?.due_time_utc))).format('hh:mm A'),
            lesson: lesson?.attributes?.lesson,
            lessonLive: lesson?.attributes?.lesson?.live_lesson,
            startDate: momentTz(new Date(this.handleGetTimeUtc(lesson?.attributes?.start_time_utc))).format('YYYY-MM-DD'),
            lessonCode: lesson?.attributes?.lesson?.lesson_code,
          }
        });
        
      const quizEvents = lessonsData1
        ?.filter((quiz: any) => quiz?.attributes?.event_type === EventType.QUIZ)
        .map((quiz: any) => {
        if (quiz?.attributes?.event_type === EventType.QUIZ) {
          return {
            id: quiz.id,
            title: quiz.attributes?.quiz?.quiz_name,
            start: momentTz(new Date(this.handleGetTimeUtc(quiz?.attributes?.start_time_utc))).toDate(),
            end: momentTz(new Date(this.handleGetTimeUtc(quiz?.attributes?.due_time_utc))).toDate(),
            startDate: momentTz(new Date(this.handleGetTimeUtc(quiz?.attributes?.start_time_utc))).format('YYYY-MM-DD'),
            endDate: momentTz(new Date(this.handleGetTimeUtc(quiz?.attributes?.due_time_utc))).format('YYYY-MM-DD'),
            startTime: momentTz(new Date(this.handleGetTimeUtc(quiz?.attributes?.start_time_utc))).format('hh:mm A'),
            endTime: momentTz(new Date(this.handleGetTimeUtc(quiz?.attributes?.due_time_utc))).format('hh:mm A'),
            teacherClass: quiz?.attributes?.teacher_class_id,
            description: quiz?.attributes?.quiz?.details,
            quiz: quiz?.attributes?.quiz,
            quizFolderId: quiz?.attributes?.quiz_folder_id,
            quizId: quiz?.attributes?.quiz_id,
            type: EventType.QUIZ,
            quizCode: quiz?.attributes?.quiz?.quiz_code,
            quizLive: quiz?.attributes?.quiz?.live_quiz,
          }
        }
        });

      const assignEvents = assignmentsData
      ?.filter((quiz:any) => quiz.attributes.assignment.data !== null)?.map((quiz: any) => {
          return {
            description: quiz.attributes?.assignment?.data?.attributes?.details,
            teacherClass: quiz?.attributes?.teacher_class_id,
            assignment: quiz?.attributes?.assignment,
            startTime: momentTz(new Date(this.handleGetTimeUtc(quiz?.attributes?.start_time_utc))).format('hh:mm A'),
            endDate: momentTz(new Date(this.handleGetTimeUtc(quiz?.attributes?.due_time_utc))).format('YYYY-MM-DD'),
            assignFolderId: quiz?.attributes?.assignment_folder_id,
            type: EventType.ASSIGNMENT,
            endTime: momentTz(new Date(this.handleGetTimeUtc(quiz?.attributes?.due_time_utc))).format('hh:mm A'),
            title: quiz.attributes?.assignment?.data?.attributes?.assignment_name,
            start: momentTz(new Date(this.handleGetTimeUtc(quiz?.attributes?.start_time_utc))).toDate(),
            end: momentTz(new Date(this.handleGetTimeUtc(quiz?.attributes?.due_time_utc))).toDate(),
            id: quiz.id,
            startDate: momentTz(new Date(this.handleGetTimeUtc(quiz?.attributes?.start_time_utc))).format('YYYY-MM-DD'),
            status: quiz.attributes?.assignment?.data?.attributes?.assignment_status,
          }
        });
      const assignedQuizEvents = assignmentsData
      ?.filter((event:any) => event.attributes.quiz.data !== null)?.map((event: any) => {
        return {
          endTime: momentTz(new Date(this.handleGetTimeUtc(event?.attributes?.due_time_utc))).format('hh:mm A'),
          id: event.id,
          title: event.attributes.quiz.data.attributes.quiz_name,
          start: momentTz(new Date(this.handleGetTimeUtc(event?.attributes?.start_time_utc))).toDate(),
          end: momentTz(new Date(this.handleGetTimeUtc(event?.attributes?.due_time_utc))).toDate(),
          startDate: momentTz(new Date(this.handleGetTimeUtc(event?.attributes?.start_time_utc))).format('YYYY-MM-DD'),
          description: event.attributes?.quiz?.data?.attributes?.details,
          teacherClass: event?.attributes?.teacher_class_id,
          assignment: event?.attributes?.assignment,
          startTime: momentTz(new Date(this.handleGetTimeUtc(event?.attributes?.start_time_utc))).format('hh:mm A'),
          endDate: momentTz(new Date(this.handleGetTimeUtc(event?.attributes?.due_time_utc))).format('YYYY-MM-DD'),
          assignFolderId: event?.attributes?.assignment_folder_id,
          quizCode: event.attributes.quiz.data.attributes.quiz_code,
          quizLive: event.attributes.quiz.data.attributes.live_quiz,
          type: EventType.QUIZ,
        };
      })
      if (
        lessonsEvents1 !== undefined ||
        quizEvents !== undefined ||
        assignEvents !== undefined ||
        assignedQuizEvents !== undefined
      ) {
        const events = [...lessonsEvents1, ...quizEvents, ...assignEvents, ...assignedQuizEvents];
        this.setState({
          calendarData: {
            events: this.refactorEventsBasedOnOverlap(events),
            name: responseJson.full_name,
          },
        });
      }
      if (
        responseJson?.message === "Events Not found" ||
        !quizEvents ||
        !lessonsEvents1
      ) {
        this.setState({
          calendarData: {
            events: this.refactorEventsBasedOnOverlap([]),
            name: responseJson?.full_name,
          },
        });
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }


  async componentDidMount() {
    this.getStudentCalendarEvents();
    window.addEventListener('resize', this.handleResize);
  }

  // Function to check if two events overlap
  doEventsOverlap(event1: any, event2: any) {
    return event1?.start < event2?.end && event1?.end > event2?.start;
  }
   // Function to check if an event spans multiple days
   isMultiDayEvent(event: any) {
    const eventStartDate = moment(event.start).toDate();
    const eventEndDate = moment(event.end).toDate();
    return eventStartDate.getDate() !== eventEndDate.getDate();
  }
  removeMultiDayEvents(eventsData: any) {
    const multiDayEvents = [];
    const events = [];

    for (let eventData of eventsData) {
      if (!!this.isMultiDayEvent(eventData)) {
        eventData["multiDayEvent"] = true;
        eventData["overlap"] = false;
        multiDayEvents.push(eventData);
      } else {
        eventData["multiDayEvent"] = false;
        eventData["overlap"] = true;
        events.push(eventData);
      }
    }
    return { multiDayEvents, events };
  }

  
  refactorEventsBasedOnOverlap(eventsData: any) {
    // Iterate through the events to categorize them
    const { multiDayEvents, events } = this.removeMultiDayEvents(eventsData);

    const mainArr = [...events, ...multiDayEvents];
    for (let [i, event] of mainArr.entries()) {
      if (event.multiDayEvent === true) continue;
      // Compare the current event with all other mainArr
      for (let [j, eventj] of mainArr.entries()) {
        if (i !== j && this.doEventsOverlap(event, eventj)) {
          break; // No need to check further if there's an overlap
        }
      }

      if (event?.multiDayEvent && !event?.overlap) {
        event["overlap"] = true;
        event["multiDayEvent"] = false;
      } else {
        event["overlap"] = false;
        event["multiDayEvent"] = true;
      }
    }
    return [...mainArr];
  }

  handleStudentDateChange = (date: Date) => {
    this.setState({ selectedDate: date, setOpen: false }, () => {
      this.getStudentCalendarEvents();
    });
  };

  handleLesssonToLive = (eventProps:EventPropsObj)=>{
    if(eventProps.event.lessonLive){
      window.location.replace(`/joined/${eventProps.event.lessonCode}`)
    }else{
      this.setState({isLiveModalActive: true, sessonLive: eventProps.event})
    }
  }
  handleQuizToLive = (eventProps:EventPropsObj)=>{
    if(eventProps.event.quizLive){
      window.location.replace(`/joined/${eventProps.event.quizCode}`)
    }else{
      this.setState({isLiveModalActive: true, sessonLive: eventProps.event})
    }
  }
  

  handleOpenAssignment = (eventProps: EventPropsObj, event: React.MouseEvent<{}>,) => {
    if (eventProps?.event.type === 'assignment') {
      this.setState({
        eventPropsObj: eventProps,
        openAssignment: true,
      });
    } else if (eventProps.event.type === 'lesson') {
      this.handleLesssonToLive(eventProps)
    }else if(eventProps.event.type === 'quiz'){
      this.handleQuizToLive(eventProps)
    }
  };


  closeAssignment = () => {
    this.setState({ openAssignment: false })
  }

  handleCloseLiveModal = () =>{
    this.setState({isLiveModalActive: false},()=>{
      window.location.reload();
    })
  }

  handleEditEventTypeCheck = (eventProps: any) => {
    if (eventProps?.event?.type === 'lesson') {
      return "Join Lesson";
    } else if (eventProps?.event?.type === 'quiz') {
      return "Join Quiz";
    } else {
      return "Open Assignment"
    }
  }

  handleStudentOpen = () => {
    this.setState({ setOpen: true });
  };

  handleStudentClose = () => {
    this.setState({ setOpen: false });
  };

  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  getStudentCalendarEvents() {
    const year1 = this.state.selectedDate.getFullYear();
    const month1 = String(this.state.selectedDate.getMonth() + 1).padStart(2,"0");
    const day1 = String(this.state.selectedDate.getDate()).padStart(2, "0");
    
    const formattedDate1 = `${year1}/${month1}/${day1}`;
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCalendarEventsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GetMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStudentEventList}?start_date=${formattedDate1}`
    );

   
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleResize = () => {
    this.setState({ isSmallScreen: window.innerWidth <= 600, openNoMobileModal: window.innerWidth <= 1200 });
  };

  navigateToAssessment=(id:string,value:string)=>{
    if(value==="pending"){
      this.props.navigation.navigate("AssessmentTest", {            
        id:id,
      });
    }else{
      this.props.navigation.navigate("AssessmentView", {
        id:id,
      });
    }
  }
}
