import React, { Component } from "react";
import {
  Box,
  Typography,
  Popover,
  Modal,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { chnagePasswordIcon, closeNotificationIcon, imageLogoutDark, notification, notificationMockImg, profileIcon } from "./assets";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import JoinClassModal from "./JoinClassModal.web";
import { styled } from "@material-ui/core/styles";
import { deleteIcon } from "../../blocks/notifications/src/assets";
import Sidebar from "./Sidebar.web";
import { removeStorageData } from "../../framework/src/Utilities";
import Badge from '@material-ui/core/Badge';

var host = require('../../framework/src/config')

interface AppHeaderProps extends RouteComponentProps {
  history: any,
  title: string,
  fetchNotifications?:number
}

interface AppState {
  open: boolean;
  profileImage: string;
  role: string;
  openModal:boolean;
  activeButton: number | null;
  isNotificationOpen: boolean;
  anchorNotification: null | HTMLElement
  isSmallScreen: boolean;
  allNotifIDs:number[];
  notificationData:any[]
}

const theme = createTheme({
  typography: {
    h1: {
      fontSize: "5rem",
      "@media (max-width:600px)": {
        fontSize: "3rem"
      }
    },
   }
});

const gradientBackground = "linear-gradient(to right bottom, #2B3D7D, #39C6B3)";
type TextTransformProperty = "capitalize"

class AppHeader extends Component<AppHeaderProps, AppState>{
  constructor(props: AppHeaderProps) {
    super(props);
    this.state = {
      open: false,
      profileImage: "",
      role: "",
      openModal:false,
      activeButton: null,
      isNotificationOpen: false,
      anchorNotification: null,
      isSmallScreen: window.innerWidth <= 600,
      allNotifIDs: [],
      notificationData:[]
    };
  }

  handleProfileClick = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleLogOut = () => {
    window.localStorage.removeItem("authToken");
    window.localStorage.removeItem("id")
    window.localStorage.removeItem("classId")
    window.localStorage.removeItem("reportTypeSummary")
    window.localStorage.removeItem("isShare")
    window.localStorage.removeItem("sharedId")
    window.localStorage.removeItem("item")
    window.localStorage.removeItem("studentLibraryClassId")
    window.localStorage.removeItem("studentLibraryPracticeType")
    window.localStorage.removeItem("teacherLibraryClassId")
    window.localStorage.removeItem("teacherLibraryPracticeType")
    window.localStorage.removeItem("searchInputClassName")
    removeStorageData("shareRoute")
    this.props.history.push("/login")
  };
  
  handleCloseModal=()=>{
    this.setState({ openModal: false });
  }

  handleOpenModal=()=>{
    this.setState({ openModal: true });
  }

  handleCloseNotification = () => {
    this.setState({ isNotificationOpen: false });
  }

  handleOpenNotification = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ isNotificationOpen: true, anchorNotification: event.currentTarget });
  }

  getLoggedInUser = () => {
    const authToken = localStorage.getItem('authToken');
    const headers = new Headers();
    headers.append('token', authToken!);

    fetch(`${host.baseURL}/account_block/accounts/logged_user`, {
      method: 'get',
      headers: headers
    })
      .then(response => response.json())
      .then(resData => {
        window.localStorage.setItem("fullName", resData.data.attributes.full_name);
      window.localStorage.setItem("id",resData.data.id)

        this.setState({ profileImage: resData.data.attributes.image, role: resData.data.attributes.role, })
      });
      
  };
  getNotifications = () => {
    const authToken = localStorage.getItem('authToken');
    const headers = new Headers();
    headers.append('token', authToken!); 
  
    fetch(`${host.baseURL}/bx_block_notifications/notifications`, {
      method: 'GET', 
      headers: headers,
    })
      .then(response => response.json())
      .then(apiResponse => {
        this.setState({notificationData:apiResponse.data ?? []})
      })
      .catch(error => {
        console.error("Error fetching notifications:", error);
      });
  }
  markAsRead = (id:number) => {
    const authToken = localStorage.getItem('authToken');
    const headers = new Headers();
    headers.append('Content-Type','application/json');    
    headers.append('token', authToken!); 
  
    fetch(`${host.baseURL}/bx_block_notifications/notifications/${id}`, {
      method: 'PUT', 
      headers: headers
    })
      .then(response => response.json())
      .then(apiResponse => {
        this.getNotifications()
      })
      .catch(error => {
        console.error("Error marking notification as read:", error);
      });
  };

  deleteNotifications = (id:number) => {
    const authToken = localStorage.getItem('authToken');
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('token', authToken!); 
  
    fetch(`${host.baseURL}/bx_block_notifications/notifications/${id}`, {
      method: 'DELETE', 
      headers: headers
    })
      .then(response => response.json())
      .then(apiResponse => {
        this.getNotifications()
      })
      .catch(error => {
        console.error("Error deleting notification:", error);
      });
  };

  deleteAllNotifications = () => {
    const authToken = localStorage.getItem('authToken');
    const notificationIDs = this.state.allNotifIDs.join(','); 
    const url = `${host.baseURL}/bx_block_notifications/notifications/clear_all_notification[${notificationIDs}]`;
  
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('token', authToken!); 
  
    fetch(url, {
      method: 'DELETE',
      headers: headers
    })
      .then(response => response.json())
      .then(apiResponse => {
        if (apiResponse.message === 'All Notifications Deleted Successfully') {
          this.getNotifications();
        }
      })
      .catch(error => {
        console.error("Error deleting all notifications:", error);
      });
  };
  markAllAsRead = () => {
    const authToken = localStorage.getItem('authToken');
    const url = `${host.baseURL}/bx_block_notifications/notifications/mark_read_all_notification`;
  
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('token', authToken!); 
  
    fetch(url, {
      method: 'PUT', 
      headers: headers
    })
      .then(response => response.json())
      .then(apiResponse => {
          this.getNotifications();
      })
      .catch(error => {
        console.error("Error deleting all notifications:", error);
      });
  };
  
  


  handleButtonHover = (buttonId: number | null) => {
    this.setState({ activeButton: buttonId });
  }  

  componentDidMount(): void {
    this.getLoggedInUser()
    this.getNotifications()
    window.addEventListener('resize', this.handleResize);

  }

  componentDidUpdate(prevProps: Readonly<AppHeaderProps>, prevState: Readonly<AppState>, snapshot?: any): void {
    if(prevProps.fetchNotifications != this.props.fetchNotifications && this.props.fetchNotifications){
      this.getNotifications()
    }
  }

  handleResize = () => {
    this.setState({ isSmallScreen: window.innerWidth <= 600 });
  };


  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  
  timeSince(date: string) {
    var seconds = Math.floor(
      (new Date().valueOf() - new Date(date).valueOf()) / 1000
    );
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days ago";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hrs ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " mins ago";
    }
    return Math.floor(seconds) + " secs ago";
  }
  redirectToNotification=()=>{
   this.props.history.push('/notifications');
  }
  render() {
      const hasUnread = this.state.notificationData?.find((x:any)=>!x.attributes.is_read)!=undefined
    return (
      <ThemeProvider theme={theme}>
        <Box style={{position:'relative'}}>
        {this.state.isSmallScreen && <Sidebar />}
        </Box>
        <Box sx={{
          ...webStyles.dashboardHeader, padding: { xs: 1, md: 2 }, px: { xs: 2, md: '30px' },
          flexDirection: (this.state.isSmallScreen && this.state.role === "Teacher") ? 'row' : ((this.state.isSmallScreen && this.state.role === "Student") ? 'column' : 'row'),
        }

      }>
        <Typography
        variant="h4"
        style={{
          ...webStyles.headtext,
          marginLeft: this.state.isSmallScreen && (this.state.role == "Teacher" || this.state.role == "Sub-Admins" )? 30 : 'initial',
          fontSize: this.state.isSmallScreen  ? "20px" : "",
          textAlign:  this.state.isSmallScreen ? "start" : "initial",
        }}
        >            {this.props.title}
          </Typography>
          <Box sx={{
            ...webStyles.btnrow, justifyContent: this.state.isSmallScreen && this.state.role == "Teacher" ? 'end' : 'space-around',
            marginTop: this.state.isSmallScreen && this.state.role == "Student" ? '10px' : '',
            width: this.state.isSmallScreen && this.state.role != "Sub-Admins" ? 'inherit' : ''
          }}>
            {this.state.role == "Student" &&
              <Typography variant="h4" component="h4" style={webStyles.jointext} onClick={this.handleOpenModal}>
                Join Live Session
              </Typography>}
            <button style={webStyles.notification} id="demo-positioned-button">
              
              <Badge 
                color="primary"
                variant="dot"
                overlap="circle"
                anchorOrigin={{
                  horizontal: "left",
                  vertical: "top",
                }}
                component="span" 
                classes={{anchorOriginTopLeftCircle:`${hasUnread?"customDotNotification visibleDot":"customDotNotification"}`}}
                
              >
                <img src={notification} alt="Notification" onClick={this.handleOpenNotification} />
              </Badge>
            </button>
            <button
              data-test-id="btnProfile"
              style={webStyles.notification}
              onClick={this.handleProfileClick}
            >
              {
                this.state.profileImage ?
                  <img style={webStyles.profileImage} src={this.state.profileImage} alt="" />
                  :
                  <AccountCircleIcon style={webStyles.defaultImage} />
              }
            </button>
          </Box>
        </Box>
        <Popover
          open={this.state.open}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
        >
          <div style={webStyles.dropdownmore}>
            <button
              data-test-id="btnEditGroup"
              onMouseEnter={() => this.handleButtonHover(1)}
              onMouseLeave={() => this.handleButtonHover(null)}
              style={this.state.activeButton === 1 ? webStyles.moreBtnMenuActive : webStyles.moreBtnMenus}
              onClick={() => {
                this.handleClose();
                if (window.location.pathname.includes('/profile')) {
                  this.props.history.push("/profile/edit");
                  window.location.reload();
                }
                this.props.history.push("/profile/edit");
              }}
            >
              <img src={profileIcon} />
              Edit Profile
            </button>
            <button
              data-test-id="btnDeleteGroup"
              onMouseEnter={() => this.handleButtonHover(2)}
              onMouseLeave={() => this.handleButtonHover(null)}
              style={this.state.activeButton === 2 ? webStyles.moreBtnMenuActive : webStyles.moreBtnMenus}
              onClick={() => {
                this.handleClose();
                if (window.location.pathname.includes('/profile')) {
                  this.props.history.push("/profile/change");
                  window.location.reload();
                }
                this.props.history.push("/profile/change");
              }}
            >
              <img src={chnagePasswordIcon} />
              Change Password
            </button>
            <button
              data-test-id="btnLogout"
              onMouseEnter={() => this.handleButtonHover(3)}
              onMouseLeave={() => this.handleButtonHover(null)}
              style={this.state.activeButton === 3 ? webStyles.moreBtnMenuActive : webStyles.moreBtnMenus}
              onClick={this.handleLogOut}
            >
              <img src={imageLogoutDark} />
              Log Out
            </button>
          </div>
        </Popover>
        <Modal open={this.state.openModal} onClose={this.handleCloseModal}>
        <JoinClassModal
          data-test-id="option-modal"
          handleCloseModal={this.handleCloseModal}
          name={'Session'}
        />
      </Modal>
        <NotificationMenu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={this.state.anchorNotification}
          open={this.state.isNotificationOpen}
          onClose={this.handleCloseNotification}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          MenuListProps={{style:webStyles.notificationList}}
          PaperProps= {{style:webStyles.notificationPopover}}
        >
          <Box style={webStyles.notificationMainBox} >
            <Typography style={webStyles.notificationMainTitle}>Notifications</Typography>
            <Box style={webStyles.notificationTopRightBox}>
              <Typography style={webStyles.markAllRead} onClick={this.markAllAsRead}>Mark all as read</Typography>
              <img onClick={this.handleCloseNotification} style={webStyles.closeNotificationIcon} src={closeNotificationIcon} alt="" />
            </Box>
          </Box>
          <Box style={webStyles.notificationUlBox} >
            {
              this.state.notificationData.length > 0 && this.state.notificationData.slice(0,4).map((element, index)=> (
                <Box style={{...webStyles.notificationDetails,...(!element.attributes.is_read && webStyles.notificationUnread)}} key={index}>
                  <img  style={webStyles.notificationImg} src={notificationMockImg}  alt="No image" />
                  <Box style={webStyles.notificationDetailsBox}>
                    <Box style={webStyles.notificationDetailTitle}>
                      <Typography className="notificationTitle">{element.attributes.headings}</Typography>
                      <Typography className="notificationTime" style={{marginLeft: '10px'}}>{this.timeSince(element.attributes.created_at)}</Typography>
                    </Box>                  
                    <Typography style={webStyles.notificationDescH}>{element.attributes.contents}</Typography>
                    <Box style={webStyles.notificationBottomLink}>
                      <Typography data-test-id='btnReadUnreadNotif' onClick={()=> this.markAsRead(element.id)} style={webStyles.notificationMarkAsReadHeader}>{element.attributes.is_read ?"Mark as unread": "Mark as read"}</Typography>
                      <img data-test-id='btnDeleteNotif' onClick={()=> this.deleteNotifications(element.id)} style={webStyles.deleteIconImg} src={deleteIcon} alt="" />
                    </Box>                  
                  </Box>                                
                </Box>
              ))
            }
          </Box>
          <button style={webStyles.viewAllNotificationBtn} onClick={this.redirectToNotification}>View All Notifications</button>
        </NotificationMenu>
      </ThemeProvider>
    );
  }
}

export default withRouter(AppHeader);

const webStyles = {
  dashboardHeader: {
    boxShadow: "0px 2px 16px 2px rgba(0,0,0,0.08)",
    background: "#fff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center',
    '@media (max-width: 600px)': {
      padding: '10px',
    }
  },
  btnrow: {
    display: "flex",
    gap: "10px",

  },
  notification: {
    width: "44px",
    height: "44px",
    background: "#F2F3F7",
    borderRadius: "50px",
    overflow: "hidden",
    padding: "0",
    border: "none",
    cursor: "pointer",
    '@media (max-width: 600px)': {
      width: "36px",
      height: "36px",
    }
  },
  headtext: {
    color: "#2B3D7D",
    letterSpacing: "0.32px",
    margin: 0,
    fontFamily: "Poppins",
    textTransform: 'capitalize' as TextTransformProperty,
    fontWeight: 600,
    '@media (max-width: 600px)': {
      marginLeft:30
    }
  },
  jointext: {
    fontSize: "20px",
    color: "#2B3D7D",
    letterSpacing: "0.32px",
    margin: 0,
    cursor:"pointer",
    marginTop:"10px",
    fontFamily: "Poppins",
    textTransform: 'capitalize' as TextTransformProperty,
    fontWeight: 600,
    '@media (max-width: 600px)': {
      fontSize: "16px",
    }
  },
  dropdownmore: {
    display: "block",
    gap: "10px",
    borderRadius: "6px",
    padding: "15px 0",
    width: "max-content",
    '@media (max-width: 600px)': {
      width: "100%",
    }
  },
  moreBtnMenus: {
    fontSize: '16px',
    color: '#1c1c1e',
    fontWeight: 400,
    display: 'flex',
    gap: '10px',
    backgroundColor: '#fff',
    border: 'none',
    width: '100%',
    marginBottom: '0px',
    padding: '12px',
    cursor: 'pointer',
    '@media (max-width: 600px)': {
      fontSize: '14px',
      padding: '10px',
    }
  },
  moreBtnMenuActive: {
    background: gradientBackground,
    fontSize: '16px',
    color: '#fff',
    fontWeight: 400,
    display: 'flex',
    gap: '10px',
    height: "45px",
    alignItems: 'center',
    border: 'none',
    width: '100%',
    marginBottom: '0px',
    padding: '12px',
    cursor: 'pointer',
    '@media (max-width: 600px)': {
      fontSize: '14px',
      padding: '10px',
    }
  },
  profileImage: {
    width: "100%",
    '@media (max-width: 600px)': {
      width: "36px",
      height: "36px",
    }
  },
  defaultImage: {
    width: "44px",
    height: "44px",
    color: "gray",
    '@media (max-width: 600px)': {
      width: "36px",
      height: "36px",
    }
  },
  notificationMainBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '15px',
  },
  notificationMainTitle: {
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '27px',
    color: '#282829',
    '@media (max-width: 600px)': {
      fontSize: '16px',
    }
  },
  notificationTopRightBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      width: '100%',
      marginTop: '10px',
    }
  },
  markAllRead: {
    marginRight: '12px',
    marginLeft: '8px',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21px',
    color: '#2B3D7D',
    textDecoration: 'underline',
    "text-underline-offset": '2px',
    cursor: 'pointer',
    '@media (max-width: 600px)': {
      fontSize: '12px',
    }
  },
  closeNotificationIcon: {
    cursor: 'pointer',
  },
  notificationDetails: {
    display: 'flex',
    boxSizing: 'border-box' as 'border-box',
    padding: '16px 28px',
    marginBottom: '1px',
    position: 'relative' as 'relative',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    }
  },
  notificationUlBox: {
    maxHeight: "calc(100vh - 175px)",
    overflowY:"auto" as "auto"
  },
  notificationImg: {
    width: '80px',
    height: '80px',
    marginRight: '14px',
    borderRadius: '4px',
    boxShadow: '0px 1px 3px 0px #00000014',
    '@media (max-width: 600px)': {
      width: '60px',
      height: '60px',
    }
  },
  notificationDetailsBox: {
    flex: 1,
  },
  notificationUnread: {
    backgroundColor: '#2B3D7D0F',
  },
  notificationDescH: {
    color: '#282829',
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'Poppins',
    lineHeight: '16px',
    marginTop: '8px',
    'max-width': '253px',
    'overflow': 'hidden',
    'word-wrap': 'break-word',
    'white-space': 'normal',
    '@media (max-width: 600px)': {
      fontSize: '10px',
    }
  },
  notificationMarkAsReadHeader: {
    fontFamily: 'Poppins',
    lineHeight: '16px',
    fontWeight: 400,
    fontSize: '12px',
    color: '#2B3D7D',
    textDecoration: 'underline',
    "text-underline-offset": '2px',
    marginTop: '8px',
    cursor: 'pointer',
    '@media (max-width: 600px)': {
      fontSize: '10px',
    }
  },
 
  deleteIconImg: {
    cursor: 'pointer',
    marginLeft: "10px",
  },
  viewAllNotificationBtn: {
    display: 'block',
    height: '48px',
    margin: '16px auto 20px',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '16px',
    color: '#2B3D7D',
    border: '1px solid #2B3D7D',
    background: 'none',
    borderRadius: '8px',
    '@media (max-width: 600px)': {
      width: '100%',
      fontSize: '14px',
    },
    cursor:"pointer"
  },
  notificationDetailTitle: {
    display: "flex",
    justifyContent: "space-between"
  },
  notificationBottomLink: {
    display: "flex",
    justifyContent: "space-between"
  },
  notificationTitle:{
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '16px',
    color: '#282829',
    flex: 1,
    marginRight: "10px",
    '@media (max-width: 600px)': {
      fontSize: '14px',
    }
  },
  notificationTime:{
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    color: '#282829',
    position: 'absolute' as 'absolute',
    right: 28,
    '@media (max-width: 600px)': {
      fontSize: '10px',
      right:10
    }
  },
  notificationList:{
    padding: '0',
    width: "100%",
  },
  notificationPopover: {
    overflow: "hidden",
    maxHeight: "unset",
  }
};


const NotificationMenu = styled(Menu)(({ theme }) => ({
  "& .MuiList-root": {
    width: '436px',
    '@media (max-width: 600px)': {
    width: '300px', 
      padding: '10px',
    }
  },
  "& .MuiPaper-rounded": {
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px', 
    },
  },
  "& .MuiList-padding": {
    

  },
}));
