import {
  Box,
  ListItemIcon,
  ListItem,
  List,
  Modal,
  IconButton,
  Drawer
} from "@material-ui/core";
import {
  accountsIcon,
  accountsIconActive,
  calenderIcon,
  calenderIconActive,
  dashboardIconActive,
  libraryIcon,
  peopleManagementIcon,
  reportsIcon,
  reportsActiveIcon,
  userGroupsIcon,
  profileSettingActive,
  profileSettingInactive,
  userGroupsIconActive,
  peopleManagementActive,
  supportInactive,
  userLibraryActive,
  join_live_class,
  join_live_class_active,
  taskIcon,
  taskActiveIcon,
  AssignTaskIcon,
  mydownloads,
  downloadIconInactive,
  AssignTaskDeActive
} from "./assets";
import { logoMiniIcon } from "../../blocks/dashboard/src/assets";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import {Roles} from "./types"
import JoinClassModal from "./JoinClassModal.web";
import MenuIcon from "@material-ui/icons/Menu";
import { setStorageData } from "../../framework/src/Utilities";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
const gradientBackground = "linear-gradient(rgb(43, 61, 125) 60%, rgb(29 105 95) 100%)";
const useStyles = makeStyles((theme) => ({
  asidemenu: {
    background: gradientBackground,
    height: "100%",
    top: "0px",
    left: "0px",
    width: "100%",
    overflowX: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: "200px",
    }
  },
  logo: {
    maxWidth: "100%",
  },
  listul: {
    width: "85%",
    [theme.breakpoints.down('lg')]: {
      width: "95%",
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    }
  },
  listitem: {
    color: "#fff",
    fontSize: "16px",
    fontFamily: "poppins",
    fontWeight: 500,
    letterSpacing: "0.16px",
    lineHeight: "28px",
  },
  active: {
    backgroundColor: '#fff !important',
    borderRadius: '0 10px 10px 0',
    color: '#2B3D7D',
    fontSize:"16px",
    fontFamily:"poppins",
    fontWeight:500,
    letterSpacing:"0.16px",
    lineHeight:"28px",
  },
  activeIcon: {
    color: "#2B3D7D",
    minWidth: "40px",
    [theme.breakpoints.down('lg')]: {
      minWidth: "30px",
    },
  },
  listicon: {
    color: "#fff",
    minWidth: "40px",
    [theme.breakpoints.down('lg')]: {
      minWidth: "30px",
    },
  },
  link:{
    textDecoration:"none",
  },
  listText: {
    fontSize: '16px',
    fontFamily: "'Poppins' !important",
    fontWeight: 500,
    letterSpacing: '0.16px',
    lineHeight: '28px',
    margin: '4px 0'
  },
  iconOnly: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    }
  },
  fullSidebar: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  menuButton: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      position: 'absolute',
      top: '8px',
      left: '10px',
    }
  }
}));



const Sidebar = (props:any) => {
  const classes = useStyles();
  const [role, setRole] = React.useState<any>(window.localStorage.getItem("role"));
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isJoinSuccessful, setIsJoinSuccessful] = React.useState(false);
  const alreadyInside = "You are already in this class"

  const handleJoinClassResponse = (response: any) => {
    if (!response.error && response.message !== alreadyInside) {
      setIsJoinSuccessful(true);
  } else {
      setIsJoinSuccessful(false);
  }
};

  let tabs:any[]=[];
  if(role===Roles.SUB_ADMIN){
    tabs = [
      { name: "Dashboard", icon: reportsIcon, activeIcon: dashboardIconActive ,  path:"/dashboard"},
      { name: "Accounts", icon: accountsIcon, activeIcon: accountsIconActive ,path:"/accountgroups"},
      { name: "Calendar", icon: calenderIcon, activeIcon: calenderIconActive , path:"/teachers"},
      { name: "Library", icon: libraryIcon, activeIcon: userLibraryActive ,path:"/library"},
      { name: "Reports", icon: reportsIcon, activeIcon: reportsActiveIcon ,path:"/reports"},
      { name: "User Groups", icon: userGroupsIcon, activeIcon: userGroupsIconActive,path:"/usergroups" },
      { name: "People Management", icon: peopleManagementIcon, activeIcon: peopleManagementActive,path:"/peoplemanagement" },
      { name: "Profile Settings", icon: profileSettingInactive, activeIcon: profileSettingActive,path:"/profile" },
      { name: "Feedback", icon: profileSettingInactive, activeIcon: profileSettingActive,path:"/feedback" },
    ];
  }else if(role===Roles.TEACHER){
    tabs = [
      { name: "Dashboard", icon: reportsIcon, activeIcon: dashboardIconActive ,  path:"/dashboard"},
      { name: "My Classes", icon: accountsIcon, activeIcon: accountsIconActive ,path:"/classes/myclasses"},
      { name: "My Calendar", icon: calenderIcon, activeIcon: calenderIconActive , path:"/teachers-calendar"},
      { name: "Assignments", icon: AssignTaskDeActive, activeIcon: AssignTaskIcon , path:"/assigntasks"},
      { name: "Library", icon: libraryIcon, activeIcon: userLibraryActive ,path:"/teacherlibrary"},
      // { name: "My Downloads", icon: downloadIconInactive, activeIcon: mydownloads ,path:"/mydownloads"},
      // { name: "Reports", icon: reportsIcon, activeIcon: reportsActiveIcon ,path:"/reports"},
      { name: "User Groups", icon: userGroupsIcon, activeIcon: userGroupsIconActive,path:"/usergroups" },
      { name: "People Management", icon: peopleManagementIcon, activeIcon: peopleManagementActive,path:"/peoplemanagement" },
      { name: "My Profile", icon: profileSettingInactive, activeIcon: profileSettingActive,path:"/profile" },
      { name: "Feedback", icon: profileSettingInactive, activeIcon: profileSettingActive,path:"/feedback" },
    ];
  }
  else if(role===Roles.STUDENT){
    tabs = [
      { name: "Dashboard", icon: reportsIcon, activeIcon: dashboardIconActive ,  path:"/dashboard"},
      { name: "Join Class", icon: join_live_class, activeIcon: join_live_class_active ,path:""},
      { name: "My Classes", icon: accountsIcon, activeIcon: accountsIconActive ,path:"/classes/myclasses"},
      { name: "My Tasks", icon: taskIcon, activeIcon: taskActiveIcon ,path:"/task"},
      { name: "My Calendar", icon: calenderIcon, activeIcon: calenderIconActive , path:"/students-calender"},
      { name: "Library", icon: libraryIcon, activeIcon: userLibraryActive ,path:"/studentlibrary"},
      // { name: "My Downloads", icon: downloadIconInactive, activeIcon: mydownloads ,path:"/mydownloads"},
      { name: "My Profile", icon: profileSettingInactive, activeIcon: profileSettingActive,path:"/profile" },
      { name: "Feedback", icon: profileSettingInactive, activeIcon: profileSettingActive,path:"/feedback" },
    ];
  }
  const currentLocation = window.location.pathname.split("/")
  const currentPath= currentLocation.slice(1)[0];
  const currentFullPath= currentLocation.slice(1).join("/");
  const calendarTab:any = tabs.find(tab => tab.path === "/"+currentPath);
  let renderActiveTab:string="";
  const handleInitialActiveTab=()=>{
    if(currentPath==="course"){
      renderActiveTab=tabs[0]?.name
    }else if ((currentPath === "library" || currentPath === "TeacherLibrary") && role === Roles.TEACHER) {
        renderActiveTab=tabs[4]?.name
    }
    return renderActiveTab;
  }
  const initialActiveTab = handleInitialActiveTab() ? renderActiveTab : calendarTab?.name || (role === Roles.STUDENT ? tabs[2]?.name : tabs[1]?.name);
  const [activeTab, setActiveTab] = React.useState(initialActiveTab);
  const [openModal, setOpenModal] = React.useState(false);
  
  const handleCloseModal = () => {
    if (isJoinSuccessful) {
      window.location.href = '/dashboard';
    }
    setActiveTab("Dashboard")
    setOpenModal(false)
    setIsJoinSuccessful(false);
  }
  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

const navigateToDashboard = () => {
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
}

  return (
    <>
    <Box style={{position:'relative'}}>
    <IconButton
      color="inherit"
      aria-label="open drawer"
      edge="start"
      onClick={handleDrawerToggle}
      className={classes.menuButton}
      >
      <MenuIcon />
    </IconButton>
    </Box>
    <Drawer
      variant="temporary"
      anchor="left"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      classes={{
        paper: classes.asidemenu,
      }}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Box className={classes.asidemenu}>
        <Box paddingX={2} paddingY={4}>
          <img src={logoMiniIcon} className={classes.logo} />
        </Box>
        <div className="sidebar">
          <List className={classes.listul}>
            {tabs.map((tab) => (
              <React.Fragment key={tab.name}>
                {tab.name.includes("Join") ?
                  <ListItem
                    button
                    className={
                      activeTab === tab.name ? classes.active : classes.listitem
                    }
                    onClick={() => {
                      setActiveTab(tab.name);
                      handleOpenModal();
                    }}
                  >
                    <ListItemIcon className={
                          activeTab === tab.name
                            ? classes.activeIcon
                            : classes.listicon
                        }>
                      <img src={activeTab === tab.name ? tab.activeIcon : tab.icon} />
                    </ListItemIcon>
                    <p className={classes.listText}>{tab.name}</p>
                  </ListItem> :
                  <Link className={classes.link} to={tab.path}>
                    <ListItem
                      button
                      className={
                        activeTab === tab.name ? classes.active : classes.listitem
                      }
                      onClick={() => {
                        if (activeTab === tab.name) {
                          window.location.reload();
                        }
                        setActiveTab(tab.name);
                        setMobileOpen(false);
                      }}
                      >
                      <ListItemIcon
                        className={
                          activeTab === tab.name
                            ? classes.activeIcon
                            : classes.listicon
                        }
                      >
                        <img
                          src={activeTab === tab.name ? tab.activeIcon : tab.icon}
                        />
                      </ListItemIcon>
                      <p className={classes.listText}>{tab.name}</p>
                    </ListItem>
                  </Link>}
              </React.Fragment>
            ))}
          </List>
        </div>
      </Box>
    </Drawer>
    <Box className={`${classes.asidemenu} ${classes.fullSidebar}`}>
      <Box paddingX={2} paddingY={4}>
        <img src={logoMiniIcon} className={classes.logo} />
      </Box>
      <div className="sidebar">
        <List className={classes.listul}>
          {tabs.map((tab) => (
            <React.Fragment key={tab.name}>
              {tab.name.includes("Join") ?
                <ListItem
                  button
                  className={
                    activeTab === tab.name ? classes.active : classes.listitem
                  }
                  onClick={() => {
                    setActiveTab(tab.name);
                    handleOpenModal();
                  }}
                >
                  <ListItemIcon className={
                          activeTab === tab.name
                            ? classes.activeIcon
                            : classes.listicon
                        }>
                    <img src={activeTab === tab.name ? tab.activeIcon : tab.icon} />
                  </ListItemIcon>
                  <p className={classes.listText}>{tab.name}</p>
                </ListItem> :
                <Link className={classes.link} to={tab.path}>
                  <ListItem
                    button
                    className={
                      activeTab === tab.name ? classes.active : classes.listitem
                    }
                    onClick={() => {
                      if(tab.name === 'People Management'){
                        localStorage.setItem('isFromDashboard', JSON.stringify(false))
                      }
                      if(tab.name === 'My Tasks'){
                        localStorage.setItem('studentTaskListTab', 'assignment')
                      }
                      if(tab.name === 'Assign Tasks'){
                        setStorageData('assignTaskMainTab', '0');
                        setStorageData('assignTaskTab', '0');
                      }
                      if(tab.name === 'Library' || tab.name === 'Reports'){
                        window.localStorage.removeItem('cameFromDashboard')
                      }
                      if(tab.name==='Dashboard'){
                        navigateToDashboard()
                      }else if (activeTab === tab.name) {
                        window.location.reload();
                      }
                      setActiveTab(tab.name);
                    }}
                  >
                    <ListItemIcon
                      className={
                        activeTab === tab.name
                          ? classes.activeIcon
                          : classes.listicon
                      }
                    >
                      <img
                        src={activeTab === tab.name ? tab.activeIcon : tab.icon}
                      />
                    </ListItemIcon>
                    <p className={classes.listText}>{tab.name}</p>
                  </ListItem>
                </Link>}
            </React.Fragment>
          ))}
        </List>
      </div>
    </Box>
    <Modal open={openModal} onClose={handleCloseModal}>
      <JoinClassModal
        data-test-id="option-modal"
        handleCloseModal={handleCloseModal}
        name={'Class'}
        onJoinResponse={handleJoinClassResponse}
      />
    </Modal>
  </>
  );
};

export default Sidebar;
