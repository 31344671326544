import React from "react";
import { Box, Grid, useMediaQuery } from "@material-ui/core";
import GenericStaticCard from "../../../../../components/src/GenericStaticCard.web";
import { MY_CLASSES_VIEWS } from "../../../../../components/src/types";
import {
  studentsOption,
  assignmentsOption,
  classLibraryOption,
  quizzes,
} from "../../assets";
import CustomBreadcrumb from "../../../../../components/src/CustomBreadcrumb.web";
import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  options: {
    [theme.breakpoints.down('xs')]: {
      display: "flex",
      alignContent : "center",
      justifyContent: "center",
      flexDirection: "column",
      gap: "20px",
      width: "100%"
    },
  }
}));

const Options = [
  {
    label: "Previous Lessons",
    image: classLibraryOption,
  },
  {
    label: "Previous Quizzes",
    image: quizzes,
  },
  {
    label: "Assignments",
    image: assignmentsOption,
  },
  {
    label: "Join Live Session",
    image: studentsOption,
  },
];
const ClassDetails = (props: any) => {
  const { destination, locations, selectedClass, showStudentListView, showStudentLessonListView, redirect, handleParamsNavigation } = props;
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const classes = useStyles();

  const handleClick = (label: string) => {
    if (label === "Join Live Session") {
      showStudentLessonListView({
        view: MY_CLASSES_VIEWS.CLASS_DETAILS,
        breadcrumbDisplayName: destination,
        destination: label
      });
    }
    else if(label === "Assignments") {      
      handleParamsNavigation("StudentLibraryPractice", {
        type: "Assignments",
        id: selectedClass
      });
    }
    else if(label === "Previous Quizzes"){
      handleParamsNavigation("StudentLibraryPractice", {
        type: "Quizzes",
        id: selectedClass
      });
    }
    else if(label === "Previous Lessons") { 
      handleParamsNavigation("StudentLibraryPractice", {
        type: "Lessons",
        id: selectedClass
      });
    }
    else {
      showStudentListView({
        breadcrumbDisplayName: destination,
        view: MY_CLASSES_VIEWS.CLASS_DETAILS,
        destination: label,
      });
    }
  };

  return (
    <Box sx={{ px: "35px" }}>
      <Box sx={{ mt: "30px" }}>
        <CustomBreadcrumb
          locations={locations}
          destination={destination}
          redirect={redirect}
        />
        <></>
      </Box>
      <Box sx={{ mt: "30px" }}>
        <Grid container={isSmallScreen ? false : true} spacing={4} className={classes.options}>
          {Options.map((option) => (
            <Grid item key={option.label}>
              <GenericStaticCard
                label={option.label}
                image={option.image}
                data-test-id={`card${option.label}`}
                handleClick={() => {
                  handleClick(option.label);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default ClassDetails;
