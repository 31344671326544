import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import ArrayDisplayField from "../SchoolAdmin/ArrayDisplayField.web";
import TeacherCustomListEllipses from "./TeacherCustomListEllipses.web";

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#FDF4DC",
    color: "#202020",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
  },
  body: {
    fontSize: "14px",
    color: "#3F526D",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({}))(TableRow);
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function StudentTable(props: any) {
  const { 
    students, 
    pageNo, 
    deleteStudentProfileApi,
    isDeleteSuccess,
    handleCloseDeleteSuccess,
    handleOpenDeleteSuccessModal,
    handleOpenDeleteProfileModal,
    handleCloseDeleteProfileModal,
    openDeleteStudentModal,
    showAddStudentForm 
  } = props;
  const classes = useStyles();
  const [openContextMenu, setOpenContextMenu] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [contextMenuUserData, setContextMenuUserData] = React.useState<any>({});
  const [visiblePasswords, setVisiblePasswords] = React.useState<any>({});
  const [viewDetailModal,setViewDetailModal] = React.useState<boolean>(false);

  const handleOpenContextMenuList = (
    event: any,
    id: string, 
    name: string, 
    studentData:any
    ) => {
    event.preventDefault();
    setContextMenuUserData({
      id,
      name,
      pageNo,
      studentData
    });
    setAnchorEl(event.currentTarget)
    setOpenContextMenu(true)
  }
  const handleCloseContextMenuList = (event: any) => {
    event.preventDefault()
    setAnchorEl(null)
    setOpenContextMenu(false)
  }
  const handleOpenViewDetailModal=()=>{
    setViewDetailModal(true)
  }
  const handleCloseViewDetailModal=()=>{
    setViewDetailModal(false)
  }
  const togglePasswordVisibility = (studentId:any) => {
    setVisiblePasswords((prevVisiblePasswords:any) => ({
      ...prevVisiblePasswords,
      [studentId]: !prevVisiblePasswords[studentId],
    }));
  };
  
  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">S.No.</StyledTableCell>
              <StyledTableCell align="center">Members</StyledTableCell>
              <StyledTableCell align="center">Class</StyledTableCell>
              <StyledTableCell align="center">Student Email ID</StyledTableCell>
              <StyledTableCell align="center">Password</StyledTableCell>
              <StyledTableCell align="center">Date Created</StyledTableCell>
              <StyledTableCell align="center">Profile Status</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.map((data: any, index: number) => (
              <StyledTableRow key={data.id}>
                <StyledTableCell align="center">
                  {(pageNo - 1) * 10 + index + 1}
                </StyledTableCell>
                <StyledTableCell align="center">{data.fullName}</StyledTableCell>
                <StyledTableCell align="center">
                  <ArrayDisplayField data={data.classes.map((item: any) => item.name)} />
                </StyledTableCell>
                <StyledTableCell align="center">{data.email}</StyledTableCell>
                <StyledTableCell align="center">
                {visiblePasswords[data.id]  ? data.password : `**********`}
                </StyledTableCell>
                <StyledTableCell align="center">{data.dateCreated}</StyledTableCell>
                <StyledTableCell align="center">{data.profileStatus}</StyledTableCell>
                <StyledTableCell align="center">
                  <MoreVertIcon
                    style={{cursor:"pointer"}}
                    data-test-id="open-action"
                    onClick={(e) =>
                      handleOpenContextMenuList(
                        e, data.id, data.fullName, data)
                      }
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TeacherCustomListEllipses
        data-test-id="action-menu"
        anchorEl={anchorEl}
        openContextMenu={openContextMenu}
        handleCloseContextMenu={handleCloseContextMenuList}
        contextMenuUserData={contextMenuUserData}
        handleOpenViewDetailModal={handleOpenViewDetailModal}
        handleCloseViewDetailModal={handleCloseViewDetailModal}
        viewDetailModal={viewDetailModal}
        deleteStudentProfileApi={deleteStudentProfileApi}
        isDeleteSuccess={isDeleteSuccess}
        handleCloseDeleteSuccess={handleCloseDeleteSuccess}
        handleOpenDeleteSuccessModal={handleOpenDeleteSuccessModal}
        handleOpenDeleteProfileModal={handleOpenDeleteProfileModal}
        handleCloseDeleteProfileModal={handleCloseDeleteProfileModal}
        openDeleteStudentModal={openDeleteStudentModal}
        showAddStudentForm={showAddStudentForm}
        togglePasswordVisibility={togglePasswordVisibility}
      />
    </>
  );
}
