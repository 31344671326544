Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ContentManagement";
exports.labelBodyText = "ContentManagement Body";
exports.GET = "GET";
exports.POST = "POST";
exports.DELETE = "DELETE";
exports.PUT = "PUT";
exports.PATCH = "PATCH";
exports.btnExampleTitle = "CLICK ME";
exports.getClassList = "bx_block_content_management/teacher_flows/current_user_classes_list"
exports.createClass = "bx_block_content_management/teacher_flows"
exports.getStudentListByClass = "bx_block_content_management/teacher_flows/get_students_with_class"
exports.createStudent = "account_block/add_users/create_student_by_teacher"
exports.viewStudent = "account_block/accounts/show"
exports.deleteStudent = "account_block/add_users/delete_account"
exports.sendReminder = "bx_block_notifications/notifications"
exports.updateStudent = "account_block/accounts/update_student_by_teacher"
exports.getLessonListByClass="bx_block_content_management/teacher_flows/teacher_lesson_index"
exports.getStudentLessonListByClass="bx_block_content_management/lessons/student_lesson_library_list"
exports.formErrors={
  fullName:"Please enter full name.",
  email:"Please enter a valid email ID.",
  phone:"Please enter phone number.",
  class:"Please select class.",
  fieldPassword:`Please enter a valid password.<br/>Your password must Include at least :<br/>- 8 characters length<br/>- One upper case character (A-Z)<br/>- One lower case character (a-z)<br/>- One digit (0-9)<br/>- One Symbol/Special character (!,#,$,etc)`,
  confirmPassword:"The password and confirm password do not match.",
  currentPassword:"The current password should not be empty if you are trying to change the password.",
  profileStatus:"Please select profile status."
}
exports.fieldPasswordMatched="The password and confirm password matched successfully."
exports.profileCreatedTitle="New student successfully added!"
exports.profileUpdatedTitle="Changes Saved Successfully!"
exports.reminderSentTitle="Reminder Sent Successfully!"
exports.deleteSuccessTitle="Profile Deleted Successfully!"
exports.getStudentClassList = "bx_block_peoplemanagement2/people_managements/student_classes_list"
exports.updateLiveLession = "bx_block_cfcoursecreation23/lesson_markups/update_live_lesson"
// exports.fetchAssignmentList="bx_block_content_management/assignments/list_of_assignments"
exports.fetchAssignmentList="bx_block_content_management/assignments/assignment_folder_list"
exports.fetchQuizList="bx_block_content_management/teacher_flows/list_of_quizzes"
exports.updateQuiz = "bx_block_questionbank/quiz_markups/update_live_quiz"
exports.deleteClassApi = "/bx_block_content_management/teacher_flows/delete_class"
exports.deliver = "Deliver Lesson"

// Customizable Area End