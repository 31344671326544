import React from "react";
import { fabric } from 'fabric';
import { AlignLeft , View_Polygon, Textlabel, AlignRight,AlignCenter , View_arrow, View_attachment, View_delete, View_duplicate, View_highlight, View_line,
  View_Oval,View_pen,  ViewTextTool, View_redo, View_undo, View_rounded_Rectangle,View_shape, View_Star, View_Triangle ,whitePen, swapIcon,swapIconActive} from "../assets"
  import { Select, MenuItem, FormControl } from "@material-ui/core";
  import { SketchPicker } from "react-color";

  import CfCourseCreation23Controller from "../CfCourseCreation23Controller";
  let WebFont = require('webfontloader');
class DrawingCanvas  extends CfCourseCreation23Controller {
   canvasRef: React.RefObject<HTMLCanvasElement> = React.createRef();
   canvas: fabric.Canvas | null = null;
   sketchRef: any = React.createRef()
   

  
   async componentDidMount() {
     if (this.canvasRef?.current) {
      fabric.Object.prototype.controls.mtr.y = 0
      fabric.Object.prototype.controls.mtr.x = -0.5
      fabric.Object.prototype.controls.mtr.offsetY = 0
      fabric.Object.prototype.controls.mtr.offsetX = -30
      this.canvas = new fabric.Canvas(this.canvasRef.current, {
        backgroundColor: "white",
        isDrawingMode: false, 
      });
      if(this.props.setCanvasObj) this.props.setCanvasObj(this.canvas);
      this.saveCanvasState()
      this.printCanvas()
      this.canvas?.on('object:modified', this.onObjectModified);
      this.canvas?.on('object:added',this.onObjectModified);
      this.canvas?.on('object:removed', this.onObjectModified);    
      this.canvas?.on('selection:created', this.onObjectSelected);
      this.canvas?.on('selection:updated', this.onObjectSelected);
      this.canvas?.on('selection:cleared', this.onObjectSelected);
      this.canvas?.on('mouse:down', this.handleMouseDown);
      document.addEventListener('mousedown', this.handleClickOutside);
      window.addEventListener('resize', this.resizeCanvas);
 
      this.resizeCanvas()
      WebFont.load({
        google: {
          families: this.allFontFamily
        }
      });

    }
  }
  handleMouseDown = () => {
    this.setState({
      colorPicker:false
    });
  };

  async componentWillUnmount() {
    this.canvas?.dispose(); 
    window.removeEventListener('resize', this.resizeCanvas);
  
  }

  resizeCanvas = () => {
    if(this.canvasRef.current && this.canvas){
      const canvasContainer = this.canvasRef.current.parentNode as HTMLDivElement;
      const canvas = this.canvas
      const objects = canvas.getObjects();
      let maxX = 0;
      let maxY = 0;

      objects.forEach((obj) => {
        const boundingRect = obj.getBoundingRect();
        maxX = Math.max(maxX, boundingRect.left + boundingRect.width);
        maxY = Math.max(maxY, boundingRect.top + boundingRect.height);
      });

      const canvasDimensions = {
        width:canvasContainer?.clientWidth,
        height:canvasContainer?.clientHeight
      }
      // Calculate the new canvas dimensions
      const newWidth = Math.max(canvasDimensions.width, maxX);
      let newHeight = Math.max(canvasDimensions.height, maxY);

      if(newHeight == canvasDimensions.height){
        newHeight = newHeight*0.95;
      }
      if (newWidth !== canvas.width || newHeight !== canvas.height) {        
        canvas.setWidth(newWidth);
        canvas.setHeight(newHeight);
        canvas.renderAll();
      }
    }
  }

  handleClickOutside = (event:any) => {
    if(this.sketchRef.current && this.sketchRef.current.focus(event.target))
    {

      this.setState({colorPicker:false})
    }

  };

  onObjectModified = () => {
    this.resizeCanvas()
    if(this.props.handleCanvas == "AddText"){
      localStorage.setItem("addTextCanvasdata",JSON.stringify(this.canvas))
      localStorage.setItem("addTextCanvasSize",JSON.stringify({height:this.canvas?.height,width:this.canvas?.width}))
    }else{
      this.props.setHasCanvasItem(true)
      localStorage.setItem("canvasdata",JSON.stringify(this.canvas))
      localStorage.setItem("canvasSize",JSON.stringify({height:this.canvas?.height,width:this.canvas?.width}))
    }
    this.saveCanvasState()
  }
  

// Text Tool
 addText = () => {
  this.setState({open: false})
const text = new fabric.Textbox("Type your text", {
left: 50,
top: 50,
width: 150,
fontSize: 20,
});

this.canvas?.add(text);
};
 addCircle = () => {
const circleData = {
radius: 30, 
fill: this.state.color, 
selectable: true,
objectCaching :true,
position: {
  x: 150, 
  y: 150 ,
},
left: 50,
top: 50,
}
const circle = new fabric.Circle(
circleData
);
this.canvas?.add(circle);

};

copyToClipboard = async () => {
  const selection = window.getSelection();
  if (!selection) return;

  const selectedText = selection.toString();

  try {
    await navigator.clipboard.writeText(selectedText);
  } catch (error) {
    console.error('Failed', error);
  }

};
// Alignment Tools
  alignLeft = () => {
    const activeObjects = this.canvas?.getActiveObjects()
    activeObjects?.forEach(activeObject => {
      if (activeObject && activeObject.type == "textbox") {
        const selectedObject = activeObject as fabric.Textbox;
        selectedObject.set("textAlign", "left");       
      }      
    });
    this.canvas?.renderAll();
    this.onObjectModified()
  };
  
  alignCenter = () => {
    const activeObjects = this.canvas?.getActiveObjects()
    activeObjects?.forEach(activeObject => {
      if (activeObject && activeObject.type == "textbox") {
        const selectedObject = activeObject as fabric.Textbox;
        selectedObject.set("textAlign", "center");       
      }      
    });
    this.canvas?.renderAll();
    this.onObjectModified()
  };
  
  
  alignRight = () => {
    const activeObjects = this.canvas?.getActiveObjects()
    activeObjects?.forEach(activeObject => {
      if (activeObject && activeObject.type == "textbox") {
        const selectedObject = activeObject as fabric.Textbox;
        selectedObject.set("textAlign", "right");       
      }      
    });
    this.canvas?.renderAll();
    this.onObjectModified()
  };

addArrow = () => {
  const line = new fabric.Line([70, 70, 100, 100], {
    stroke: this.state.color,
    strokeWidth: 2,
    selectable: true,
  });
  const angle = Math.atan2(150 - 50, 150 - 50) * (180 / Math.PI);
  const triangle = new fabric.Triangle({
    left: 106,  // Position it at the end of the line
    top: 100, 
    width: 7,  // Smaller width
    height: 7,  // Smaller height
    fill: this.state.color,
    angle: angle + 90, 
    selectable: true,
  });

  const group = new fabric.Group([line, triangle], {
    selectable: true,
  });

  this.canvas?.add(group);
};

deleteObject = () => {    
  const activeObjects = this.canvas?.getActiveObjects()
  if (activeObjects && activeObjects.length > 0) {
    activeObjects?.forEach(activeObject => {
      this.canvas?.remove(activeObject);           
    });
  }  
};

fileImageUrl = (img: any) => {
  if (!this.canvas) return;
  const maxWidth = 100; 
  const maxHeight = 100; 

  const originalWidth = img.width;
  const originalHeight = img.height;

  const scaleX = maxWidth / originalWidth;
  const scaleY = maxHeight / originalHeight;
  
  const scale = Math.min(scaleX, scaleY);
  img.set({
    left: 50, 
    top: 50, 
    scaleX: scale, 
    scaleY: scale, 
  });
  this.canvas.add(img);
}

handleInsertImage =(event: any) => {
  const target = event.target as HTMLInputElement;
  if (!target || !target.files) return; 

  const file = target.files[0];
  const reader = new FileReader();
  reader.onload = (event: ProgressEvent<FileReader>) => {
    const result = event.target?.result;
    if (!result || typeof result !== 'string') return; 

    const imgUrl = result;
    fabric.Image.fromURL(imgUrl, this.fileImageUrl);
  };
  reader.readAsDataURL(file);
};


insertImage = () => {
  const input = document.createElement('input');
  input.type = 'file';
  input.accept = 'image/*';
  input.onchange = this.handleInsertImage
  input.click();

};

// Line Tool
 addLine = () => {
  const line = new fabric.Line([70, 70, 120, 70], {
stroke: this.state.color,
strokeWidth: 2,
selectable: true,
width: 7,  // Smaller width
height: 7,
});
this.canvas?.add(line);
};
addRoundedRectangle = () => {
  const rect = new fabric.Rect({
  left: 50,
  top: 50,
  width: 50,
  height: 50,
  rx: 10, 
  ry: 10,
  fill: this.state.color,
  selectable: true,
  });
  this.canvas?.add(rect);
  };

// Add Shapes Tool
 addRectangle = () => {
const rect = new fabric.Rect({
left: 50,
top: 50,
width: 50,
height: 50,
fill: this.state.color,
selectable: true,
});
this.canvas?.add(rect);
};

// Bold Tool
 toggleBold = (event:any) => {
  const activeObjects = this.canvas?.getActiveObjects()
  activeObjects?.forEach(activeObject => {
    if (activeObject && activeObject.type == "textbox") {
      const selectedObject = activeObject as fabric.Textbox;
      selectedObject.set('fontWeight', `${event.target.value}`);        
    }      
  });
  this.canvas?.renderAll();
  this.onObjectModified() 
};

 async componentDidUpdate(PrevProps:any,prevState:any) {
   if(prevState.updatedData != this.state.updatedData){
    this.props.handleRef.current = this.canvasRef.current
    }
    if(PrevProps.canvas != this.props.canvas){
       this.printCanvas()
      this.onObjectModified()
  }
  if (this.canvasRef.current) {
    this.canvasRef.current.style.pointerEvents = this.props.isCanvasFocused ? 'auto' : 'none';
    this.canvas?.renderAll()
  }
}

 toggleHighlighter = () => {
  const activeObjects = this.canvas?.getActiveObjects()
  activeObjects?.forEach(activeObject => {
    if (activeObject && activeObject.type == "textbox") {
      const selectedObject = activeObject as fabric.Textbox;
      const selectedText = selectedObject.get('backgroundColor') === '';
      selectedObject.set('backgroundColor', selectedText ? this.state.color : '');       
    }      
  });
  this.canvas?.renderAll();
};


drawPolygon = () => {
  const pentagon = new fabric.Polygon([

{ x: 13, y: 27 },
    { x: 41, y: 55 },
    { x: 68, y: 27 },
    { x: 55, y: 2 },
    { x: 27, y: 2 },
  ], {
    fill: this.state.color,
    left: 50,
    top: 50,
    width: 50,
    height: 50,
    selectable: true
  });
  pentagon.rotate(180);
  
  this.canvas?.add(pentagon)
};

drawStar = () => {

  const star = new fabric.Polygon([
 
    { x: 37.5, y: 0 },
    { x: 48.75, y: 22.5 },
    { x: 75, y: 22.5 },
    { x: 56.25, y: 37.5 },
    { x: 63.75, y: 67.5 },
    { x: 37.5, y: 52.5 },
    { x: 11.25, y: 67.5 },
    { x: 18.75, y: 37.5 },
    { x: 0, y: 22.5 },
    { x: 26.25, y: 22.5 },
    
  ], {
    fill: this.state.color,
    left: 50,
    top: 50,
  });

  this.canvas?.add(star);
};

drawTriangle = () => {
  const triangle = new fabric.Triangle({
    width: 70,
    height: 50,
    fill: this.state.color,
    left: 50,
    top: 50,
  });

  this.canvas?.add(triangle);
};

handleChange = (event: string) => {
  this.setState({ selectedFontFamily: event });
  const activeObjects = this.canvas?.getActiveObjects()
    activeObjects?.forEach(activeObject => {
        if (activeObject && activeObject.type == "textbox") {
        (activeObject as fabric.Textbox).set("fontFamily",event)
      }      
  });
  this.canvas?.renderAll();  
  this.onObjectModified()
}

handleFontSize = (event: string) => {
  this.setState({selectedFontSize: event})
  const activeObjects = this.canvas?.getActiveObjects()
  activeObjects?.forEach(activeObject => {
    if (activeObject && activeObject.type == "textbox") {
    (activeObject as fabric.Textbox).set("fontSize",Number(event))
    }      
  });
  this.canvas?.renderAll();
  this.onObjectModified()
}


  handleAlignMent = (event:any) =>{
    this.setState({selectedAlign: event.target.value})
    let dataItem:Record<string, any>= {
  "1": this.alignLeft,
  "2": this.alignCenter,
  "3": this.alignRight,
    }
  
    const selectedFunction = dataItem[event.target.value];
  
    if (typeof selectedFunction === 'function') {
      selectedFunction();
    }
  }

   handleChange2 = (newColor:any) => {
    this.setState({color: newColor.hex});
    this.applyColor(newColor.hex);
  };

   setColorToObject = (newColor:string,objects?:fabric.Object[]) => {
    objects?.forEach(activeObject => {
      if(activeObject.fill){
        activeObject.set("fill", newColor);
      }
      if(activeObject.stroke){
        activeObject.set("stroke", newColor);
      }      
      if(activeObject.type == "group"){
        const groupObject = activeObject as fabric.Group
        this.setColorToObject(newColor,groupObject._objects)
      }
    });
   }

   applyColor = (newColor:string) => {
    const activeObjects = this.canvas?.getActiveObjects()
    this.setColorToObject(newColor,activeObjects)
    if(this.canvas) this.canvas.freeDrawingBrush.color = newColor;
    this.canvas?.renderAll();
    this.onObjectModified()
  };

  printCanvas =() => {
    this.canvas?.clear();
    if(this.props.canvas)
    {
      const parsedData:any = JSON.parse(this.props.canvas)
      if(parsedData &&  parsedData.objects ){
        parsedData.objects.forEach((objectData:any) => {
          (fabric as any)[fabric.util.string.capitalize(objectData.type,true)].fromObject(objectData, (obj:fabric.Object) => {
              this.canvas?.add(obj);
          });
        });
      }
    
    this.onObjectModified()
    this.canvas?.renderAll();
    }
  }
  activatePen = () => {
    this.setState((prevState:any)=>{
      return {
        penStop: !prevState.penStop
      }
    },()=>{
      if(this.canvas) {
        this.canvas.isDrawingMode = this.state.penStop;
        this.canvas.freeDrawingBrush.color = this.state.color;
      }
    })
  };
  

  saveCanvasState = () => {
    if(this.state.isUndoRedo) return
    const { undoStack, currentIndex } = this.state;
    const state = JSON.stringify(this.canvas?.toJSON());
    const newStack = [...undoStack.slice(0, currentIndex + 1), state];
    this.setState({ undoStack: newStack, currentIndex: currentIndex + 1 });
  };

  undo = () => {
    this.setState({ isUndoRedo:true },()=>{
      const {  undoStack, currentIndex } = this.state;
      if (currentIndex > 0 ) {
        const prevState = undoStack[currentIndex - 1];
        this.canvas?.loadFromJSON(prevState, () => {
          this.canvas?.renderAll();
          this.setState({ currentIndex: currentIndex - 1,isUndoRedo:false });
        });
      }else{
        this.props.setHasCanvasItem(false)
      }
    })
  };

  redo = () => {
    this.setState({ isUndoRedo:true },()=>{
    const {  undoStack, currentIndex } = this.state;
    if (currentIndex < undoStack.length - 1) {
      const nextState = undoStack[currentIndex + 1];
      this.canvas?.loadFromJSON(nextState, () => {
        this.canvas?.renderAll();
        this.setState({ currentIndex: currentIndex + 1,isUndoRedo:false });
      });
    }
  })
  };
  
  onObjectSelected = () => {
    const objects = this.canvas?.getActiveObjects();    
    let showTextEditor = false;
    for (let i = 0; i < (objects?.length ?? 0); i++) {
      if(objects){
        let object = objects[i];
        if (object.isType("textbox")) {
          showTextEditor = true;
          break;
        }
      }      
    }
    this.setState({
      textModal: showTextEditor,
      open: showTextEditor?false:this.state.open
    })
  }
  renderToolItem = (id:string, src:string, style:any, testId:string, onClickHandler:any) => (
    <img
      id={id}
      src={src}
      style={style}
      data-testId={testId}
      onClick={() => this.props.isCanvasFocused && onClickHandler()}
    />
  );
  render() {
    
const fontSizes= [8,9,10,11,12,14,18,24,30,36,48,60,72,96]
const fontWeights = ['normal', 'bold', 'bolder', 'lighter'];

    return (
      <>
        {this.state.colorPicker && <div style={{ position:"absolute",zIndex:99999999,top: "50px"}} ref={this.sketchRef} data-testId="refId"><SketchPicker  color={this.state.color} data-testId="colorPicker" onChange={this.handleChange2} /></div>}
        {(this.state.open &&this.props.isCanvasFocused)&& <div style={webStyle.divstyle}>
          <img src={View_shape} id="rectangleTool" style={webStyle.imgSize} data-testId="squareRectId" onClick={()=> this.addRectangle()}/>
          <img src={View_rounded_Rectangle} id="addRoundedRectangle"  data-testId = "rectId"  style={webStyle.imgSize} onClick={()=>this.addRoundedRectangle()}/>
          <img src={View_Oval} id="circleTool"  data-testId = "circleId"  style={webStyle.imgSize} onClick={()=> this.addCircle()}/>
          <img src={View_Triangle} id="triangle" data-testId = "triangleId" style={webStyle.svgSize} onClick={()=>this.drawTriangle()}/>
          <img src={View_Star} id="star" data-testId = "starId" style={webStyle.svgSize} onClick={()=>this.drawStar()}/>
          <img src={View_Polygon} id="polygon" data-testId = "polyId" style={webStyle.svgSize} onClick={()=>this.drawPolygon()}/>
        </div>}
        {this.state.textModal && <div style={webStyle.divStyle2}>
        <select value={this.state.selectedFontFamily}  data-testId = "familyId"  onChange={(event) => this.handleChange(event.target?.value)} style={{background:"white", width: "120px", border:"none",height:"36px", cursor: "pointer"}}>
          {this.allFontFamily.map((item)=><option key={item} value={item}>{item}</option>)}
        </select>
        <select value={this.state.selectedFontSize}  data-testId = "fontSizeId"  onChange={(event)=> this.handleFontSize(event.target?.value)} style={{background:"white", border:"none",height:"36px",cursor: "pointer"}}>
          {fontSizes.map((item)=>
          <option key={item} value={item}>{item}</option>
          )}
        </select>
        <Select
        data-testId="selector"
        value={this.state.selectedAlign}
        onChange={this.handleAlignMent}
        disableUnderline
      >          <MenuItem key={"alignLeft"} value={"1"}>
            <img  src={AlignLeft} />
          </MenuItem>
          <MenuItem key={"alignCenter"} value={"2"}>
            <img  src={AlignCenter} />
          </MenuItem>
          <MenuItem key={"alignRight"} value={"3"}>
            <img  src={AlignRight} />
          </MenuItem>
      </Select> 
      <Select
        data-testId="toggleBold"
        value={this.state.defaultBold}
        onChange={this.toggleBold}
        disableUnderline
      >          <MenuItem key={"1"} value={"1"}>
            <img  src={Textlabel} />
          </MenuItem>
          {
          fontWeights.map((item)=>
            <MenuItem key={item} value={item}>
           {item}
          </MenuItem>) 
          }
        
      </Select> 

      </div>}
    <div id="canvasOutDiv" style={{position:"absolute",width:"100%",height:"95%",pointerEvents:this.props.isCanvasFocused?"all":"none",overflow:"auto"}}>
      <div style={{position:"absolute",zIndex:999,pointerEvents:"all",width:"100%",backgroundColor:"#FFF",}}>
        <div style={{height:"36px",border:"0.5px solid gray" , borderRadius:"4px", display: "flex",
        gap: "21px", alignItems: "center",paddingLeft: "10px", paddingRight: "10px", width:"max-content"}}>
        <div  style={{width:"12px",height:"12px",borderRadius:"50px", background:`${this.state.color}`, border:"1px solid black", cursor:"pointer"}} data-testId="colorPickerState" onClick={()=> this.props.isCanvasFocused && this.setState((prevState:any)=>({colorPicker:!prevState.colorPicker}))}></div>
        {this.renderToolItem("textTool", ViewTextTool, webStyle.imgSize, "textToolId", this.addText)}
      {this.renderToolItem("arrowTool", View_arrow, webStyle.svgSize, "addArrow", this.addArrow)}
      {this.renderToolItem("lineTool", View_line, webStyle.svgSize, "addLineId", this.addLine)}
      {this.renderToolItem("shapeTool", View_shape, webStyle.imgSize, "addRectangleId", () => this.setState({ open: !this.state.open, textModal: false }))}
      {this.renderToolItem("penTool", this.state.penStop ? whitePen : View_pen, this.state.penStop ? webStyle.imgSize2 : webStyle.imgSize, "addPenId", this.activatePen)}
      {this.renderToolItem("highlighterTool", View_highlight, webStyle.svgSize, "highlighterId", this.toggleHighlighter)}
      {this.renderToolItem("insertImageTool", View_attachment, webStyle.imgSize, "imageId", this.insertImage)}
      {this.renderToolItem("undoTool", View_undo, webStyle.svgSize, "undoId", this.undo)}
      {this.renderToolItem("redoTool", View_redo, webStyle.imgSize, "redoId", this.redo)}
      {this.renderToolItem("deleteTool", View_delete, webStyle.svgSize, "deleteId", this.deleteObject)}
        <img src={this.props.isCanvasFocused?swapIconActive:swapIcon} style={this.props.isCanvasFocused?webStyle.canvasFoc:webStyle.canvas}id="swapTool" data-testId = "swapTool"  onClick={()=>{this.props.setCanvasFocused && this.props.setCanvasFocused(!this.props.isCanvasFocused)}}/>              </div>
      
      </div>
      <canvas ref={this.canvasRef} id="canvasId" />
      </div>
      </>
     
    );
  }
}

export default DrawingCanvas;
export const webStyle={
imgSize:
{ height:"12px",
  width:"12px",
  cursor: "pointer"
},
imgSize2:
{ height:"28px",
  width:"28px",
  cursor: "pointer"
},
svgSize : {
  height:"28px",
  width:"28px",
  cursor: "pointer"
},
divstyle: {
  width: "216px",
  height: "36px",
  border: "0.2px solid gray",
  borderRadius: "2px",
  display: "flex",
  gap: "19px",
  alignItems: "center",
  paddingLeft: "10px",
  paddingRight: "10px",
  position: "absolute" as "absolute",
  top: "-40px",
  left:"180px"
},
divStyle2:{
  width: "352px",
  height: "36px",
  border: "0.5px solid gray",
  borderRadius: "2px",
  display: "flex",
  gap: "20px",
  alignItems: "center",
  position: "absolute" as "absolute",
  zIndex: 111,
  left: '88px',
  top: "-40px",
},
canvas:{
  width:"18px"
},
canvasFoc:{
  width:"24px"
}
}
