
import React,{useEffect,useRef,useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { fabric } from "fabric";
const useStyles = makeStyles((theme) => ({
    canvasMainDiv:{
        width:"100%",
    },
    canvasFullHeight:{
      // height:"200%"
    }
}));

const StaticCanvas = (props: {canvasData:object,id:string,canvasSize?:{height:number,width:number},isFull?:boolean,callbackCanvas?:(height:number)=>void}) => {
    let canvasRef = useRef<HTMLCanvasElement>(null);
    let canvas!: fabric.StaticCanvas|undefined;
  const { canvasData,id,isFull,canvasSize } = props;
  
  const classes = useStyles();
 const [lastCanvasData,setLastCanvasData] = useState<any>(null);

  useEffect(()=>{
    if (canvasRef?.current) {
      if(lastCanvasData == null || JSON.stringify(canvasData) != JSON.stringify(lastCanvasData)){
        setLastCanvasData(canvasData)
        canvas = new fabric.StaticCanvas(canvasRef.current, {
          backgroundColor: "#f0f0f0",
          isDrawingMode: false,
          
        });
        canvas.loadFromJSON(canvasData, function() {
          if(canvasRef.current){
            const canvasContainer = canvasRef.current.parentNode as HTMLDivElement;
            const height = canvasContainer.clientHeight
            const width = canvasContainer.clientWidth
  
  
            canvas?.setWidth(width);
            canvas?.setHeight(height);
            if(props.canvasSize && !props.isFull){
  
              const scaleX = width / props.canvasSize.width;
              const scaleY = height / props.canvasSize.height;
              const scaleFactor = Math.min(scaleX, scaleY);
              // Scale and reposition all objects
              canvas?.forEachObject((obj) => {
                if(obj.left && obj.top){
                  obj.left *= scaleX;
                  obj.top *= scaleY;
                }
                if(obj.scaleX && obj.scaleY){
                  obj.scaleX *= scaleFactor;
                  obj.scaleY *= scaleFactor;
                }
                obj.setCoords();  // Update the object's coordinates
              });
            }
  
            if(props.callbackCanvas) props.callbackCanvas(height);
          }
            // Render the canvas
            canvas?.renderAll();
        })
      }
    }
  },[canvasRef?.current,canvasData])
  
  return (
    <>
     <div className={`${classes.canvasMainDiv} ${props.isFull && classes.canvasFullHeight}`} style={{height:`${props.isFull && canvasSize?.height}`}}>
          <canvas ref={canvasRef} id={id} />          
        </div>
    </>
  );
};

export default StaticCanvas;
