Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.feedbackEndPoint = "bx_block_multipageforms2/feedbacks";
exports.feedbackMethodType = "POST";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "MultipageForms2";
exports.labelBodyText = "MultipageForms2 Body";

exports.btnExampleTitle = "CLICK ME";

exports.feedbackFormTxt = "Feedback Form";
exports.hyphenTxt = "-";
exports.feedbackFormTitle = "Lesson 1";
exports.feedbackDescTxt="We would love to hear from you so that we keep improving Engagemo to better suit your needs, priorities, and expectations. Please fill out this form to share your experience with us.";
exports.overAllRatingTxt = "Overall Experience Rating";
exports.yourNameInputLabel = "Your Name";
exports.emailIdInputLabel = "Email ID";
exports.overallCommentInputLabel = "Overall comments ";
exports.enterCommentsPlaceholder = "Enter comments";
exports.thingsLikeLabel = "Things I like about Engagemo (Optional)";
exports.improvementLabel = "Suggestions for improvement (Optional)";
exports.submitTxt = "Submit";
exports.thanksForSubmitForm = "Thanks for submitting the form!";
exports.responseRecorded = "Your response has been recorded.";
exports.backToHome = "Back to Home";
// Customizable Area End