import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { Roles } from "../../../components/src/types";
import TeacherLiveStreaming from "./Teacher/TeacherLiveStreaming.web"
import LeaveLessonModal from "../../../components/src/LeaveLessonModal.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import LiveStreamingController, {
  Props,
  configJSON,
} from "./LiveStreamingController";


export default class LiveStreaming extends LiveStreamingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.prevStage= this.prevStage.bind(this)
    this.nextStage= this.nextStage.bind(this)
    this.setShowQuestion= this.setShowQuestion.bind(this)
    // Customizable Area End
  }

  // Customizable Area Start
  renderViewBasedOnRole(role: string | null) {
    if (role === Roles.TEACHER) {
      return (
        <TeacherLiveStreaming 
        testID="handleShowResultId"
        room={this.state.room}
        stages={this.state.stages}
        currentStage={this.state.currentStage}
        parentStage={this.state.parentStage}
        messages={this.state.messages}
        nextStage={this.nextStage}
        showQuestion={this.state.showQuestion}
        prevStage={this.prevStage}
        setShowQuestion={this.setShowQuestion}
        participants={this.state.participants}
        lessonName={this.state.lessonName}
        className={this.state.className}
        role={this.state.role}
        screen={this.state.screen}
        setScreen={this.setScreen}
        leaveLesson={this.leaveLesson}
          isDescHide={this.state.isDescHide}
          handleShowResult={this.handleShowResult}
          rightAnswerTick={this.state.rightAnswerTick}
          isOpenResult={this.state.isOpenResult}
          handleStudentResult={this.handleStudentResult}
          isStudentResult={this.state.isStudentResult}
          studentResultList={this.state.studentResultList}
          rightKwlTick={this.state.rightKwlTick}
          isStudentKwlData={this.state.isStudentKwlData}
          showResults={configJSON.showResults}
          showNames={configJSON.showNames}
          studentName={configJSON.studentName}
          question={configJSON.question}
          answer={configJSON.answer}
          kWLChartQuestion={configJSON.kWLChartQuestion}
          isMatching={this.state.isMatching}
          isMatching_question={this.state.isMatching_question}
          matchingQuestion={configJSON.matchingQuestion}
          isOpenName={this.state.isOpenName}
          wordsAnswer={this.state.wordsAnswer}
          pollResult={this.state.pollResult}
          lessonStartTime={this.state.lessonStartTime}
          isQuiz={this.state.isQuiz}
          handleMedia={this.handleMedia}
          getLiveStreamCanvas={this.state.getLiveStreamCanvas}
          saveCanvas={this.endLesson}
          subStages={this.state.subStages}
          showCommentsIcon={this.state.showCommentsIcon}
          readComments={this.readComments}
          updateHighlightToFirebase={this.updateHighlightToFirebase}
          showLoader={this.state.isLoading}
        />
      );
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
       <LeaveLessonModal 
        data-test-id = "leaveLessonModal"
          open={this.state.endLessonModal} 
          exitAndSaveLesson={(checked:boolean) => this.createTacherLibrary(checked)} 
          exitLesson={()=>this.endLesson(false)} 
          handleClose={this.modalClose}
          showSuccess={this.state.endLessonSuccess}
          checkSignIn={this.onChangeSwitch}
        />
       {this.renderViewBasedOnRole(this.state.role)}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
