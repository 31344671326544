import React from "react";
import moment from "moment";
import Sidebar from "../../../../../components/src/Sidebar.web";
import TeacherCalendarController, { Props } from "./TeacherCalendarController";
import { Grid } from "@material-ui/core";
import TeacherCalendar from "./TeacherCalendar.web";
export default class Calendar extends TeacherCalendarController {
  constructor(props: Props) {
    super(props);
    this.handleDeleteEvent = this.handleDeleteEvent.bind(this);
    this.handleEventWrapper = this.handleEventWrapper.bind(this);
  }

  handleEventWrapper(props: any){
    const { children, event } = props;
    if (props?.type === "time") {
      const eventStartHour = moment(event.start).hour();
      const eventEndHour = moment(event.end).hour();
      const eventStopMins = moment(event.end).minutes();
      const eventRowStart = eventStartHour + 1;
      let eventSpanValue = 1;

      if(eventEndHour - eventStartHour >= 2) {
        if(eventStopMins === 0){
          eventSpanValue = eventEndHour - eventStartHour;
        }else {
          eventSpanValue = eventEndHour - eventStartHour + 1;
        }
      }else if(eventEndHour - eventStartHour && eventStopMins > 0){
        eventSpanValue = eventEndHour - eventStartHour + 1;
      }

      return (
        <div
          style={{ gridRow: `${eventRowStart} / span ${eventSpanValue}`, marginRight: '12px', minHeight: '80px', zIndex: 1, maxWidth: "350px" }}
        >
          {children.props.children}
        </div>
      );
    }

    return <>{children?.props?.children}</>;
  }
  
  render() {
    return (
      <>
        <Grid container style={{overflowX:"hidden", height:"100%"}}>
          <Grid item {...(!this.state.isSmallScreen && { xs: 2 })}>
            {!this.state.isSmallScreen && <Sidebar />}
          </Grid>
          <Grid item {...(!this.state.isSmallScreen && { xs: 10 })} style={{height: "100%", overflowY: "scroll"}}>
            <TeacherCalendar
              loading={this.state.loading}
              handleSetDate={this.handleSetDate}
              calendarData={this.state.calendarData}
              handleDeleteEvent={this.handleDeleteEvent}
              getCalendarEvents={this.getCalendarEvents}
              selectedDate={this.state.selectedDate}
              handleColorEvents={this.handleColorEvents}
              handleEventWrapper={this.handleEventWrapper}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}
