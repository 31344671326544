import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CustomBreadcrumb from "./CustomBreadcrum.web";
import { defaultImage } from "../assets";
import { Class } from "./types";
import Loader from "../../../../components/src/Loader.web";
const useStyles = makeStyles(() => ({
  listItemBox: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    margin: "20px",
    padding: "15px",
    minHeight: "135px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    "&:hover": {
      background: "#ebf8fe",
      borderColor: "#3093DDFF",
      color: "#121212",
    },
    "@media (max-width: 600px)": {
      flexDirection:'column'
    },
  },
  listItemFont: {
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#282829",
    fontWeight: 600,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  listItemImage: {
    width: "100%",
    height: "100%",
  },
  noRecords: {
    fontFamily: "Poppins",
    textAlign: "center",
    marginTop: "20px",
  },
  imageBox: {
    width: "90px",
    height: "90px",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    marginRight: "10px",
    padding: "8px",
    backgroundColor: "#fff",
  },
}));
const ClassList = (props: any) => {
  const { redirect, classesData, isLoading } = props;
  const locations = [
    {
      breadcrumbDisplayName: "People Management",
      renderView: "People Management",
    },
  ];
  const currentLocation = `${classesData.name}'s Classes`;
  const classes = useStyles();
  return (
    <>
      <CustomBreadcrumb
        locations={locations}
        destination={currentLocation}
        redirect={redirect}
      />
      {isLoading ? (
        <Loader loading={isLoading} />
      ) : (
        <>
          {classesData.classes.length === 0 ? (
            <Typography className={classes.noRecords}>
              No records found
            </Typography>
          ) : (
            <>
              <Box sx={{ px: "20px", pt: "5px" }}>
                <Grid container>
                  {classesData.classes.map((course: Class, i: number) => (
                    <Grid item md={4} sm={6} xs={12} key={i}>
                      <Box
                        data-testid="list-item"
                        className={classes.listItemBox}
                      >
                        <Box className={classes.imageBox}>
                          <img
                            className={classes.listItemImage}
                            src={course.image ? course.image : defaultImage}
                          />
                        </Box>
                        <Typography
                          data-testid={"course-name"}
                          className={classes.listItemFont}
                        >
                          {course.name}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ClassList;
