import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { io, Socket } from "socket.io-client";
import { fabric } from "fabric";
import firebase from "firebase";
import { Roles } from "../../../components/src/types";
let WebFont = require('webfontloader');
import { eraserIcon } from "./assets"
import StorageProvider from '../../../framework/src/StorageProvider';
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  fullscreen?:boolean;
  saveCurrentCanvas?:(whiteboard:boolean)=>void;
  getCanvas?:boolean
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

interface S {
  // Customizable Area Start
  color: string;
  canvas: any;
  canvasRef: any;
  textModal: boolean;
  open: boolean;
  colorPicker: boolean;
  selectedFontSize: string;
  selectedFontFamily: string;
  allFontFamily: any;
  fontSizes: any,
  eraserMode: boolean;
  selectedTool: string
  penStop: boolean;
  undoStack: string[];
  currentIndex: number;
  opacity: number;
  size: number;
  percentageValue: number
  stateCanvas: any;
  isUndoRedo:boolean;
  isPanning:boolean;
  isMouseDown:boolean;
  lastPosX:number;
  lastPosY:number;
  isStudent:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class WhiteboardCollaborationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  canvas!: fabric.Canvas| undefined;
  textInput!: React.RefObject<HTMLInputElement>;
  socket!: Socket;
  defaultScaleX!: number;
  defaultScaleY!: number;
  canvasRef!: any;
  unsubscribeFunctions: any[] = []
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      canvas: null,
      color: "#F00",
      canvasRef: null,
      textModal: false,
      selectedTool: "",
      eraserMode: false,
      open: false,
      colorPicker: false,
      selectedFontSize: "10",
      selectedFontFamily: "Arial",
      penStop: false,
      undoStack: [],
      currentIndex: -1,
      fontSizes: [8, 9, 10, 11, 12, 14, 18, 24, 30, 36, 48, 60, 72, 96],
      allFontFamily: [
        'Arial Black',
        'Arial Narrow',
        'Arimo',
        'Century Gothic',
        'Comic Neue',
        'Courier',
        'Courier New',
        'Garamond',
        'Georgia',
        'Gill Sans',
        'Helvetica',
        'Impact',
        'monospace',
        'Palatino',
        'Poppins',
        'Roboto',
        'sans-serif',
        'Tahoma',
        'Times',
        'Times New Roman',
        'Trebuchet MS',
        'Verdana'
      ],
      stateCanvas: null,
      size: 100,
      opacity: 100,
      percentageValue: 100,
      isUndoRedo:false,
      isPanning:false,
      isMouseDown:false,
      lastPosX:0,
      lastPosY:0,
      isStudent:window.localStorage.getItem("role") == Roles.STUDENT
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async initFirebase() {
    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey: "AIzaSyADj2MO1ayerqP0BnVuVgdl_cQBhYd2GD4",
        authDomain: "iqlearn-2ada0.firebaseapp.com",
        projectId: "iqlearn-2ada0",
        storageBucket: "iqlearn-2ada0.appspot.com",
        messagingSenderId: "273787809764",
        appId: "1:273787809764:web:48c68a55c65a154d3ee1f1",
        measurementId: "G-F5K4FKLMB5"
      });
    }
    if (this.state.isStudent) {
      const room = `LIVE_${window.location.pathname.split("/")[2]}`
      const db = firebase.firestore();
      let jsonCanvas:object
      const unsubscribeCanvas = db
        .collection("canvas")
        .where("room", "==", room)
        .onSnapshot((querySnapshot) => {
          const canvasData = querySnapshot.docs.map((doc) => doc.data());
          if (canvasData.length && canvasData[0] && canvasData[0].canvas){
            jsonCanvas = typeof canvasData[0].canvas == "object" ? canvasData[0].canvas : JSON.parse(canvasData[0].canvas)
            this.printCanvas(jsonCanvas)
          }
        })
      this.unsubscribeFunctions = [
        unsubscribeCanvas
      ]
    }
  }
  async componentDidMount() {
    if (this.canvasRef?.current) {
      this.canvas = new fabric.Canvas(this.canvasRef.current, {
        backgroundColor: "white",
        isDrawingMode: false,
       
      });  
    }
    
    window.addEventListener('resize', this.resizeCanvas);
    this.resizeCanvas()
    this.saveCanvasState()
    this.initFirebase()
    this.setState({ stateCanvas: this.canvas })
    WebFont.load({
      google: {
        families: this.state.allFontFamily
      }
    });
    const role = window.localStorage.getItem("role")
    if (role === Roles.TEACHER) {
      this.canvas?.on('object:modified', this.onObjectModified);
      this.canvas?.on('object:added', this.onObjectModified);
      this.canvas?.on('object:removed', this.onObjectModified);
      this.canvas?.on('erasing:end', this.onObjectModified);
      this.canvas?.on('selection:created', this.onObjectSelected);
      this.canvas?.on('selection:updated', this.onObjectSelected);
      this.canvas?.on('selection:cleared', this.onObjectSelected);
      const savedCanvasData = await StorageProvider.get("whiteBoardCanvas");
      if (savedCanvasData) {
          const canvasData = JSON.parse(savedCanvasData);
          this.canvas?.loadFromJSON(canvasData, this.canvas.renderAll.bind(this.canvas));
      }
      this.setCanvasInFirebase()
    }
    
    this.canvas?.on('mouse:down', this.handleMouseDown);
    this.canvas?.on('mouse:move', this.handleMouseMove);
    this.canvas?.on('mouse:up', this.handleMouseUp);
    this.canvas?.on('mouse:out', this.handleMouseUp);

  }

  resizeCanvas = () => {
    if(this.canvasRef.current && this.canvas){
      const canvasContainer = this.canvasRef.current.parentNode;
      this.canvas.setHeight(canvasContainer.clientHeight);
      this.canvas.setWidth(canvasContainer.clientWidth);
      this.canvas.renderAll();
    }
  }

  onObjectSelected = () => {
    const objects = (this.canvas as fabric.Canvas).getActiveObjects();
      let showTextEditor = false;
      for (let i = 0; i < (objects?.length ?? 0); i++) {
        if(objects){
          let object = objects[i];
          if (object.isType("textbox")) {
            showTextEditor = true;
            break;
          }
        }
       
      }
      this.setState({
        textModal: showTextEditor
      })
  }
  panUpdate = (prevState: S) => {
    if(prevState.selectedTool == 'pan' && this.state.selectedTool !== 'pan'){
      this.setState((prevState:S)=>({
        isPanning: !prevState.isPanning
      }), () => {
       this.changePanning()
      })
    }    
  }

  async componentDidUpdate(PrevProps: Props, prevState: S) {
    
    if(prevState.selectedTool == 'pen' && this.state.selectedTool !== 'pen' && this.state.selectedTool !== 'eraser'){
      if (this.canvas) {
        (this.canvas as fabric.Canvas).isDrawingMode = false;
      }
    }
    
    this.panUpdate(prevState)

    if(PrevProps.fullscreen != this.props.fullscreen){
      this.resizeCanvas()
    }
    if(prevState.selectedTool == 'eraser' && this.state.selectedTool !== 'eraser' && this.state.selectedTool !== 'pen'){
      this.setState({eraserMode:false})
      if (this.canvas) {
        (this.canvas as fabric.Canvas).isDrawingMode = false;
      }
    }
    if(PrevProps.getCanvas != this.props.getCanvas && this.props.getCanvas && this.props.saveCurrentCanvas){
      StorageProvider.set("whiteBoardCanvas", JSON.stringify(this.canvas))
      this.props.saveCurrentCanvas(true)
    }
  }
  async componentWillUnmount() {
    if (!this.canvas) return;
    const canvasData = this.canvas;
    const role = window.localStorage.getItem("role")
    if (role === Roles.TEACHER) {
      await StorageProvider.set("whiteBoardCanvas", JSON.stringify(canvasData))
      this.canvas.off('object:modified', this.onObjectModified);
      this.canvas.off('object:added', this.onObjectModified);
      this.canvas.off('object:removed', this.onObjectModified);
      this.canvas.off('erasing:end', this.onObjectModified);
      this.canvas.off('selection:created', this.onObjectSelected);
      this.canvas.off('selection:updated', this.onObjectSelected);
      this.canvas.off('selection:cleared', this.onObjectSelected);
    }    
    this.canvas.off('mouse:down', this.handleMouseDown);
    this.canvas.off('mouse:move', this.handleMouseMove);
    this.canvas.off('mouse:up', this.handleMouseUp);
    this.canvas.off('mouse:out', this.handleMouseUp);
    this.canvas?.dispose();
    window.removeEventListener('resize', this.resizeCanvas);
    this.unsubscribeFunctions.forEach(unsubscribe => unsubscribe())
  }
  onObjectModified = () => {
        this.setState({ stateCanvas: this.canvas })
    this.saveCanvasState()
    this.updateCanvasInFirebase()
    
  }
  async setCanvasInFirebase() {
    const db = firebase.firestore();
    const doc = {
      canvas: JSON.parse(JSON.stringify(this.canvas)),
      screen: "Whiteboard",
      room: `LIVE_${window.location.pathname.split("/")[2]}`,
    };

    try {
      await db.collection("canvas").doc(doc.room).set(doc);
    } catch (error) {
    }
  }

  async updateCanvasInFirebase() {
    const db = firebase.firestore();
    try {
      await db.collection("canvas").doc(`LIVE_${window.location.pathname.split("/")[2]}`).update({
        "canvas": JSON.stringify(this.canvas)
      })
    } catch (error) {
          }

  }

  printCanvas = (canvas: any) => {
    this.canvas?.clear();
    this.canvas?.setBackgroundColor('white', this.canvas?.renderAll.bind(this.canvas));
    if (canvas) {
      const parsedData: any = canvas
      parsedData.objects.forEach((objectData: any) => {
        (fabric as any)[fabric.util.string.capitalize(objectData.type, true)].fromObject(objectData, (obj: fabric.Object) => {
          obj.selectable = false;
          obj.hoverCursor = "not-allowed";
          this.canvas?.add(obj);
        });
      });
      this.canvas?.renderAll();
    }
  }
  //
  //Eraser Tool
  handleEraser = () => {
    if(this.state.isStudent) return
    const eraserMode = !this.state.eraserMode;
    const fabricCanvas = this.canvas as fabric.Canvas
    if(eraserMode){
      fabricCanvas.freeDrawingBrush = new fabric.EraserBrush(fabricCanvas);
      fabricCanvas.freeDrawingBrush.width = 15; // Set the eraser width
      fabricCanvas.freeDrawingCursor = `url('${eraserIcon}'), auto`;
    }
    fabricCanvas.isDrawingMode = eraserMode
    this.setState({ eraserMode: eraserMode, selectedTool: eraserMode?"eraser":"" });

  }

  isIntersecting = (object: any, pointer: any) => {
    return (
      pointer.x > object.left &&
      pointer.x < object.left + object.width &&
      pointer.y > object.top &&
      pointer.y < object.top + object.height
    );
  }
  // Text Tool
  addText = () => {
    if(this.state.isStudent) return
    this.setState({
      open: false,
      selectedTool: ""
    })
    const text = new fabric.Textbox("Type your text", {
      left: 100,
      top: 50,
      fontSize: 20,
      width:150,
    });
    this.canvas?.add(text);
      };
  addCircle = () => {
    const circleData = {
      radius: 50,
      fill: this.state.color,
      selectable: true,
      objectCaching: true,
      position: {
        x: 150,
        y: 150
      },
      left: 100,
      top: 0,
    }
    const circle = new fabric.Circle(
      circleData
    );
    this.canvas?.add(circle);

  };


  addArrow = () => {
    const line = new fabric.Line([50, 50, 150, 150], {
      stroke: "blue",
      strokeWidth: 2,
      selectable: true,
    });
    const angle = Math.atan2(150 - 50, 150 - 50) * (180 / Math.PI);
    const triangle = new fabric.Triangle({
      left: 163,
      top: 155,
      width: 10,
      height: 10,
      fill: this.state.color,
      angle: angle + 90,
      selectable: true,
    });

    const group = new fabric.Group([line, triangle], {
      selectable: true,
    });

    this.canvas?.add(group);
  };
  saveCanvasState = () => {
    if(this.state.isUndoRedo) return
    const { undoStack, currentIndex } = this.state;
    const state = JSON.stringify(this.canvas?.toJSON());
    const newStack = [...undoStack.slice(0, currentIndex + 1), state];
    this.setState({ undoStack: newStack, currentIndex: currentIndex + 1 });
  };

  undo = () => {
    if(this.state.isStudent) return
    this.setState({ selectedTool: "",isUndoRedo:true },()=>{
      const { undoStack, currentIndex } = this.state;
      if (currentIndex > 0) {
        const prevState = undoStack[currentIndex - 1];
        this.canvas?.loadFromJSON(prevState, () => {
          this.canvas?.renderAll();
          this.setState({ currentIndex: currentIndex - 1 ,isUndoRedo:false});
        });
      }
    })    
  };

  redo = () => {
    if(this.state.isStudent) return
    this.setState({ selectedTool: "",isUndoRedo:true },()=>{
      const { undoStack, currentIndex } = this.state;
      if (currentIndex < undoStack.length - 1) {
        const nextState = undoStack[currentIndex + 1];
        this.canvas?.loadFromJSON(nextState, () => {
          this.canvas?.renderAll();
          this.setState({ currentIndex: currentIndex + 1,isUndoRedo:false });
        });
      }
    })    
  };
  // Delete Functionality
  deleteObject = () => {
    if(this.state.isStudent) return
    const activeObjects = (this.canvas as fabric.Canvas).getActiveObjects()
    if (activeObjects && activeObjects.length > 0) {
    activeObjects?.forEach(activeObject => {
      this.canvas?.remove(activeObject);           
    });
  }
    this.setState({ selectedTool: "" })
    
  };

  addRoundedRectangle = () => {
    const rect = new fabric.Rect({
      left: 100,
      top: 0,
      width: 100,
      height: 100,
      rx: 10,
      ry: 10,
      fill: this.state.color,
      selectable: true,
    });
    this.canvas?.add(rect);
  };

  // Add Shapes Tool
  addRectangle = () => {
    const rect = new fabric.Rect({
      left: 100,
      top: 0,
      width: 100,
      height: 100,
      fill: this.state.color,
      selectable: true,
    });
    this.canvas?.add(rect);
  };

  // Bold Tool
  toggleBold = () => {
    const activeObjects = (this.canvas as fabric.Canvas).getActiveObjects()
    activeObjects?.forEach(activeObject => {
      if (activeObject && activeObject.type == "textbox") {
        const selectedObject = activeObject as fabric.Textbox;
        const isBold = selectedObject.get('fontWeight') === 'bold';
        selectedObject.set('fontWeight', isBold ? 'normal' : 'bold');
          
      }      
    });
    this.canvas?.renderAll();
    this.onObjectModified()
  };
  activatePen = () => {
    if(this.state.isStudent) return
    const penStop = !this.state.penStop
    this.setState({ penStop: penStop, selectedTool: penStop?"pen":"" })
    if (this.canvas) {
      (this.canvas as fabric.Canvas).isDrawingMode = penStop;
      if(penStop){
        (this.canvas as fabric.Canvas).freeDrawingBrush = new fabric.PencilBrush((this.canvas as fabric.Canvas));
        (this.canvas as fabric.Canvas).freeDrawingCursor = 'crosshair'
      }
      (this.canvas as fabric.Canvas).freeDrawingBrush.color = this.state.color;
    }
  };

  drawPolygon = () => {
    const pentagon = new fabric.Polygon([
      { x: 100, y: 10 },
      { x: 200, y: 10 },
      { x: 250, y: 100 },
      { x: 150, y: 200 },
      { x: 50, y: 100 }
    ], {
      fill: this.state.color,
      left: 100,
      top: 0,
      selectable: true
    });

    this.canvas?.add(pentagon)
  };

  drawStar = () => {
    const star = new fabric.Polygon([
      { x: 50, y: 0 },
      { x: 65, y: 30 },
      { x: 100, y: 30 },
      { x: 75, y: 50 },
      { x: 85, y: 90 },
      { x: 50, y: 70 },
      { x: 15, y: 90 },
      { x: 25, y: 50 },
      { x: 0, y: 30 },
      { x: 35, y: 30 },
    ], {
      fill: this.state.color,
      left: 100,
      top: 0,
    });

    this.canvas?.add(star);
  };

  drawTriangle = () => {
    const triangle = new fabric.Triangle({
      width: 100,
      height: 100,
      fill: this.state.color,
      left: 100,
      top: 0,
    });

    this.canvas?.add(triangle);
  };

  handleChange = (event: string) => {
    this.setState({ selectedFontFamily: event });
    
    const activeObjects = (this.canvas as fabric.Canvas).getActiveObjects()
    activeObjects?.forEach(activeObject => {
        if (activeObject && activeObject.type == "textbox") {
        (activeObject as fabric.Textbox).set("fontFamily",event)
      }      
    });
    this.canvas?.renderAll();
    this.onObjectModified()
  }
  handleFontSize = (event: string) => {
    
    const activeObjects = (this.canvas as fabric.Canvas).getActiveObjects()
    activeObjects?.forEach(activeObject => {
      if (activeObject && activeObject.type == "textbox") {
      (activeObject as fabric.Textbox).set("fontSize",Number(event))
      }      
    });
    this.canvas?.renderAll();
    this.onObjectModified()
  }

  handleChange2 = (newColor: any) => {
    this.setState({ color: newColor.hex });
    this.applyColor(newColor.hex);
  };

  applyColor = (newColor: string) => {
    const activeObjects = (this.canvas as fabric.Canvas).getActiveObjects()
    activeObjects?.forEach(activeObject => {
      if(activeObject.fill){
        activeObject.set("fill", newColor);
      }
      if(activeObject.stroke){
        activeObject.set("stroke", newColor);
      }    
    });
    if(this.canvas) (this.canvas as fabric.Canvas).freeDrawingBrush.color = newColor;
    this.canvas?.renderAll();
    this.onObjectModified()
  };
  handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    this.updateValues(parseFloat(value));
    this.handlePercentageValue(parseFloat(value))
  }
  updateValues(value: number) {
    const size = value;
    this.setState({ opacity: 1, size: size }, () => {
      this.canvas?.setZoom(size/100)
    });
  }
  
  handleIncrement = () => {
    const newValue = Math.min(this.state.size + 25, 100);
    this.setState({ percentageValue: newValue })
    this.updateValues(newValue);
  }

  handleDecrement = () => {
    const newValue = Math.max(this.state.size - 25, 25);
    this.setState({ percentageValue: newValue })
    this.updateValues(newValue);
  }
  handlePercentageValue = (value: number) => {
    this.setState({ percentageValue: value })
  }
  handlePointer = () => {
    if(this.state.isStudent) return
    this.setState({
      selectedTool: "",
      textModal: false,
      eraserMode: false,
      open: false,
      colorPicker: false, penStop: false,
    });
    (this.canvas as fabric.Canvas).discardActiveObject().renderAll()
  }
  handleShape = () => {
    if(this.state.isStudent) return
    const isOpen = !this.state.open
    this.setState({open: isOpen,textModal: false,selectedTool:isOpen?"shape":""}) 
  }

  changePanning = () => {
    if(this.canvas) {
      const isPanning = this.state.isPanning;
      const hoverCursorType = this.state.isStudent?"not-allowed":"default";
      (this.canvas as fabric.Canvas).defaultCursor = isPanning ? 'grab' : 'default';
      (this.canvas as fabric.Canvas).selection = !isPanning
      const allObjects = this.canvas?.getObjects()
      if (allObjects && allObjects.length > 0) {
      allObjects?.forEach(object => {
          object.selectable = !isPanning && !this.state.isStudent;
          const cursorType = isPanning?"grab":hoverCursorType
          object.hoverCursor = cursorType
      });
      (this.canvas as fabric.Canvas).discardActiveObject().renderAll()
    }}
  }

  handlePan = () => {
    const isPanning = this.state.isPanning;
    if(isPanning) {
      this.setState({
        selectedTool: ""
      })
    }
    else{
      this.setState({
        isPanning: true,
        selectedTool: "pan"
      }, () => {
       this.changePanning()
      })
    }
  }
  handleMouseDown = (opt:any) => {
    if (!this.canvas) return;

    const pointer =(this.canvas as fabric.Canvas).getPointer(opt.e,true);
    const { x: startX, y: startY } = pointer;
    this.setState({
      lastPosX: startX,
      lastPosY: startY,
      isMouseDown:true,
      colorPicker:false
    });
    if (this.state.isPanning) {
      (this.canvas as fabric.Canvas).selection = false;
    }
  };

  handleMouseMove = (opt:any) => {
    if (!this.canvas) return;

    const { isPanning,isMouseDown, lastPosX, lastPosY,size } = this.state;

    if (isPanning && isMouseDown) {
      const pointer = (this.canvas as fabric.Canvas).getPointer(opt.e,true);
      const { x: currentX, y: currentY } = pointer;
      const vpt = this.canvas.viewportTransform;
      if(!vpt) return
      vpt[4] += (currentX - lastPosX) * (size/100);
      vpt[5] += (currentY - lastPosY)* (size/100);
      this.canvas.renderAll();
      this.setState({
        lastPosX: currentX,
        lastPosY: currentY,
      }); 
    }
  };

  handleMouseUp = () => {
    if (!this.canvas) return;
    this.setState({isMouseDown:false})
  };
  
  handleColorPicker=()=>{
    if(this.state.isStudent) return
    this.setState({colorPicker:!this.state.colorPicker})
  }
  
  
  // Customizable Area End
}
