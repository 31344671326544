import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import Highcharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import VennModule from "highcharts/modules/venn.js";
VennModule(Highcharts);
HighchartsExporting(Highcharts);
import { venn } from "../../assets";

const useStyles = makeStyles(() => ({
  mainLayout: {
    height: "740px",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    backgroundColor: "#FFFFFF",
    marginBottom: "50px",
  },
  vennInstructionTitle: {
    fontFamily: "Poppins",
    fontSize: "20px",
    color: "#282829",
    fontWeight: 500,
    marginRight: "5px",
  },
  vennInstructionPlaceholder: {
    "&:empty:before": {
      content: "attr(data-placeholder)",
      color: "#9FA1A4",
      fontFamily: "Poppins",
      fontSize: "20px",
      fontWeight: 400,
    },
    color: "#282829",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 500,
    "&:focus-visible": {
      outline: "none",
    },
  },
  vennInstructionBox: {
    display: "flex",
    alignItems: "center",
    padding: "0 25px",
    marginBottom: "20px",
    marginTop: "15px",
  },
  vennBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: "100px",
  },
}));

const VennDiagram = (props: any) => {
  const classes = useStyles();
  return (
    <div id="venn-container" className={classes.mainLayout}>
      <Box className={classes.vennBox}>
        <img src={venn} />
      </Box>
    </div>
  );
};

export default VennDiagram;
