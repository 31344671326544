import React,{useState} from "react";
import { Box, Grid, Typography,TextField,Table,Modal,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper,Tooltip, useMediaQuery } from "@material-ui/core";
import Sidebar from "../../../../components/src/Sidebar.web";
import AppHeaderWeb from "../../../../components/src/AppHeader.web";
import { MY_CLASSES_VIEWS, CLASS_OPTIONS,REPORT_OPTIONS } from "../../../../components/src/types";
import MyClasses from "./Pages/MyClasses.web";
import ClassDetails from "./Pages/ClassDetails.web";
import StudentList from "./Pages/StudentList.web";
import AddEditStudent from "./Pages/AddEditStudent.web";
import Loader from "../../../../components/src/Loader.web";
import ClassLibrary from "./Pages/ClassLibrary.web";
import LessonList from "./Pages/LessonList.web";
import ReportList from "./Pages/ReportList.web";
import SummeryReportList from "./Pages/SummeryReportList.web";
import DetailReportList from "./Pages/DetailReportList.web";
import { makeStyles,withStyles } from "@material-ui/core/styles";
import CustomBreadcrumb from "../../../../components/src/CustomBreadcrumb.web";
import SearchInput from "../../../../components/src/SearchInput.web";
import BasicPagination from "../../../../components/src/BasicPagination.web";
import { contentCopy,quizIcon } from "../assets";
import NotForMobile from "../../../../components/src/NotForMobile.web";

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#FDF4DC",
    color: "#202020",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
  },
  body: {
    fontSize: "14px",
    color: "#3F526D",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({}))(TableRow);

const useStyles = makeStyles((theme) => ({
  searchBarLayoutQ: {
    display: "flex",
  },
  addButtonLayoutQ: {
    width: "100%",
    display: "flex",
    marginTop: "20px",
    marginBottom: "20px",
    justifyContent: "space-between",
  },
  noRecordsQ: {
    textAlign: "center",
    paddingTop: "35px",
    fontFamily: "Poppins",
  },
  tableQuiz: {
    minWidth: 700,
  },
  button: {
    fontSize: '16px',
    color: '#2B3D7D',
    fontWeight: 500,
    backgroundColor: '#fff',
    borderColor: '#2B3D7D',
    borderRadius: '8px',
    marginBottom: '0px',
    padding: '6px 20px',
    cursor: 'pointer',
    fontFamily: 'Poppins',
  },
  div: {
    display: "flex",
    width: "100%"
  },
  img: {
    height: '30px',
    width: '30px',
    marginLeft: '24px',
    [theme.breakpoints.down('xs')]: {
      marginLeft:0
     },
  },
  img_box: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex"
  },
  div_p: {
    paddingLeft: "6px",
    textAlign: "start",
    alignItems: "center"
  },
  code_img: {
    height: "15px", width: "15px", marginLeft: "25px", cursor: 'pointer'
  }
}));

const ReportView=(selectedView: string,props:any)=>{

  const {locations,destination,showLessonListView,redirect}=props
  if (selectedView === REPORT_OPTIONS.SUMMARTY_REPORT) {
    return (  
      <SummeryReportList
        locations={locations}
        destination={destination}
        showLessonListView={showLessonListView}
        redirect={redirect}
      />
      )
  } 
  if (selectedView === REPORT_OPTIONS.DETAILED_REPORT) {  
    return (  
      <DetailReportList
        locations={locations}
        destination={destination}
        showLessonListView={showLessonListView}
        redirect={redirect}
      />
      )
  } 
}

const getAssignmentsList = (props:any,classes:any,toolTipText:string="",setToolTipText?:React.Dispatch<React.SetStateAction<string>>,labelName?: string ,setLabelName?: React.Dispatch<React.SetStateAction<string>>,isSmallScreen?: boolean ) => {
  const {
    destination,
    locations,
    redirect,
    handleSearchAssignmentName,
    pageLimitAssignment,
    pageNoAssignment,
    handlePageAssignmentList,
  } = props;

  const {
    assignmentList,
    searchInputAssignmentName
  } = props.myClassesState;

  const tableHead = () => {
    return (
      <TableRow>
        <StyledTableCell align="left" style={{paddingLeft: "34px"}}>Assignment Name</StyledTableCell>
        <StyledTableCell align="center">Assignment Status</StyledTableCell>
      </TableRow>
    );
  };

  const tableBody = () => {
    return assignmentList.map((data: any, index: number) => (
      <StyledTableRow key={data.id}>
        <StyledTableCell align="left">
          <div className={classes.div}>
            <div className={classes.img_box} style={{ width: "60px"}}>
              <img src={quizIcon} className={{...classes.img, marginLeft: 0}} alt="quizIcon" />
            </div>
            <div className={classes.div_p}>
              <p>{data.assignmentName}</p>
            </div>
          </div>
        </StyledTableCell>
        <StyledTableCell align="center">
          <p>{data.assignmentStatus}</p>
        </StyledTableCell>
      </StyledTableRow>
    ));
  };

  return (
    <>
      <Box sx={{ px: "35px" }}>
        <Box sx={{ mt: "30px" }}>
          <CustomBreadcrumb
            locations={locations}
            destination={destination}
            redirect={redirect}
          />
        </Box>
        <Box className={classes.addButtonLayoutQ}>
          <Box className={classes.searchBarLayoutQ}>
            <SearchInput
              handleSearchInput={handleSearchAssignmentName}
              searchInput={searchInputAssignmentName}
              placeholder={"Search by name"}
            />
          </Box>
        </Box>
        <Box>
          {assignmentList.length === 0 ? (
            <Typography className={classes.noRecordsQ}>
              No records found
            </Typography>
          ) : (
            <>              
              <TableContainer component={Paper}>
                <Table className={classes.tableQuiz} aria-label="customized table">
                  <TableHead>{tableHead()}</TableHead>
                  <TableBody>{tableBody()}</TableBody>
                </Table>
              </TableContainer>
              <Box sx={{ px: "35px", py: "50px" }}>
                <BasicPagination
                  handlePageChange={handlePageAssignmentList}
                  pageLimit={pageLimitAssignment}
                  pageNo={pageNoAssignment}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  )
}

const getAssignmentDesign = (isLoading:boolean,selectedView: string, propsData: any,classes?:any,toolTipText?:string,setToolTipText?:React.Dispatch<React.SetStateAction<string>>,labelName?: string,setLabelName?: React.Dispatch<React.SetStateAction<string>>,isSmallScreen?: boolean) => {
  return isLoading ? (
    <Loader loading={isLoading} />
  ) : (
    getAssignmentsList(propsData,classes,toolTipText,setToolTipText,labelName,setLabelName,isSmallScreen)
  );
}

const getQuizzesList = (props:any,classes:any,toolTipText:string="",setToolTipText?:React.Dispatch<React.SetStateAction<string>>,labelName?: string ,setLabelName?: React.Dispatch<React.SetStateAction<string>>,isSmallScreen?: boolean ) => {
  const {
    destination,
    locations,
    redirect,
    handleSearchQuizName,
    pageLimitQuiz,
    pageNoQuiz,
    handlePageQuizList,
    deliverQuiz,
    handleCloseNoMobileModal,
    openNoMobileModal,
    handleOpenNoMobileModal
  } = props;

  const {
    quizList,searchInputQuizName
  } = props.myClassesState
  const handleCopyCode = (data: any) => {
    navigator.clipboard.writeText(data);
    if(setToolTipText){
      setToolTipText("Copied")
      setTimeout(()=>{
        setToolTipText("Copy Code")
      },2000)
   }
  };
  
  const handleClickQuiz = (data: { id: number; }) => {
    if (isSmallScreen) {
      handleOpenNoMobileModal();
      if (setLabelName) setLabelName('Deliver');
    } else {
      deliverQuiz(data.id);
    }
  };

  const tableHead = () => {
    return (
      <TableRow>
        <StyledTableCell align="center">Quiz Name</StyledTableCell>
        <StyledTableCell align="center">Stages</StyledTableCell>
        <StyledTableCell align="center">Quiz Code</StyledTableCell>
        <StyledTableCell align="center">Action</StyledTableCell>
      </TableRow>
    );
  };

  const tableBody = () => {
    return quizList.map((data: any, index: number) => (
      <StyledTableRow key={data.id}>
        <StyledTableCell align="center">
          <div className={classes.div}>
            <div className={classes.img_box}>
              <img src={quizIcon} className={classes.img} alt="quizIcon" />
            </div>
            <div className={classes.div_p}>
              <p>{data.quizName}</p>
            </div>
          </div>
        </StyledTableCell>
        <StyledTableCell align="center">
          <p>{data.totalStage}</p>
        </StyledTableCell>
          <StyledTableCell align="center">
            <Box display="flex" alignItems="center" textAlign="center">
              <Box width={"76%"} display={"flex"} justifyContent={"center"} alignItems="center">
                <TextField
                  disabled
                  InputProps={{ disableUnderline: true,endAdornment:<Typography id="codeInput">{data.quizCode}</Typography> }}
                />
              <Box onClick={()=>handleCopyCode(data.quizCode)} data-test-id="copy-code">
              <Tooltip title={toolTipText} data-test-id="tooltip-text">
                <img src={contentCopy} className={classes.code_img} alt="copyIcon" />
                </Tooltip>
              </Box> 
              </Box>
            </Box>
          </StyledTableCell>
        <StyledTableCell align="center">
          <button className={classes.button}
          data-test-id="handleClickQuiz"
          onClick={()=>handleClickQuiz(data)}
          disabled={!data.totalStage}
          style={{ opacity: data.totalStage ? 1 : 0.3}}
           >Deliver</button>
        </StyledTableCell>
      </StyledTableRow>
    ));
  };

  return (
    <>
      <Box sx={{ px: "35px" }}>
        <Box sx={{ mt: "30px" }}>
          <CustomBreadcrumb
            locations={locations}
            destination={destination}
            redirect={redirect}
          />
        </Box>
        <Box className={classes.addButtonLayoutQ}>
          <Box className={classes.searchBarLayoutQ}>
            <SearchInput
              handleSearchInput={handleSearchQuizName}
              searchInput={searchInputQuizName}
              placeholder={"Search by name"}
            />
          </Box>
        </Box>
        <Box>
          {quizList.length === 0 ? (
            <Typography className={classes.noRecordsQ}>
              No records found
            </Typography>
          ) : (
            <>              
              <TableContainer component={Paper}>
                <Table className={classes.tableQuiz} aria-label="customized table">
                  <TableHead>{tableHead()}</TableHead>
                  <TableBody>{tableBody()}</TableBody>
                </Table>
              </TableContainer>
              <Box sx={{ px: "35px", py: "50px" }}>
                <BasicPagination
                  handlePageChange={handlePageQuizList}
                  pageLimit={pageLimitQuiz}
                  pageNo={pageNoQuiz}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Modal 
      onClose={handleCloseNoMobileModal} open={openNoMobileModal} data-testid="nomobile-modal" >
             <NotForMobile 
             handleCloseModal={handleCloseNoMobileModal} 
             moduleName={labelName} />
      </Modal>
    </>
  )
}

const getQuizDesign = (isLoading:boolean,selectedView: string, propsData: any,classes?:any,toolTipText?:string,setToolTipText?:React.Dispatch<React.SetStateAction<string>>,labelName?: string,setLabelName?: React.Dispatch<React.SetStateAction<string>>,isSmallScreen?: boolean) => {
  return isLoading ? (
    <Loader loading={isLoading} />
  ) : (
    getQuizzesList(propsData,classes,toolTipText,setToolTipText,labelName,setLabelName,isSmallScreen)
  );
}

const getDesignBasedOnOption = (selectedView: string, propsData: any,classes?:any,toolTipText?:string,setToolTipText?:React.Dispatch<React.SetStateAction<string>>,labelName?: string,setLabelName?: React.Dispatch<React.SetStateAction<string>>,isSmallScreen?: boolean) => {
  const {
    destination,
    locations,
    showLessonListView,
    redirect,
    isLoading,
    pageNoLesson,
    pageLimitLesson,
    handlePageLessonList,
    handleSearchLessonName,
    redirectToLiveLessonStreamView,
    showSummaryReportListView,
    handleCloseNoMobileModal,
    openNoMobileModal,
    handleOpenNoMobileModal
  } = propsData;

  const {
    searchInputLessonName,
    lessonList,
    selectedClass,
  } = propsData.myClassesState;
  switch(selectedView){
    case CLASS_OPTIONS.LESSONS:
      return isLoading ? (
        <Loader loading={isLoading} />
      ) : (
        <LessonList
          locations={locations}
          destination={destination}
          lessonList={lessonList}
          showLessonListView={showLessonListView}
          redirect={redirect}
          handleSearchLessonName={handleSearchLessonName}
          searchInputClassName={searchInputLessonName}
          pageNoLesson={pageNoLesson}
          pageLimitLesson={pageLimitLesson}
          handlePageLessonList={handlePageLessonList}
          redirectToLiveLessonStreamView={redirectToLiveLessonStreamView}
          handleOpenNoMobileModal={handleOpenNoMobileModal}
          handleCloseNoMobileModal={handleCloseNoMobileModal}
          openNoMobileModal={openNoMobileModal}
        />
      );
    case MY_CLASSES_VIEWS.CLASS_LIBRARY:
      return (
        <ClassLibrary
          locations={locations}
          destination={destination}
          showLessonListView={showLessonListView}
          redirect={redirect}
        />
      );
    case CLASS_OPTIONS.ASSIGNMENTS:
      return getAssignmentDesign(isLoading,selectedView,propsData,classes,toolTipText,setToolTipText,labelName,setLabelName,isSmallScreen)
    case CLASS_OPTIONS.QUIZZES:
      return getQuizDesign(isLoading,selectedView,propsData,classes,toolTipText,setToolTipText,labelName,setLabelName,isSmallScreen)
    case MY_CLASSES_VIEWS.REPORT:
      return (
        <ReportList
          classTitle={destination}
          classID={selectedClass}
          locations={locations}
          destination={destination}
          showLessonListView={showLessonListView}
          redirect={redirect}
          showSummaryReportListView={showSummaryReportListView}
        />
      );
    default:
      if(selectedView.includes("Report")){
        return (
          <>{ReportView(selectedView, propsData)}</>)
      }
      else {
        return <></>;
      }
  }  
}

export const renderSwitch = (selectedView: string, propsData: any,classes?:any,toolTipText?:string,setToolTipText?:React.Dispatch<React.SetStateAction<string>>,labelName?: string,setLabelName?: React.Dispatch<React.SetStateAction<string>>,isSmallScreen?: boolean) => {
  const {
    handleSearchClassName,
    handlePageChangeClassList,
    showClassDetailView,
    createClass,
    destination,
    locations,
    showStudentListView,
    showAddStudentView,
    redirect,
    createStudent,
    updateStudent,
    viewStudent,
    deleteStudent,
    isDeleteSuccess,
    deleteCleanup,
    sendReminder,
    isReminderSuccess,
    reminderCleanup,
    isLoading,
    pageNo,
    pageLimit,
    pageNoStudent,
    pageLimitStudent,
    handlePageChangeStudent,
    searchInputStudentName,
    handleSearchStudent,
    setCreateUpdateStudentModal,
    studentApiError,
    showReportView,
    openReportModal,
    closeReportModal,
    openReportModalFunc,
    deleteClassCallback,
    deleteClassErrorMsg,
    showError,
    handleCloseError,
    internalDate,
    setInternalDate
  } = propsData;

  const {
    classList,
    searchInputClassName,
    studentList,
    studentDetails,
    selectedStudent,
    formClassList,
    lessonList,
    selectedClass,
  } = propsData.myClassesState;
  switch(selectedView){
    case MY_CLASSES_VIEWS.MY_CLASSES:
      return isLoading ? (
        <Loader loading={isLoading} />
      ) : (
        <MyClasses
          classList={classList}
          searchInputClassName={searchInputClassName}
          handleSearchClassName={handleSearchClassName}
          handlePageChangeClassList={handlePageChangeClassList}
          showClassDetailView={showClassDetailView}
          createClass={createClass}
          pageNo={pageNo}
          pageLimit={pageLimit}
          deleteClassCallback={deleteClassCallback}
          deleteClassErrorMsg={deleteClassErrorMsg}
          showError={showError}
          handleCloseError={handleCloseError}
        />
      );
    case MY_CLASSES_VIEWS.CLASS_DETAILS:
      return (
        <ClassDetails
          locations={locations}
          destination={destination}
          showStudentListView={showStudentListView}
          redirect={redirect}
          showReportView={showReportView}
          openReportModal={openReportModal}
          closeReportModal={closeReportModal}
          openReportModalFunc={openReportModalFunc}
          selectedClass={selectedClass}
          internalDate={internalDate}
          setInternalDate={setInternalDate}
        />
      );
    case MY_CLASSES_VIEWS.STUDENT_LIST:
      return isLoading ? (
        <Loader loading={isLoading} />
      ) : (
        <StudentList
          locations={locations}
          destination={destination}
          studentList={studentList}
          pageNoStudent={pageNoStudent}
          pageLimitStudent={pageLimitStudent}
          showAddStudentView={showAddStudentView}
          redirect={redirect}
          selectedStudent={selectedStudent}
          viewStudent={viewStudent}
          deleteStudent={deleteStudent}
          isDeleteSuccess={isDeleteSuccess}
          deleteCleanup={deleteCleanup}
          sendReminder={sendReminder}
          isReminderSuccess={isReminderSuccess}
          reminderCleanup={reminderCleanup}
          handlePageChangeStudent={handlePageChangeStudent}
          handleSearchStudent={handleSearchStudent}
          searchInputStudentName={searchInputStudentName}
        />
      );
    case MY_CLASSES_VIEWS.ADD_STUDENT:
      return (
        <AddEditStudent
          createStudent={createStudent}
          studentDetails={studentDetails}
          showStudentListView={showStudentListView}
          updateStudent={updateStudent}
          formClassList={formClassList}
          setCreateUpdateStudentModal={setCreateUpdateStudentModal}
          studentApiError={studentApiError}
        />
      );
    default :
        return getDesignBasedOnOption(selectedView,propsData,classes,toolTipText,setToolTipText,labelName,setLabelName,isSmallScreen)
  }
};

const TeacherContentManagement = (props: any) => {
  const [toolTipText, setToolTipText] = useState("Copy code")
  const [labelName, setLabelName] = React.useState<string>('')
  const {
    selectedView,
    studentDetails,
  } = props.myClassesState;
  const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const headerTitle = () => {
    if (selectedView === MY_CLASSES_VIEWS.ADD_STUDENT) {
      const isUpdateRequest = Object.keys(studentDetails).length;
      if (isUpdateRequest) return "Edit Profile";
      else return "Add Student";
    }
    return MY_CLASSES_VIEWS.MY_CLASSES;
  };
  return (
    <Box>
      <Grid container={isSmallScreen ? false : true} style={{ overflowX: "hidden", height: "100%" }}>
        <Grid item {...(!isSmallScreen && { xs: 2 })}>
        {!isSmallScreen && <Sidebar />}
        </Grid>
        <Grid item {...(!isSmallScreen && { xs: 10 })} style={{ overflowY: "scroll", height: "100%" }}>
          <Grid item md={12}>
            <AppHeaderWeb title={headerTitle()} />
          </Grid>
          <Grid item md={12} data-testid="renderSwitch">
            {renderSwitch(selectedView, props,classes,toolTipText,setToolTipText,labelName,setLabelName,isSmallScreen)}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TeacherContentManagement;
