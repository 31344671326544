import React, { useEffect } from "react";
import { Box, Typography, Button,Modal,Switch  } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { closeModalIcon,lessonLibIcon,assigmenTsave,viewassignment,viewassignment2 } from "./assets";
import { COURSES_PAGES } from "./types";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "520",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    padding:"45px 40px 36px",
    "@media (max-height: 600px)": {
      maxHeight: "370px", 
      overflowY: "auto",
     },
    "& .close-modal":{
      display:"block",
      position: "absolute",
      top: "0",
      right: "0",
    },
    "& .cross-icon":{
      cursor:"pointer"
    }
  },
  heading: {
    fontFamily: "Poppins",
    fontSize: "20px",
    color: "#2B3D7D",
    fontWeight: 600,
    lineHeight:"1.5",
    textAlign:"center",
    padding:"0 15px",
    marginBottom:"10px",
  },
  saveHeading: {
    fontFamily: "Poppins",
    fontSize: "24px",
    color: "#2B3D7D",
    fontWeight: 800,
    lineHeight:"1.6",
    textAlign:"center",
    padding:"0 15px",
    marginBottom:"10px",
  },
  text:{
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight:"1.5",
    textAlign:"center",
    padding:"0 15px",
    color:'#48484A',
  },
  popupContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom:"20px",
    "& .image-wrapper":{
      marginBottom:"15px",
      minWidth:"212px"
    }
  },
  btnLayout: {
    display: "flex",
    justifyContent: "center",
    "& button + button":{
      marginLeft:"16px",
    }
  },
  siteBtn:{
    height: "52px",
    lineHeight: "52px",
    border: "1px solid #2B3D7D",
    color: "#2B3D7D",
    minWidth: "152px",
    width: "auto",
    padding: "0 15px",
    fontSize: "18px",
    fontWeight: 500,
    transition:"all 0.5s",
    textTransform:"unset",
    borderRadius:"10px",
    "&:hover":{
      backgroundColor:"#1e2b58",
      color:"#FFF"
    },
    "&.fill-btn":{
      color:"#FFF",
      backgroundColor:"#2B3D7D",
      "&:hover":{
        backgroundColor:"#1e2b58",
      },
    }
  },
  coloredBtn:{
    backgroundImage:
    "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
    color: "#FFF",
    borderRadius: "10px",
    width: "152px",
    padding: "12px",
    textTransform: "none",
    fontFamily: "Poppins",
    fontSize: "18px",
  },
  saveDriveBlock:{
    width:"100%",
    display:"flex",
    flexDirection:"column",
    borderRadius:"8px",
    border:"1px solid #E5E5EA",
    marginBottom:"30px",
    "& .download":{
      flex:"0 0 55px",
      maxWidth:"55px",
      borderLeft:"1px solid #E5E5EA",
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
    },
    "& .switch-block":{
      flex:"1",
      width:"100%",
      padding:"18px",
      boxSizing:"border-box",
      "& .switch-inner":{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        "& p":{
          fontFamily: "Poppins",
          fontSize: "16px",
          color: "#2B3D7D",
          fontWeight: 600,
          lineHeight:"1.42",
        },

        "& .switch-wrapper":{
          "& .MuiSwitch-root":{
            padding:"9px",
            "& .MuiSwitch-thumb":{
              width:"15px",
              height:"15px",
              backgroundColor:"#FFF",
            },
            "& .MuiSwitch-track":{
              borderRadius:"13px",
            },
            "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track":{
              backgroundColor:"2B3D7D",
              opacity:"1",
            },
            "& .MuiSwitch-colorSecondary.Mui-checked":{
              color:"transparent",
            },
            "& .MuiSwitch-switchBase":{
              transform:"translateX(3px)",
            },
            "& .MuiSwitch-switchBase.Mui-checked":{
              transform:"translateX(20px)",
            },
            "& .MuiIconButton-label":{
              position:"relative",
              top:"2px",
            }
          }
        }
      },
      "& + .switch-block":{
        borderTop:"1px solid #e5e5ea",
      },
    },
  },
  modalMainOuter:{
    height: "100%",
    padding: "25px",
    position: "relative",
    overflowY: "auto",
    boxSizing: "border-box",
  }
}));
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const SaveLessonModal = (props: any) => {
  const { open, saveModalClose,saveLessonInLib,showSuccess,type } = props;
  const [modalStyle] = React.useState(getModalStyle);
  const [publicON,setPublicON] = React.useState(false);
  const [googleON,setGoogleON] = React.useState(false);
  const classes = useStyles();
  const showType = (type as String).charAt(0).toUpperCase()+(type as String).substring(1).toLowerCase()
  let imageIcon =lessonLibIcon
  if(type==COURSES_PAGES.ASSIGNMENT){
    imageIcon=showSuccess?assigmenTsave:viewassignment2
  }

  useEffect(()=>{
    setPublicON(false)
    setGoogleON(false)
  },[open])

  return (
    <Modal
        open={open}
    >
      <Box className={classes.modalMainOuter}>
        <Box style={modalStyle} className={classes.paper}>
          <a data-test-id="saveModalClose" onClick={saveModalClose} title="close modal" className="close-modal"><img src={closeModalIcon} alt="Modal close icon" className="cross-icon"/></a>
          <Box className={classes.popupContent}>
            {!showSuccess && 
            <Box className={classes.saveDriveBlock}>
              <Box className="switch-block">
                <Box className="switch-inner">
                  <Typography>Make {`${type.toLowerCase()}`} available in the shared library</Typography>
                  <Box className="switch-wrapper">
                    <Switch name="checkedA"
                      checked={publicON} 
                      onChange={event=>setPublicON(event.target.checked)}
                      data-test-id="switchPublic"
                    />
                  </Box>
                </Box>
              </Box>
              <Box className="switch-block">
                <Box className="switch-inner">
                  <Typography>Save to Google Drive</Typography>
                  <Box className="switch-wrapper">
                    <Switch name="checkedA" 
                      checked={googleON} 
                      onChange={event=>setGoogleON(event.target.checked)}
                      data-test-id="switchGoogle"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            } 
            <Box className="image-wrapper">
              <img src={imageIcon} alt="Modal close icon"/>
            </Box>
            {showSuccess ? 
            <Typography className={classes.saveHeading}>
                {`${showType}`} Saved Successfully!
            </Typography>
            :
            <>
            <Typography className={classes.heading}>
                Are you sure you want to save {`${type.toLowerCase()}`}?
              </Typography>
              <Typography className={classes.text}>Your {`${type.toLowerCase()}`} will be saved to your Private Library by default.</Typography>
              </>
            }  
          </Box>
          {!showSuccess && 
          <Box className={classes.btnLayout}>
              <Button
                data-test-id="saveLessonBtn"
                className={classes.coloredBtn}
                onClick={()=>saveLessonInLib(publicON, googleON)}
                >
                Save
              </Button>
          </Box>}
        </Box>
      </Box>
    </Modal>
  );
};

export default SaveLessonModal;
