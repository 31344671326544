import React from "react";
import { Box, Typography, Grid,Button , Modal,TextField, Tab, styled} from "@material-ui/core";
import DashboardController from "../../DashboardController";
import Loader from "../../../../../components/src/Loader.web";
import Sidebar from "../../../../../components/src/Sidebar.web";
import AppHeaderWeb from "../../../../../components/src/AppHeader.web";
import SearchInput from "../../../../../components/src/SearchInput.web";
import ClearIcon from "@material-ui/icons/Clear";
const configJSON = require("../../config");
import CustomBreadcrumb from "../../../../../components/src/CustomBreadcrumb.web"
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from  '@material-ui/icons/HighlightOff';
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker
  } from "@material-ui/pickers";
  import DateFnsUtils from "@date-io/date-fns";
  import moment from "moment";
import { FilterIcon, DefaultClassIcon, noClassImg,
     TickIcon,  SuccessIcon,  assignments,  quizzes, StudentIcon, 
     folderDefaultIcon,
     groupAssignmentIcon,
     groupQuizIcon} from "../../assets"
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from "@material-ui/core/CircularProgress";
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import TaskPagesList from "../../../../tasks/src/TaskPagesList.web";
import { Roles } from "../../../../../components/src/types";
import { Assignments, Card, QuizzeList, StyleBox, StyledBox, StyledTab, StyledTabs } from "../../../../../components/src/AssignNewTaskComp/TeacherAssignNewTask";
import EditModal from "./EditModal.web";
import DeleteConfirmationModal from "./DeleteConfirmationModal.web";
import { searchIcon } from "../../../../../components/src/assets";
import { getStorageData, setStorageData } from "../../../../../framework/src/Utilities";

const styles = {
    tabs: {
        "&.MuiTabs-root": {
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
            borderRadius: "8px",
            border: "0.2px solid #2B3D7D",
        },
        "& .MuiTabs-indicator": {
            display: "none",
        },
        "&.MuiTabs-flexContainer": {
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
        },
    },
    tab: {
        "&.MuiTab-root": {
            fontWeight: 500,
            color: "#2B3D7D",
            width: "218px",
            fontSize: "20px",
            textTransform: "capitalize",
            borderRadius: "8px",
        },
        "&.MuiTab-root.Mui-selected": {
            color: "#FFFFFF",
            fontWeight: 600,
            textTransform: "capitalize",
            backgroundImage:
                "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
                borderRadius: "8px",
            fontSize: "20px",
            width: "218px",
        },
    },
    itemBox2: {
        alignItems: "center",
        justifyContent: "space-evenly",
        display: "flex",
        border: "1px solid rgba(149, 157, 165, 0.2)",
        padding: "15px 30px",
        borderRadius: "10px",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        minHeight: "204px",
        cursor: "pointer",
        flexDirection: "column" as "column",
        maxWidth: "180px",
        "&:hover": {
          background: "#ebf8fe",
          borderColor:"#3093DDFF",
          color: "#121212"
        }
      },
    searchBarLayout: {
        display: "flex",
        gap: "5%"
    },
    tabBar: {
        marginTop: "30px",
        display: "flex",
        justifyContent: "space-between"
    },
    addButtonLayout: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
        marginBottom: "20px",
    },
    head: {
        color: "#202020",
        backgroundColor: "#FDF4DC",
        fontWeight: 500,
        fontFamily: "Poppins",
        fontSize: "16px",
    },
    body: {
        color: "#3F526D",
        fontSize: "14px",
        fontWeight: 400,
        fontFamily: "Poppins",
    },
    input: {
      borderRadius: 4,
      border: '1px solid #ccc',
      padding: '10px 12px',
      '&:hover': {
        borderColor: '#888',
      },
      '&.Mui-focused': {
      },
    },
    inputRoot: {
      '&:before': {
        borderBottom: 'none',
      },
      '&:after': {
        borderBottom: 'none',
      },
    },
    imageBox: {
      width: "90px",
      height: "90px",
      border: "1px solid rgba(149, 157, 165, 0.2)",
      marginRight: "10px",
      padding: "8px",
      borderRadius: "10px",
      backgroundColor: "#fff",
    },
    listItemImage: {
      width: "100%",
      height: "100%",
    },
};

interface UserEmailClassData {
  id: string;
  attributes: {
    class_name: string;
  }
}
interface UserEmailStudentEmail {
  id: string;
  email: string;
}
  
class AssignNewTask extends DashboardController {

       options = [
        {
          name: "Assignments",
          image: assignments,
          action:"Assignment"
        },
        {
            name: "Quizzes",
            image: quizzes,
            action:"Quiz"
          },
      ];
       menuProps = {
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom' as "bottom",
          horizontal: 'left' as "left",
        },
        transformOrigin: {
          vertical: 'top' as "top",
          horizontal: 'left' as "left",
        },
        PaperProps: {
          style: {
            maxHeight: 48 * 4.5 + 8,
            border:"1px solid gray",
            borderTop: "none",
            padding:"0px"
          },
 
        },
      };
    
    async componentDidMount() {
        this.setState({
          role: window.localStorage.getItem("role")
        });
        this.getQuizData("assignment");

        let mainTabs = await getStorageData('assignTaskMainTab');
        let bottomTabs = await getStorageData('assignTaskTab');

        if(mainTabs == 1){
          this.setState({ activeState: true })
        }else{
          this.setState({ activeState: false })
        }

        this.setState({ tabValue: Number(bottomTabs) })
    window.addEventListener('resize', this.handleResize);

    }

  handleTabs = (value:string , status: boolean) => {
    this.setState({activeState: status, loading: true, quizList: [], page: 1, taskListPageNo: 1,quizName:""},
      ()=>{
        if (this.state.quizName === "") {
          this.getQuizData(value);
        }
      }
    )
    if(value === 'quiz'){
      setStorageData('assignTaskMainTab', '1')
    }else{
      setStorageData('assignTaskMainTab', '0')
    }
  }
  renderTabs = () => {
    const { emailActiveTab } = this.state;
    
    if (emailActiveTab === "CLASS") {
      return (
        <>
          <InnerTabsAfter  label="Class" />
          <InnerTabsBefore  label="User Email" data-testId="quizId3" onClick={() => this.handleEmailTabs("USEREMAIL")} />
          <InnerTabsBefore  label="User Group" data-testId="quizId4" onClick={() => this.handleEmailTabs("USERGROUP")} />
        </>
      );
    } else if (emailActiveTab === "USEREMAIL") {
      return (
        <>
          <InnerTabsBefore  label="Class" data-testId="LibraryId2" onClick={() => this.handleEmailTabs("CLASS")} />
          <InnerTabsAfter  label="User Email" />
          <InnerTabsBefore  label="User Group" data-testId="quizId5" onClick={() => this.handleEmailTabs("USERGROUP")} />
        </>
      );
    } else {
      return (
        <>
          <InnerTabsBefore  label="Class" data-testId="LibraryId3" onClick={() => this.handleEmailTabs("CLASS")} />
          <InnerTabsBefore  label="User Email" data-testId="LibraryId4" onClick={() => this.handleEmailTabs("USEREMAIL")} />
          <InnerTabsAfter  label="User Group" />
        </>
      );
    }
  }
  renderQuizTab = () => {
    return (
      <React.Fragment>
        <TabsLayoutMain>
          {!this.state.activeState
            ? <React.Fragment>
                <TabAfter  label="Assignment" />
                <TabBefore label="Quiz" data-testId="quizId" onClick={()=>this.handleTabs("quiz", true)}/>
              </React.Fragment>
            : <React.Fragment>
                <TabBefore label="Assignment" data-testId="assignmentId" onClick={()=> this.handleTabs("assignment",false) } />
                <TabAfter label="Quiz" />
              </React.Fragment>
          }
        </TabsLayoutMain>
      </React.Fragment>
    )
  }


  handleValidationMsg = (val: string, isValidCheck: boolean, message: string) => {
    if (val === '' && isValidCheck) {
      return message;
    } else {
      return ""
    }
  }
  handleStartDueDateValidateMsg = (val: boolean, isValidCheck: boolean, message: string) => {
    if (val && isValidCheck) {
      return message;
    } else {
      return ""
    }
  }
  handleStartDueTimeSameValidationMsg = (isCheck: boolean, message: string) => {
    if (isCheck) {
      return message;
    } else {
      return ""
    }
  }
  initStartDate = () => {
    return this.state.selectedStartDate
      ? moment(this.state.selectedStartDate, "DD/MM/YYYY", true).toDate()
      : undefined;
  };
  initDueDate = () => {
    return this.state.selectedDueDate
      ? moment(this.state.selectedDueDate, "DD/MM/YYYY", true).toDate()
      : undefined;
  };

  handleStartDateChange = (date: any) => {
    const formattedDate: string = moment(date).format("DD/MM/YYYY");
    this.setState({ selectedStartDate: formattedDate, isStartDueDateEvent: false });
  };

  handleDueDateChange = (date: any) => {
    const formattedDate: string = moment(date).format("DD/MM/YYYY");
    this.setState({ selectedDueDate: formattedDate, isStartDueDateEvent: false }, () => {
      if (this.isValidateAssignTask()) {
        this.setState({isValidateCreateEvent: this.isValidateAssignTask()});
      }
    });
  };


  handleStartTimeChange = (event: any) => {
    let formatted=  moment(event).format('hh:mm A');
      this.setState({selectedStartTime: formatted, isSameStartDueTime: false})
  }
  initStartTime = () => {
    return this.state.selectedStartTime
      ? moment(this.state.selectedStartTime,  'hh:mm A').toDate() 
      : undefined;
  };
  handleDueTimeChange = (event: any) => {
    let formatted=  moment(event).format('hh:mm A');
      this.setState({selectedDueTime: formatted,isSameStartDueTime: false})
  }
  initDueTime = () => {
    return this.state.selectedDueTime
      ? moment(this.state.selectedDueTime,  'hh:mm A').toDate() 
      : undefined;
  };
  renderAutoCompleteForClass = (id: string, options: any) => {
    return (
      <Autocomplete
        id={id}
        data-testId={id}
        placeholder="Class"
        value={{
          id: options.classId,
          attributes: { class_name: options.className }
        }}
        options={this.state.classesData}
        getOptionLabel={(option) => option?.attributes?.class_name}
        onChange={options.changeHandler}
        style={{fontFamily:"poppins", lineHeight:"14px", fontSize:"14px", marginTop:"10px"}}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Class" inputProps={{ ...params.inputProps }} />
        )}
        renderOption={(props: UserEmailClassData, option: any) => {
          return (
            <React.Fragment key={props.id}>
              <span>{props?.attributes?.class_name}</span>
              <CheckIcon fontSize="small" style={{ visibility: option.selected ? 'visible' : 'hidden' }} />
            </React.Fragment>
          );
        }}
      />
    )
  }
  
  renderSelectBox = () => {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" style={{ fill: "#1976d2" }} />;
    return (
      <React.Fragment>
        <Box style={{display: "flex", gap: "5%",justifyContent: "space-around", padding: "30px", width: "95%"}}>
          <Box style={{width:"50%"}}>
            {this.renderAutoCompleteForClass("user-email-class-name", {
              classId: this.state.assignNewSelectedUserEmailClassId,
              className: this.state.assignNewSelectedUserEmailClassName,
              changeHandler: this.handleUserEmailClassNameChange,
            })}
          </Box>
          <Box style={{width:"50%"}}>
            <Autocomplete
              id="user-email-student-email"
              data-testId="user-email-student-email"
              multiple
              placeholder="Student Email"
              noOptionsText="No Records Found"
              value={this.state.assignNewSelectedUserEmailStudentEmails}
              options={this.state.studentMails}
              getOptionLabel={(option) => option?.email}
              onChange={this.handleUserEmailStudentEmailChange}
              style={{fontFamily:"poppins", lineHeight:"14px", fontSize:"14px", marginTop:"10px"}}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Student Email" inputProps={{ ...params.inputProps }} />
              )}
              renderOption={(props: UserEmailStudentEmail, option: any) => {
                const { id, ...optionProps } = props;
                return (
                  <li key={id} {...optionProps}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={option.selected}
                    />
                    {props?.email}
                </li>
                );
              }}
            />
          </Box>
        </Box>
      </React.Fragment>
    );
  }

  renderCalender = () => {
    const timeOptions: any[] = [];
    const startTime = new Date();
    startTime.setHours(0, 0, 0, 0); // Set to midnight
    for (let i = 0; i < 24 * 4; i++) {
      // 24 hours * 4 intervals/hour
      const time = new Date(startTime.getTime() + i * 15 * 60 * 1000); // Add 15 minutes
      timeOptions.push(time);
    }
    return <DatesModal >
          <Box display="flex" alignItems={"baseline"}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Box mb={"1rem"}>
                <Typography style={webStyles.saveTimeTypo}>Start Date & Time</Typography>
                <KeyboardDatePicker
                  id="startDate"
                  name="startDate"
                  variant="dialog"
                  data-testid={"startDateId"}
                  format="dd/MM/yyyy"
                  inputVariant="outlined"
                  onChange={this.handleStartDateChange}
                  style={{ ...webStyles.picker, ...webStyles.borderStartTimeRadius }}
                  value={this.initStartDate()}
                  labelFunc={() =>
                    this.state.selectedStartDate || "Select Date"
                  }
                  className="start-time"
                  InputProps={{
                    style: webStyles.inputDateStyle,
                  }}
                  minDate={new Date()}
                />
                <Typography style={webStyles.errorTypo}>{this.handleValidationMsg(this.state.selectedStartDate, this.state.isValidateCreateEvent, "Select Start Date")}</Typography>
                <Typography style={{ ...webStyles.errorTypo }}>{this.handleStartDueDateValidateMsg(this.state.isStartDueDateEvent, this.state.isValidateCreateEvent, "Start date is greater")}</Typography>
              </Box>
              <Box>
                <KeyboardTimePicker
                  margin="normal"
                  InputProps={{
                    style: webStyles.inputDateStyle,
                  }}
                  data-testid="startTimeId"
                  id="startTime"
                  inputVariant="outlined"
                  value={this.initStartTime()}
                  onChange={this.handleStartTimeChange}
                  labelFunc={() =>
                    this.state.selectedStartTime || 'Select Time'
                  }
                  minutesStep={15}
                  mask="__:__ _M"
                  ampm
                  views={['hours', 'minutes']}
                  inputProps={{ step: 900 }}
                  className="due-time"
                  keyboardIcon={<AccessTimeIcon />}
                  style={{ ...webStyles.picker, ...webStyles.borderDueTimeRadius }}

                  {...timeOptions}
                />
                <Typography style={webStyles.errorTypo}>{this.handleValidationMsg(this.state.selectedStartTime, this.state.isValidateCreateEvent, "Select Start Time")}</Typography>
                <Typography style={webStyles.errorTypo}>{this.handleStartDueTimeSameValidationMsg(this.state.isSameStartDueTime, "Time are same!")}</Typography>
              </Box>
            </MuiPickersUtilsProvider>
          </Box>
          <Box display="flex" alignItems={"baseline"} >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Box>
                <Typography style={webStyles.saveTimeTypo}>Due Date & Time</Typography>
                <KeyboardDatePicker
                  id="dueDate"
                  name="startDate"
                  variant="dialog"
                  data-testid={"dueDateId"}
                  inputVariant="outlined"
                  onChange={this.handleDueDateChange}
                  inputProps={{ style: { fontFamily: 'Poppins', } }}
                  value={this.initDueDate()}
                  labelFunc={() =>
                    this.state.selectedDueDate || "Select Date"
                  }
                  format="dd/MM/yyyy"
                  className="start-time"
                  style={{ ...webStyles.picker, ...webStyles.borderStartTimeRadius }}
                  InputProps={{
                    style: webStyles.inputDateStyle,
                  }}
                  disabled={!this.state?.selectedStartDate || !this.state.selectedStartTime}
                  minDate={moment(this.state?.selectedStartDate, "DD/MM/YYYY", true)}
                  minDateMessage="End date should not be before the start date"
                />
                <Typography style={webStyles.errorTypo}>{this.handleValidationMsg(this.state.selectedDueDate, this.state.isValidateCreateEvent, "Select Due Date")}</Typography>
              </Box>
              <Box>
                <KeyboardTimePicker
                  margin="normal"
                  InputProps={{
                    style: webStyles.inputDateStyle,
                  }}
                  id="dueTime"
                  inputVariant="outlined"
                  value={this.initDueTime()}
                  onChange={this.handleDueTimeChange}
                  labelFunc={() =>
                    this.state.selectedDueTime || 'Select Time'
                  }
                  minutesStep={15}
                  ampm
                  views={['hours', 'minutes']}
                  inputProps={{ step: 900 }}
                  data-testid="dueTimeId"
                  keyboardIcon={<AccessTimeIcon />}
                  className="due-time"
                  style={{ ...webStyles.picker, ...webStyles.borderDueTimeRadius }}
                  mask="__:__ _M"
                  {...timeOptions}
                  disabled={!this.state?.selectedStartDate || !this.state.selectedStartTime}
                />
                <Typography style={webStyles.errorTypo}>{this.handleValidationMsg(this.state.selectedDueTime, this.state.isValidateCreateEvent, "Select Due Time")}</Typography>
                <Typography style={webStyles.errorTypo}>{this.handleStartDueTimeSameValidationMsg(this.state.isSameStartDueTime, "Time are same!")}</Typography>
              </Box>
            </MuiPickersUtilsProvider>
          </Box>
    </DatesModal>
  }
  renderAutoCompleteTags = (tagValue: any) => {
    return <>
    { tagValue.map((option:any, index:number) => (
                  <Box
                  data-testId = "closeBoxId"
                    key={index}
                    style={{
                      height: '45px',
                      width: 'fit-content',
                      cursor: 'pointer',
                      padding: '5px',
                      borderRadius: '8px',
                      gap: '10px',
                      border: '1px solid rgba(149, 157, 165, 0.2)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <CloseIcon
                    data-testId = "closeIconId"
                      style={{ color: '#2B3D7D' }}
                      onClick={this.deleteChips.bind(this,index)}
                    />
                    <Typography style={{ color: '#2B3D7D' }}>
                      {`Group - ${option?.attributes?.user_group_name}`}
                    </Typography>
                  </Box>
                ))
              }</>
  }

  renderGroupTabContent = () =>  {
    return (
      <Box style={{display: "flex", gap: "5%",justifyContent: "space-around", padding: "30px", width: "95%"}}>
        <Box style={{width:"50%"}}>
          {this.renderAutoCompleteForClass("user-group-class-name", {
            classId: this.state.assignNewSelectedUserGroupClassId,
            className: this.state.assignNewSelectedUserGroupClassName,
            changeHandler: this.handleUserGroupClassNameChange,
          })}
        </Box>
        <Box style={{width:"50%"}}>
          <Autocomplete
            multiple
            id="user-group-group-name"
            data-testId="user-group-group-name"
            noOptionsText="No Records Found"
            value={this.state.selectedChipsValue}
            onChange={this.handleChange}
            inputValue={this.state.searchnputValue}
            onInputChange={this.handleInputChange}
            placeholder="Search & Select Group"
            options={this.state.filteredUserGroupData}
            style={{fontFamily:"poppins", lineHeight:"14px", fontSize:"14px", marginTop:"10px"}}
            getOptionLabel={(option)=> this.getOption(option)}
            renderTags={(tagValue) => this.renderAutoCompleteTags(tagValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <span>{option?.attributes?.user_group_name}</span>
                <CheckIcon fontSize="small" style={{ visibility: selected ? 'visible' : 'hidden' }} />
              </React.Fragment>
            )}
          />
        </Box>
      </Box>
    )
  }

  renderEmailActiveClassList=()=>{
    return(
      <ClassList container spacing={2}>
      {(this.state.loading) && (
        <Box style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
          <CircularProgress style={{color: "#2B3D7D"}} />
        </Box>
      )}
      {(!this.state.loading && !this.state.classesData.length) && (
        <Box style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
          <Typography style={{fontFamily:"poppins", fontSize:"18px"}}>No Records Found</Typography>
        </Box>
      )}
      {!this.state.loading && this.state.classesData.map((item2:any) =>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <CardModal
          key={item2.id}
          style={{
            border:`${this.state.selectedAssignment == item2.id ? "1px solid #2B3D7D":"1px solid rgba(149, 157, 165, 0.2)"}`,
            background:`${this.state.selectedAssignment == item2.id ? "#E4F7F1":""}`,
          }}
          data-testId = "getFoldersDataId2"
          onClick={()=> 
            this.setState({
              selectedAssignment: item2.id,
              selectedClassName: item2?.attributes?.class_name,
            })
          }
        >
          {this.state.selectedAssignment == item2.id && <img src={TickIcon} style={{position: "absolute",top:"-9px", right: "-6px",}} />}
          <Box style={styles.imageBox}>
            <img style={styles.listItemImage} src={item2.attributes.attachment || noClassImg} />
          </Box>
          <Box style={{overflow:'hidden'}}>
            <Typography style={{fontFamily:"poppins", lineHeight:"32px", fontSize:"20px",letterSpacing:"0.2px",marginBottom: "10px",overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"}}>
              {item2.attributes.class_name}
            </Typography>
            <Box style= {{display:"flex", gap:"10px",justifyContent: "start",alignItems: "center"}}>
              <img src={StudentIcon} style={{ height:"14px",width:"14px" }}/>
              <Typography style={{ color:"#2B3D7D", fontSize:"14px",fontFamily:"poppins" }}>
                {`Student Count - ${item2.attributes.student_count}`}
              </Typography>
            </Box>
          </Box>
        </CardModal></Grid>
      )}
    </ClassList>
    )
  }

  renderAssignTaskModal = () => {
    const isDisabled = this.isAssignNowDisabled();

    return (
      <Modal
        style={{display:"flex", justifyContent:"center", alignItems:"center"}}
        open={this.state.assignNewTasksModal}
        onClose={this.handleAssignNewTaskModal}
      >
        <AssignTaskModal  >
          <InnerModal >
            <ClearIcon
              onClick={this.handleAssignNewTaskModal}
              style={webStyles.crossIconOption}
            />
            <Typography style={{fontFamily:"poppins", color:"#2B3D7D",fontSize:28, marginBottom:"10px",marginLeft:"30px", fontWeight:600}}>Assign Task</Typography>
            <Box>
              <Box style={webStyles.borderedBox}>{this.renderCalender()}</Box>
              <Box>
                <Typography style={{fontFamily:"poppins",marginLeft:"30px",marginBottom:"10px",marginTop:"10px", lineHeight:"14px", fontSize:`${this.state.emailActiveTab === "USEREMAIL" ? "16px" : "14px"}`, color:"#2C2C2E"}}>Assign to</Typography>
                <TabsLayout >
                  {this.renderTabs()}
                </TabsLayout>
                {
                  this.state.emailActiveTab== "CLASS" &&
                  <SearchBox style={{ padding:"20px 30px", marginBottom: "20px"}}>
                    <SearchInput
                      placeholder={"Search by name"}
                      handleSearchInput={this.handleSearch}
                      searchInput={this.state.inputValue}
                    />
                  </SearchBox>
                }
                {(this.state.emailActiveTab == "CLASS") &&
                <> {this.renderEmailActiveClassList()}</>
                }
                {(this.state.emailActiveTab == "USEREMAIL") && <React.Fragment>{this.renderSelectBox()}</React.Fragment>}
                {(this.state.emailActiveTab == "USERGROUP") && <React.Fragment>{this.renderGroupTabContent()}</React.Fragment>}
                {(this.state.emailActiveTab != "CLASS" ) && <Box style={{height:"400px", paddingTop:"25px", overflow:"auto", width:"1188px",display: "flex", flexWrap: "wrap",gap: "20px",paddingRight: "15px", position:"relative", top:"55px"}}></Box>}
                <ButtonOuter >
                  <Button
                    style={{width:"150",height:"52", border:"0.5px solid #3F526D" ,borderRadius:"8px", color:"#3F526D", fontFamily:"poppins", lineHeight:"16px", fontSize:"18px",letterSpacing:"0.11px", textAlign:"center", textTransform: "capitalize"}}
                    data-testId="cancelId2"
                    onClick={() => {
                      if (this.state.classActiveTab) {
                        this.handleCancel("FOLDER","cancel");
                        this.getSharedLibraryData("");
                      } else {
                        this.handleCancel("ASSIGNMENTS","cancel");
                        this.getFoldersData();
                      }
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{width:"150",height:"52",borderRadius:"8px", textAlign:"center", color:"white", fontFamily:"poppins", lineHeight:"16px", fontSize:"18px",letterSpacing:"0.11px", textTransform: "capitalize", backgroundImage:"linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)", opacity: (isDisabled ? 0.5 : 1)}}
                    data-testId="assignNowID"
                    disabled = {isDisabled}
                    onClick={() => this.assingTaskApi()}
                  >
                    Assign Now
                  </Button>
                </ButtonOuter>
                <Box style={webStyles.tabBarStyle}></Box>
              </Box>
            </Box>
          </InnerModal>
        </AssignTaskModal>
      </Modal>
    )
  }

  renderLibraryTabs = () => {
    return (
      <React.Fragment>
        <TabContainer
          

        >
          {!this.state.classActiveTab
            ? (
              <React.Fragment>
                <TabAfter  label="My Library" />
                <TabBefore  label="Shared Library" data-testId="quizId2" disabled={this.state.selectedClass!=="CLASS"} onClick={()=>this.handleClassTabs(true)}/>
              </React.Fragment>
            )
            : (
              <React.Fragment>
                <TabBefore  label="My Library" data-testId="LibraryId" disabled={this.state.selectedClass!=="CLASS"} onClick={()=> this.handleClassTabs(false) } />
                <TabAfter label="Shared Library" />
              </React.Fragment>
            )
          }
        </TabContainer>
      </React.Fragment>
    )
  }
  renderAssignModal = () => {
    return <>
              <Modal style={{display:"flex", justifyContent:"center", alignItems:"center"}} open={this.state.openAssignModal} onClose={this.handleCloseModal}>
    <StyledDetails >
    <Box style={webStyles.container}>
      <ClearIcon
        onClick={this.handleCloseModal}
        style={webStyles.crossIconOption}
      />
      <Box style={webStyles.popupContentOption}>
        <OptionTitle >
          {configJSON.selectAssignOption}
        </OptionTitle>
        <OptionsLayout >
          {this.options.map((option: any) => (
            <Box key={option.name}>
              <StyleBox
                data-testId=" openModalId234"
                onClick={()=> this.handleOpenClassModal(option.action)}
              >
                <img src={option.image} style={webStyles.imageOption}  />
                <Typography style={webStyles.optionTitle}>
                  {option.name}
                </Typography>
              </StyleBox>
            </Box>
          ))}
        </OptionsLayout>
      </Box>
    </Box>
  </StyledDetails>
    </Modal>
    </>
  }

  returnFoldersAndList = () => {
    let folders: any = [];
    let foldersList: any = [];

    if (this.state.actionName === "Assignment") {
      folders = this.state.foldersList?.assignment_folders?.data || [];
      foldersList = this.state.foldersList?.assignments?.data || [];
    } else if (this.state.actionName === "Quiz") {
      folders = this.state.foldersList?.quiz_folders?.data || [];
      foldersList = this.state.foldersList?.quizzes?.data || [];
    }

    return { folders, foldersList }
  }

  getFolderStyle = (selectedFolderId: string | number, folderId: string | number) => {
    if (selectedFolderId === folderId) {
      return {
        background: "#ebf8fe",
        borderColor: "#3093DDFF",
        color: "#121212",
      };
    }
    return {};
  };

  renderTickIcon = (selectedClassId: number | string, itemId: number | string) => {
    if (selectedClassId === itemId) {
      return (
        <img
          src={TickIcon}
          style={{ top: "-9px", 
            right: "-6px",position: "absolute" }}
          alt="Tick Icon"
        />
      );
    }
    return null;
  };

  getImageSrc = (attachmentImg: string | null) => {
    if(this.state.actionName==="Assignment"){
      return attachmentImg || groupAssignmentIcon;
    }else{
      return attachmentImg || groupQuizIcon
    }
  };

  getActionText = (actionName: string, index: number): string => {
    return `${actionName === "Assignment" ? "Assignments" : "Quiz"} ${index + 1}`;
  };

  handleFolderAction = () => {
    this.handleCancel("FOLDER",this.state.selectedClass === "CLASS" ? "cancel":"");
    if (this.state.classActiveTab) {
      this.getQuizData("assignment");
    } else {
      this.getClassesData("");
    }
  };

  getButtonText = (selectedClass: string): string => {
    return selectedClass === "CLASS" ? "Cancel" : "Previous";
  };
  getButtonType = (selectedClass: string):string =>{
    return selectedClass === "CLASS" ? "cancel" : "";
  }
  isButtonDisabled = () => {
    return !(
      this.state.selectedFoldersData.id ||
      this.state.selectedClassId ||
      this.state.assignentId ||
      this.state.selectedSubFolderData.id
    );
  };
 handleOpenFolder=(item2:any)=>{
  this.setState({
    selectedFoldersData: item2,
    selectedClassId: "",
    assignentId: ""
  },()=>{
    if(this.state.actionName==="Assignment"){
      this.getAssignmentFolderData()
    }else{
      this.getQuizFolderData()
    }
  })
  
 }

 handleTaskName=(item2:any)=>{
  if (this.state.actionName === "Assignment") {
    if(item2.attributes.assignment_name){
      return item2.attributes.assignment_name
    }
   
  } else if (this.state.actionName === "Quiz") {
    if(item2.attributes.quiz_name){
      return item2.attributes.quiz_name
    }
  }
 }
  renderFolderBox = () => {
    const { folders, foldersList } = this.returnFoldersAndList();

    return (
      <React.Fragment>
        <ClassList  container spacing={2} >
          {folders.map((item2:any) => {
            let length = 0;
            if (this.state.actionName === "Assignment") {
              length = item2.attributes.assignments.data.length;
            } else if (this.state.actionName === "Quiz") {
              length = item2.attributes.quizzes.data.length;
            }
            return (
              <Grid item xs={12} sm={6} md={4} lg={4}>
              <CardModal
                data-testId= "folderID"
                onClick={() =>this.handleOpenFolder(item2) }
                style={{
                  ...this.getFolderStyle(this.state.selectedFoldersData.id, item2.id),
                }}
              >
                <Box style={styles.imageBox}>
                  <img style={styles.listItemImage} src={folderDefaultIcon} />
                </Box>
                <Box style={{overflow:'hidden'}}>
                  <Typography style={{fontFamily:"poppins", lineHeight:"32px", fontSize:"20px",letterSpacing:"0.2px",marginBottom: "10px",overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"}}>{item2.attributes.folder_name}</Typography>
                  <Box style={{display:"flex", gap:"10px",justifyContent: "start",alignItems: "center"}}>
                    <img src={StudentIcon} style={{height:"14px",width:"14px"}}/>
                    <Typography style={{color:"#2B3D7D", fontSize:"14px",fontFamily:"poppins",overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"}}>{this.state.actionName === "Assignment"?`Total Assignments - ${length}`:`Total Quizzes - ${length}`}</Typography>
                  </Box>
                </Box>
              </CardModal>
              </Grid>
            );
          })}
          {foldersList.map((item2:any, index: number) => {
            let attachmentImg = "";
            if (this.state.actionName === "Assignment") {
              attachmentImg = item2.attributes?.assignment_attachment?.url;
            } else if (this.state.actionName === "Quiz") {
              attachmentImg = item2.attributes?.quiz_attachment?.url;
            }
            return (
              <Grid item xs={12} sm={6} md={4} lg={4}>
              <CardModal                   
                style={{
                  border:`${this.state.selectedClassId == item2.id ? "1px solid #2B3D7D":"1px solid rgba(149, 157, 165, 0.2)"}`,
                  background:`${this.state.selectedClassId == item2.id ? "#E4F7F1":""}`,
                }}
                data-testId="selectedAssignmentId2"
                onClick={()=> this.handleFolders(item2)}
              >
                {this.renderTickIcon(this.state.selectedClassId, item2.id)}
                <Box style={styles.imageBox}>
                  <img style={styles.listItemImage} src={this.getImageSrc(attachmentImg)} />
                </Box>
                <Box style={{overflow:'hidden'}}>
                  <Box style={{display:"flex", gap:"10px", alignItems: "center" ,justifyContent: "start"}}>
                    <img src={StudentIcon} style={{height:"14px",width:"14px",}}/>
                    <Typography style={{fontFamily:"poppins",color:"#2B3D7D", fontSize:"14px",overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"}}>{this.getActionText(this.state.actionName, index)}</Typography>
                  </Box>
                  <Typography style={{fontFamily:"poppins", fontSize:"20px", lineHeight:"32px",letterSpacing:"0.2px",marginBottom: "10px",overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"}}>{this.handleTaskName(item2)}</Typography>
                </Box>
              </CardModal>
              </Grid>
            );
          })}
          {(this.state.loading) && (
            <Box style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
              <CircularProgress style={{color: "#2B3D7D"}} />
            </Box>
          )}
          {(!this.state.loading && !folders.length && !foldersList.length) && (
            <Box style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
              <Typography style={{fontFamily:"poppins", fontSize:"18px",}}>No Records Found</Typography>
            </Box>
          )}
        </ClassList>
        <ButtonOuter style={{display:"flex",justifyContent:"flex-end", gap:"10px", alignItems:"center", padding:"52px" }}>
          <Button
            style={{width:"150",height:"52", border:"0.5px solid #3F526D" ,borderRadius:"8px", color:"#3F526D", fontFamily:"poppins", lineHeight:"16px", fontSize:"18px",letterSpacing:"0.11px", textAlign:"center",textTransform: "capitalize"}}
            data-testId="cancelId"
            onClick={this.handleFolderAction}
          >
            {this.getButtonText(this.state.selectedClass)}
          </Button>
          <Button
            style={{width:"150",height:"52",borderRadius:"8px", textAlign:"center", color:"white",fontFamily:"poppins", lineHeight:"16px", fontSize:"18px",letterSpacing:"0.11px", textTransform: "capitalize", backgroundImage:"linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)"}}
            data-testId = "nextClickId2"
            disabled={this.isButtonDisabled()}
            onClick={()=> {
              this.setState({ inputValue: "" }, () => {
                this.handleCondition();
                this.getClassesData("");
              });
            }}
          >
            Next
          </Button>
        </ButtonOuter>
      </React.Fragment>
    );
  }
handleOpenSubFolder=(item2:any)=>{
  this.setState({
    selectedClassId:"",
    assignentId: "",
    selectedSubFolderData:item2.data
  },()=>{
    if(this.state.actionName==="Assignment"){
      this.getAssignmentSubFolderData()
    }
  })
}
  renderAssignmentBox = () => {
    return (
      <React.Fragment>
        <ClassList container spacing={2}>
        {this.state.folderData.map((item2:any, index: number) =>
        <Grid item xs={12} sm={6} md={4} lg={4}>
              <CardModal
                style={{
                  ...this.getFolderStyle(this.state.selectedSubFolderData.id, item2.data.id),
                }}
                data-testId="selectedAssignmentId1"
                onClick={()=> 
                  this.handleOpenSubFolder(item2)}
              >
                <Box style={styles.imageBox}>
                  <img style={styles.listItemImage} src={folderDefaultIcon} />
                </Box>
                <Box>
                  <Typography style={{fontFamily:"poppins", lineHeight:"32px", fontSize:"20px",letterSpacing:"0.2px",marginBottom: "10px"}}>{item2.data.attributes.folder_name}</Typography>
                  <Box style={{display:"flex", gap:"10px",justifyContent: "start",alignItems: "center"}}>
                    <img src={StudentIcon} style={{height:"14px",width:"14px"}}/>
                    <Typography style={{color:"#2B3D7D", fontSize:"14px",fontFamily:"poppins"}}>{this.state.actionName === "Assignment"?`Total Assignments - ${item2.data.attributes.assignments.data.length}`:`Total Quizzes - ${item2.data.attributes.quizzes.data.length}`}</Typography>
                  </Box>
                </Box>
              </CardModal>
              </Grid>
            )
          }
          {this.state.quizzesData.map((item2:any, index: number) =>
           <Grid item xs={12} sm={6} md={4} lg={4}>
              <CardModal
                style={{
                  border:`${this.state.selectedClassId == item2.data.id ? "1px solid #2B3D7D":"1px solid rgba(149, 157, 165, 0.2)"}`,
                  background:`${this.state.selectedClassId == item2.data.id ? "#E4F7F1":""}`,
                }}
                data-testId="selectedAssignmentId3"
                onClick={()=> this.handleSubFolders(item2.data)}
              >
                {this.renderTickIcon(this.state.selectedClassId, item2.data.id)}
                <Box style={styles.imageBox}>
                  <img style={styles.listItemImage} src={this.getImageSrc(item2.data.attributes.assignment_attachment)} />
                </Box>
                <Box>
                  <Box style={{display:"flex", gap:"10px",justifyContent: "center",alignItems: "center"}}>
                    <img src={StudentIcon} style={{height:"14px",width:"14px"}}/>
                    <Typography style={{color:"#2B3D7D", fontSize:"14px",fontFamily:"poppins"}}>{this.state.actionName==="Assignment"?`Assingment ${index+1}`:`Quiz ${index+1}`}</Typography>
                  </Box>
                  <Typography style={{fontFamily:"poppins", lineHeight:"32px", fontSize:"20px",letterSpacing:"0.2px",marginBottom: "10px"}}>{this.state.actionName==="Assignment"?item2.data.attributes?.assignment_name:item2.data.attributes.quiz_name}</Typography>
                </Box>
              </CardModal>
              </Grid>
            )
          }
          {(this.state.selectedSubFolderList.length === 1 &&
            this.state.selectedSubFolderList[0].folders.length === 0 &&
            this.state.selectedSubFolderList[0].quizzes.length === 0) && (
              <Box>
                <Typography style={{ fontFamily: "poppins", fontSize: "18px" }}>
                  No Records Found
                </Typography>
              </Box>
            )}
        </ClassList>
        {(this.state.selectedClass != "FOLDER") && 
          <Box style={{display:"flex",justifyContent:"flex-end", gap:"10px", alignItems:"center", padding:"52px", marginTop:"80px" }}>
            <Button
              data-testId="cancelId"
              style={{width:"150",height:"52", borderRadius:"8px",color:"#3F526D", fontFamily:"poppins", border:"0.5px solid #3F526D" ,lineHeight:"16px", fontSize:"18px",letterSpacing:"0.11px", textAlign:"center",textTransform: "capitalize"}}
              onClick={() =>
                this.handleCancel(this.state.selectedClass,this.state.selectedClass=="CLASS" ? "cancel":"")}
            >
              {this.state.selectedClass=="CLASS" ? "Cancel": "Previous"}
            </Button>
            <Button
              data-testId = "nextClickId3"
              style={{width:"150",height:"52",borderRadius:"8px", textAlign:"center", color:"white",fontFamily:"poppins", lineHeight:"16px", fontSize:"18px",letterSpacing:"0.11px",textTransform: "capitalize",backgroundImage:"linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)"}}
              disabled = {this.isButtonDisabled()}
              onClick={()=> {
                this.setState({ inputValue: "" }, () => {
                  this.handleCondition();
                  this.getClassesData("");
                });
              }}
            >
              Next
            </Button>
          </Box>
        }
      </React.Fragment>
    );
  }
  renderShowSubfolderBox = () => {
    return (
      <React.Fragment>
        <Box style={{height:"425px", overflow:"auto", display: "flex", flexWrap: "wrap",gap: "20px",padding: "20px 30px 0 30px"}}>
          {this.state.selectedAssignmentList.map((item2:any, index: number) =>
              <Card
                style={{
                  border:`${this.state.selectedClassId == item2.id ? "1px solid #2B3D7D":"1px solid rgba(149, 157, 165, 0.2)"}`,
                  background:`${this.state.selectedClassId == item2.id ? "#E4F7F1":""}`,
                  width: '335px',
                  justifyContent: 'start',
                  padding: '0 15px'
                }}
                data-testId="selectedAssignmentId"
                onClick={()=> this.handleSubFolders(item2)}
              >
                {this.state.selectedClassId == item2.id && <img src={TickIcon} style={{position: "absolute",left: "335px", top: "-17px"}} />}
                <Box style={styles.imageBox}>
                  <img style={styles.listItemImage} src={this.getImageSrc(item2.attributes.assignment_attachment)} />
                </Box>
                <Box>
                  <Box style={{display:"flex", gap:"10px",justifyContent: "center",alignItems: "center"}}>
                    <img src={StudentIcon} style={{height:"14px",width:"14px"}}/>
                    <Typography style={{color:"#2B3D7D", fontSize:"14px",fontFamily:"poppins"}}>{this.state.actionName==="Assignment"?`Assingment ${index+1}`:`Quiz ${index+1}`}</Typography>
                  </Box>
                  <Typography style={{fontFamily:"poppins", lineHeight:"32px", fontSize:"20px",letterSpacing:"0.2px",marginBottom: "10px"}}>{this.state.actionName==="Assignment"?item2.attributes?.assignment_name:item2.attributes.quiz_name}</Typography>
                </Box>
              </Card>
            )
          }
          {(!this.state.selectedAssignmentList.length) && (
            <Box>
              <Typography style={{fontFamily:"poppins", fontSize:"18px",}}>No Records Found</Typography>
            </Box>
          )}
        </Box>
        {(this.state.selectedClass != "FOLDER") && 
          <Box style={{display:"flex",justifyContent:"flex-end", gap:"10px", alignItems:"center", padding:"52px", marginTop:"80px" }}>
            <Button
              style={{width:"150",height:"52", border:"0.5px solid #3F526D" ,borderRadius:"8px",color:"#3F526D", fontFamily:"poppins", lineHeight:"16px", fontSize:"18px",letterSpacing:"0.11px", textAlign:"center",textTransform: "capitalize"}}
              data-testId="cancelId"
              onClick={() => this.handleCancel(this.state.selectedClass,this.state.selectedClass=="CLASS" ?"cancel":"")}
            >
              {this.state.selectedClass=="CLASS" ? "Cancel": "Previous"}
            </Button>
            <Button
              style={{width:"150",height:"52",borderRadius:"8px", textAlign:"center", color:"white",fontFamily:"poppins", lineHeight:"16px", fontSize:"18px",letterSpacing:"0.11px",textTransform: "capitalize",backgroundImage:"linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)"}}
              data-testId = "nextClickId3"
              disabled = {this.state.assignentId ?  false : true}
              onClick={()=> this.handleClasses()}
            >
              Next
            </Button>
          </Box>
        }
      </React.Fragment>
    );
  }
 handleAction=(item2:any)=>{
  if(this.state.actionName === "Assignment"){
    return item2.attributes.assignment_name
  }else{
    return item2.attributes.quiz_name
  }
 } 
 handleFolderPagesUi=()=>{
  if(this.state.selectedClass == "FOLDER"){
    return this.renderFolderBox()
  }else if(this.state.selectedClass == "ASSIGNMENTS"){
    return this.renderAssignmentBox()
  }
 }
  renderClassListDataModal = () => {
    return (
      <ClassList  container spacing={2}>
        {(this.state.loading) && (
          <Box style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
            <CircularProgress style={{ color: "#2B3D7D" }} />
          </Box>
        )}

        {(!this.state.loading) && this.state.classesData.map((item2: any) => {
          let className = 0;
          let studentCount = 0;

          if (this.state.classActiveTab) {
            className = this.handleAction(item2)
            studentCount = item2.attributes.student_submit_count;
          } else {
            className = item2.attributes.class_name;
            studentCount = item2.attributes.student_count;
          }
          return (
         <Grid item xs={12} sm={6} md={4} lg={4}>

            <CardModal            
                data-testId="getFoldersDataId"
              onClick={() => { this.setState({ selectedClassData: item2 }) }}
              style={{
                ...this.getFolderStyle(this.state.selectedClassData.id, item2.id),
                
              }}
            >
              <Box style={styles.imageBox}>
                <img style={styles.listItemImage} src={item2.attributes.attachment || noClassImg} />
              </Box>
              <Box style={{overflow:"hidden"}}>
                <Typography style={{ fontFamily: "poppins", lineHeight: "32px", fontSize: "20px", letterSpacing: "0.2px", marginBottom: "10px",overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap" }}>{className}</Typography>
                <Box style={{ display: "flex", gap: "10px", justifyContent: "start", alignItems: "center" }}>
                  <img src={StudentIcon} style={{ height: "14px", width: "14px" }} />
                  <Typography style={{ color: "#2B3D7D", fontSize: "14px", fontFamily: "poppins" }}>{`Student Count - ${studentCount}`}</Typography>
                </Box>
              </Box>
            </CardModal>
</Grid>

          );
        })}
        {(!this.state.loading && !this.state.classesData.length) && (
          <Box style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
            <Typography style={{ fontFamily: "poppins", fontSize: "18px" }}>No Records Found</Typography>
          </Box>
        )}
      </ClassList>
    )
  }

  renderClassListModal = () => {
    return (
      <React.Fragment>
        <Modal
          style={{display:"flex", justifyContent:"center", alignItems:"center"}}
          open={this.state.openClassListModal}
          onClose={this.handleClassListModal}
        >
          <AssignTaskModal >
            <InnerModal >
              <ClearIcon
                onClick={this.handleClassListModal}
                style={webStyles.crossIconOption}
              />
              <Typography style={{fontFamily:"poppins", color:"#2B3D7D",fontSize:28, marginBottom:"10px",marginLeft:"30px", fontWeight:600}}>Assign Task</Typography>
              {this.renderLibraryTabs()}
              <Box style={styles.addButtonLayout} sx={{ px: "35px" }}>
                <Box>
                  <Box style={{marginBottom:"10px"}}>
                    <CustomBreadcrumb
                      data-test-id="breadCrumb"
                      locations={this.locations}
                      destination={this.state.currentLocation}
                      redirect={this.redirect.bind(this)}
                    />
                  </Box>
                  <SearchBox >
                    <SearchInput
                      placeholder={"Search by name"}
                      data-testId = "searchClassId"
                      handleSearchInput={this.handleSearch}
                      searchInput={this.state.inputValue}
                    />
                  </SearchBox>
                </Box>
              </Box>
              {(this.state.selectedClass == "CLASS") &&
                <>{this.renderClassListDataModal()}</>
              }
              {this.handleFolderPagesUi()}
              
              {
                (this.state.selectedClass == "SUBFOLDER") && <>{this.renderShowSubfolderBox()}</>
              }
              {(this.state.selectedClass == "CLASS") &&
                <ButtonOuter >
                  <Button
                    style={{width:"150",height:"52", border:"0.5px solid #3F526D" ,borderRadius:"8px", color:"#3F526D",
                            fontFamily:"poppins", lineHeight:"16px", fontSize:"18px",letterSpacing:"0.11px", textAlign:"center",
                            textTransform: "capitalize"}}                      
                    data-testId="cancelId3"
                    onClick={() => { 
                      this.handleCancel(this.state.selectedClass,this.getButtonType(this.state.selectedClass)) }}
                  >
                    {this.state.selectedClass=="CLASS" ? "Cancel": "Previous" }
                  </Button>
                  <Button
                    style={{width:"150",height:"52",borderRadius:"8px", textAlign:"center", color:"white",
                          fontFamily:"poppins", lineHeight:"16px", fontSize:"18px",letterSpacing:"0.11px", textTransform: "capitalize",
                          backgroundImage:"linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)"
                    }}                          
                    data-testId = "nextClickId"
                    disabled={this.state.selectedClassData.id ? false : true}
                    onClick={()=> {
                      this.setState({
                        inputValue: "",
                        selectedClass: "FOLDER",
                        foldersList: [],
                        selectedClassId: "",
                        selectedFoldersData: {},
                        assignNewSelectedClassId: this.state.selectedClassData?.id,
                        assignNewselectedTeacherId: this.state.selectedClassData?.attributes?.teacher_id,
                        assignNewSelectedClassName: this.state.selectedClassData?.attributes?.class_name
                      }, () => {
                        if (this.state.classActiveTab) {
                          // Shared Library
                          this.handleFolders(this.state.selectedClassData);
                          this.handleClasses();
                          this.getClassesData("");
                        } else {
                          // My Library
                          this.getClasses(this.state.selectedClassData);
                          this.getFoldersData();
                        }
                      });
                    }}
                  >
                    Next
                  </Button>
                </ButtonOuter>
              }
            </InnerModal>
          </AssignTaskModal>
        </Modal>
      </React.Fragment>
    );
  }
  renderSecTabs=()=>{
    const a11yProps=(index: any)=> {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      };
    }
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    let value = !this.state.activeState ? "assignment" : "quiz";
      this.setState({tabValue:newValue, taskListPageNo: 1,quizList: [],page: 1,loading: true,},
        ()=>{
          this.getQuizData(value);
        }
      );
      if(newValue === 0){
        setStorageData('assignTaskTab', '0')
      }else if(newValue === 1){
        setStorageData('assignTaskTab', '1')
      }else {
        setStorageData('assignTaskTab', '2')
      }
    };
    return (
      <React.Fragment>
        <Box sx={{ px:"10px",display: "flex",justifyContent: "space-between" }}>
          <StyledTabs variant="scrollable"
          scrollButtons="on"
          style={{
            maxWidth: "100%",
            width: "calc(100% - 20px)",
          }}
          data-test-id='handleTabValueChange' value={this.state.tabValue} indicatorColor="primary" onChange={handleChange} aria-label="simple tabs example">
            <StyledTab label="Assigned Tasks"  {...a11yProps(0)} />
            <StyledTab label="Feedback sent" {...a11yProps(1)} />
            <StyledTab label="Pending Review" {...a11yProps(2)} />
          </StyledTabs>
        </Box>
      </React.Fragment>
    )
  }
  renderEditModal = () => {
    let lessonType = !this.state.activeState ? "Assignment" : "Quiz";
    return (
      <EditModal
        open={this.state.openEditModal}
        state={this.state}
        lessonType={lessonType}
        handleClose={this.handleCloseEditModal}
        handleEditClassChange={this.handleEditClassChange}
        handleLessonNameChange={this.handleLessonNameChange}
        handleEditStartDateChange={this.handleEditStartDateChange}
        handleEditDueDateChange={this.handleEditDueDateChange}
        handleLessonObjectiveChange={this.handleLessonObjectiveChange}
        handleEditAction={this.editFuncAssignedTask}
      />
    );
  }
  renderDeleteConfirmationModal = () => {
    const label = "Task";
    let lessonType = !this.state.activeState ? "Assignment" : "Quiz";
    return (
      <DeleteConfirmationModal
        open={this.state.openDeleteConfirmationModal}
        state={this.state}
        label={label}
        lessonType={lessonType}
        handleClose={this.handleCloseDeleteConfirmationModal}
        handleDeleteAction={this.deleteFuncTask}
      />
    );
  }

  render() {
    return (
      <React.Fragment>
        <Grid container style={{ overflowX: "hidden", height: "100%" }}>
        {!this.state.isSmallScreen && (
              <Grid item xs={2}>
                <Sidebar />
              </Grid>
            )}
          <Grid item {...(!this.state.isSmallScreen && { xs: 10 })}  style={{ overflowY: "scroll", height: "100%" }}>
            <Grid item md={12}>
              <AppHeaderWeb title={"Assign Task"} />
            </Grid>
            <Grid item md={12} data-testid="renderSwitch">


          <Box>
                <MainTab  sx={{ px: "35px" }}>
                  {this.renderQuizTab()}
                    <Tab style={{...webStyles.assgnBtn,borderRadius:"8px", opacity: (this.state.role === Roles.SUB_ADMIN ? 0.5 : 1)}}  data-testId="openModalId" onClick={()=>this.setState({openAssignModal: true})} label="+ Assign New" disabled={this.state.role === Roles.SUB_ADMIN}/></MainTab>
                {this.renderSecTabs()}
                {
                   this.state.tabValue === 0 &&
                <Box style={styles.addButtonLayout} sx={{ px: "35px" }}>
                    <Box style={styles.searchBarLayout}>
                        <Box style={webStyles.searchbarBox}>
                          <Box style={webStyles.searchbarIconbox}> <img src={searchIcon} alt="Search Icon" /></Box>
                          <ResponsiveInputSearch
                            data-test-id='inputSearch'
                            placeholder={'Search by name'}
                            value={this.state.quizName}
                            onChange={(e)=> this.setQuizeName(e.target.value)}
                          />
                        </Box>
                    </Box>
                </Box>
                }
                {!this.state.activeState  && this.state.tabValue === 0 && (
                  <Assignments
                    getTaskLists={this.getTaskLists}
                    taskLists={this.state.quizList}
                    page={this.state.page}
                    pageLimit={this.state.pageLimit}
                    loading={this.state.loading}
                    tabValue={this.state.tabValue}
                    handleOpenEditModal={this.handleOpenEditModal}
                    handleOpenDeleteConfirmationModal={this.handleOpenDeleteConfirmationModal}
                    viewMyLibraryAssignment={this.viewMyLibraryAssignment}
                    handlePageChange={this.handlePageChange.bind(this)}
                  />
                )}
                {this.state.activeState && this.state.tabValue === 0 && (
                  <QuizzeList
                    quizList={this.state.quizList}
                    page={this.state.page}
                    pageLimit={this.state.pageLimit}
                    loading={this.state.loading}
                    tabValue={this.state.tabValue}
                    handleOpenEditModal={this.handleOpenEditModal}
                    handleOpenDeleteConfirmationModal={this.handleOpenDeleteConfirmationModal}
                    viewMyLibraryQuiz={this.viewMyLibraryQuiz}
                    handlePageChange={this.handlePageChange.bind(this)}
                  />
                )}
                    <TaskPagesList
                      navigation={'assigntasks'}
                      id="12"
                      activeState={this.state.activeState}
                      tabValue={this.state.tabValue}
                      taskListPageNo={this.state.taskListPageNo}
                      taskListPageLimit={this.state.taskListPageLimit}
                      setTaskPageNo={this.setTaskPageNo}
                      setTaskPageLimit={this.setTaskPageLimit}
                    />
                
            </Box> 
            
            </Grid>
         </Grid> 
         </Grid>
        {this.renderAssignModal()}
        {this.renderClassListModal()}
        {this.renderAssignTaskModal()}
        {this.renderEditModal()}
        {this.renderDeleteConfirmationModal()}
        <Modal
          style={{display:"flex", justifyContent:"center", alignItems:"center"}}
          open={this.state.successModal}
          onClose={this.handleCloseModal}
        >
          <SuccessBox >
            <Box style={webStyles.container}>
              <ClearIcon
                onClick={this.handleCloseModal}
                style={webStyles.crossIconOption}
              />
              <Box style={webStyles.popupContentOption}>
                <Box style={webStyles.optionsLayout}>
                  <Box style={{display:"flex", flexDirection:"column",justifyContent:"center",alignItems: "center"}}>
                    <StyledBox data-testId="openModalId234">
                      <img src={SuccessIcon} style={webStyles.imageOption} />
                    </StyledBox>
                    <Typography style={{...webStyles.titleOption, marginTop:"20px", marginBottom:"0px", textAlign: "center"}}>
                      {this.state.assignNewSelectedClassName} - {this.state.assignNewSelectedFolderName}
                    </Typography>
                    <Typography style={{fontFamily:"poppins", lineHeight:"28px", fontSize: "18",textAlign: "center"}}> {this.state.actionName === "Quiz" ? 'Quiz' : 'Assignment' } has been set successfully!</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </SuccessBox>
        </Modal>
      </React.Fragment>
    )
  }
}
export default AssignNewTask;
const StyledDetails = styled('div')({
width: "740px",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        borderRadius: "10px",
        display: "flex",
        position: "absolute" as "absolute",
        padding: "35px 0",
        justifyContent: "center",
        backgroundColor: "white",
        alignItems: "center",
        "@media (max-width: 748px)": {
    width: "80%",
        padding: "30px 0",
},
        "@media (max-width: 600px)": {
    width: "90%",
        padding: "26px 0",
},
 });
 const OptionsLayout = styled('div') ({
  display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
    marginBottom: "40px",
"@media (max-width: 600px)": {
  flexDirection:"column",
      alignItems: "center",
      gap: "20px",
      padding: "0px 10px",
      textAlign: "center"
      }
  },

);
export const ResponsiveInputSearch = styled('input') ({
  width: "538px",
  height: "52px",
  border: "1px solid rgba(43,61,125,0.39)",
  background: "#fff",
  fontWeight: 400,
  paddingLeft: "40px",
  fontFamily: "Poppins",
  fontSize: "16px",
  borderRadius: "8px",
  '@media (max-width: 748px)': {
    width: '100%'
  },
  
})
 const OptionTitle = styled(Typography) ({
fontWeight: 600,
        color: "#2B3D7D",
        fontFamily: "Poppins",
        marginBottom: "40px",
        fontSize: "28px",
  paddingTop: '5px',

"@media (max-width: 600px)": {
  fontSize: "1.1rem",
      marginTop: "20px"
      }
})
const TabContainer = styled(Box) ({
  borderRadius: "4px",
  marginLeft: "30px",
  width: '36.1%',
  display:"flex",
  "@media (max-width: 600px)": {
    // width:"35%",
    flexDirection:"column" ,
    marginLeft: "10px",
    marginRight: "10px",
  },
})

const AssignTaskModal = styled(Box) ({
  position: "absolute" as "absolute",
    width: "91vw",
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    padding: "35px 0",
    justifyContent: "center",
    alignItems: "center",
    height:"calc(100% - 70px)",
    overflow:"auto",
})
const SearchBox = styled(Box) ({
  width: "456px",
  "@media (max-width: 600px)": {
    width:"auto",
   paddingRight:'15px'

  },
})


const ClassList = styled(Grid)({
  height: "425px",
  overflow: "auto",
  paddingLeft: "35px",
  paddingRight:"35px",
  '@media (max-width: 600px)':{
    paddingRight:"35px",
    width: "100%", 
  }
});
const TabAfter = styled(Tab) ({
  fontWeight: 600,
  color: "#FFFFFF",
  textTransform: "capitalize" as "capitalize",
  backgroundColor: "#2B3D7D",
  fontSize: "20px",
  borderRadius: "4px",
  width: "218px",
  opacity: 1,
  '@media (max-width: 870px)': {
    minWidth: "80%",
  },
  '@media (max-width: 600px)': {
    minWidth: "100%",
  },
  
})
const TabBefore = styled(Tab)({
  color: "#2B3D7D",
  fontWeight: 500,
  fontSize: "20px",
  width: "218px",
  textTransform: "capitalize" as "capitalize",
  borderRadius: "4px",
  border: "1px solid #2B3D7D",
  '@media (max-width: 870px)': {
    minWidth: "80%",
  },
  '@media (max-width: 600px)': {
    minWidth: "100%",
  },
 
})
const CardModal = styled(Box)({
  cursor: "pointer",
  display: "flex",
  height: "135px",
  borderRadius: "8px",
  justifyContent: "center",
  gap: "20px",
  alignItems: "center",
  position: "relative",
  border: "1px solid rgba(149, 157, 165, 0.2)",
  padding: '0 15px',
  "&:hover": {
    background: "#ebf8fe",
    borderColor: "#3093DDFF",
    color: "#121212",
  },
 
});


const InnerModal = styled(Box)({
  backgroundColor: "#FFFFFF",
  width: "100%",
  height: "800px",
})
const DatesModal = styled(Box)({
  display:'flex', 
  gap:'16px',
  
  '@media (max-width: 1024px)': {
    flexDirection:'column',
    gap:'0px'
  },
  '@media (max-width: 600px)': {
    flexDirection:'column',
    gap:'0px'
  },
})
const MainTab = styled(Box)({
  marginTop: "30px",
  display: "flex",
  justifyContent: "space-between",
  
  '@media (max-width: 480px)': {
    flexDirection:'column',
    gap:'10px'
  },
})
const ButtonOuter = styled(Box)({
  display: "flex", justifyContent: "flex-end", gap: "10px", alignItems: "center", padding: "52px", paddingBottom: 0,
  '@media (max-width: 1024px)': {
    width: "80%",
  },
  '@media (max-width: 600px)': {
    // width: "45%",
  },
})
const SuccessBox = styled(Box)({
  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
  borderRadius: "10px",
  display: "flex",
  position: "absolute" as "absolute",
  // padding: "35px 0",
  justifyContent: "center",
  backgroundColor: "white",
  alignItems: "center",
   width:"460px", height:"343px", padding: "35px 15px",
  '@media (max-width: 600px)': {
    width: "70%",
  },
  // '@media (max-width: 600px)': {
  //   // width: "45%",
  // },
})
const TabsLayout = styled(Box)({
   borderRadius:"4px",width:" 480px", marginLeft: "30px",
  
  '@media (max-width: 600px)': {
    width:"auto"
  },
  '@media (max-width: 530px)': {
    display:'flex',
    flexDirection:'column',
    width:"auto"
  },
})
const TabsLayoutMain = styled(Box)({
  
 '@media (max-width: 480px)': {
   width:"218px"
 },
})
const InnerTabsBefore = styled(Tab)({
  color: "#2B3D7D",
  fontWeight: 500,
  fontSize: "20px",
  width: "160px",
  textTransform: "capitalize" as "capitalize",
  borderRadius: "4px",
  border: "1px solid #2B3D7D",
  '@media (max-width: 600px)': {
    width:"33%"
  },
  '@media (max-width: 530px)': {
    width:"100%"
  },
})
const InnerTabsAfter = styled(Tab)({
  fontWeight: 600,
    color: "#FFFFFF",
    textTransform: "capitalize" as "capitalize",
    backgroundColor:"#2B3D7D",
    fontSize: "20px",
    borderRadius: "4px",
    width: "160px",
    opacity:1,
  '@media (max-width: 600px)': {
    width:"33%"
  },
  '@media (max-width: 530px)': {
    width:"100%"
  },
})
export const webStyles = {
    tabBefore: 
        {
        color: "#2B3D7D", 
        fontWeight: 500, 
        fontSize: "20px", 
        width: "218px",
        textTransform: "capitalize" as "capitalize",
        borderRadius: "4px",
        borderRight:"1px solid gray"
    },
    tabAfter: {
    fontWeight: 600,
    color: "#FFFFFF",
    textTransform: "capitalize" as "capitalize",
    backgroundColor:"#2B3D7D",
    fontSize: "20px",
    borderRadius: "4px",
    width: "218px",
    opacity:1
    },
    assgnBtn: {
        color:" #FFFFFF",
        width: "218px",
        fontSize: "20px",
        fontWeight: 500,
        opacity:1,
        textTransform: "capitalize" as "capitalize",
        backgroundImage: "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
    },
      imageOption: {
        marginBottom: "10px",
      },
      popupContentOption: {
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "center",
      },
      container: {
        width: "100%",
      },
      titleOption: {
        fontWeight: 600,
        color: "#2B3D7D",
        fontFamily: "Poppins",
        marginBottom: "40px",
        fontSize: "28px",
      },
      optionTitle: {
        fontWeight: 500,
        fontFamily: "Poppins",
        color: "#282829",
        fontSize: "20px",
      },
      optionsLayout: {
        marginBottom: "40px",
        display: "flex",
        width: "100%",
        justifyContent: "space-evenly",
      },
      paperOption: {
        width: "740px",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        borderRadius: "10px",
        display: "flex",
        position: "absolute" as "absolute",
        padding: "35px 0",
        justifyContent: "center",
        backgroundColor: "white",
        alignItems: "center",
      },
    
      crossIconOption: {
        position: "absolute" as "absolute",
        top: "10px",
        right: "10px",
        cursor:"pointer"
      },
      tabStyle: {
        "&.MuiTab-root": {
          color: "#2B3D7D",
          fontWeight: 500,
          fontSize: "20px",
          width: "218px",
          textTransform: "capitalize" as "capitalize",
         fontFamily:"Poppins"
        },
        "&.MuiTab-root.Mui-selected": {
          fontWeight: 600,
          color: "#FFFFFF",
          textTransform: "capitalize" as "capitalize",
          backgroundImage:
            "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
          fontSize: "20px",
          width: "218px",
        },
      },
      tabBarStyle: {
        marginTop: "30px",
        display: "flex",
        justifyContent: "start",
        marginLeft: "35px"
      },
      borderedBox: {
        display: 'flex',
        alignItems: 'baseline',
        marginLeft:"30px",
        width:"34%"
      },
      picker: {
        position: "relative" as "relative",
        "& .MuiInputAdornment-root": {
          marginRight: 0,
        },
        "& .MuiIconButton-root": {
          position: "absolute",
          left: 0,
          padding: "10px",
        },
        "& .MuiInputBase-input": {
          paddingLeft: "40px",
        },
      },
      searchbarIconbox: {
        position: "absolute" as "absolute",
        top: "13px",
        left: "10px",
      },
      searchbarBox: {
        position: "relative" as "relative",
        display: "flex",
        gap: "4px",
      },
      searchFilterinput: {
        width: "538px",
        height: "52px",
        border: "1px solid rgba(43,61,125,0.39)",
        background: "#fff",
        fontWeight: 400,
        paddingLeft: "40px",
        fontFamily: "Poppins",
        fontSize: "16px",
        borderRadius: "8px",
        '@media (max-width: 600px)': {
          width: '100%'
        },
      },
      errorTypo: {
        color: "red", paddingTop: "4px", fontSize: "14px", fontFamily: 'Poppins'
      },
      inputDateStyle: {
        border: "none", fontFamily: 'Poppins', width: '210px',
      },
      noRecords: {
        fontFamily: "Poppins",
        textAlign: "center" as "center",
        paddingTop: "35px",
      },
      borderDueTimeRadius: {
        borderBottomLeftRadius: "0px !important", borderTopLeftRadius: '0px !important', marginTop: '23px', fontFamily: 'Poppins'
      },
      borderStartTimeRadius: {
        borderBottomRightRadius: "0px !important", borderTopRightRadius: '0px !important', marginTop: '16px', fontFamily: 'Poppins'
      },
      saveTimeTypo: {
        color: "#2C2C2E",
        fontFamily: 'Poppins'
      },
      addButtonLayout: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
        marginBottom: "20px",
      },
}
