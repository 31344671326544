import React from "react";
import { Modal } from "@material-ui/core";

import NotesController, {
    Props,
} from "../NotesController";
import SaveNotesPopup from "./components/SaveNotesPopup.web";
import DeleteNotesModal from "./components/DeleteNotesModal.web";
import AddEditNotesModal from "./components/AddEditNotesModal";

export default class TeacherNotes extends NotesController {
    constructor(props: Props) {
        super(props);
        this.handleCloseSavedSuccessPopUp = this.handleCloseSavedSuccessPopUp.bind(this);
        this.handleOpenSavedSuccessModal = this.handleOpenSavedSuccessModal.bind(this);
        this.handleOpenDeleteModal = this.handleOpenDeleteModal.bind(this);
        this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
        this.addNotes = this.addNotes.bind(this)
        this.handleChangeNotesInput = this.handleChangeNotesInput.bind(this)
        this.updateNoteData = this.updateNoteData.bind(this)
        this.deleteNoteApi = this.deleteNoteApi.bind(this)
        this.handleCloseAddEditModal=this.handleCloseAddEditModal.bind(this)
    }


    renderDefaultView() {
console.log("<===opennnnn",this.props.open)
        return (<>
            <Modal open={this.props.open.show} onClose={this.props.handleCloseModal}>
                <AddEditNotesModal
                    openDeleteModal={this.handleOpenDeleteModal}
                    addNotes={this.addNotes}
                    handleChangeNotesInput={this.handleChangeNotesInput}
                    notesList={this.state.notesList}
                    data={this.props.open}
                    updateNoteData={this.updateNoteData}
                    handleCloseAddEditModal={this.handleCloseAddEditModal}
                />
            </Modal>
            <Modal
                open={this.state.showSaveNotesSuccessModal}
                onClose={this.handleCloseSavedSuccessPopUp}>
                <SaveNotesPopup
                    handleCloseSavedSuccessPopUp={this.handleCloseSavedSuccessPopUp}
                    lessonName={this.state.lessonName}
                />
            </Modal>
            <Modal open={this.state.showDeletePopUp} onClose={this.handleCloseDeleteModal}>
                <DeleteNotesModal
                    handleCloseDeleteModal={this.handleCloseDeleteModal}
                    deleteNoteApi={this.deleteNoteApi}
                    lessonName={this.state.lessonName}
                    itemType={this.state.itemType}
                />
            </Modal>
        </>
        );
    }

    render() {
        return (
            <>
                {this.renderDefaultView()}
            </>
        );
    }
}

