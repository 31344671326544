import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    Grid,
    Button,
    DialogContent,
    Modal,
    Backdrop,
    Fade
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';

import AssessmentQuizTeacherController, {
    Props,
    configJSON,
} from "./AssessmentQuizTeacherController.web";
import { RightSideIcon, infoIcon, leftSideIcon, studentSubmittedIcon } from ".././assets";
import { IconBtn } from ".././AssessmentTest.web";
import { closeIcon, tickIcon } from "../../../MultipageForms2/src/assets";
import { BootstrapDialog } from "../AssessmentTable.web";
import { SuccessSnackbar } from "../../../../components/src/CustomSuccessPopup.web";
import StaticCanvas from "../../../../components/src/StaticCanvas.web";
interface QuizMarkupTool {
    id: string;
    attributes: Attributes;
    type: string;
}

interface Attributes {
    quiz_id: number;
    quiz_stage_id: number;
    canvas_data: any;
    description: string | null;
    stage_number: number;
    point: boolean;
    questions: QuestionItem[];
    double_point: boolean;
    countdown_timer: string;
    quiz_stage_name: string;
    title: string | null;
    background_image: null;
    videos: null;
    images: null;
    music: null;
    matching_pairs: MatchingPair[];
}

interface MatchingPair {
    pair_question: string;
    id: number;
    order_no: number;
    pair_correct_answer: string;
    pair_answer: string;
    lesson_markup_tools_id: number | null;
    created_at: string;
    quiz_markup_tools_id: number;
    assignment_markup_tools_id: number | null;
    updated_at: string;
}

interface QuestionItem {
    options: Option[];
    question_background_image: string | null;
    question: Question;
}

interface Question {
    lesson_markup_tools_id: number;
    id: number;
    created_at: string;
    updated_at: string;
    question: string;
}

interface Option {
    marked_as_right_answer: boolean | null;
    id: number;
    lesson_mcq_questions_id: number;
    created_at: string;
    answer: string;
    order_no: number | null;
    updated_at: string;
}


interface AnswerApiResponse {
    student_name?: string;
    student_image?: string | null;
    question?: string | null;
    student_answer?: string | number | string[];
    options?: string | null;
    teacher_correct_answer?: string | null;
    answers?: {
        student_name: string;
        student_image: string | null;
        teacher_correct_answer: string | null;
        student_answer: string | number | string[] | undefined;
    }[];
}

// Customizable Area End

export default class AssessmentQuizTeacher extends AssessmentQuizTeacherController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderOpenEndedQuestion(currentData: QuizMarkupTool, answerData: any) {
        let finalData = answerData && answerData[answerData.length - 1]

        const { quizCanvass, quizCanvassSize } = this.canvasImageCondition(currentData)

        return (
            <PropertyDeveloperCss>
            <Box style={webStyle.openEndedWrapper}>
                <Box className={"openEndedMainBoxStage"}>
                    <Grid style={webStyle.firstTextGrid}><Typography style={webStyle.firstHedingText}>Open-Ended Question</Typography></Grid>
                    <Grid><Typography style={webStyle.textStyle2} dangerouslySetInnerHTML={{ __html: this.setInnerHTML(currentData.attributes.title) }} /></Grid>
                    {quizCanvass && <StaticCanvas canvasData={quizCanvass} id="canvasId" canvasSize={quizCanvassSize ? JSON.parse(quizCanvassSize) : undefined}/>}
                    <Grid item>
                        <>
                            <textarea rows={10} placeholder="Answers"
                                disabled
                                style={{
                                    ...webStyle.questionTextfield,
                                    padding: '5px 0 0 5px',
                                }}
                                value={finalData && finalData?.student_answer}
                            />
                        </>
                    </Grid>
                    <Grid item>
                        <Button style={webStyle.submitButtonActive as React.CSSProperties}
                            onClick={() => this.addFeedback(currentData.id)}
                            data-test-id="BtnSendFeedbackOpenEnded"
                        >{this.getFeedbackBtnTxt(this.state.quizStatus)}</Button>
                    </Grid>
                </Box>
            </Box>
            </PropertyDeveloperCss>
        );
    }

    renderMCQorPollingQuestion(currentData: QuizMarkupTool, answerData: any) {
        let finalData = answerData && answerData[answerData.length - 1]

        const { quizCanvass, quizCanvassSize } = this.canvasImageCondition(currentData)

        return (
            <Box sx={webStyle.mainWrapper}>
                <Grid justifyContent="center" alignItems="center" container style={webStyle.mainWrapperContainer as React.CSSProperties}>
                    <MainContainersWidth item >
                        <Grid style={webStyle.mainWrapperForWordCloud}>
                            <Grid style={webStyle.firstTextGrid}><Typography style={webStyle.firstHedingText}>
                                {this.checkIsMcqOrPolling(currentData.attributes.quiz_stage_name)}
                            </Typography>
                            </Grid>
                            <Grid>
                                <Typography style={webStyle.textStyle2} dangerouslySetInnerHTML={{ __html: this.setInnerHTML(currentData.attributes.questions[0].question.question) }} />
                            </Grid>
                            {quizCanvass && <StaticCanvas canvasData={quizCanvass} id="canvasId" canvasSize={quizCanvassSize ? JSON.parse(quizCanvassSize) : undefined}/>}
                            {currentData.attributes.questions && currentData.attributes.questions[0].options.map((value, index) => (
                                <Grid
                                    data-test-id="selectAnswerMcq"
                                    style={this.getAnswerStyle(value.answer, finalData, currentData.attributes.quiz_stage_name) as React.CSSProperties}
                                    key={index}
                                >
                                    {value.answer}
                                    {(finalData && finalData.answers && finalData.answers[0] && finalData.answers[0].answer && finalData.answers[0].answer.includes(value.answer)) &&
                                        <Grid style={webStyle.containerForIcon as React.CSSProperties}>
                                            <img src={studentSubmittedIcon} alt="profileIcon" style={webStyle.icoStyle as React.CSSProperties} />
                                        </Grid>
                                    }
                                    { currentData.attributes.quiz_stage_name === 'MCQ' && value.answer === (finalData && finalData.student_answer) &&
                                        <Grid style={webStyle.containerForIcon as React.CSSProperties}>
                                            <img src={studentSubmittedIcon} alt="profileIcon" style={webStyle.icoStyle as React.CSSProperties} />
                                        </Grid>
                                    }
                                </Grid>
                            ))}
                            <Grid item>
                                <Button style={webStyle.submitButtonActive as React.CSSProperties}
                                    onClick={() => this.addFeedback(currentData.id)}
                                    data-test-id="BtnSendFeedbackMcqAndPolling"
                                >{this.getFeedbackBtnTxt(this.state.quizStatus)}</Button>
                            </Grid>
                        </Grid>
                    </MainContainersWidth>
                </Grid>
            </Box>
        );
    }

    renderMatchingQuestion(currentData: QuizMarkupTool, answers: AnswerApiResponse[]) {
        const { quizCanvass, quizCanvassSize } = this.canvasImageCondition(currentData)
        
        return (
            <PropertyDeveloperCss>
                <Box sx={webStyle.mainWrapper}>
                    <Grid justifyContent="center" alignItems="center" container style={webStyle.mainWrapperContainer as React.CSSProperties}>
                        <MainContainerWidthDragQuiz>
                            <Typography style={webStyle.firstHedingText} >{configJSON.matchingQuestionTitle}</Typography>
                            <Typography style={webStyle.textStyle2} dangerouslySetInnerHTML={{ __html: this.setInnerHTML(currentData.attributes.title) }} />
                            {quizCanvass && <StaticCanvas canvasData={quizCanvass} id="canvasId" canvasSize={quizCanvassSize ? JSON.parse(quizCanvassSize) : undefined}/>}
                            <Box>
                                <Box className="headingLayout">
                                    <Box className="heading">A</Box>
                                    <Box className="heading">B</Box>
                                </Box>
                                <Grid container justifyContent="space-between" spacing={3}>
                                    <Grid item md={6} sm={6} xs={6}>
                                        {currentData.attributes.matching_pairs.map((question, index) => (<Box key={index} className="matchingBoxA">{question.pair_question}</Box>))}
                                    </Grid>
                                    <Grid item md={6} sm={6} xs={6}>
                                        {answers?.map((question, index) => {
                                            return <Box
                                                data-test-id="dragDrop"
                                                key={index}
                                                className={question?.answers?.[question?.answers.length - 1]?.student_answer === question?.answers?.[question?.answers.length - 1]?.teacher_correct_answer ? 'matchingBoxBCorrect' : 'matchingBoxBWrong'}
                                            >
                                                {question?.answers?.[question?.answers.length - 1]?.student_answer}
                                            </Box>
                                        })}
                                    </Grid>
                                </Grid>
                            </Box>
                            <Grid item>
                                <Button style={webStyle.submitButtonActive as React.CSSProperties}
                                    onClick={() => this.addFeedback(currentData.id)}
                                    data-test-id="BtnSendFeedbackMatchingQuestion"
                                >{this.getFeedbackBtnTxt(this.state.quizStatus)}</Button>
                            </Grid>
                        </MainContainerWidthDragQuiz>
                    </Grid>
                </Box>
            </PropertyDeveloperCss>
        );
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { quizName, className, dueDate, quizStatus, isQuizLoading, answerData } = this.state
        const currentData = this.state.allData[this.state.currentStep];
        const dataType = currentData?.attributes?.quiz_stage_name;

        let content;

        switch (dataType) {
            case 'open_ended_question':
                content = this.renderOpenEndedQuestion(currentData, answerData);
                break;
            case 'MCQ':
            case 'polling_question':
                content = this.renderMCQorPollingQuestion(currentData, answerData);
                break;
            case 'matching_question':
                content = this.renderMatchingQuestion(currentData, answerData);
                break;
            default:
                content = null;
        }

        // Customizable Area End
        return (
            // Customizable Area Start
            <Box style={webStyle.mainBox}>
                <SuccessSnackbar
                    open={this.state.isLimitModalOpen} 
                    message={'Your 2GB storage limit has been reached. Please delete few files to refresh your limit.'} 
                    type={'error'}
                    onClose={this.handleCloseSnackbar} 
                />
<NavMain container>
                        <Grid item>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <ArrowBackIosIcon
                                        data-test-id="btnBack"
                                        onClick={() => this.backToTaskList()}
                                        style={webStyle.backIcon}
                                    />
                                </Grid>
                                <Grid item style={webStyle.quizName}>{quizName}</Grid>
                            </Grid>
                        </Grid>

                        <Grid item style={{ flex: 1 }}>
                            <NavMainRight container spacing={2}>
                                <Grid item>
                                    <Grid container direction="column">
                                        <Grid item style={webStyle.rightNavTopTxt}>{configJSON.classTxt}</Grid>
                                        <Grid item style={webStyle.rightNavBottomTxt}>{className}</Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="column">
                                        <Grid item style={webStyle.rightNavTopTxt}>{configJSON.dueDateTxt}</Grid>
                                        <Grid item>
                                            <Grid container alignItems="center">
                                            <Typography style={webStyle.dueDate} >
                                <QueryBuilderIcon style={webStyle.timeIcon} />
                                {dueDate} {configJSON.daysLeft}
                            </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="column">
                                        <Grid item style={webStyle.rightNavTopTxt}>{configJSON.statusTxt}</Grid>
                                        <Grid item style={webStyle.rightNavBottomTxt}>{quizStatus}
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {
                            quizStatus === 'Completed' ? '' :
                            <Grid item>
                            <button
                                data-test-id='sendOverAllFeedback'
                                style={webStyle.markAsCompleted}
                                onClick={() => this.sendOverallFeedback(false)}
                            >
                                {configJSON.sendFeedbackTxt}
                            </button></Grid>
                        }
                            </NavMainRight>
                        </Grid>
                    </NavMain>
                {content}

                {
                    !isQuizLoading && <Grid container alignItems="center" justifyContent="center" style={webStyle.pginationGrid as React.CSSProperties}>
                        <Grid item><img src={leftSideIcon} style={webStyle.cursorPointer} data-test-id="handleBack" onClick={this.handleBack} /></Grid>
                        <Grid item style={webStyle.paginationText}>{`${this.state.allData[this.state.currentStep]?.attributes?.stage_number}/${this.state.allData.length}`}</Grid>
                        <Grid item>
                            <img src={RightSideIcon} style={webStyle.cursorPointer} data-test-id="handelNext"
                                onClick={() => {
                                    if (this.state.currentStep < this.state.allData.length - 1) {
                                        this.handelNext();
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                }

                <BootstrapDialog
                    onClose={this.handleCloseModal}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.isModalOpen}
                >
                    <IconBtn
                        aria-label="close"
                        data-test-id='btnCloseModal'
                        onClick={this.handleCloseModal}
                    >
                        <img src={closeIcon} alt="close_icon" />
                    </IconBtn>

                    <DialogContent dividers>
                        <img style={webStyle.tickIcon} src={tickIcon} alt="" />
                        <Typography style={webStyle.feedbackSentTxt}>
                            Feedback Sent!
                        </Typography>
                        <button
                         data-test-id='handleDoneAfterSendFeedback' 
                         onClick={this.handleDoneAfterSendFeedback} 
                         style={webStyle.feedbackSentDoneBtn}>Done</button>
                    </DialogContent>
                </BootstrapDialog>

                <BootstrapDialog
                    open={this.state.isAlertModalOpen}
                    aria-labelledby="customized-dialog-title"
                    onClose={this.handleCloseAlertModal}
                >
                    <IconBtn
                        onClick={this.handleCloseAlertModal}
                        data-test-id='btnCloseAlertModal'
                        aria-label="close"
                    ><img src={closeIcon} alt="close_icon" /></IconBtn>
                    
                    <DialogContent dividers>
                        <img style={webStyle.infoIcon} src={infoIcon} alt="" />
                        <Typography style={webStyle.doYouWantToTxt}>Do you want to send your feedback now?</Typography>
                        <Typography style={webStyle.alertModalQuizName} >{quizName}</Typography>
                        <AlertBtnBoxQuiz>
                            <SendbtnNowQuiz data-test-id='BtnsendOverallDirect' onClick={() => this.sendOverallFeedback(true)}  >Send Now</SendbtnNowQuiz>
                            <SaveAndContiBtnQuiz data-test-id='btndoneSendFeedback' onClick={this.handleDoneAfterSendFeedback} >Save & Continue Later</SaveAndContiBtnQuiz>
                        </AlertBtnBoxQuiz>
                    </DialogContent>
                </BootstrapDialog>

                <Modal
                    data-test-id="handleClose"
                    onClose={this.handleClose}
                    open={this.state.openModel}
                    BackdropComponent={Backdrop}
                    closeAfterTransition
                    BackdropProps={{timeout: 500}}
                >
                    <Fade in={this.state.openModel}>
                        <FeedbackBtnQuiz
                        >
                            <h2>Feedback</h2>
                            <textarea
                                style={{ width: "100%", resize: "none", padding: "3%", margin: "0px 0px 10px" }}
                                data-test-id="handelFeedbackWrite"
                                rows={18}
                                placeholder={this.state.feedbackPlaceHolder}
                                onChange={this.handelFeedbackWrite}
                                value={this.state.feedbackText}
                                disabled={!this.checkIsPendingQuiz(this.state.quizStatus)}
                            >
                            </textarea>
                            {
                                this.checkIsPendingQuiz(this.state.quizStatus) && <Button 
                                    data-test-id="btnSendQuestionFeedback" 
                                    variant="contained" 
                                    style={webStyle.submitButtonActive as React.CSSProperties} 
                                    onClick={this.handleSendQuestionFeedback}
                                >
                                    Save
                                </Button>
                            }
                            
                        </FeedbackBtnQuiz>
                    </Fade>
                </Modal>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const NavMain=styled(Grid)({
        width: '100%',
        boxSizing: 'border-box' as 'border-box',
        padding: '15px 24px',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        'box-shadow': '0px 1px 3px 0px #00000014',
        position: "fixed" as "fixed",
        top: '0',
        // zIndex: '1000',
    "@media (max-width: 440px)":{
            display:"flex",
            flexDirection:'column',
            gap:'16px',
            alignItems:'flex-start'
        },
  })
  const NavMainRight=styled(Grid)({
    justifyContent:"flex-end", alignItems:"center",
    "@media (max-width: 440px)":{
        justifyContent:"space-between",
        },
  })
  const FeedbackBtnQuiz = styled('div')({
    position: 'fixed' as 'fixed',
        bottom: '20px',
        right: '20px',
        width: '25%',
        backgroundColor: 'white',
        padding: '16px',
        boxShadow: '0px 3px 6px rgba(0,0,0,0.1)',
        outline: 'none',
        borderRadius: '8px',
        "@media (max-width: 1024px)":{
            width: '30%',
        },
        "@media (max-width: 768px)":{
            width: '35%',
        },
        "@media (max-width: 600px)":{
            width: '50%',
        },
        "@media (max-width: 412px)":{
            width: '60%',
        }
  })
  const MainContainersWidth=styled(Grid)({
    width: "40%",
    overflow: "auto",
    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
    "@media (max-width: 748px)":{
            width: '60%',
        },
        "@media (max-width: 600px)":{
            width: '70%',
        },
"@media (max-width: 412px)":{
            width: '100%',
        }
  })
  const MainContainerWidthDragQuiz=styled(Grid)({
    width: "40%",
    backgroundColor: "#fff",
    padding: "3%",
    borderRadius: "20px",
    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
    "@media (max-width: 748px)":{
            width: '50%',
        },
        "@media (max-width: 600px)":{
            width: '70%',
        },
        "@media (max-width: 412px)":{
            width: '95%',
        }
  })
  const SendbtnNowQuiz=styled('button')({
    padding: "16px 24px 16px 24px",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 400,
        color: '#3F526D',
        background: 'none',
        border: '1px solid #2B3D7D',
        borderRadius: '50px',
        cursor: 'pointer',
    "@media (max-width: 500px)":{
        padding: "10px 10px",
        },
        "@media (max-width: 454px)":{
            padding: "10px 10px",
            },
        "@media (max-width: 412px)":{
            padding: "16px 24px",
            }
  })
  const SaveAndContiBtnQuiz=styled('button')({
    padding: "16px 24px 16px 24px",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 400,
        color: '#FFFFFF',
        background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)',
        border: 'none',
        borderRadius: '50px',
        cursor: 'pointer',
    "@media (max-width: 500px)":{
        padding: "10px 10px",
        },
        "@media (max-width: 454px)":{
            padding: "10px 10px",
            },
        "@media (max-width: 412px)":{
            padding: "16px 24px",
            }
  })
  const AlertBtnBoxQuiz=styled('div')({
    margin: "auto",
    marginTop: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding:'3px',
    "@media (max-width: 412px)":{
        flexDirection:'column',
        gap:'10px'
        }
  })
export const PropertyDeveloperCss = styled(Box)({
    '& .question': {
        fontWeight: 500,
        fontSize: "18px",
        color: "#282829",
        marginBottom: "20px",
        fontFamily: "Poppins",
    },
    "& .instruction": {
        fontWeight: 500,
        fontSize: "14px",
        fontFamily: "Poppins",
        color: "#282829",
        marginBottom: "15px",
    },
    "& .matchingBoxA": {
        color: "#282829",
        padding: "16px 12px",
        borderRadius: "10px",
        fontFamily: "Poppins",
        fontSize: "14px",
        marginBottom: "10px",
        fontWeight: 500,
        border: "1px solid rgba(43, 61, 125, 0.24)",
    },
    "& .matchingBoxB": {
        cursor: "move",
        padding: "16px 12px",
        borderRadius: "10px",
        fontWeight: 500,
        fontFamily: "Poppins",
        fontSize: "14px",
        color: "#282829",
        marginBottom: "10px",
        backgroundColor: "rgba(43, 61, 125, 0.08)",
        border: "1px solid rgba(43, 61, 125, 0.24)",
    },
    "& .matchingBoxBCorrect": {
        border: "1px solid rgba(43, 61, 125, 0.24)",
        padding: "16px 12px",
        cursor: "move",
        fontSize: "14px",
        background: "#00A52C",
        fontFamily: "Poppins",
        marginBottom: "10px",
        color: "#282829",
        fontWeight: 500,
        borderRadius: "10px",
    },
    "& .matchingBoxBWrong": {
        background: "#FF0000",
        borderRadius: "10px",
        cursor: "move",
        border: "1px solid rgba(43, 61, 125, 0.24)",
        padding: "16px 12px",
        fontWeight: 500,
        fontSize: "14px",
        color: "#282829",
        marginBottom: "10px",
        fontFamily: "Poppins",
    },
    "& .matchingBoxBActive": {
        border: "1px solid #2B3D7D",
        padding: "16px 12px",
        cursor: "move",
        backgroundColor: "#2B3D7D",
        fontFamily: "Poppins",
        marginBottom: "10px",
        fontSize: "14px", 
        fontWeight: 500, 
        color: "#fff",
        borderRadius: "10px",
    },
    "& .heading": {
        fontSize: "21px",
        fontFamily: "Poppins",
        fontWeight: 700,
        color: "#282829",
    },
    "& .headingLayout": {
        display: "flex",
        marginBottom: "15px",
        textAlign: "center",
        justifyContent: "space-around",
    },

    "& .mainLayout": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        padding: "30px 60px",
    },
    "& .contentBox": {
        backgroundColor: "#FFF",
        width: "380px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        borderRadius: "10px",
        padding: "30px 60px",
        boxShadow: "0px 0px 10px 0px #F1F5F9",
    },
    "& .submitBtn": {
        height: "44px",
        width: "107px",
        fontSize: "14px",
        fontWeight: 500,
        color: "#FFFFFF",
        borderRadius: "50px",
        backgroundColor: "#2B3D7D",
        fontFamily: "Poppins",
        "&:hover": {
            color: "#FFFFFF",
            backgroundColor: "#2B3D7D",
        },
    },
    "& .disabledBtn": {
        height: "44px",
        width: "107px",
        fontSize: "14px",
        fontWeight: 500,
        borderRadius: "50px",
        color: "#FFFFFF",
        backgroundColor: "#2B3D7D",
        opacity: "0.5",
        fontFamily: "Poppins",
        "&:hover": {
            backgroundColor: "#2B3D7D",
            color: "#FFFFFF",
        },
    },
    "& .submitBtnLayout": {
        cursor: "pointer",
        marginTop: "25px",
    },
    "& .openEndedMainBoxStage":{
        height: "auto",
        width: '34%',
        margin: 'auto',
        padding: "24px 24px 24px 24px",
        backgroundColor: "#fff",
        borderRadius: "9px",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
        overflowY: 'scroll' as 'scroll',
        msOverflowStyle: 'none' as 'none',
        boxSizing: 'border-box' as 'border-box',
        scrollbarWidth: 'none' as 'none',
        '&::-webkit-scrollbar': { display: 'none' },
        '@media (max-width: 748px)':{
            width:"70%"
        },
        '@media (max-width: 600px)':{
            width:"85%"
        }
    },
})

const webStyle = {
    mainBox: {
        minHeight: '100vh',
        background: "#F1F5F9",
        paddingTop: '60px',
    },
    navbar: {
        height: '60px',
        width: '100%',
        boxSizing: 'border-box' as 'border-box',
        padding: '0 24px 0 24px',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        'box-shadow': '0px 1px 3px 0px #00000014',
        position: "fixed" as "fixed",
        top: '0',
        zIndex: '1000',
    },
    leftNav: {
        display: 'flex',
        alignItems: 'center',
        width: '30%',
        height: '100%',
    },
    backIcon: {
        fontSize: "14px", marginRight: '16px', cursor: 'pointer'
    },
    quizName: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 500,
        color: '#282829',
    },
    markAsCompleted: {
        border: 'none',
        cursor: 'pointer',
        width: "fit-content",
        background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)',
        borderRadius: "25px",
        color: "#fff",
        fontFamily: "Poppins",
        fontSize: "14px",
        textTransform: "none" as "none",
        padding: "8px 10px",
        fontWeight: 700
    },
    rightNav: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '70%',
        height: '100%',
    },
    rightNavSmalBox: {
        // width: '100%',
        marginRight: '60px',
    },
    rightNavTopTxt: {
        fontFamily: 'Poppins',
        fontSize: '11px',
        fontWeight: 400,
        color: '#282829',
    },
    rightNavBottomTxt: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 500,
        color: '#282829',
    },
    dueDate: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#282829',
        fontWeight: 500,
    },
    timeIcon: {
        width: '16px',
        height: '16px',
        marginRight: '6px',
    },
    openEndedWrapper: {
        minHeight: "92%",
        background: "#F1F5F9",
        paddingTop: "30px",
        paddingBottom: "30px",
        backrgroundColor: "#B2F1F5F9",
    },
    questionTextfield: {
        fontSize: "14px", fontFamily: "Poppins", color: "#282829", fontWeight: 600, width: "100%",
        borderRadius: "4px", border: "1px solid #5C2B3D7D", resize: "none" as 'none', margin: "2% 0px 5%"
    },
    openEndedMainBoxStage: {
        height: "auto",
        width: '34%',
        margin: 'auto',
        padding: "24px 24px 24px 24px",
        backgroundColor: "#fff",
        borderRadius: "9px",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
        overflowY: 'scroll' as 'scroll',
        msOverflowStyle: 'none' as 'none',
        boxSizing: 'border-box' as 'border-box',
        scrollbarWidth: 'none' as 'none',
        '&::-webkit-scrollbar': { display: 'none' },
    },
    infoIcon: {
        display: 'block',
        margin: 'auto',
        marginBottom: '12px',
    },
    alertModalQuizName: {
        color: '#2C2C2E',
        height: "60px",
        margin: 'auto',
        marginTop: '28px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '16px',
        borderRadius: '6px',
        fontWeight: 600,
        backgroundColor: '#2B3D7D14',
        border: '0.5px solid #2B3D7D1F',
        fontFamily: 'Poppins',
    },
    doYouWantToTxt: {
        color: '#202020',
        fontWeight: 400,
        lineHeight: '28px',
        fontFamily: 'Poppins',
        textAlign: 'center' as 'center',
        fontSize: '18px',
    },
    
    sendNowBtn: {
        cursor: 'pointer',
        padding: "16px 24px 16px 24px",
        fontSize: '16px',
        border: '1px solid #2B3D7D',
        fontWeight: 400,
        borderRadius: '50px',
        color: '#3F526D',
        background: 'none',
        fontFamily: 'Poppins',
    },
    saveAndContiBtn: {
        background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)',
        color: '#FFFFFF',
        padding: "16px 24px 16px 24px",
        fontSize: '16px',
        fontWeight: 400,
        cursor: 'pointer',
        borderRadius: '50px',
        border: 'none',
        fontFamily: 'Poppins',
    },
    alertBtnsBox: {
        width: '412px',
        margin: "auto",
        marginTop: '24px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    
    
    submitIcon: {
        backgroundColor: "#2B3D7D",
        borderRadius: "50%",
        padding: "3px"
    },
    submitedAndEditText: {
        fontSize: "14px",
        fontFamily: "Poppins",
        color: "#3F526D",
        fontWeight: 700,
        marginLeft: "10px"
    },
    iconStyle: {
        color: "#fff",
        fontSize: "15px",
    },
    editBtnContainer: {
        cursor: "pointer",
        border: "2px solid #2B3D7D",
        borderRadius: "25px",
        width: "100px",
        padding: "9px 0px"
    },
    submitButtonBeforeClick: {
        width: "145%",
        background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)', opacity: "0.5",
        borderRadius: "25px",
        color: "#fff",
        fontFamily: "Poppins",
        fontSize: "14px",
        textTransform: "none",
        padding: "14px 10px",
        fontWeight: 700,
        marginTop: "10px",
        marginBottom: '10px'
    },
    submitButtonBeforeClickDrag: {
        width: "30%",
        background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)',
        opacity: "0.5",
        borderRadius: "25px",
        color: "#fff",
        fontFamily: "Poppins",
        fontSize: "14px",
        textTransform: "none",
        padding: "14px 10px",
        fontWeight: 700,
        marginTop: "10px"
    },
    submitButtonActive: {
        width: "45%", background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)'
        , borderRadius: "25px", color: "#fff",
        fontFamily: "Poppins", fontSize: "14px", textTransform: "none", padding: "14px 10px", fontWeight: 700,
        marginTop: "10px"
    },
    firstTextGrid: { paddingTop: "20px" },
    firstHedingText: {
        fontSize: "14px",
        fontWeight: 500,
        color: "#282829",
        fontFamily: "Poppins",
        textTransform: 'capitalize' as 'capitalize'
    },
    pginationGrid: {
        width: "120px",
        position: "fixed",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
        padding: "4px",
        left: "0",
        right: "0",
        bottom: "20px",
        marginLeft: "auto",
        marginRight: "auto",
        background: '#fff',
        borderRadius: "11px",
    },
    paginationText: {
        margin: "0px 8px",
        fontSize: "14px",
        fontWeight: 600,
        color: "#3F526D",
        fontFamily: "Poppins"
    },
    cursorPointer: { cursor: 'pointer' },
    textStyle2: {
        fontSize: "21px",
        fontWeight: 500,
        color: "#282829",
        fontFamily: "Poppins",
        margin: "20px 0px"
    },
    mainWrapper: {
        display: "flex",
        height: "91.2%",
        flexDirection: "column",
        alignItems: "center",
        background: "#F1F5F9",
        marginTop: "1px",
        zIndex: '1',
        fontFamily: "Roboto-Medium",
    },
    mainContainerWidthDrag: {
        width: "40%",
        backgroundColor: "#fff",
        padding: "3%",
        borderRadius: "20px",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)"
    },
    mainContainerWidth: {
        width: "40%",
        overflow: "auto",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)"
    },
    mainWrapperForWordCloud: {
        height: "100%",
        borderRadius: "9px",
        padding: "4% 7% 7%",
        backgroundColor: "#fff",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)"
    },
    containerForIcon: { position: "absolute", top: "9px", right: "12px", borderRadius: "50%", padding: "3px" },

    icoStyle: {
        color: "#fff",
        fontSize: "20px"
    },
    mainWrapperContainer: {
        height: "100%",
        padding: "44px 44px 44px 44px",
        position: "relative" as "relative",
        width: "100%",
        overflow: "auto",
    },
    greenTick: {
        height: '60px',
        width: '60px',
        display: 'block',
        margin: 'auto',
        marginBottom: '20px',
    },
    modalText: {
        textAlign: 'center' as 'center',
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: '#282829',
        lineHeight: '24px',
    },
    tickIcon: {
        display: 'block',
        height: '60px',
        width: '60px',
        margin: 'auto',
    },
    feedbackSentTxt: {
        fontSize: '24px',
        margin: '12px 0 16px 0',
        textAlign: 'center' as 'center',
        color: '#202020',
        fontFamily: 'Poppins',
    },
    feedbackSentDesc: {
        color: '#202020',
        fontSize: '18px',
        textAlign: 'center' as 'center',
        fontWeight: 400,
        fontFamily: 'Poppins',
    },
    feedbackSentDoneBtn: {
        background: 'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)',
        display: 'block',
        border: 'none',
        margin: 'auto',
        fontSize: '16px',
        fontFamily: 'Poppins',
        marginTop: '28px',
        color: '#FFFFFF',
        borderRadius: '50px',
        padding: '14px 42px 14px 42px',
        fontWeight: 400,
    },
    canvasImgStyle: {
        width: '100%',
        marginBottom: '10px',
    },
};
// Customizable Area End