import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Tooltip from '@material-ui/core/Tooltip';
import { Box, Typography, TextField, Modal, useMediaQuery } from "@material-ui/core";
import { contentCopy, newLessonIcon } from "../../assets";
import NotForMobile from "../../../../../components/src/NotForMobile.web";
const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#FDF4DC",
    color: "#202020",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
  },
  body: {
    fontSize: "14px",
    color: "#3F526D",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({}))(TableRow);
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  img_box: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex"
  },
  
  div: {
    display: "flex",
    width: "100%"
  },

  button: {
    fontSize: '16px',
    color: '#2B3D7D',
    fontWeight: 500,
    backgroundColor: '#fff',
    borderColor: '#2B3D7D',
    borderRadius: '8px',
    marginBottom: '0px',
    padding: '6px 20px',
    cursor: 'pointer',
    fontFamily: 'Poppins',
  },
  img: {
    marginLeft: '24px',
    height: '30px',
    width: '30px',
    [theme.breakpoints.down('xs')]: {
      marginLeft:0
     },

  },
 
  code_img: {
    height: "15px", width: "15px", marginLeft: "25px", cursor: 'pointer'
  },
  div_p: {
    alignItems: "center",
    paddingLeft: "6px",
    textAlign: "start",
  },
}));

export default function LessonTable(props: any) {
  const [toolTipText, setToolTipText] = useState("Copy code")
  const [labelName, setLabelName] = React.useState<string>('')
  const isSmallScreen = useMediaQuery('(max-width:600px)');
 
  const {
    lessonList,
    handleClick,
    handleCloseNoMobileModal,
    openNoMobileModal,
    handleOpenNoMobileModal
  } = props;
  const classes = useStyles();
  const handleCopyCode = (data: any) => {
    navigator.clipboard.writeText(data);
    setToolTipText("Copied")
    setTimeout(()=>{
      setToolTipText("Copy Code")
    },2000)
  };

  const tableHead = () => {
    return (
      <TableRow>
        <StyledTableCell align="center">Lesson Name</StyledTableCell>
        <StyledTableCell align="center">Stages</StyledTableCell>
        <StyledTableCell align="right">Lesson Code<br />(Live Session)</StyledTableCell>
        <StyledTableCell align="center">Action</StyledTableCell>
      </TableRow>
    );
  };
  const tableBody = () => {
    return lessonList.map((data: any, index: number) => {
      const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (isSmallScreen) {
          handleOpenNoMobileModal();
          setLabelName("Deliver");
        } else {
          handleClick(e, data.id);
        }
      };
      return(
      <StyledTableRow key={data.id}>
        <StyledTableCell align="center">
          <div className={classes.div}>
            <div className={classes.img_box}>
              <img src={newLessonIcon} className={classes.img} />
            </div>
            <div className={classes.div_p}>
              <p>{data.lessonName}</p>
            </div>
          </div>
        </StyledTableCell>
        <StyledTableCell align="center">
          <p>{data.totalStage}</p>
        </StyledTableCell>
          <StyledTableCell align="right">
            <Box display="flex" alignItems="center" textAlign="right">
              <Box flexGrow={1}>
                <TextField
                  disabled
                  InputProps={{ disableUnderline: true,endAdornment:<Typography id="codeInput">{data.lessonCode}</Typography> }}
                />
              </Box>
              <Box onClick={()=>handleCopyCode(data.lessonCode)} data-test-id="copy-code">
              <Tooltip title={toolTipText} data-test-id="tooltip-text">
                <img src={contentCopy} className={classes.code_img} />
                </Tooltip>
              </Box>
            </Box>
          </StyledTableCell>
        <StyledTableCell align="center">
          <Tooltip title={data.totalStage ? "" : "No stage could be found for this lesson, please add one to deliver"} data-test-id="button-tooltip-text">
            <button
              className={classes.button}
              data-test-id="handleClick"
              disabled={!data.totalStage}
              style={{ opacity: data.totalStage ? 1 : 0.3}}
              onClick={handleClick ? handleButtonClick : undefined}
            >Deliver</button>
          </Tooltip>
        </StyledTableCell>
      </StyledTableRow>
      )
  });
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>{tableHead()}</TableHead>
          <TableBody>{tableBody()}</TableBody>
        </Table>
      </TableContainer>
      <Modal onClose={handleCloseNoMobileModal} open={openNoMobileModal} data-testid="nomobile-modal" >
             <NotForMobile 
             handleCloseModal={handleCloseNoMobileModal} 
             moduleName={labelName} />
      </Modal>
    </>
  );
}
