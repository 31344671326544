import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import firebase from "firebase";

// Customizable Area Start
import React from "react";
import { debounce } from "lodash";
import generatePDF from "react-to-pdf";
import { Roles } from "../../../components/src/types";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

interface UserAttempt {
  id: number;
  full_name: string;
  lessons: Lesson[];
}
interface Lesson {
  total_markup_tools: number;
  user_attempt_markup_tools: number;
  open_ended_questions: {
      total: number;
      user_attempts: number;
  };
  mcq: {
      total: number;
      user_attempts: number;
  };
  word_cloud: {
      total: number;
      user_attempts: number;
  };
  matching_question: {
      total: number;
      user_attempts: number;
  };
  polling_question: {
      total: number;
      user_attempts: number;
  };
  graphic: {
      total: number;
      user_attempts: number;
  };
}
interface SummaryReportItem {
  student_name: string;
  mcq_marks: number;
  total_mcq_marks: number;
  open_ended_question: number;
  polling_question_marks: number;
  sum_word_cloud: number;
  total_word_marks:number;
  graphic_organisation_marks: number;
  total_graphic_marks:number;
  sum_matching_question_marks: number;
  total_matching_question_marks: number;
  open_ended_total: number;
  polling_question_total: number;
}
interface Totals {
  open_ended_questions: { total: number; user_attempts: number };
  mcq: { total: number; user_attempts: number };
  word_cloud: { total: number; user_attempts: number };
  matching_question: { total: number; user_attempts: number };
  polling_question: { total: number; user_attempts: number };
  graphic: { total: number; user_attempts: number };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleClick: () => void; 
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  view : string;
  classTitle: string;
  searchQuery: string;
  searchStudentQuery: string;
  pageLimit: number;
  pageLimitStudents: number;
  pageNo: number;
  pageNoStudents: number;
  classListData: any;
  studentListData: any[];
  selectedTeacher: any;
  teachersList: any[];
  dashboardLoading: boolean;
  token: string;
  loading: boolean;
  errorMsg: string;
  dashboardData: any;
  studentCount: number;
  teacherCount: number;
  reportType: string;
  dateRange: any;
  teacherId: string;
  classId: string;
  selectStudents: any[],
  summaryReportList:any[],
  studentIdList:any[],
  data:any[],
  selectAll:boolean,
  selectedIds:any[],
  openModal:boolean,
  csvSucessShow:boolean,
  detailReportList:any[],
  selectedStudent:number,
  visiblevalue:boolean;
  startDate: any;
  endDate: any;
  internalDate: any;
  isSmallScreen:boolean;
  pdfLoading:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  studentsDetailReportRef: any;
  apiDashboardItemCallId: string = "";
  classListApiCallId: string = "";
  studentListAPICallId: string = ''
  summaryReportListAPICallId: string = ''
  detailReportListAPICallId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area Start
      ,getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      txtInputValue: '',
      view: "teacherList",
      classTitle: "",
      searchQuery: "",
      searchStudentQuery: "",
      pageLimit: 1,
      pageLimitStudents: 1,
      pageNo: 1,
      pageNoStudents: 1,
      classListData: [],
      studentListData: [],
      selectedTeacher: {},
      teachersList: [],
      dashboardLoading: true,
      token: "",
      loading: false,
      errorMsg: "",
      dashboardData: [],
      studentCount: 0,
      teacherCount: 0,
      reportType: '',
      dateRange: '',
      teacherId: '',
      classId: '',
      selectStudents: [],
      summaryReportList:[],
      studentIdList:[],
      data:[],
      selectAll:false,
      selectedIds:[],
      openModal:false,
      csvSucessShow:false,
      detailReportList:[],
      selectedStudent:0,
      visiblevalue:false,
      startDate: "",
      endDate: "",
      internalDate: null,
      isSmallScreen: window.innerWidth <= 600,
      pdfLoading:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.studentsDetailReportRef = React.createRef();
    this.getDetailReportListDataAPI = this.getDetailReportListDataAPI.bind(this);
    this.getSummaryReportListDataAPI = this.getSummaryReportListDataAPI.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
    this.getStudentListData = this.getStudentListData.bind(this);
    // Customizable Area End
    if (firebase.apps.length !== 0) {
      const defaultAnalytics = firebase.app().analytics();
      defaultAnalytics.logEvent("Analytics::Web::Load");
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    {getName(MessageEnum.SessionResponseMessage) === message.id &&
      this.renderDashboardData(message)}
  
      {(getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.apiDashboardItemCallId != null &&
        this.apiDashboardItemCallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) &&
        this.renderTeachersData(message) }
  
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.classListApiCallId != null &&
      this.classListApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          if (!responseJson?.errors && responseJson?.data) {
            this.setState({classListData: responseJson?.data})
          }else {
            const errorResponse = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            this.parseApiCatchErrorResponse(errorResponse);
          }
          this.setState({
            dashboardLoading: false
          });
        }

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.studentListAPICallId != null &&
      this.studentListAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          if (!responseJson?.errors && responseJson?.data) {
            this.setState({studentListData: responseJson.data, pageLimitStudents: responseJson?.meta?.total_page})
          }else {
            this.setState({studentListData:[],pageLimitStudents:1})
            const errorResponse = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            this.parseApiCatchErrorResponse(errorResponse);
          }
        }
        this.otherAPI(message)  
        
    // Customizable Area End 
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  async doButtonPressed() {
    const defaultAnalytics = firebase.app().analytics();
    defaultAnalytics.logEvent("Analytics::Web::button_clicked");
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    
    let cameFromDashboard = await getStorageData("cameFromDashboard");
    cameFromDashboard = JSON.parse(cameFromDashboard)

    let role = await getStorageData("role");

    if(role ===Roles.SUB_ADMIN ){
      this.getDashboardDataListing();   
      if(cameFromDashboard){
        this.getClassesList({}, {"id":cameFromDashboard.attributes.teacher_id,"image": cameFromDashboard.attributes.attachment, "name": cameFromDashboard.attributes.full_name})
        this.getClassDetails(cameFromDashboard.attributes.class_name, cameFromDashboard.id)
      } 
    }
    this.setState({selectAll:false})
  }
  // commit 
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  renderDashboardData=(message: Message)=>{
    let token = message.getData(getName(MessageEnum.SessionResponseToken));
    this.setState({ token: token, loading: true }, () => {
      this.getDashboardDataListing();
    });
  }

  renderTeachersData=(message: Message)=>{
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson.errors && responseJson.data) {
      if (responseJson.data.length === 0) {
        this.setState({
          errorMsg: "Data Not Found",
          loading: false
        });
      } else {
        this.setState({
          dashboardData: responseJson.data,
          errorMsg: "",
          loading: false,
          studentCount: responseJson.data[0].attributes.student_count,
          teacherCount: responseJson.data[0].attributes.teacher_count,
          teachersList: responseJson.data,
          pageLimit: responseJson.meta.total_page
        });
      }
    } else {
      this.setState({teachersList: []})
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (errorReponse === undefined) {
        this.setState({
          errorMsg: "Something went wrong",
          loading: false
        });
      } else {
        this.setState({
          errorMsg: errorReponse,
          loading: false
        });
      }
    }
    this.setState({
      dashboardLoading: false
    });
  }

  getDashboardDataListing(): boolean {
    
    const headerDashboardAPI = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessageDashboardAPI = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessageDashboardAPI.messageId;
    requestMessageDashboardAPI.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.dashboardGetUrl}?per_page=9&full_name=${this.state.searchQuery}&page=${this.state.pageNo}`
    );

    requestMessageDashboardAPI.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerDashboardAPI)
    );

    requestMessageDashboardAPI.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessageDashboardAPI.id, requestMessageDashboardAPI);
    return true;
  }

  handleBreadCrumb=(view: string)=>{
    this.handleSelectAll()
    this.setState({view: view})
    if(view === "teacherList"){
      this.setState({classListData: [], studentListData: []})
    }  
    if(view === "classList") {
      this.setState({studentListData: []})
    }
    if(view === "studentDetails") {
      this.setState({selectStudents: []})
    }
  }

  handlePageChange = (event: any, page: number) => {
    this.setState({
      pageNo: page
    },
    () => { 
      this.getDashboardDataListing(); 
     });   
  }

  handleStudentsPageChange = (event: any, page: number) => {
    this.setState({
      pageNoStudents: page
    },
    () => { 
      this.getStudentListData(this.state.teacherId, this.state.classId); 
     });   
  }

  getClassesList=(event: any,teacher: any)=>{
    this.setState({view: 'classList'})
    this.getClassListDataListing(teacher.id)
    this.setState({selectedTeacher: {
      id:teacher.id,
      attributes:{
       full_name:teacher.name,
       image:teacher.image
      } 
     }})
    this.setState({
       dashboardLoading: true,
      teacherId: teacher.id
    });
  }

  getClassListDataListing(id: string): boolean {
    const headerClassListData = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessageClassListData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.classListApiCallId = requestMessageClassListData.messageId;
    requestMessageClassListData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.classListGetUrl}?teacher_id=${id}`
    );

    requestMessageClassListData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerClassListData)
    );

    requestMessageClassListData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessageClassListData.id, requestMessageClassListData);
    return true;
  }

  getStudentListData(teacherId: string, classId: string, startDate?:any, endDate?:any): boolean {
    let endpoint;
    if(startDate && endDate){
      endpoint = `${configJSON.filterStudentsList}?start_date=${startDate}&end_date=${endDate}&teacher_id=${teacherId}&class_id=${classId}&full_name=${this.state.searchStudentQuery}`
    } else {
      endpoint = `${configJSON.studentListGetUrl}?teacher_id=${teacherId}&class_id=${classId}&full_name=${this.state.searchStudentQuery}&page=${this.state.pageNoStudents}`
    }
    
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.studentListAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleModalClose = (data: any) => {
    this.setState({selectAll:false})
    this.setState({view: "studentDetails",
    reportType: data.reportType,
    dateRange: data.dateRange
  })
  }

  debouncedSearch = debounce(() => {
    this.getDashboardDataListing();
  }, 1000); 

  handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => { 
    this.setState({ searchQuery: event.target.value,  },
    () => { 
      this.debouncedSearch(); 
    }); 
  };

  debouncedStudentSearch = debounce(() => {
    this.fetchDatesAndGetDetailsForTeacher()
  }, 1000); 

  handleStudentSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => { 
    this.setState({ searchStudentQuery: event.target.value },
    () => { 
      this.debouncedStudentSearch(); 
    }); 
  };

  handleCloseVisible=()=>{
    this.setState({visiblevalue:false, internalDate: null})
    removeStorageData("adminStartDate")
    removeStorageData("adminEndDate")
  }
  handleOpenVisible=(className: string, id: string)=>{
    this.setState({visiblevalue:true,view: "classDetails",classTitle: className, classId: id})
    removeStorageData("adminStartDate")
    removeStorageData("adminEndDate")
  }
  getClassDetails=(className: string, id: string)=>{
    this.setState({visiblevalue:true,view: "classDetails",classTitle: className, classId: id})
    this.getStudentListData(this.state.teacherId, id)
  }
  getTeacherCalendar(id: string) {
    const currentLocation = window.location.toString(); 
    const newLocation = `${currentLocation.replace(/\/dashboard$/, '/teachers')}/${id}`; 
    window.location.href = newLocation;
  }

  handleSubmitSelectStudent = (data: any[],view:string) => {
    this.setState({
      selectStudents: data,
      view: view
    })
  }

  getSummaryReportListDataAPI(ids:any,csv?:boolean,startDate?:any,endDate?:any): boolean {
    let paramsdata = ids.student_ids.join("&student_ids[]=")
    let endpoint;
    if(startDate && endDate){
      endpoint = `bx_block_statisticsreports2/statisticsreports/generate_report_for_students_lesson?student_ids[]=${paramsdata}&teacher_classes_id=${this.state.classId}&start_date=${startDate}&end_date=${endDate}`
    } else {
      endpoint = `bx_block_statisticsreports2/statisticsreports/generate_report_for_students_lesson?student_ids[]=${paramsdata}&teacher_classes_id=${this.state.classId}`   
    }
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: window.localStorage.getItem("authToken")
      };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.summaryReportListAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  otherAPI=(message:any)=>{
        const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson || responseJson.data){
        
        let summaryList = responseJson?.data ? responseJson?.data : [];
        let detailList = responseJson?.data ? responseJson?.data : responseJson;
    
        if (responseJson.user_attempts) {
          summaryList = responseJson.user_attempts;
    
          const updatedTotals = this.calculateTotalsForEachStudent(responseJson.user_attempts);
    
          if (updatedTotals) {
            summaryList = updatedTotals;  
          }
        }
    
        this.setState({
          summaryReportList: summaryList, 
          detailReportList: detailList, 
        });
      }else{
        this.setState({summaryReportList:[]})
      }
      
  }


  handleTeacherStudentsPageChange = (event: any, page: number) => {
    this.setState({
      pageNoStudents: page
    })
  }

  handleTeachersStudentSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchStudentQuery: event.target.value },
      () => { 
        this.debouncedStudentSearch(); 
      }); 
  };

  handleSelectAll = () => {
    this.setState({
      selectAll: !this.state.selectAll,
      selectedIds: !this.state.selectAll ? this.state.studentListData?.map((item:any) => item.id) :[],
      studentListData:this.state.studentListData.map((item: any) => {
          return { ...item, selected: !this.state.selectAll }
        })
    });
  };



  handleChange = (record:any) => {
    
    const newData = this.state.studentListData.map(item => {
      return item.id === record.id ? { ...item, selected: !item.selected } : item;
    });
    const selectedIds = newData.filter(item => item.selected).map(item => item.id);
    if(this.state.studentListData.length==selectedIds.length){
      this.setState(
        { selectAll: true });
    }else{
      this.setState(
        { selectAll: false });
    }
    this.setState(
    { studentListData: newData, selectedIds: selectedIds });
  };

  handleCloseModal=()=>{
    this.setState({ openModal: false });
  }

  handleOpenModal=()=>{
  
    this.setState({ openModal: true });
  }

  handleDownloadPDF = async () => {
    const targetElement: HTMLElement | null = this.studentsDetailReportRef.current;
    if (!targetElement) return;
    // this.handleCloseModal()

    this.setState({ pdfLoading: true , openModal: false});
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: "A4",
    });

    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const scaleFactor = 1.5;
    let currentPosition = 0;
    let isFirstPage = true;

    const clonedElement = targetElement.cloneNode(true) as HTMLElement;
    clonedElement.style.transform = "scale(0.8)";
    clonedElement.style.transformOrigin = "top left";
    clonedElement.style.width = `${pageWidth / 0.8}px`;

    clonedElement.style.position = "fixed";
    clonedElement.style.top = "-10000px";
    document.body.appendChild(clonedElement);

    while (currentPosition < clonedElement.scrollHeight) {
      const canvas = await html2canvas(clonedElement, {
        scale: scaleFactor,
        useCORS: true,
        y: currentPosition,
        height: pageHeight / scaleFactor,
        width: pageWidth,
        windowHeight: clonedElement.scrollHeight,
      });
      const imageData = canvas.toDataURL("image/jpeg", 1);
      if (isFirstPage) {
        pdf.addImage(imageData, "JPEG", 0, 0, pageWidth, pageHeight);
        isFirstPage = false;
      } else {
        pdf.addPage();
        pdf.addImage(imageData, "JPEG", 0, 0, pageWidth, pageHeight);
      }
      currentPosition += pageHeight / scaleFactor;
    }

    document.body.removeChild(clonedElement);

    this.setState({ pdfLoading: false });
    pdf.save("students-detail-report.pdf");
  };


   formatAttempt = (attempts:number, total:number) => {
    if (attempts === 0 && total === 0) {
      return 'NA';
    }
    return ` ${attempts}/${total}`;
  };
   getOpenEndedStatus = (openEndedQuestion:number, openEndedTotal:number) => {
    if (openEndedQuestion === 0 && openEndedTotal === 0) {
      return "NA";
    } else if(openEndedQuestion=== openEndedTotal){
      return "Yes"
    }else{
      return  "No";
    }
    
  };
  getPollingStatus = (pollingQuestion:number, pollingTotal:number) => {
    if (pollingQuestion === 0 && pollingTotal === 0) {
      return "NA";
    }else if(pollingQuestion ===  pollingTotal){
      return "Yes"
    }else{
      return  "No";
    }
  };
  getExportReportApicall = () => {
    if (this.state.summaryReportList && this.state.summaryReportList[0]) {
      const filteredReportList = this.state.summaryReportList.map((row) => {
        return {
          "Student Name": row.student_name,
          "Multiple Choice Questions": this.formatAttempt(row.mcq_marks, row.total_mcq_marks),
          "Open Ended Questions": this.getOpenEndedStatus(row.open_ended_question, row.open_ended_total),
          "Polling Questions": this.getPollingStatus(row.polling_question_marks,row.polling_question_total ),
          "Word Cloud": this.formatAttempt(row.sum_word_cloud, row.total_word_marks),
          "Graphic Organizers": this.formatAttempt(row.graphic_organisation_marks, row.total_graphic_marks),
          "Matching Questions": this.formatAttempt(row.sum_matching_question_marks, row.total_matching_question_marks),
        };
      });
  
      const csvContent =
        "data:text/csv;charset=utf-8," +
        Object.keys(filteredReportList[0]).join(",") +
        "\n" +
        filteredReportList.map((row) => Object.values(row).join(",")).join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "SummaryReportList.csv");
      document.body.appendChild(link);
      link.click();
      this.setState({csvSucessShow:true})
      setTimeout(() => this.handleCloseModal(),1500)
  }
  };

  getDetailReportListDataAPI(ids:any,startDate?:any,endDate?:any): boolean {
   
    let paramsdata = ids.student_ids.join("&student_ids[]=")
    let endpoint;
    if(startDate && endDate){
      endpoint = `${configJSON.deatilReportList}?student_ids[]=${paramsdata}&start_date=${startDate}&end_date=${endDate}&class_id=${this.state.classId}`
    } else {
      endpoint = `${configJSON.deatilReportList}?student_ids[]=${paramsdata}&class_id=${this.state.classId}`   
    }
    
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.detailReportListAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.get
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleSelection(index:number) {
    this.setState({ selectedStudent: index });
  }

  handleScroll = () => {
    const studentDetailContainer = document.getElementById("abc");
  
    if (studentDetailContainer) {
      const studentSections = Array.from(studentDetailContainer.querySelectorAll('[id^="stundent-"]'));
      
      let selectedIndex = -1;
  
      studentSections.forEach((section, index) => {
        const rect = section.getBoundingClientRect();
        const topPos = rect.top + studentDetailContainer.scrollTop;
  
        if (studentDetailContainer.scrollTop >= topPos - 900) {
          selectedIndex = index;
        }
      });
  
      if (selectedIndex !== -1 && this.state.selectedStudent !== selectedIndex) {
        this.setState({ selectedStudent: selectedIndex });
      }
    }
  }

  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setInternalDate = (internalDate:any) => {
    this.setState({
      internalDate
    })
  }

  handleResize = () => {
    this.setState({ isSmallScreen: window.innerWidth <= 600 });
  };
  
   fetchDatesAndGetDetails = async () => {
    const startDate = await getStorageData("adminStartDate");
    const endDate = await getStorageData("adminEndDate");
    
    if (startDate && endDate) {
      this.getStudentListData(this.state.teacherId, this.state.classId, startDate, endDate);
    } else {
      this.getStudentListData(this.state.teacherId, this.state.classId);
    }
  };
  getSummaryReportListData = async (ids:any,csv?:boolean) => {
    const startDate = await getStorageData("startDateRange");
    const endDate = await getStorageData("endDateRange");

    if (startDate && endDate) {
      this.getSummaryReportListDataAPI(ids,csv, startDate, endDate);
    } else{
      this.getSummaryReportListDataAPI(ids,csv);
}}
getDetailReportListData = async (ids:any) => {
  const startDate =  localStorage.getItem("startDateRange");
  const endDate =  localStorage.getItem("endDateRange");
  if (startDate && endDate) {
    this.getDetailReportListDataAPI(ids, startDate, endDate);
  } else{
    this.getDetailReportListDataAPI(ids);
}
  };

   calculateTotalsForEachStudent=(data: UserAttempt[]): SummaryReportItem[] =>{
    return  data
    .filter((userAttempt: UserAttempt) => userAttempt.lessons.length > 0) 
    .map((userAttempt: UserAttempt) => {
        const studentTotals: Totals = {
            mcq: { total: 0, user_attempts: 0 },
            matching_question: { total: 0, user_attempts: 0 },
            polling_question: { total: 0, user_attempts: 0 },
            open_ended_questions: { total: 0, user_attempts: 0 },
            word_cloud: { total: 0, user_attempts: 0 },
            graphic: { total: 0, user_attempts: 0 },
        };

        userAttempt.lessons.forEach((lesson: Lesson) => {
            for (const questionType in studentTotals) {
                const lessonQuestion = lesson[questionType as keyof Lesson];

                if (typeof lessonQuestion === 'object' && lessonQuestion !== null && 'total' in lessonQuestion) {
                    const lessonTyped = lessonQuestion as { total: number; user_attempts: number };
                    studentTotals[questionType as keyof Totals].total += lessonTyped.total;
                    studentTotals[questionType as keyof Totals].user_attempts += lessonTyped.user_attempts;
                }
            }
        });

        return {
            student_name: userAttempt.full_name,
            open_ended_question: studentTotals.open_ended_questions.user_attempts,
            total_mcq_marks: studentTotals.mcq.total,
            mcq_marks: studentTotals.mcq.user_attempts,
            polling_question_marks: studentTotals.polling_question.user_attempts,
            graphic_organisation_marks: studentTotals.graphic.user_attempts,
            total_matching_question_marks: studentTotals.matching_question.total,
            polling_question_total: studentTotals.polling_question.total,
            sum_matching_question_marks: studentTotals.matching_question.user_attempts,
            total_word_marks:studentTotals.word_cloud.total,
            total_graphic_marks:studentTotals.graphic.total,
            sum_word_cloud: studentTotals.word_cloud.user_attempts,
            open_ended_total: studentTotals.open_ended_questions.total
        };
    });
}
fetchDatesAndGetDetailsForTeacher = async () => {
  const startDate =  localStorage.getItem("startDateRange");
  const endDate =  localStorage.getItem("endDateRange");
  
  if (startDate && endDate) {
    this.getStudentListData(this.state.teacherId, this.state.classId, startDate, endDate);
  } else {
    this.getStudentListData(this.state.teacherId, this.state.classId);
  }
};


  // Customizable Area End
}
