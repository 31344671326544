import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

import { Roles } from "../../../components/src/types";
import TeacherNotes from "./Teacher/TeacherNotes.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import NotesController, {
  Props,
  configJSON,
} from "./NotesController";

export default class Notes extends NotesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderViewBasedOnRole(role: string | null) {
    if (role === Roles.TEACHER || role === Roles.STUDENT ) {
      return (
        <TeacherNotes
          id=""
          navigation={this.props.navigation}
          handleCloseModal={this.props.handleCloseModal}
          open={this.props.open}
          handleShowNotesIcon={this.props.handleShowNotesIcon}
          selectedType={this.props.selectedType}
        />
      );
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {this.renderViewBasedOnRole(this.state.role)}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
