import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

interface Pairs {
    id: number;
    pair_question: string;
    pair_answer: string;
    pair_correct_answer: string;
    order_no: number;
    lesson_markup_tools_id: number | null;
    created_at: string;
    updated_at: string;
    quiz_markup_tools_id: number | null;
}

interface Questionss {
    question?: string;
    questionId?: string;
    isSubmit?: boolean;
    answer?: string;
}

interface QuestionssForMultiple {
    isSubmit?: boolean;
    question?: string;
    answers?: string[];
    questionId?: string;
}

interface QuizMarkupTool {
    id: string;
    type: string;
    attributes: Attributes;
}

interface Attributes {
    quiz_stage_id: number;
    quiz_id: number;
    canvas_data: "{canvas: {objects: []}}";
    stage_number: number;
    point: boolean;
    double_point: boolean;
    title: string | null;
    description: string | null;
    countdown_timer: string;
    quiz_stage_name: string;
    matching_pairs: MatchingPair[];
    questions: QuestionItem[];
    background_image: null;
    multiple: boolean;
    images: null;
    videos: null;
    music: null;
}

interface MatchingPair {
    id: number;
    pair_question: string;
    pair_answer: string;
    pair_correct_answer: string;
    order_no: number;
    lesson_markup_tools_id: number | null;
    created_at: string;
    updated_at: string;
    quiz_markup_tools_id: number;
    assignment_markup_tools_id: number | null;
}

interface QuestionItem {
    question: Question;
    question_background_image: string | null;
    options: Option[];
}

interface Question {
    id: number;
    lesson_markup_tools_id: number;
    question: string;
    created_at: string;
    updated_at: string;
}

interface Option {
    id: number;
    lesson_mcq_questions_id: number;
    answer: string;
    marked_as_right_answer: boolean | null;
    created_at: string;
    updated_at: string;
    order_no: number | null;
}

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    authToken: string;
    openEndedText: {
        topic: string;
        answer: string;
        isSubmit: boolean;
    };
    slectedOption: Questionss;
    slectedOptionForMultiple: QuestionssForMultiple;
    inputValues: {
        [keys: string]: string;
    };
    inputValuesForChart: {
        topic: string; answer: string
    }[];
    pairsMatching: { question: string; answer: string | null }[];
    rightArray: string[];
    currentStep: number;
    allDataSave: any[];
    dragSomethingSubmit: boolean;
    allData: QuizMarkupTool[];
    dragSomething: boolean;
    totalStep: null | number,
    dragItem: null | number;
    dragOverItem: null | number;
    quizId: string | number;
    quizName: string;
    className: string;
    dueDate: number;
    quizStatus: string;
    isQuizLoading: boolean;
    studentId: number | string;
    current_markup_tool_id: number | string;
    first_markup_tools_id: number | string;
    answerData: any[],
    answerDataMatchingQuestion: any[],
    isModalOpen: boolean;
    isFeedbackModalOpen: boolean;
    feedbackText: string;
    currentQuestionId: number | string;
    isFeedbackLoad: boolean;
    submittedAnswersIds: (string | number)[];
    isLimitModalOpen: boolean;
    submittedAnswerCount: number;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class AssessmentQuizTestController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getQuizDataApiCallId: string = "";
    submitPostApiId: string = "";
    markQuizAsCompletedApiCallId: string = "";
    getQuizAnswersDataApiCallId: string = "";
    getQuestionFeedbackApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            authToken: '',
            openEndedText: { topic: "", answer: "", isSubmit: false },
            slectedOption: {
                questionId: "",
                question: "",
                answer: "",
                isSubmit: false,
            },
            slectedOptionForMultiple: {
                answers: [],
                questionId: "",
                isSubmit: false,
                question: "",
            },
            inputValues: {},
            inputValuesForChart: [],
            pairsMatching: [],
            rightArray: [],
            currentStep: 0,
            allDataSave: [],
            dragSomethingSubmit: false,
            allData: [],
            dragSomething: false,
            totalStep: 1,
            dragItem: null,
            dragOverItem: null,
            quizId: '',
            quizName: '',
            className: '',
            dueDate: 0,
            quizStatus: '',
            isQuizLoading: false,
            studentId: 0,
            current_markup_tool_id: 0,
            first_markup_tools_id: 0,
            answerData: [],
            answerDataMatchingQuestion: [],
            isModalOpen: false,
            isFeedbackModalOpen: false,
            feedbackText: '',
            currentQuestionId: 0,
            isFeedbackLoad: false,
            submittedAnswersIds: [],
            isLimitModalOpen: false,
            submittedAnswerCount: 0,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if(this.getQuizDataApiCallId === apiRequestCallId){
            
            if(responseJson?.data){
                const sortedData = responseJson.data.attributes.quiz_markup_tools.data.sort((toolA:QuizMarkupTool, toolB:QuizMarkupTool) => {
                    return toolA.attributes.stage_number - toolB.attributes.stage_number;
                });

                this.setState({ 
                    allData: sortedData,
                    quizName: responseJson.data.attributes.quiz_name,
                    className: responseJson.data.attributes.class_name,
                    dueDate: responseJson.data.attributes.days_left,
                    quizStatus: responseJson.data.attributes.quiz_status,
                    isQuizLoading: false,
                    current_markup_tool_id: responseJson.data.attributes.quiz_markup_tools.data[this.state.currentStep].id,
                    first_markup_tools_id: responseJson.data.attributes.quiz_markup_tools.data[0].id,
                }, () => {
                    this.state.quizStatus !== 'pending' && this.handleGetQuizAnswers();
                })
            }
        }
        else if (this.submitPostApiId === apiRequestCallId) {
            if (responseJson?.data?.id) {
                this.submitApiCall()
            }
            if(responseJson && (responseJson.error === 'Request size exceeds the maximum allowed size of 200 MB')){
                this.setState({ isLimitModalOpen: true })
            }
        }
        else if(this.getQuizAnswersDataApiCallId === apiRequestCallId) {
            this.setState({
                answerData: responseJson[responseJson.length - 1],
                answerDataMatchingQuestion: responseJson,
            })
        }
        else if(this.markQuizAsCompletedApiCallId === apiRequestCallId) {
            if(responseJson.message === 'Done'){
                this.setState({isModalOpen: true})
            } else if(responseJson && (responseJson.error === 'Request size exceeds the maximum allowed size of 200 MB')){
                this.setState({ isLimitModalOpen: true })
            }
        }
        else if (this.getQuestionFeedbackApiCallId === apiRequestCallId) {
            if(responseJson && responseJson.data && responseJson.data.length > 0){

            this.setState({ 
                isFeedbackLoad: false,
                feedbackText: responseJson?.data?.[responseJson?.data.length - 1]?.attributes?.description,
            })
            } else {
                this.setState({
                    feedbackText: 'No feedback available'
                })
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        const authToken = await getStorageData("authToken");
        const studentId = await getStorageData("id");
        
        this.setState({
            authToken: authToken,
            studentId: studentId,
        });

        const currRole = await getStorageData("role");

        if(currRole !== "Student"){
            const roleMessage = new Message(getName(MessageEnum.NavigationMessage));
            roleMessage.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLogin");
            roleMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(roleMessage);
        }

        const pathname = window.location.pathname;
        const parts = pathname.split('/');
        const quiz_id = parts[2];

        this.setState({quizId: quiz_id}, () => {
            this.handleGetQuizData();
        })
    }

    submitApiCall = () => { }

    selectAnswer = (value: string, question: string, index: string) => {
        this.setState({ slectedOption: { answer: value, isSubmit: false, question: question, questionId: index } })
    };

    getOptionStyle = (value: string, data: any, type: string) => {
        if(this.state.quizStatus === 'complete'){
            return this.getAnswerStyle(value, data, type)
        }else{

        if (this.state.slectedOption.answer === value) {
            if (this.state.slectedOption.isSubmit) {
                return {
                    width: "92%",
                    background: "#2B3D7D",
                    borderRadius: "20px",
                    position: "relative",
                    margin: "20px 0px",
                    padding: "15px",
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#fff",
                    cursor: "pointer",
                    border: "2px solid #2B3D7D",
                    fontFamily: "Poppins",
                    lineHeight: "16px",
                } as React.CSSProperties;
            } else {
                return {
                    width: "92%",
                    border: "2px solid #2B3D7D",
                    borderRadius: "20px",
                    position: "relative",
                    margin: "20px 0px",
                    padding: "15px",
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#282829",
                    cursor: "pointer",
                    fontFamily: "Poppins",
                    lineHeight: "16px"
                };
            }
        } else {
            return {
                position: "relative",
                width: "92%",
                border: "1px solid #2B3D7D",
                borderRadius: "20px",
                margin: "20px 0px",
                padding: "15px",
                fontSize: "14px",
                fontWeight: 500, color: "#282829",
                cursor: "pointer",
                fontFamily: "Poppins",
                lineHeight: "16px"
            };
        }
    }

    };

    getOptionStyleForMultiple = (value: string, data: any, type: string, isMultiple: boolean) => {
        if(this.state.quizStatus === 'complete'){
            return this.getAnswerStyle(value, data, type, isMultiple)
        }else{

        if (this.state.slectedOptionForMultiple.answers?.includes(value)) {
            if (this.state.slectedOptionForMultiple.isSubmit) {
                return {
                    border: "2px solid #2B3D7D",
                    width: "92%",
                    position: "relative",
                    margin: "20px 0px",
                    padding: "15px",
                    fontSize: "14px",
                    lineHeight: "16px",
                    fontWeight: 500,
                    borderRadius: "20px",
                    color: "#fff",
                    background: "#2B3D7D",
                    cursor: "pointer",
                    fontFamily: "Poppins",
                } as React.CSSProperties;
            } else {
                return {
                    border: "2px solid #2B3D7D",
                    width: "92%",
                    position: "relative",
                    padding: "15px",
                    margin: "20px 0px",
                    lineHeight: "16px",
                    fontSize: "14px",
                    color: "#282829",
                    cursor: "pointer",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    borderRadius: "20px",
                };
            }
        } else {
            return {
                width: "92%",
                border: "1px solid #2B3D7D",
                position: "relative",
                margin: "20px 0px",
                padding: "15px",
                fontSize: "14px",
                lineHeight: "16px",
                cursor: "pointer",
                borderRadius: "20px",
                fontFamily: "Poppins",
                fontWeight: 500, 
                color: "#282829",
            };
        }
    }

    };

    getAnswerStyle = (valueAnswer: string, data: any, type: string, isMultiple?: boolean) => {
        const studentAnswer = data && data.student_answer;
        const studentAnswerPoll = data && data.answers && data.answers[0] && data.answers[0].answer;
        const teacherAnswer = data && data.teacher_correct_answer;
        if (valueAnswer === teacherAnswer || (type !== "MCQ" && (isMultiple ? (studentAnswerPoll && studentAnswerPoll.includes(valueAnswer)) : valueAnswer === studentAnswerPoll))) {
            return {
                width: "92%",
                borderRadius: "20px", position: "relative" as "relative",
                border: "2px solid #00A52C",
                background: "#00A52C",
                margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#fff",
                fontFamily: "Poppins", lineHeight: "16px",
                cursor: "pointer",
            }  as React.CSSProperties
        } else if (valueAnswer !== teacherAnswer && valueAnswer !== studentAnswer) {
            return {
                width: "92%",
                cursor: "pointer",
                borderRadius: "20px",
                fontFamily: "Poppins", lineHeight: "16px", position: "relative" as "relative",
                margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#282829",
                border: "1px solid #2B3D7D",
            }  as React.CSSProperties
        } else {
            return {
                cursor: "pointer",
                border: "1px solid #FF0000", borderRadius: "20px",
                position: "relative" as "relative",
                width: "92%",
                margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#fff",
                background: "#FF0000",
                fontFamily: "Poppins", lineHeight: "16px",
            }  as React.CSSProperties
        }
    };

    handleGetQuizData = () => {
        this.setState({ isQuizLoading: true })

        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.authToken,
        };

        const getQuizDataMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getQuizDataApiCallId = getQuizDataMsg.messageId;

        getQuizDataMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getQuizDataApiEndPoint}?quiz_id=${this.state.quizId}`
        );

        getQuizDataMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getQuizDataMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(getQuizDataMsg.id, getQuizDataMsg);
    }

    handelOpenEnded = (event: React.ChangeEvent<HTMLTextAreaElement>, topic: string | null) => {
        this.setState({
            openEndedText: {
                topic: topic ? topic : "",
                answer: event.target.value,
                isSubmit: false
            }
        })
    }

    submitAnswer = (indexs: string, stageName: string, assignment_id: number | string, mainIndex: number) => {

        const isMultiple = this.state.allData[this.state.currentStep].attributes.multiple;
        const {
            submittedAnswerCount, 
            submittedAnswersIds,
        } = this.state;

        if (!submittedAnswersIds.includes(assignment_id)) {
            this.setState({
                submittedAnswersIds: [...submittedAnswersIds, assignment_id],
                submittedAnswerCount: submittedAnswerCount + 1,
            });
        }

        const { slectedOption, slectedOptionForMultiple, rightArray, openEndedText, currentStep, pairsMatching, allDataSave } = this.state;
        let bodyData: { question?: string, topic?: string; answer?: string }[];

        const updateTheAllDataSave = (newData: Questionss | { topic: string; answer: string | null; }[] | string[]) => {
            const updatedAllDataSaves = allDataSave.filter((entry: { id: string | number; }) => entry.id !== currentStep);
            updatedAllDataSaves.push({ id: currentStep, data: newData });
            return updatedAllDataSaves;
        };

        if (stageName === "polling_question" || stageName === "MCQ") {
            bodyData = [
                { 
                    question: slectedOption.question, 
                    answer: slectedOption.answer 
                }
            ];

            let multipleBody = [
                { 
                    question: slectedOptionForMultiple.question, 
                    answer: slectedOptionForMultiple.answers
                }
            ]

            if(isMultiple){
                this.setState(prevState => ({
                    slectedOptionForMultiple: { ...prevState.slectedOptionForMultiple, isSubmit: true, },
                    allDataSave: updateTheAllDataSave(this.state.slectedOptionForMultiple),
                }), () => { this.handleSubmitApi(indexs, assignment_id, multipleBody, mainIndex) });
            }else{
                this.setState(prevState => ({
                    slectedOption: { ...prevState.slectedOption, isSubmit: true, },
                    allDataSave: updateTheAllDataSave(this.state.slectedOption),
                }), () => { this.handleSubmitApi(indexs, assignment_id, bodyData, mainIndex) });
            }
        }

        if (stageName === "open_ended_question") {
            bodyData = [{ topic: openEndedText.topic, answer: openEndedText.answer }];

            this.setState(prevState => ({
                openEndedText: { ...prevState.openEndedText, isSubmit: true },
                allDataSave: updateTheAllDataSave(prevState.openEndedText)
            }), () => { this.handleSubmitApi(indexs, assignment_id, bodyData, mainIndex) });
        }

        if (stageName === "matching_question") {
            this.setState(prevState => ({
                dragSomethingSubmit: true, allDataSave: updateTheAllDataSave(rightArray)
            }), () => { this.handleSubmitApi(indexs, assignment_id, pairsMatching, mainIndex); });
        }
    };

    editData = () => {
        const step = this.state.currentStep
        const datas = this.state.allDataSave.find((value: { id: string | number }) => value.id === step);
        const dataTypes = this.state.allData[step]?.attributes.quiz_stage_name;

        switch (dataTypes) {
            case "polling_question":
            case "MCQ":
                const textData = datas ? {
                    question: datas.data.question,
                    questionId: datas.data.questionId,
                    isSubmit: false,
                    answer: datas.data.answer,
                } : {
                    question: "",
                    questionId: "",
                    isSubmit: false,
                    answer: "",
                };
                this.setState({ slectedOption: textData });
                break;

            case "matching_question":
                this.setState({ rightArray: datas ? datas.data : [], dragSomethingSubmit: false });
                break;

            case "open_ended_question":
                const openEndedData = datas ? {
                    answer: datas.data.answer,
                    isSubmit: false,
                    topic: datas.data.topic,
                } : {
                    topic: "",
                    isSubmit: false,
                    answer: "",
                };
                this.setState({ openEndedText: openEndedData });
                break;
            default:
                break;
        }
    };

    editDataMultiplePoll = () => {
        const step = this.state.currentStep
        const datas = this.state.allDataSave.find((value: { id: string | number }) => value.id === step);

        const textData = datas ? {
            question: datas.data.question,
            questionId: datas.data.questionId,
            isSubmit: false,
            answers: datas.data.answers,
        } : {
            question: "",
            questionId: "",
            isSubmit: false,
            answers: [],
        };
        this.setState({ slectedOptionForMultiple: textData });
    };

    findCorrectAnswer = () => {
        const { allData, currentStep } = this.state;

        const options = allData[currentStep].attributes.questions[0].options;
        for (const option of options) {
            if (option.marked_as_right_answer) {
              return option.answer;
            }
        }

        return null; 
    };

    handleSubmitApi = (index: string, assignment_id: number | string, thatData: any, mainIndex: number) => {
        const { allData, currentStep } = this.state;
        
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.authToken
        };

        let validation = allData[currentStep]?.attributes?.quiz_stage_name === "MCQ"

        let body;

        if(validation){
            const correct_answer = this.findCorrectAnswer();

            body = {
                "quiz_id": mainIndex,
                "quiz_markup_tools_id": assignment_id,
                "correct_answer": correct_answer,
                "question": thatData[0].question,
                "answers": thatData,
            }
        }else {
            body = {
                "quiz_id": mainIndex,
                "quiz_markup_tools_id": assignment_id,
                "answers": thatData,
            }
        }

        const verifyCodeSignMes = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        verifyCodeSignMes.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.submitQuizAnswerEndPoint
        );
        verifyCodeSignMes.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        verifyCodeSignMes.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
        verifyCodeSignMes.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        this.submitPostApiId = verifyCodeSignMes.messageId;
        runEngine.sendMessage(verifyCodeSignMes.id, verifyCodeSignMes);
    };

    setOldData = (step: number) => {
        const data = this.state.allDataSave.find((value: { id: string | number }) => value.id === step);
        const dataType = this.state.allData[step]?.attributes.quiz_stage_name;
        const isMultiple = this.state.allData[step]?.attributes.multiple;

        switch (dataType) {
            case "MCQ":
            case "polling_question":
                if(isMultiple){
                    const textDataForMultiple = data ? {
                        question: data.data.question,
                        answers: data.data.answers,
                        questionId: data.data.questionId,
                        isSubmit: true,
                    } : {
                        questionId: "",
                        question: "",
                        answers: [],
                        isSubmit: false,
                    };
                    this.setState({ slectedOptionForMultiple: textDataForMultiple });
                }else{
                    const textData = data ? {
                        questionId: data.data.questionId,
                        answer: data.data.answer,
                        question: data.data.question,
                        isSubmit: true,
                    } : {
                        answer: "",
                        question: "",
                        questionId: "",
                        isSubmit: false
                    };
                    this.setState({ slectedOption: textData });
                }
                
                break;

            case "open_ended_question":
                const openEndedData = data ? {
                    answer: data.data.answer,
                    topic: data.data.topic,
                    isSubmit: true
                } : {
                    answer: "",
                    topic: "",
                    isSubmit: false,
                };
                this.setState({ openEndedText: openEndedData });
                break;

            case "matching_question":
                this.setState({
                    rightArray: data ? data.data : [],
                    dragSomethingSubmit: !!data,
                    dragSomething: !!data
                });
                break;

            default:
                break;
        }
    }

    handelNext = () => {
        if (this.state.currentStep < this.state.allData.length - 1) {
            this.setState(prevState => ({
                currentStep: prevState.currentStep + 1,
                current_markup_tool_id: this.state.allData[this.state.currentStep + 1].id,
            }), () => {
                this.setOldData(this.state.currentStep)
                this.state.quizStatus !== 'pending' && this.handleGetQuizAnswers();
            });
        }
        this.setState({
            slectedOption: {
                answer: "",
                questionId: "",
                question: "",
                isSubmit: false
            },
            slectedOptionForMultiple: {
                answers: [],
                questionId: "",
                question: "",
                isSubmit: false,
            },
            dragSomethingSubmit: false,
            dragSomething: false,
            openEndedText: { topic: "", answer: "", isSubmit: false },
            inputValuesForChart: [],
        });
    }

    handleBack = () => {
        this.setState(prevState => ({
            currentStep: Math.max(prevState.currentStep - 1, 0),
            current_markup_tool_id: this.state.allData[Math.max(prevState.currentStep - 1, 0)].id,
        }), () => {
            this.setOldData(this.state.currentStep)
            this.state.quizStatus !== 'pending' && this.handleGetQuizAnswers();
        });
        this.setState({
            slectedOption: {
                answer: "",
                question: "",
                questionId: "",
                isSubmit: false
            },
            slectedOptionForMultiple: {
                answers: [],
                questionId: "",
                question: "",
                isSubmit: false,
            },
            dragSomethingSubmit: false,
            inputValuesForChart: [],
            dragSomething: false,
            openEndedText: { topic: "", answer: "", isSubmit: false },
        });
    }

    backToTaskList = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "TaskList");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    }

    setData = (data: Pairs[]) => {
        const fullData = data.map((pair) => ({ question: pair.pair_question, answer: pair.pair_answer }))
        if (this.state.rightArray.length < 1) {
            this.setState({
                pairsMatching: fullData,
                rightArray: data.map((value) => value.pair_answer),
            })
        }
    };

    handleSort = () => {
        const { dragOverItem, dragItem, rightArray } = this.state;

        if (dragItem == null || dragOverItem == null) return;

        let tempRightArray = [...rightArray];

        let temp = tempRightArray[dragItem];
        tempRightArray[dragItem] = tempRightArray[dragOverItem];
        tempRightArray[dragOverItem] = temp;

        let _pairs = tempRightArray.map((answer, index) => ({
            question: this.state.pairsMatching[index].question,
            answer: answer,
        }));

        this.setState({
            dragItem: null, rightArray: tempRightArray, dragSomething: true, dragOverItem: null, pairsMatching: _pairs,
        });
    };

    handleDragEnter = (index: number) => {
        this.setState({ dragOverItem: index });
    };

    handleDragStart = (index: number) => {
        this.setState({ dragItem: index });
    };

    setInnerHTML = (text: string | null) => {
        return text ? text : '';
    }

    checkIsMcqOrPolling = (text: string) => {
        return text === "MCQ" ? "Multiple Choice Question" : "Polling Question"
    }

    checkSubmitAnswerMcqOrPolling = (currentData: QuizMarkupTool, answer: string) => {
        if(this.state.quizStatus !== 'complete'){
            if (currentData.attributes.questions && currentData.attributes.questions.length > 0 && !this.state.slectedOption.isSubmit) {
                this.selectAnswer(answer, currentData.attributes.questions[0].question.question, currentData.id);
            }
        }
    }

    selectAnswerForMultiple = (currentData: QuizMarkupTool, answer: string) => {
        if (this.state.quizStatus !== 'complete') {
            if (currentData.attributes.questions && currentData.attributes.questions.length > 0 && !this.state.slectedOptionForMultiple.isSubmit) {
                const { answers = [] } = this.state.slectedOptionForMultiple;
                let updatedAnswers;
    
                if (answers.includes(answer)) {
                    updatedAnswers = answers.filter(ans => ans !== answer);
                } else {
                    updatedAnswers = [...answers, answer];
                }
    
                this.setState({
                    slectedOptionForMultiple: {
                        answers: updatedAnswers,
                        isSubmit: false,
                        question: currentData.attributes.questions[0].question.question,
                        questionId: currentData.id
                    }
                });
            }
        }
    }

    checkOpenEndedOnchange = (event: React.ChangeEvent<HTMLTextAreaElement>, topic: string | null) => {
        !this.state.openEndedText.isSubmit && this.handelOpenEnded(event, topic)
    }

    handleMarkQuizAsCompleted = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.authToken
        };
        
        const body = {
            "markup_tools_id": this.state.first_markup_tools_id,
            "student_id": this.state.studentId,
            "quiz_id": this.state.quizId,
            "type": "quiz",
        }

        const markQuizAsCompletedMes = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        markQuizAsCompletedMes.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.markQuizAsCompletedEndPoint
        );
        markQuizAsCompletedMes.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        markQuizAsCompletedMes.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );
        markQuizAsCompletedMes.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        this.markQuizAsCompletedApiCallId = markQuizAsCompletedMes.messageId;
        runEngine.sendMessage(markQuizAsCompletedMes.id, markQuizAsCompletedMes);
    };

    handleGetQuizAnswers = () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": this.state.authToken,
        };

        const getQuizAnswersDataMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getQuizAnswersDataApiCallId = getQuizAnswersDataMsg.messageId;

        getQuizAnswersDataMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getQuizAnswersEndPoint}${this.state.current_markup_tool_id}&student_id=${this.state.studentId}`
        );

        getQuizAnswersDataMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getQuizAnswersDataMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(getQuizAnswersDataMsg.id, getQuizAnswersDataMsg);
    }

    handleCloseModal = () => {
        this.setState({isModalOpen: false})

        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "TaskList");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);

        setStorageData('studentTaskListTab', 'quiz')
    }

    viewFeedback = (questionId: number | string) => {
        this.setState({ isFeedbackModalOpen: true, currentQuestionId: questionId }, () => {
            this.getFeedbackForQuestion();
        });
    }

    getFeedbackForQuestion = () => {
        const { authToken, currentQuestionId } = this.state;

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: authToken,
        };
        const reqMsgs = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        reqMsgs.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getQuizQuestionFeedback}${currentQuestionId}&student_id=${this.state.studentId}`
        );
        reqMsgs.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        reqMsgs.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        this.getQuestionFeedbackApiCallId = reqMsgs.messageId;

        runEngine.sendMessage(reqMsgs.id, reqMsgs);
    }

    handleCloseFeedbackModal = () => {
        this.setState({isFeedbackModalOpen: false, feedbackText: ''})
    }

    handleQuesValidation = (validate: QuizMarkupTool) => {
        return validate && validate.attributes  && validate.attributes.questions  && validate.attributes.questions[0] && validate.attributes.questions[0].options
    }

    checkIsCorrect = (data: any, answer: string) => {
        let isRight = false;
        if((answer === data.student_answer) && (answer === data.teacher_correct_answer)){
            isRight = true;
        }
        return isRight;
    }

    handleCloseSnack  = () => {
        this.setState({
          isLimitModalOpen: false,
        })
    }

    canvasImageCondition = (data: QuizMarkupTool) => {
        let quiz_canvas_data = data && data.attributes && data.attributes.canvas_data && JSON.parse(data.attributes.canvas_data)
        let quizCanvas = quiz_canvas_data && JSON.parse(quiz_canvas_data.canvas)
        let quizCanvasSize = quiz_canvas_data && quiz_canvas_data.canvasSize

        return { quizCanvas, quizCanvasSize }
    }
    // Customizable Area End
}
