import React, { useEffect, useState } from "react";
import { Box, IconButton, Popover , Grid, Divider, Switch,Typography, Modal, styled } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AddBackgroundImageModal from "../../../../CFWordCloud/src/Teacher/components/AddBackgroundImageModal.web";
import ContentEditable from "react-contenteditable";
import { Cross,points, whiteSetTimer, settingBtn, addBackground, whiteAddBackground, blueTimer, whitePoints, whiteDoublePoint, whiteMusic, blueDoublePoint, blueMusicBtn, timer } from "../../assets";
import StorageProvider from "../../../../../framework/src/StorageProvider";
import GenericButton from "../../../../../components/src/GenericButton.web";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { minutesDec, minutesInc, secondsDec, secondsInc } from "./Mcq.web";

interface Props {
    preview:string | null;
    addOpenEndedTextState: any;
    setAddOpenEndedTextState: (data:any)=>void
    setPreview:(url: string | null) => void;
    updateTime: (minutes:string, seconds:string) => void;
    openendedState:any
    setopenendedState:any
    openEndedState:{
      checkedA: boolean,
      checkedB: boolean,
      checkedC: boolean,
    }
    handleOpenEndedChange:(value: boolean, name: string) => void;
    setBackgroundImage?:(image:any)=>void
   }

const OpenSwitch = withStyles((theme) => ({
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
          boxSizing: "border-box"
        },
      },
    },
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
      "& .MuiSwitch-track":{
        boxSizing: "border-box"
      }
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
      backgroundColor:"white"
    },
    checked: {},
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "grey",
    },
  }))(Switch);


  interface SetTimer {
    seconds: string;
    minutes: string;
  }
  
  const handleSetTimer = (props: { updateTime: (value: string, value1: string) => void; },minutes: number,seconds: number,setTimeset: (arg0: { minutes: string; seconds: string; }) => void,setIsModalOpen: (arg0: boolean) => void,setIsTimerSet: (arg0: boolean) => void) => {    
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    props.updateTime(formattedMinutes, formattedSeconds)
    setTimeset({
      minutes: formattedMinutes,
      seconds: formattedSeconds,
    });
    setIsModalOpen(false)
    setIsTimerSet(true)
  }

  


  const AddPoints = (handlePointsLeave: React.MouseEventHandler<HTMLElement>,setIsPointsHovered: { (value: React.SetStateAction<boolean>): void; (value: boolean): void; },isPointsHovered: boolean,openEndedState: { checkedA: boolean; checkedB?: boolean; checkedC?: boolean; },handleOpenEndedChange:(value: boolean, name: string) => void ) => {
    const classes = useStyles();      
    return(
      <Box className={classes.openPopup}
        data-test-id="switchpointTestId"
        onMouseEnter={() => handlePointsHovered(setIsPointsHovered)}
        onMouseLeave={handlePointsLeave}>
        <Box className={classes.openPopupItems}>
          <img src={isPointsHovered ? whitePoints : points} />
          <Typography className={classes.popupHeading}>Points</Typography>
        </Box>
        <Grid item>
          <OpenSwitch data-test-id="changePointTestId" checked={openEndedState.checkedA ? true : false} onChange={(event) => handleOpenEndedChange(event.target.checked, event.target.name)} name="checkedA" />
        </Grid>
      </Box>
    )
  }


  const handleDoublePointEnter = (setIsDoubleHovered: (value: boolean) => void) => {
    setIsDoubleHovered(true)
 }


 const SettingPopver = (pageTypeData: string | undefined,isClockSet: boolean,timeSet: SetTimer,handleClick: React.MouseEventHandler<HTMLButtonElement> ) => {
  const classes = useStyles();
  return(
    <>
      {pageTypeData == "quizName" && <Box className={classes.settingBtn} >
        <Box style={{ display: "flex" }}>
          {isClockSet && <Box className={classes.timerMainBox}>
            <Box className={classes.timerFirst}>
              <img className={classes.clockImage} src={whiteSetTimer} />
            </Box>
            <Box className={classes.timerSecond}>
              <Typography style={{ color: "black" }}>{timeSet.minutes}</Typography>
              <Typography style={{ color: "black" }}>{`:${timeSet.seconds}`}</Typography>
            </Box>
          </Box>}
        </Box>
        <IconButton onClick={handleClick} data-test-id="popOverTestId">
          <img src={settingBtn} />
        </IconButton>
      </Box>}
    </>
  )
 }

 const handleMusicEnter = (setIsMusicHovered: (value: boolean) => void) => {
   setIsMusicHovered(true)
 }

 const handleDoublePointLeave = (setIsDoubleHovered: (value: boolean) => void) => {
   setIsDoubleHovered(false)
 }

 const handleCloseModal = (setModalOpen: (value: boolean) => void) => {
   setModalOpen(false);
 }

  const handleMouseLeave = (setIsHovered: (value: boolean) => void) => {
    setIsHovered(false);
  };

  const handleTimerLeave = (setIsTimerHovered: (value: boolean) => void) => {
    setIsTimerHovered(false);
  };

  const handleMusicLeave = (setIsMusicHovered: (value: boolean) => void) => {
    setIsMusicHovered(false)
  }

  const handlePointsHovered = (setIsPointsHovered: (value: boolean) => void) => {
    setIsPointsHovered(true);
  }

  const handleMouseEnter = (setIsHovered: (value: boolean) => void) => {
    setIsHovered(true);
  };

  const handleModalTimewrClose = (setIsModalOpen: (value: boolean) => void) => {
    setIsModalOpen(false)
  }

const useStyles = makeStyles(() => ({
    editArea: {
        height: "740px",
        border: "1px solid rgba(149, 157, 165, 0.2)",
        borderRadius: "10px",
        backgroundColor: "#FFFFFF",
        marginTop:"60px",
        marginBottom: "50px",
        padding: '20px',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "15px",
        position:"relative"
    },
    mainWrapper: {
        width: "100%",
        fontWeight: "bold",
        marginLeft:"12px",
        fontSize:"16px",
        color:"#0A033C",
        fontFamily: "Poppins",
    },
    settingIcon: {
        borderRadius: "50%",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 0px 0px 3px",
        position: "relative",
        cursor: "pointer",
        width: "35px",
        height:" 35px",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",

    },
    settingMenu: {
        display: "flex",
        flexDirection: "column",
        width: "190px",
        border: "1px solid #979797",
        borderRadius: "8px",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        position: "absolute",
        zIndex: 999,
        right: "5px",
        top: "27px",
        backgroundColor: "#fff"
    },
    action: {
        "&:hover": {
            backgroundImage:
                "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
            color: "#FFF",
        },
        display: "flex", padding: "8px", gap: "4px",
        color: "#2B3D7D",
        borderRadius: "4px",
        cursor: "pointer"
    },
    formWrapper: {
        display: "block"
    },
    editArea2: {
        width: "94%",
        height: "300px",
        border: "1px solid rgba(149, 157, 165, 0.2)",
        borderRadius: "10px",
        backgroundColor: "#FFFFFF",
        marginBottom: "50px",
        padding: "25px",
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 400,
        overflow: "auto",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        marginTop:"60px",
        "&:empty:before": {
            content: "attr(data-placeholder)",
            color:"#5D5A6F",
            fontSize: "16px",
            fontWeight: 600,
            fontFamily: "Poppins",
          },
    },
    timerMainBox :{
      display:"flex",
      borderRadius:"40px"
    },
    clockImage:{
      height: "20px",
      width: "18px" 
    },
    timerFirst :{
    width: "40px",
    height: "40px",  
    marginTop: "20px",
    borderRadius:"20px 0px 0px 20px",
    justifyContent:"center",
    display:"flex",
    backgroundColor: "#2B3D7D", 
    alignItems:"center",
    alignContent:"center" 
    },
    settingBtn : {
        position :"absolute",
        right :"10px",
        display:"flex",
        top :"10px",
        zIndex:200
      },
      openPopup: {
        display: 'flex',
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 30px",
        cursor: "pointer",
        color: "#324381",
        "&:hover": {
          background: "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
          color :"white",
        }, 
      },
      timerSecond :{
        display: "flex",
        width: "90px",
        height: "40px",
        marginTop: "20px",
        borderRadius: "0px 20px 20px 0px",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        backgroundColor: "white", 
      },
      openPopupItems: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        padding: "15px 0"
      },
      popupHeading: {
        fontSize: "12px",
        fontFamily: "Poppins",       
        fontWeight: 500,
        "&:hover": {
          color: "white"
        }
      },
}));
const OpenEndedQuestion = (props: Props) => {
  let openendedtimeString = props?.openendedState?.countdown_timer ? props.openendedState.countdown_timer : "00:00"
  let openEndedsplitTime = openendedtimeString?.split(":");
    const [backgroundImage, setBackgroundImage] = React.useState<null>(null);
    const { addOpenEndedTextState, setAddOpenEndedTextState, handleOpenEndedChange, openEndedState } = props;
    const classes = useStyles();
    const [isDoubleHovered, setIsDoubleHovered] = useState(false);
    const [seconds, setSeconds] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [timeSet, setTimeset] = useState<SetTimer>({minutes: openEndedsplitTime[0] ? openEndedsplitTime[0] : "00", seconds: openEndedsplitTime[1] ?openEndedsplitTime[1] : "00" });
    const [isMusicHovered, setIsMusicHovered] = useState(false);
    const handleOpenEndedText = (e: any) => {
        setAddOpenEndedTextState(e.target.value);
    }
    const [isTimerHovered, setIsTimerHovered] = useState(false);
    const [isPointsHovered, setIsPointsHovered] = useState(false);
    const [minutes, setMinutes] = useState(0);
    const [isClockSet, setIsTimerSet] = useState<boolean>(props.openendedState?.countdown_timer);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [state, setState] = React.useState({
      checkedA: false,
      checkedB: false,
      checkedC: false,
    });
    const [modalOpen, setModalOpen] = React.useState<boolean>();
    const [pageTypeData, setpageTypeData] = React.useState<string>();
    const [isHovered, setIsHovered] = useState(false);

    useEffect(()=>{
      pageType()
    },[])

    useEffect(() => {
      props.setBackgroundImage && props.setBackgroundImage(backgroundImage);
    }, [backgroundImage])
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleModalTimerOpen = () => {
      setIsModalOpen(true)
      setAnchorEl(null)
    }

    const secondsIncremented = seconds + 1 < 60 ? (seconds + 1) : 0;
    const secondIncremented = secondsIncremented < 10 ? "0" + secondsIncremented : secondsIncremented;


    const handleModalOpen = () => {
      setModalOpen(true)
      setAnchorEl(null)
    }

    const handleTimerEnter = () => {
      setIsTimerHovered(true);
    };
    
    const pageType = async () => {
      let quizName = await StorageProvider.get("pageTypeData")
      setpageTypeData(quizName)
    }

    const minutesIncremented = minutes + 1 < 60 ? (minutes + 1) : 0;
    const minuteIncremented = minutesIncremented < 10 ? "0" + minutesIncremented : minutesIncremented;
  

    const handlePointsLeave = () => {
      setIsPointsHovered(false);
    }

    const secondsDecremented = seconds - 1 > -1 ? (seconds - 1) : 59;
     const SecondDecremented = secondsDecremented < 10 ? "0" + secondsDecremented : secondsDecremented;

    const MinutesDecremented = minutes - 1 > -1 ? (minutes - 1) : 59;
     const formattedMinutesDecremented = MinutesDecremented < 10 ? "0" + MinutesDecremented : MinutesDecremented;

    const backgroundStyle = {
      height: '100vh',
      backgroundSize: 'cover',
      backgroundPosition: 'center', 
      backgroundImage: `linear-gradient(rgba(255,255,255,0.6), rgba(255,255,255,0.6)), url('${props.preview}')`,
    };
  
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    };
  
  
    const openId = Boolean(anchorEl);
    const settingId = openId ? 'simple-popover' : undefined;
    return (
        <Box style={props.preview ? backgroundStyle : undefined} className={classes.editArea}>
            {SettingPopver(pageTypeData,isClockSet,timeSet,handleClick)}
            <Popover
                data-test-id="closehandleTestId"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{
                    style: {
                        marginLeft: "60px",
                    },
                }}
                id={settingId}
                open={openId}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                {AddImage(setIsHovered,handleModalOpen,isHovered)}
                <Divider />
                {AddTimer(handleTimerEnter,setIsTimerHovered,handleModalTimerOpen,isTimerHovered)}
            </Popover>
            <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
                <Box className={classes.mainWrapper}>
                    Answers
                </Box>
            </Box>
            <ContentEditable
                className={classes.editArea2}
                html={addOpenEndedTextState ? addOpenEndedTextState : ""}
                data-placeholder="Type your answer"
                data-test-id="content-box"
                disabled={true}
                onChange={handleOpenEndedText}
            />
        <AddBackgroundImageModal
          data-test-id="closeImageModal"
          open={modalOpen}
          onClose={()=>handleCloseModal(setModalOpen)}
          backgroundImage={backgroundImage}
          setBackgroundImage={setBackgroundImage}
          setPreview={props.setPreview}
        />
        <Modal open={isModalOpen} onClose={handleModalTimerOpen}>
        <TopBox>
          <img data-test-id="modalTimeTestId" className="closIcon" src={Cross} onClick={()=>handleModalTimewrClose(setIsModalOpen)} />
          <Typography className="pickerModalTitle">Add countdown timer</Typography>
          <Box className="timeSectionWrapper">
            {TiemSecond(minutes,seconds,setMinutes,setSeconds,minuteIncremented,formattedMinutesDecremented)}
            {SecondMinute(seconds ,minutes,setMinutes,setSeconds,SecondDecremented ,secondIncremented)}
          </Box>
          <GenericButton data-test-id="setTimerTestId" handleClick={()=>handleSetTimer(props,minutes,seconds,setTimeset,setIsModalOpen,setIsTimerSet)} label="Set Timer" type={"COLORED"} customStyle={{width: "400px" }}/>
        </TopBox>
      </Modal>
        </Box >
    );
};

let timeout: NodeJS.Timeout | null = null;

const clearExistingTimeout = () => {
  if (timeout !== null) {
    clearTimeout(timeout);
  }
};

export const updateMinutes = (dataDelta: number, minutes: number, setMinutes: (value: number | ((prevMinutes: number) => number)) => void) => {
  if (dataDelta === 1 && minutes === 59) {
    setMinutes(0);
  } else if (dataDelta === -1 && minutes === 0) {
    setMinutes(59);
  } else {
    setMinutes((minPrevious) => Math.min(Math.max(minPrevious + dataDelta, 0), 59));
  }
};

export const updateSeconds = (dataDelta: number, seconds: number, setMinutes: (value: number | ((prevMinutes: number) => number)) => void, setSeconds: (value: number | ((prevSeconds: number) => number)) => void) => {
  if (dataDelta === 1 && seconds === 59) {
    setMinutes((minPrevious) => (minPrevious + 1) % 60);
    setSeconds(0);
  } else if (dataDelta === -1 && seconds === 0) {
    setMinutes((minPrevious) => (minPrevious - 1 + 60) % 60);
    setSeconds(59);
  } else {
    setSeconds((prevSeconds) => Math.min(Math.max(prevSeconds + dataDelta, 0), 59));
  }
};

const scrollHandle = (
  event: React.WheelEvent<HTMLInputElement>,
  type: string,
  minutes: number,
  seconds: number,
  setMinutes: (value: number | ((prevMinutes: number) => number)) => void,
  setSeconds: (value: number | ((prevSeconds: number) => number)) => void
) => {
  const dataDelta = event.deltaY > 0 ? 1 : -1;

  clearExistingTimeout();

 
    if (type === "minutes") {
      updateMinutes(dataDelta, minutes, setMinutes);
    } else if (type === "seconds") {
      updateSeconds(dataDelta, seconds, setMinutes, setSeconds);
    }
};

const SecondMinute = (seconds: number ,minutes: number,setMinutes: { (value: React.SetStateAction<number>): void; (value: number | ((prevMinutes: number) => number)): void; },setSeconds: { (value: React.SetStateAction<number>): void; (value: number | ((prevSeconds: number) => number)): void; },SecondDecremented: {} | null | undefined ,secondIncremented: {} | null | undefined) =>{
  return(
    <div className="timeSection">
      <ArrowDropUpIcon 
        style={{width:'32px', height: '32px'}}
        data-test-id='btnOpenEndedSecondsInc'
        onClick={()=> secondsInc(seconds, minutes, setSeconds, setMinutes)}
      />
      <Typography className="passiveTime">{SecondDecremented}</Typography>
      <input
        className="inpt"
        id="minute"
        min={0}
        max={59}
        value={seconds < 10 ? "0" + seconds : seconds}
        onWheel={(event) => scrollHandle(event, "seconds", minutes, seconds, setMinutes, setSeconds)}
        disabled
      />
      <Typography className="passiveTime">{secondIncremented}</Typography>
      <ArrowDropDownIcon 
        style={{width:'32px', height: '32px'}}
        data-test-id='btnOpenEndedSecondsDec'
        onClick={()=> secondsDec(seconds, minutes, setSeconds, setMinutes)}
      />
    </div>
  )
}


const AddDoublePoints = (setIsDoubleHovered: { (value: React.SetStateAction<boolean>): void; (value: boolean): void; (value: boolean): void; },isDoubleHovered: boolean,state: { checkedA?: boolean; checkedB: boolean; checkedC?: boolean; },handleDoublePointOpenEnded: (value: boolean, name: string) => void,openEndedState:any ) => {
  const classes = useStyles();
  return(
    <Box className={classes.openPopup}
      data-test-id="doubleTestId"
      onMouseEnter={() => handleDoublePointEnter(setIsDoubleHovered)}
      onMouseLeave={() => handleDoublePointLeave(setIsDoubleHovered)}>
      <Box className={classes.openPopupItems}>
        <img src={isDoubleHovered ? whiteDoublePoint : blueDoublePoint} />
        <Typography className={classes.popupHeading} >Double Points</Typography>
      </Box>
      <OpenSwitch data-test-id="doublePointChangeTestId" checked={ openEndedState.checkedB? true : false} onChange={(event) => handleDoublePointOpenEnded(event.target.checked, event.target.name)} name="checkedB" />
    </Box>
  )
}

const TiemSecond = (minutes: number ,seconds: number ,setMinutes: ((value: number | ((prevMinutes: number) => number)) => void),setSeconds: ((value: number | ((prevSeconds: number) => number)) => void) ,minuteIncremented: {}  ,formattedMinutesDecremented: {} ) =>{
  return(
    <div className="timeSection">
      <ArrowDropUpIcon 
        style={{width:'32px', height: '32px'}}
        data-test-id='btnOpenEndedMinuteInc'
        onClick={()=> minutesInc(minutes, setMinutes)}
      />
      <Typography className="passiveTime">{formattedMinutesDecremented}</Typography>
      <input
        data-test-id="handleScrollTestId"
        className="inpt"
        id="hour"
        min={0}
        max={59}
        value={minutes < 10 ? "0" + minutes : minutes}
        onWheel={(event) => scrollHandle(event, "minutes", minutes, seconds, setMinutes, setSeconds)}
        disabled
      />
      <Typography className="passiveTime">{minuteIncremented}</Typography>
      <ArrowDropDownIcon 
        data-test-id='btnOpenEndedMinuteDec'
        style={{width:'32px', height: '32px'}}
        onClick={()=> minutesDec(minutes, setMinutes)}
      />
    </div>
  )
}


const AddImage = (setIsHovered: { (value: React.SetStateAction<boolean>): void; (value: boolean): void; (value: boolean): void; },handleModalOpen: React.MouseEventHandler<HTMLElement> | undefined,isHovered: boolean) => {
  const classes = useStyles();
  return(
    <Box className={classes.openPopup}>
      <Box className={classes.openPopupItems}
        data-test-id="isHoverTesxtId"
        onMouseEnter={() => handleMouseEnter(setIsHovered)}
        onClick={handleModalOpen}
        onMouseLeave={() => handleMouseLeave(setIsHovered)}>
        <img src={isHovered ? whiteAddBackground : addBackground} />
        <Typography className={classes.popupHeading} >Add Background Image</Typography>
      </Box>
    </Box>
  )
}

const AddTimer = (handleTimerEnter: React.MouseEventHandler<HTMLElement> ,setIsTimerHovered: { (value: React.SetStateAction<boolean>): void; (value: boolean): void; },handleModalTimerOpen: React.MouseEventHandler<HTMLElement> ,isTimerHovered: boolean) => {
  const classes = useStyles();
  return(
    <Box className={classes.openPopup}>
      <Box className={classes.openPopupItems}
        data-test-id="handlemodalTestId"
        onMouseEnter={handleTimerEnter}
        onMouseLeave={() => handleTimerLeave(setIsTimerHovered)}
        onClick={handleModalTimerOpen}>
        <img src={isTimerHovered ? timer : blueTimer} />
        <Typography className={classes.popupHeading}>Add Countdown Timer</Typography>
      </Box>
    </Box>
  )
}


const TopBox = styled("div")({
  maxWidth: "400px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "40px",
  height:"auto",
  borderRadius: "10px",
  backgroundColor: "#fff",
  width: "calc(100% - 40px)",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  position: "relative",
  gap: "10px",
  "& .inpt": {
    width: "40px",
    fontSize: "17px",
    cursor:'pointer',
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "none",
    textAlign: "center",
    borderTop: "1px solid rgb(45, 53, 92)",
    borderBottom: "1px solid rgb(45, 53, 92)",
    "&:disabled": {
      backgroundColor: "white",
      color: "rgb(45, 53, 92)",
      cursor:'pointer'
    },
  },
  "& .pickerModalTitle": {
    color: "#2B3D7D",
    fontSize: "20px",
    textTransform: "capitalize"
  },
  "& .closIcon": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "10px",
    right: "10px",
    width: "44px",
    height: "44px",
    cursor: "pointer"
  },
  "& .timeSectionWrapper": {
    display: "flex",
    paddingTop:"20px",
    paddingBottom:"40px",
    gap: "40px",
  },
  "& .timeSection": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "10px",
  },
  "& .setimerBtn": {
    padding:"10px 0px 10px 0px",
    width: "100%",
    backgroundColor: "rgb(45, 53, 92)",
    color: "#fff",
    textTransform: "capitalize"
  }
});

export default OpenEndedQuestion;
