import React, { useState, useEffect } from 'react';

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Box,
  Checkbox
} from '@material-ui/core';
import { styled } from "@material-ui/core/styles";
type TextTransformProperty = "capitalize"
type WordBreakProperty = 'break-all'
type TextAlignProperty = 'center'

interface DataTableProps<T> {
  columns: Array<{ label: string; key: keyof T, subKey?: any }>;
  data: T[];
  pageNo: number;
  handleClick: (event: React.MouseEvent, row: T) => void;
  action: any;
  onRowSelect?: (selectedRows: T[]) => void;
  showCheckboxes?: Boolean;
}

interface Class {
  id: string;
  type: string;
  attributes: {
    class_name: string;
  };
}

const NotificationMenu = styled(Checkbox)(({ theme }) => ({
  

'&.Mui-checked': {
  color: '#2B3D7D',
},
  }));

function BasicTable<T>({ columns, data, pageNo, handleClick, action, onRowSelect, showCheckboxes }: DataTableProps<T>) {
  const [selectedRows, setSelectedRows] = useState<T[]>([]);

  const handleRowSelection = (row: T) => {
    if (selectedRows.includes(row)) {
      setSelectedRows(selectedRows.filter(selectedRow => selectedRow !== row));
    } else {
      setSelectedRows([...selectedRows, row]);
    }
  };

  useEffect(() => {
    if (onRowSelect) {
      onRowSelect(selectedRows);
    }
  }, [selectedRows, onRowSelect]);

  const renderClassNames = (classes: Class[]) => {
    return classes.map(cls => cls.attributes.class_name).join(', ');
  };

  return (
    <TableContainer style={webStyles.datatable} component={Paper}>
      <Table>
        <TableHead style={webStyles.thead}>
          <TableRow>
            {showCheckboxes && <TableCell style={webStyles.theading}></TableCell>}
            <TableCell style={webStyles.theading}>No.</TableCell>
            {columns.map((column, index) => (
              <TableCell key={index} style={webStyles.theading}>
                {column.label}
              </TableCell>
            ))}
            {action && <TableCell style={webStyles.theading}>Action</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length === 0 ? (
            <TableRow>
              <TableCell colSpan={columns.length + 2} style={{ textAlign: 'center' }}>
                No records found
              </TableCell>
            </TableRow>
          ) : (
            data.map((row: any, index) => (
              <TableRow key={index}>
                {showCheckboxes &&
                  <TableCell style={webStyles?.tdetail}>
                    <NotificationMenu
                      checked={selectedRows.includes(row)}
                      onChange={() => handleRowSelection(row)}
                    />
                  </TableCell>
                }
                <TableCell style={webStyles?.tdetail}>
                  <span>{(pageNo - 1) * 10 + index + 1}</span>
                </TableCell>
                {columns.map((column, columnIndex) => {
                  if (column.key === 'attributes' && column.subKey === 'classes') {
                    return (
                      <TableCell key={columnIndex} style={webStyles?.tdetail}>
                        {renderClassNames(row[column.key][column.subKey])}
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell key={columnIndex} style={webStyles?.tdetail}>
                      {column.subKey ? row[column.key][column.subKey] : row[column.key]}
                    </TableCell>
                  )
                })}
                {action && <TableCell >
                  <Box data-testid="actionBtn" style={webStyles?.actionBtn} onClick={(event) => handleClick(event, row)}>
                    {action}
                  </Box>
                </TableCell>}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default BasicTable;

const webStyles = {

  headtext: {
    fontSize: '32px',
    color: '#2B3D7D',
    margin: 0,
    fontFamily: "Poppins",
    textTransform: 'capitalize' as TextTransformProperty
  },
  datatable: {
    padding: '0px',
    borderRadius: '8px',
    boxShadow: '0px 0px 3px 1px rgba(52,146,159,0.16)',
    margin: '0px 15px',
    width: "auto"
  },
  thead: {
    backgroundColor: '#fdf4dc',
  },
  theading: {
    fontFamily: "Poppins",
    color: '#202020',
    fontSize: '16px',
    fontWeight: 500,
    textAlign: 'center' as TextAlignProperty
  },
  tdetail: {
    fontFamily: "Poppins",
    color: '#2c2c2e',
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'center' as TextAlignProperty,
    textTransform: "capitalize" as TextTransformProperty
  },
  tdetailBreak: {
    wordBreak: "break-all" as WordBreakProperty,
    width: "130px",
    fontFamily: "Poppins",
    color: '#2c2c2e',
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'center' as TextAlignProperty,
    textTransform: "capitalize" as TextTransformProperty
  },
  actionBtn: {
    textAlign: 'center' as TextAlignProperty,
    cursor: "pointer",
    fontSize: '16px',
  },
}