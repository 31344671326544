import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { logoutModalImage } from "./assets";
import ClearIcon from "@material-ui/icons/Clear";
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "488px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display: "flex",
    justifyContent: "center",
    aignItems: "center",
    padding: "35px 0",
    "@media (max-width: 600px)": {
      width:"90%"
    },
  },
  popupContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontFamily: "Poppins",
    fontSize: "20px",
    color: "#202020",
    fontWeight: 400,
    marginBottom: "10px",
  },
  subtitle: {
    fontFamily: "Poppins",
    fontSize: "20px",
    color: "#202020",
    fontWeight: 400,
  },
  image: {
    marginBottom: "15px",
  },
  container: {
    positiobe: "relative",
  },
  crossIcon: {
    position: "absolute",
    top: "10px",
    right: "10px",
  },
  coloredBtn: {
    backgroundImage:
      "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
    color: "#FFF",
    borderRadius: "10px",
    width: "154px",
    padding: "12px",
    textTransform: "none",
    fontFamily: "Poppins",
    fontSize: "18px",
    "@media (max-width: 600px)": {
      width:"100%"
    },
  },
  normalBtn: {
    borderRadius: "10px",
    width: "154px",
    padding: "12px",
    textTransform: "none",
    border: "1px solid #2B3D7D",
    color: "#3F526D",
    marginRight: "20px",
    fontFamily: "Poppins",
    fontSize: "18px",
    "@media (max-width: 600px)": {
      width:"100%"
    },
  },
  btnLayout: {
    display: "flex",
    justifyContent: "end",
    paddingTop: "30px",
    paddingBotton: "10px",
    "@media (max-width: 600px)": {
      width:"100%"
    },
  },
}));
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const LogoutModal = (props: any) => {
  const { configJSON, handleCloseLogoutModal, doLogout } = props;
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  return (
    <Box style={modalStyle} className={classes.paper}>
      <Box className={classes.container}>
        <ClearIcon
          onClick={handleCloseLogoutModal}
          className={classes.crossIcon}
        />
        <Box className={classes.popupContent}>
          <img className={classes.image} src={logoutModalImage} />
          <Typography className={classes.title}>
            {configJSON.logout.title}
          </Typography>
          <Typography className={classes.subtitle}>
            {configJSON.logout.subTitle}
          </Typography>
          <Box className={classes.btnLayout}>
            <Button
              onClick={handleCloseLogoutModal}
              className={classes.normalBtn}
            >
              No
            </Button>
            <Button onClick={doLogout} className={classes.coloredBtn}>
              Yes
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LogoutModal;
