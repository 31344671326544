import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Breadcrumbs,
  Button,
  useMediaQuery
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { calendarPickerIcon } from "../../assets";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import CustomDatePicker from "./CustomDatePicker.web";
import Loader from "../../../../../components/src/Loader";
import AppHeaderWeb from "../../../../../components/src/AppHeader.web";
import GenericButton from "../../../../../components/src/GenericButton.web";
import { MyEvent, MyWeekHeader } from "../../../../../components/src/SchoolAdminCalendar";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "24px",
    color: "#757575",
  },
  teacherName: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "20px",
    color: "#000000",
  },
  navigationIcon: {
    marginTop: "20px",
  },
  breadcrumbBox: {
    padding: "0px 35px",
    marginTop: "20px",
  },
  currentDateBox: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
    padding: "0 35px",
    [theme.breakpoints.down('xs')]: {
      justifyContent: "center"
    },
  },
  currenDate: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "22px",
    color: "#2C2C2E",
    paddingRight: "15px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "17px"
    },
  },
  calendarView: {
    display: "grid",
    rowGap: "10rem",
  },
  weekHeader: {
    width: "93px",
    height: "111px",
    display: "flex",
    flexDirection: "column",
    marginTop: "16px",
    marginBottom: "8px",
    paddingTop: "20px",
  },
  weekHeaderDayLabel: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "27px",
    textAlign: "center",
    color: "#858585",
    marginBottom: "10px",
  },
  weekHeaderDateLabel: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "36px",
    textAlign: "center",
    color: "#000000",
  },
  calendarBox: {
    padding: "50px 35px",
  },
  eventBox: {
    borderRadius: "10px",
    height: "100%",
    padding: "0px 10px",
    backgroundColor: "#FFFFFF",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    border: "1px solid #979797",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  eventTitle: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "14px",
    color: "#000000",
    marginBottom: "10px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    height: "2.2em",
    whiteSpace: "nowrap",
  },
  eventDueDate: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "13px",
    color: "#757575",
    overflow: "hidden",
    width: "150px",
    whiteSpace: "normal",
    wordBreak: "break-word",
  },
  contextMenuBox: {
    display: "flex",
  },
  optionIcon: {
    color: "#757575",
  },
  optionIconBtn: {
    padding: 0,
    minWidth: 0,
  },
  highlightSelectedDate: {
    width: "93px",
    height: "111px",
    display: "flex",
    flexDirection: "column",
    marginTop: "16px",
    marginBottom: "8px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    border: "1px solid #979797",
    borderRadius: "10px",
    paddingTop: "20px",
    backgroundImage:
      "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
  },
  highlightedWeekHeaderDayLabel: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "27px",
    textAlign: "center",
    color: "#FFF",
    marginBottom: "10px",
  },
  highlightedWeekHeaderDateLabel: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "36px",
    textAlign: "center",
    color: "#FFF",
  },
  link: {
    textDecoration: "none",
    cursor:"pointer",
  },
  eventTitleBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  goBackButton:{
    display:"flex",
    justifyContent:"end",
    marginBottom:"50px",
    marginRight:"35px",
    [theme.breakpoints.down('xs')]: {
      justifyContent:"center",
      marginRight: 0,
      width:"100%"
    },
  }
}));

const TeacherCalendar = (props: any) => {
  const { calendarData, handleDeleteEvent, loading, handleColorEvents, selectedDate:date, handleEventWrapper } = props;
  const { events, name } = calendarData;
  const classes = useStyles();
  const localizer = momentLocalizer(moment);
  const [open, setOpen] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState<Date>(date);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(()=>{
    setSelectedDate(date)
  },[date])

  const handleOpen = () => {
    setOpen(true);
  };

  const components = {
    eventWrapper: (props: any) => handleEventWrapper(props),
    week: {
      header: (props: any) => (
        <MyWeekHeader {...props} selectedDate={selectedDate} handleDateChange={handleDateChange} />
      ),
    },
    event: (props: any) => (
      <MyEvent {...props} handleColorEvents={handleColorEvents} handleDeleteEvent={handleDeleteEvent} />
    ),
  };

  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  };

  function handleDateChange(date: Date) {
    props.handleSetDate(date);
    const currentLocation = window.location.toString().split("/");
    const currentPath = currentLocation[currentLocation.length - 1];
    props.getCalendarEvents(parseInt(currentPath),date);
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box>
      <Grid className="testClass" container={isSmallScreen ? false : true}>
        <Grid item md={12}>
          <AppHeaderWeb title={"Calendar"} />
        </Grid>
        {!loading ? (
          <Loader loading={loading} />
        ) : (
          <Grid item md={12}>
            <Box className={classes.breadcrumbBox}>
              <Breadcrumbs
                className={classes.navigationIcon}
                separator={<NavigateNextIcon fontSize="large" />}
              >
                <span className={classes.link} data-testid="replaceLocation" onClick={()=>{location.replace('/teachers')}}>
                  <Typography className={classes.title}>Teachers</Typography>
                </span>
                <Typography className={classes.teacherName}>{name}</Typography>
              </Breadcrumbs>
            </Box>
            <Box className={classes.currentDateBox}>
              <Typography className={classes.currenDate}>{new Date(selectedDate).toLocaleDateString("en-US", options)}</Typography>
              <Button onClick={handleOpen} data-test-id = "calenderBtn">
                <img src={calendarPickerIcon} />
              </Button>
            </Box>
            <CustomDatePicker
              open={open}
              handleDateChange={handleDateChange}
              selectedDate={selectedDate}
              handleClose={handleClose}
              data-testid="custom-date-picker"
            />
            <Box className={classes.calendarBox}>
              <Calendar
                data-testid="calendar"
                localizer={localizer}
                components={components}
                views={["week"]}
                toolbar={false}
                events={events}
                defaultView="week"
                date={selectedDate}
                step={30}
              />
            </Box>
            <Box className={classes.goBackButton}>
              <GenericButton
                data-test-id="backBtn"
                type="NORMAL"
                label="Go Back"
                handleClick={() => location.replace('/teachers')}
                customStyle={{ width: "150px", marginRight: isSmallScreen ? 0 : "initial" }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default TeacherCalendar;
