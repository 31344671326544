import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(() => ({
    listItemBox: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      border: "1px solid rgba(149, 157, 165, 0.2)",
      borderRadius: "10px",
      margin: "10px",
      padding: "20px",
      width:"629px",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
      "&:hover":{
        cursor:"pointer"
      },
      "@media (max-width: 600px)": {
        width:"80%"
    },
    },
    listItemFont: {
      fontFamily: "Poppins",
      fontSize: "16px",
      color: "#282829",
      fontWeight: 500
    },
    listItemImage: {
      borderRadius: "15px",
      marginRight: "10px",
      border: "1px solid rgba(149, 157, 165, 0.2)",
      width: "48px",
      height: "48px"
    },
    logoutFont:{
      color:"#FE575A",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 500
    }
  }));
const ProfileOption = (props:any) => {
    const {image, option, callback}= props
    const classes = useStyles()
  return (
    <Box className={classes.listItemBox} onClick={()=>callback(option)}>
      <img className={classes.listItemImage} src={image} />
      <Typography className={option==="Log Out"?classes.logoutFont:classes.listItemFont}>{option}</Typography>
    </Box>
  );
};

export default ProfileOption;
