Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";

exports.changePasswordApiEndPoint = "change_password";

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomisableUserProfiles";
exports.labelBodyText = "CustomisableUserProfiles Body";

exports.btnExampleTitle = "CLICK ME";
exports.editProfile="Edit Profile"
exports.changePassword="Change Password"
exports.profileSettings="Profile Settings"
exports.logOut="Log Out"
exports.imageConstraints="Maximum Size of Image - 50KB, Jpeg, Png"
exports.getProfileDataUrl="account_block/accounts/logged_user"
exports.updateProfileDataUrl="account_block/add_users/update_account"
exports.GET="GET"
exports.PUT="PUT"
exports.errorMessage={
  password:`Please enter a valid password.<br/>Your password must Include at least :<br/>- 8 characters length<br/>- One upper case character (A-Z)<br/>- One lower case character (a-z)<br/>- One digit (0-9)<br/>- One Symbol/Special character (!,#,$,etc)`,
  institution:`Please enter the name of your institution`,
  email:`Please enter a valid email`,
  fullName:`Please enter a valid name`,
  imageSize:`File size should be less than 50 KB`,
  imageFormat:`Supported formats: JPEG and PNG`
}
exports.logout={
  title:`Are you sure you want to`,
  subTitle:`Log Out?`
}
exports.profileUpdated={
  title:`Profile Updated Successfully!`,
  subTitle:`You have successfully updated your profile.`
}

// Customizable Area End