import React, { useCallback, useEffect, useState } from "react";
import Checkbox from "./Checkbox.web";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Box
} from '@material-ui/core';
import BasicPagination from "../../../accountgroups/src/BasicPagination.web";
import { SearchSection } from "../../../dashboard/src/SchoolAdmin/SchoolAdminDashboard.web";
import { styled } from "@material-ui/styles";

type TextTransformProperty = "capitalize"
type WordBreakProperty = 'break-all'
type TextAlignProperty = 'center'

const webStyles = {

  headtext: {
    fontSize: '32px',
    color: '#2B3D7D',
    margin: 0,
    fontFamily: "Poppins",
    textTransform: 'capitalize' as TextTransformProperty
  },
  datatable: {
    padding: '0px',
    borderRadius: '8px',
    boxShadow: '0px 0px 3px 1px rgba(52,146,159,0.16)',
    margin: '0px 15px',
    width: "auto"
  },
  tHead: {
    backgroundColor: '#fdf4dc',
  },
  tHeading: {
    fontFamily: "Poppins",
    color: '#202020',
    fontSize: '16px',
    fontWeight: 500,
    textAlign: 'center' as TextAlignProperty
  },
  tDetail: {
    fontFamily: "Poppins",
    color: '#2c2c2e',
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'center' as TextAlignProperty,
    textTransform: "capitalize" as TextTransformProperty
  },
  buttonWrapper: {
    width:"inherit",
    "@media (max-width: 600px)": {
      width:"100%"
    },
  },
  rptBtn: { cursor: 'pointer',
     margin: '10px 0px', padding: '15px', 
     borderRadius: '8px', color: '#fff', fontSize: '18px', 
     fontWeight: 500,
      fontFamily: 'Poppins', 
      background: 'linear-gradient(133deg, #2B3D7D 31.28%, #39C6B3 181.36%), linear-gradient(180deg, #68008C 0%, #A63F8D 100%), #FE575A', 
      whiteSpace: "nowrap" as "nowrap",
    },
    rptBtn1: { 
      margin: '10px 0px', padding: '15px', 
      borderRadius: '8px', fontSize: '18px', 
      fontWeight: 500,
       fontFamily: 'Poppins', 
       background:  'linear-gradient(to right bottom, rgba(0, 10, 77, 1) 0%, rgba(21, 8, 112, 1) 36%, rgba(41, 142, 139, 1) 100%)',
       whiteSpace: "nowrap" as "nowrap",
       opacity: "0.5", 
     cursor: 'not-allowed',
     color: '#999', 
    
     }

}

const StyledSearchContainerBox = styled(Box)({
  "@media (max-width: 900px)":{
    flexDirection:"column"
  }
});

const StyledSearchBox = styled(Box)({
  "@media (max-width: 1080px)":{
    width:"50% !important"
  },
  "@media (max-width: 900px)":{
    width:"85% !important"
  },
  "@media (max-width: 600px)":{
    width:"100% !important"
  }
});


const StyledButtonBox = styled(Button)({
  "@media (max-width: 640px)":{
    width:'100%',
    fontSize:"16px !important"
  }
});


const ClassStudentsListing = (props: any) => {
  const {handleSubmitSelectStudent,reportType, 
    studentListData, teacherName, classTitle, 
    handleSearchInput, handleStudentsPageChange, 
    pageLimitStudents, pageNoStudents,getSummaryReportListDataApicall ,
    handleChange, data, selectAll, selectedIds, handleSelectAll ,getDetailReportListData}  = props;
  const columns: { label: string; key: any; subKey: any }[] = [
    {
      label: "Student Name",
      key: "attributes",
      subKey: "stdent_name",
    },
    { label: "Class", key: "attributes", subKey: "class" },
    { label: "Teacher", key: "attributes", subKey: "teacher_name" },
  ];
  const pageNo = 1
  const handleBtnClick=()=>{
    if(reportType =='summary'  ){
      handleSubmitSelectStudent([],"reportsView")
      getSummaryReportListDataApicall({
        student_ids: selectedIds?.length > 0 ? selectedIds :
        studentListData?.map((item: any) => { return item.id })
      })
    }else{
      handleSubmitSelectStudent([],"detailReportsView")
      getDetailReportListData({
        student_ids: selectedIds?.length > 0 ? selectedIds :
        studentListData?.map((item: any) => { return item.id })
      })
    }
  }
  return (
    <div>
      <StyledSearchContainerBox style={{margin: '-10px 10px 20px 20px', display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}}>
        <StyledSearchBox>
          <SearchSection placeholder='Search by student name' handleSearchInput={handleSearchInput} />
        </StyledSearchBox>
        <Box sx={webStyles.buttonWrapper}>
          <StyledButtonBox data-testid="generateButton"  onClick={handleBtnClick} disabled={selectedIds.length===0}
            data-test-id="btnAddGroup" type="submit"
             style={selectedIds.length===0? webStyles.rptBtn1:webStyles.rptBtn}              >
            {reportType === 'summary' ? "Generate Summary Report" : " Generate Detailed Report"}
          </StyledButtonBox>
        </Box>
      </StyledSearchContainerBox>
      <TableContainer style={webStyles.datatable} component={Paper}>
      <Table>
        <TableHead style={webStyles.tHead}>
          <TableRow>
            <TableCell style={webStyles.tHeading}>S.No.</TableCell>
            {columns.map((column, index) => (
              <TableCell key={index} style={webStyles.tHeading}>
                {column.label}
              </TableCell>
            ))}
            <TableCell data-testid="selectButton" onClick={handleSelectAll} style={webStyles.tHeading} ><div style={{display: 'flex', alignItems: 'center', gap: '5px', justifyContent: 'center'}} >Select All <Checkbox variant="green" checked={selectAll} /></div></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.length === 0 ? (
            <TableRow>
              <TableCell colSpan={columns.length + 2} style={{ textAlign: 'center' }}>
                No records found
              </TableCell>
            </TableRow>
          ) : (
            data?.map((row: any, index: number) => (
              <TableRow key={index}>
                <TableCell style={webStyles?.tDetail}>{(pageNo - 1) * 10 + index + 1}</TableCell>
                <TableCell style={webStyles?.tDetail}>{row.attributes.full_name}</TableCell>
                <TableCell style={webStyles?.tDetail}>{classTitle}</TableCell>
                <TableCell style={webStyles?.tDetail}>{teacherName}</TableCell>
                <TableCell >
                  {/*@ts-ignore */}
                  <Box data-testid="selecOption" style={{display: 'flex', width: '100%', justifyContent: 'center'}} onClick={()=> handleChange(row)}>
                  <Checkbox variant="green" checked={row.selected} />
                  </Box>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      </TableContainer>
      <div style={{
        marginTop: "100px",
        marginBottom: "50px"
      }}>
        <BasicPagination
          handlePageChange={handleStudentsPageChange}
          pageLimit={pageLimitStudents}
          pageNo={pageNoStudents}
        />
      </div>
    </div>
  );
};

export default ClassStudentsListing;
