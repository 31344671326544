import React from "react";
import CustomBreadcrumb from "../../../../../../components/src/CustomBreadcrumb.web";
import {
  Box,
  Typography,
  Modal,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PracticeCustomTable from "./PracticeCustomTable.web";
import GenericButton from "../../../../../../components/src/GenericButton.web";
import SearchInput from "../../../../../../components/src/SearchInput.web";
import Loader from "../../../../../../components/src/Loader.web";
import BasicPagination from "../../../../../../components/src/BasicPagination.web";
import AddFolderModal from "./AddFolderModal.web";
const useStyles = makeStyles(theme => ({
  noRecords: {
    fontFamily: "Poppins",
    textAlign: "center",
    paddingTop: "35px",
    width: "100%"
  },
  searchBarLayout: {
    display: "flex"
  },
  addFolderButtonLayout: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    marginTop: "20px",
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      gap: 20
    }
  }
}));
const TeacherSelectedPractice = (props: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const {
    locations,
    currentLocation,
    tableSearchInput,
    redirect,
    practiceData,
    loading,
    handleLessonPageChange,
    pageLimitPractice,
    pageNoPractice,
    handleSearchInputTableForTeacher,
    handleShowNotesIcon,
    notesList,
    openAddFolderModal,
    handleOpenAddFolderModal,
    handleCloseAddFolderModal,
    handleChangeFolderInput,
    createLessonFolder,
    folderName,
    openFolderList,
    handleOpenFolderList,
    handleCloseFolderList,
    folderAnchorEl,
    folderList,
    handleOpenMoveConfirmationPopUp,
    handleCloseMoveConfirmationPopUp,
    openMovePopup,
    moveLessonToFolder,
    handleSetFolderId,
    folderId,
    openMoveSuccessPopup,
    handleCloseSuccessPopup,
    handleOpenSuccessPopup,
    handleFolderNameSearchInput,
    folderSearchInput,
    isLoading,
    deleteLessonFolder,
    showLessonsForSelectedFolder,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    openDeleteConfirmationModal,
    handleCloseNoMobileModal,
    handleOpenNoMobileModal,
    openNoMobileModal,
    selectedLessons,
    role,
    generateLink,
    shareLink,
    deliverLessonApi,
    downloadLesson,
    editPractice,
    openSuccessBar,
    handleCloseDeleteSuccess,
    successBarData,
    editFuncMyLibrary,
    viewMyLibraryLesson,
    showLessons,
    editFuncMyLibraryLesson,
    handleLessonNameChange,
    state,
    handleEditClassChange,
    handleEditStartDateChange,
    handleEditDueDateChange,
    handleLessonObjectiveChange,
    handleCloseEditModal,
    handleOpenEditModal,
    viewMyLibraryAssignment,
    editFuncMyLibraryAssignment,
    viewMyLibraryQuiz,
    editFuncMyLibraryQuiz,
    handleEditUploadIcon,
    selectedType
  } = props;

  return (
    <>
      <Box sx={{ px: "35px" }}>
        <Box sx={{ mt: "30px" }}>
          <CustomBreadcrumb
            locations={locations}
            destination={currentLocation}
            redirect={redirect}
          />
        </Box>
        <Box className={classes.addFolderButtonLayout}>
          <Box className={classes.searchBarLayout}>
            <SearchInput
              searchInput={tableSearchInput}
              handleSearchInput={handleSearchInputTableForTeacher}
              placeholder={"Search by name"}
            />
          </Box>
          <GenericButton
            data-test-id="open-folder-modal"
            type="COLORED"
            label="+ Add Folder"
            handleClick={() => handleOpenAddFolderModal("", "Add", "")}
            customStyle={{ width: "190px", height: "50px" }}
          />
        </Box>

        {loading ? (
          <Loader loading />
        ) : (
          <>
            {!practiceData.data.length ? (
              <Typography className={classes.noRecords}>
                No records found
              </Typography>
            ) : (
              <>
                <Box>
                  <PracticeCustomTable
                    practiceData={practiceData}
                    handleShowNotesIcon={handleShowNotesIcon}
                    notesList={notesList}
                    openFolderList={openFolderList}
                    handleOpenFolderList={handleOpenFolderList}
                    handleCloseFolderList={handleCloseFolderList}
                    folderAnchorEl={folderAnchorEl}
                    folderList={folderList}
                    handleOpenMoveConfirmationPopUp={
                      handleOpenMoveConfirmationPopUp
                    }
                    handleCloseMoveConfirmationPopUp={
                      handleCloseMoveConfirmationPopUp
                    }
                    openMovePopup={openMovePopup}
                    moveLessonToFolder={moveLessonToFolder}
                    handleSetFolderId={handleSetFolderId}
                    folderId={folderId}
                    openMoveSuccessPopup={openMoveSuccessPopup}
                    handleCloseSuccessPopup={handleCloseSuccessPopup}
                    handleOpenSuccessPopup={handleOpenSuccessPopup}
                    folderSearchInput={folderSearchInput}
                    handleFolderNameSearchInput={handleFolderNameSearchInput}
                    loading={isLoading}
                    deleteLessonFolder={deleteLessonFolder}
                    showLessonsForSelectedFolder={showLessonsForSelectedFolder}
                    handleOpenDeleteModal={handleOpenDeleteModal}
                    handleCloseDeleteModal={handleCloseDeleteModal}
                    openDeleteConfirmationModal={openDeleteConfirmationModal}
                    handleOpenNoMobileModal={handleOpenNoMobileModal}
                    handleCloseNoMobileModal={handleCloseNoMobileModal}
                    openNoMobileModal={openNoMobileModal}
                    selectedLessons={selectedLessons}
                    role={role}
                    generateLink={generateLink}
                    shareLink={shareLink}
                    deliverLessonApi={deliverLessonApi}
                    downloadLesson={downloadLesson}
                    editPractice={editPractice}
                    openAddFolderModal={openAddFolderModal}
                    handleCloseAddFolderModal={handleCloseAddFolderModal}
                    handleOpenAddFolderModal={handleOpenAddFolderModal}
                    handleChangeFolderInput={handleChangeFolderInput}
                    folderName={folderName}
                    createLessonFolder={createLessonFolder}
                    openSuccessBar={openSuccessBar}
                    handleCloseDeleteSuccess={handleCloseDeleteSuccess}
                    successBarData={successBarData}
                    currentLocation={currentLocation}
                    editFuncMyLibrary={editFuncMyLibrary}
                    viewMyLibraryLesson={viewMyLibraryLesson}
                    viewMyLibraryAssignment={viewMyLibraryAssignment}
                    showLessons={showLessons}
                    editFuncMyLibraryLesson={editFuncMyLibraryLesson}
                    handleLessonNameChange={handleLessonNameChange}
                    state={state}
                    handleEditClassChange={handleEditClassChange}
                    handleEditStartDateChange={handleEditStartDateChange}
                    handleEditDueDateChange={handleEditDueDateChange}
                    handleLessonObjectiveChange={handleLessonObjectiveChange}
                    handleCloseEditModal={handleCloseEditModal}
                    handleOpenEditModal={handleOpenEditModal}
                    viewMyLibraryQuiz={viewMyLibraryQuiz}
                    editFuncMyLibraryQuiz={editFuncMyLibraryQuiz}
                    editFuncMyLibraryAssignment={editFuncMyLibraryAssignment}
                    handleEditUploadIcon={handleEditUploadIcon}
                    selectedType={selectedType}
                  />
                </Box>
                <Box sx={{ px: isSmallScreen ? 0 : "35px", my: "30px" }}>
                  <BasicPagination
                    handlePageChange={handleLessonPageChange}
                    pageLimit={pageLimitPractice}
                    pageNo={pageNoPractice}
                  />
                </Box>
              </>
            )}
          </>
        )}
        <Modal open={openAddFolderModal} onClose={handleCloseAddFolderModal}>
          <AddFolderModal
            data-test-id="folder-modal"
            handleCloseAddFolderModal={handleCloseAddFolderModal}
            handleChangeFolderInput={handleChangeFolderInput}
            createLessonFolder={createLessonFolder}
            folderName={folderName}
          />
        </Modal>
      </Box>
    </>
  );
};

export default TeacherSelectedPractice;
