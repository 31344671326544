import React from "react";
import { Box, Grid, useMediaQuery } from "@material-ui/core";
import Sidebar from "../../../../components/src/Sidebar.web";
import AppHeaderWeb from "../../../../components/src/AppHeader.web";
import { STUDENT_DASHBOARD_VIEWS } from "../../../../components/src/types";
import StudentInfo from "./pages/StudentInfo.web";
const StudentDashboard = (props: any) => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const renderSwitch = (selectedView: string) => {
    if (selectedView === STUDENT_DASHBOARD_VIEWS.DASHBOARD) {
      return (
        <StudentInfo
          classList={props.classList}
          redirectToClassDetailView={props.redirectToClassDetailView}
          handleSearchClassName={props.handleSearchClassName}
          searchInputClassName={props.searchInputClassName}
          studentActionItems={props.studentActionItems} />
      );
    } else {
      return <></>;
    }
  };
  return (
    <Box>
      <Grid container style={{ overflowX: "hidden", height: "100%", display: isSmallScreen ? "block" : "flex"}}>
        <Grid item md={2} {...(!isSmallScreen && { xs: 2 })}>
          {!isSmallScreen && <Sidebar />}
        </Grid>
        <Grid item md={10} {...(!isSmallScreen && { xs: 10 })} style={{ overflowY: "scroll", height: "100%" }}>
          <Grid item md={12}>
            <AppHeaderWeb title={STUDENT_DASHBOARD_VIEWS.DASHBOARD} />
          </Grid>
          <Grid item md={12} style={{ overflowY: "scroll" }}>
            {renderSwitch("Dashboard")}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StudentDashboard;