import React from "react";

// Customizable Area Start
import {
  Modal,
  Box,
  Grid,
  Typography,
  FormControl,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Popover,
  Button,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  closeIcon,
  filterIcon,
  imageDeletedIcon,
  searchIcon,
  studentIcon,
  teacherIcon,
  uploadImageIcon,
  defaultImage,
} from "./assets";
import Sidebar from "../../../components/src/Sidebar.web";
import BorderColorOutlinedIcon from "@material-ui/icons/BorderColorOutlined";
import EventNoteOutlinedIcon from "@material-ui/icons/EventNoteOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Account } from "./types";
import BasicPagination from "./BasicPagination.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppHeader from "../../../components/src/AppHeader.web";
import Select, { components } from "react-select";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import DropdownIndicatorProps from "../../../../node_modules/@types/react-select";
import CheckBox from "../../analytics/src/components/Checkbox.web";
import Loader from "../../../components/src/Loader.web";

const gradientBackground = "linear-gradient(to right bottom, #2B3D7D, #39C6B3)";
type WordBreakProperty = "break-all";
type TextAlignProperty = "center";
type PositionStyle = "absolute";
type ResizeStyle = "none";
type AppearanceStyle = "none";
type PositionRelative = "relative";
type TextTransformProperty = "capitalize";
type OverflowXProperty = "hidden";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
    secondary: { main: "#2b3d7d" },
  },
});

interface TextInputProps {
  label: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  nonEditable: boolean;
  placeholder?: string;
  isSmallScreen?: boolean;
}

interface PasswordInputProps {
  label: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  enablePasswordField: boolean;
  handleClickShowPassword: any;
  error?: string;
  nonEditable?: boolean;
  placeholder?: string;
  isSmallScreen?: boolean;
}

interface OptionType {
  label: string;
  value: string;
}

interface SelectInputProps {
  label: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<{ name?: string; value: string }>) => void;
  options: OptionType[];
  nonEditable: boolean;
  placeholder?: string;
  isSmallScreen?: boolean;
}

interface CommonButtonsProps {
  editId: string;
  handleCancel: any;
  nonEditable: boolean;
}

export const TextInput: React.FC<TextInputProps> = ({
  label,
  name,
  value,
  onChange,
  error,
  nonEditable,
  placeholder,
  isSmallScreen = false,
}: TextInputProps) => (
  <Grid item xs={!isSmallScreen ? 6 : 12}>
    <label style={webStyles.inputLabel}>{label}</label>
    <input
      placeholder={placeholder}
      type="text"
      name={name}
      defaultValue={value}
      style={
        value === "" ? webStyles.custominputPlaceholder : webStyles.custominput
      }
      onChange={onChange}
      readOnly={nonEditable}
    />
    <label style={webStyles.error}>{error}</label>
  </Grid>
);

export const SelectInput: React.FC<SelectInputProps> = ({
  label,
  name,
  value,
  onChange,
  options,
  nonEditable,
  placeholder,
  isSmallScreen = false,
}) => {
  return (
    <Grid item xs={!isSmallScreen? 6 : 12}>
      <FormControl fullWidth>
        <label style={webStyles.inputLabel}>{label}</label>
        <select
          name={name}
          defaultValue={value}
          style={
            placeholder && value === ""
              ? webStyles.customSelectPlaceholder
              : webStyles.customSelect
          }
          onChange={onChange}
          disabled={nonEditable}
        >
          {placeholder && value === "" && (
            <option key={placeholder} value={""}>
              {placeholder}
            </option>
          )}
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </FormControl>
    </Grid>
  );
};

export const PasswordInput: React.FC<PasswordInputProps> = ({
  label,
  name,
  value,
  onChange,
  enablePasswordField,
  handleClickShowPassword,
  error,
  nonEditable,
  placeholder,
  isSmallScreen = false,
}: PasswordInputProps) => (
  <Grid item xs={!isSmallScreen ? 6 : 12} style={webStyles.passwordInput}>
    <label style={webStyles.inputLabel}>{label}</label>
    <input
      type={enablePasswordField ? "password" : "text"}
      name={name}
      defaultValue={value}
      style={
        value === "" ? webStyles.custominputPlaceholder : webStyles.custominput
      }
      onChange={onChange}
      readOnly={nonEditable}
      placeholder={placeholder}
    />
    <IconButton
      aria-label="toggle password visibility"
      onClick={handleClickShowPassword}
      edge="end"
      style={webStyles.iconBtn}
    >
      {enablePasswordField ? (
        <VisibilityOutlinedIcon />
      ) : (
        <VisibilityOffOutlinedIcon />
      )}
    </IconButton>
    {error && (
      <label
        style={webStyles.error}
        dangerouslySetInnerHTML={{ __html: error }}
      />
    )}
  </Grid>
);

export const DataTable: React.FC<{
  data: Account[];
  pageNo: number;
  handleClick: (event: any, row: Account) => void;
  itemsPerPage: number;
  showPassword: any;
}> = ({ data, pageNo, handleClick, itemsPerPage, showPassword }) => (
  <TableContainer style={webStyles.datatable} component={Paper}>
    <Table>
      <TableHead style={webStyles.thead}>
        <TableRow>
          <TableCell style={webStyles.theading}>S.No.</TableCell>
          <TableCell style={webStyles.theading}>Full Name</TableCell>
          <TableCell style={webStyles.theading}>Role</TableCell>
          <TableCell style={webStyles.theading}>Email ID</TableCell>
          <TableCell style={webStyles.theading}>Password</TableCell>
          <TableCell style={webStyles.theading}>Date Created</TableCell>
          <TableCell style={webStyles.theading}>Profile Status</TableCell>
          <TableCell style={webStyles.theading}>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.length === 0 ? (
          <TableRow>
            <TableCell colSpan={8} style={{ textAlign: "center" }}>
              No records found
            </TableCell>
          </TableRow>
        ) : (
          data?.map((row, index) => (
            <TableRow key={row.id}>
              <TableCell style={webStyles.tdetail}>
                {(pageNo - 1) * 10 + index + 1}
              </TableCell>
              <TableCell style={webStyles.tdetail}>
                {row.attributes.full_name}
              </TableCell>
              <TableCell style={webStyles.tdetail}>
                {row.attributes.role}
              </TableCell>
              <TableCell style={webStyles.tdetailBreak}>
                {row.attributes.email ? row.attributes.email.toLowerCase() : ""}
              </TableCell>
              <TableCell style={webStyles.tdetailBreak}>  
                {showPassword[row.id] ? row.attributes.password : "************"}
              </TableCell>
              <TableCell style={webStyles.tdetail}>
                {row.attributes.date_created}
              </TableCell>
              <TableCell style={webStyles.tdetail}>
                {row.attributes.profile_status}
              </TableCell>
              <TableCell style={webStyles.actionBtn}>
                <MoreVertIcon
                  data-test-id="actionBtn"
                  onClick={(event) => handleClick(event, row)}
                />
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  </TableContainer>
);

export const CommonButtons: React.FC<CommonButtonsProps> = ({
  editId,
  handleCancel,
  nonEditable,
}) => {
  return (
    <Box style={webStyles.btnWrapper}>
      <button
        data-test-id="btnCancel"
        type="button"
        style={webStyles.cancelsBtn}
        onClick={handleCancel}
      >
        {editId ? "Go Back" : "Cancel"}
      </button>
      <button
        data-test-id="btnAddGroup"
        type="submit"
        style={webStyles.addUsersBtn}
      >
        {(() => {
          switch (true) {
            case nonEditable && editId !== "":
              return "Edit Profile";
            case editId !== "":
              return "Save Profile";
            default:
              return "Add";
          }
        })()}
      </button>
    </Box>
  );
};

export const DropdownIndicator = (props: DropdownIndicatorProps) => (
  //@ts-ignore
  <components.DropdownIndicator {...props}>
    <ArrowDropDownIcon fontSize={"large"} data-test-id="selectDropdownIcon" />
  </components.DropdownIndicator>
);

export const IndicatorSeparator = (props: any) => (
  <span
    data-test-id="selectDropdownIndicator"
    style={{ backgroundColor: "#ffffff" }}
    {...props}
  />
);

export const Option = (props: any) => (
  <components.Option {...props}>
    <div
      style={{ display: "flex", alignItems: "center", margin: "10px", fontFamily:"Poppins" }}
      data-test-id="selectDropdownOption"
    >
      <img
        src={props.data.image || defaultImage}
        alt="logo"
        style={{
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          marginRight: "10px",
        }}
      />
      {props.data.label}
    </div>
  </components.Option>
);

//@ts-ignore
export const SingleValue = ({ children, ...props }) => (
  //@ts-ignore
  <components.SingleValue {...props} data-test-id="selectDropdowSingleValue">
    {children}
  </components.SingleValue>
);

// Customizable Area End

import AccountGroupsController, {
  configJSON,
  Props,
} from "./AccountGroupsController";

export default class AccountGroups extends AccountGroupsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handlePageChange = this.handlePageChange.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start

  renderTeacherForm = () => {
    return (
      <Box paddingX={3} marginTop={5}>
        <Typography variant="h5" style={webStyles.headtext}>
          Basic Details
        </Typography>
        <form data-test-id="teacher-form" onSubmit={this.handleFormSubmit}>
          {
            <div style={{...webStyles.uploadrow, gap: this.state.isSmallScreen ? "10px" : "30px"}}>
              <div style={{...webStyles.uploadimg, width: this.state.isSmallScreen ? "100px" : "160px",
    height: this.state.isSmallScreen ? "80px" : "160px"}}>
                {this.state.imagePreview ? (
                  <img
                    src={this.state.imagePreview}
                    alt="Image Preview"
                    style={{ maxWidth: "100%", marginBottom: "1rem" }}
                  />
                ) : (
                  <img
                    src={uploadImageIcon}
                    width={"44px"}
                    height="44px"
                    alt="Default Image"
                  />
                )}
              </div>
              <input
                type="file"
                accept="image/*"
                name="image"
                onChange={this.handleFileChange}
                style={{ display: "none" }}
                ref={this.state.fileInput}
              />
              <div>
                <button
                  disabled={this.state.nonEditable}
                  data-test-id="btnAddFile"
                  type="button"
                  style={webStyles.profilebtn}
                  onClick={() => this.state.fileInput.current.click()}
                >
                  Upload photo
                </button>
                <div style={webStyles.imageText}>
                  Maximum Size of Image - 50KB, Jpeg, Png
                </div>
              </div>
            </div>
          }
          <Grid container spacing={3} style={webStyles.mt}>
            <TextInput
              data-test-id="full-name-input"
              label="Full Name*"
              name="fullName"
              value={this.state.fullName}
              onChange={this.handleChange}
              error={this.state.fullNameError}
              nonEditable={this.state.nonEditable}
              placeholder="Enter Full Name"
              isSmallScreen={this.state.isSmallScreen}
            />
            <TextInput
              data-test-id="email-input"
              label="Email ID*"
              name="email"
              value={this.state.email}
              onChange={this.handleChange}
              error={this.state.emailError}
              nonEditable={this.state.nonEditable}
              placeholder="Enter Email ID"
              isSmallScreen={this.state.isSmallScreen}
            />
            <SelectInput
              label="Gender (optional)"
              name="gender"
              value={this.state.gender}
              onChange={this.handleChange}
              options={[
                { label: "Male", value: "male" },
                { label: "Female", value: "female" },
                { label: "Other", value: "other" },
              ]}
              nonEditable={this.state.nonEditable}
              placeholder="Select"
              isSmallScreen={this.state.isSmallScreen}
            />
            <TextInput
              data-test-id=""
              label="Institution*"
              name="institution"
              value={this.state.institution}
              onChange={this.handleChange}
              error={this.state.institutionError}
              nonEditable={this.state.nonEditable}
              placeholder="Enter Institution"
              isSmallScreen={this.state.isSmallScreen}
            />
            <Grid item xs={!this.state.isSmallScreen ? 6 : 12}>
              <label style={webStyles.inputLabel}>Summary</label>
              <textarea
                name="summary"
                rows={4}
                value={this.state.summary}
                style={
                  this.state.confirmPassword === ""
                    ? {
                        ...webStyles.custominputPlaceholder,
                        ...webStyles.customTextArea,
                      }
                    : { ...webStyles.custominput, ...webStyles.customTextArea }
                }
                onChange={this.handleChange}
                readOnly={this.state.nonEditable}
                placeholder="Enter Summary"
              />
            </Grid>
            <Grid item xs={!this.state.isSmallScreen ? 6 : 12}>
              <label style={webStyles.inputLabel}>School Admin</label>
              <input
                data-test-id=""
                type="text"
                name="schoolAdmin"
                defaultValue={this.state.activeSchoolAdmin}
                style={webStyles.custominputDisable}
                onChange={this.handleChange}
                readOnly
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" style={webStyles.headtext}>
                {this.state.editId ? "Password" : "Create Password"}
              </Typography>
            </Grid>
            <PasswordInput
              data-test-id=""
              label={
                this.state.editId ? "Current Password" : "Create Password*"
              }
              name="password"
              value={this.state.password}
              onChange={this.handleChange}
              enablePasswordField={this.state.enablePasswordField}
              handleClickShowPassword={this.handleClickShowPassword}
              error={this.state.passwordError}
              nonEditable={this.state.nonEditable}
              placeholder={
                this.state.editId ? "Current Password" : "Enter New Password"
              }
              isSmallScreen={this.state.isSmallScreen}
            />
            {!this.state.editId && (
              <PasswordInput
                data-test-id=""
                label="Confirm Password*"
                name="confirmPassword"
                value={this.state.confirmPassword}
                onChange={this.handleChange}
                enablePasswordField={this.state.enableConfirmPasswordField}
                handleClickShowPassword={this.handleClickShowConfirmPassword}
                error={this.state.confirmPasswordError}
                nonEditable={this.state.nonEditable}
                placeholder="Confirm Password"
                isSmallScreen={this.state.isSmallScreen}
              />
            )}
            <Grid item xs={12}>
              <CommonButtons
                editId={this.state.editId}
                handleCancel={this.handleCancel}
                nonEditable={this.state.nonEditable}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    );
  };

  renderGenderField = () => {
    return (
      <Grid item xs={!this.state.isSmallScreen ? 6 : 12}>
        <FormControl fullWidth>
          <label style={webStyles.inputLabel}>Gender (optional)</label>
          <select
            name="gender"
            defaultValue={this.state.gender}
            style={
              !this.state.gender
                ? webStyles.customSelectPlaceholder
                : webStyles.customSelect
            }
            onChange={this.handleChange}
            disabled={this.state.nonEditable}
          >
            {!this.state.gender && (
              <option key={"Select"} value={""}>
                {"Select"}
              </option>
            )}
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </FormControl>
      </Grid>
    )
  }

  renderEmailField = () => {
    return (
      <Grid item xs={!this.state.isSmallScreen ? 6 : 12}>
        <label style={webStyles.inputLabel}>Email ID*</label>
        <input
          data-test-id="email-input"
          type="text"
          name="email"
          defaultValue={this.state.email}
          style={
            this.state.email === ""
              ? webStyles.custominputPlaceholder
              : webStyles.custominput
          }
          onChange={this.handleChange}
          readOnly={this.state.nonEditable}
          placeholder="Enter Email ID"
        />
        <label style={webStyles.error}>{this.state.emailError}</label>
    </Grid>
    )
  }

  renderDobField = () => {
    return (
      <Grid item xs={!this.state.isSmallScreen ? 6 : 12}>
        <label style={webStyles.inputLabel}>
          Date of Birth (optional)
        </label>
        <input
          data-test-id="date_of_birth"
          type="date"
          name="date_of_birth"
          style={
            this.state.date_of_birth === ""
              ? webStyles.custominputPlaceholder
              : webStyles.custominput
          }
          value={this.state.date_of_birth}
          onChange={this.handleChange}
          readOnly={this.state.nonEditable}
          placeholder="mm/dd/yyyy"
        />
      </Grid>
    )
  }

  renderStudentForm = () => {
    return (
      <Box paddingX={3} marginTop={5}>
        <form data-test-id="student-form" onSubmit={this.handleFormSubmit}>
          <Grid container spacing={3} style={webStyles.mt}>
            <Grid item xs={!this.state.isSmallScreen ? 6 : 12}>
              <label style={webStyles.inputLabel}>Full Name*</label>
              <input
                data-test-id="full-name-input"
                type="text"
                name="fullName"
                defaultValue={this.state.fullName}
                style={
                  this.state.fullName === ""
                    ? webStyles.custominputPlaceholder
                    : webStyles.custominput
                }
                onChange={this.handleChange}
                readOnly={this.state.nonEditable}
                placeholder="Enter Full Name"
              />
              <label style={webStyles.error}>{this.state.fullNameError}</label>
            </Grid>
            {this.renderDobField()}
            {this.renderGenderField()}
            <TextInput
              data-test-id=""
              label="Course"
              name="course"
              value={this.state.course}
              onChange={this.handleChange}
              nonEditable={this.state.nonEditable}
              placeholder="Enter Course"
              isSmallScreen={this.state.isSmallScreen}
            />
           {this.renderEmailField()}

            <TextInput
              data-test-id=""
              label="Mobile Number (optional)"
              name="full_phone_number"
              value={this.state.full_phone_number}
              onChange={this.handleChange}
              nonEditable={this.state.nonEditable}
              placeholder="Enter Mobile Number"
              isSmallScreen={this.state.isSmallScreen}
            />
            <TextInput
              data-test-id=""
              label="Grade"
              name="grade"
              value={this.state.grade}
              onChange={this.handleChange}
              nonEditable={this.state.nonEditable}
              placeholder="Enter Grade"
              isSmallScreen={this.state.isSmallScreen}
            />
            <Grid item xs={!this.state.isSmallScreen ? 6 : 12}>
              <label style={webStyles.inputLabel}>Teacher</label>
              <div style={{ marginTop: "10px" }}>
                {
                  //@ts-ignore
                  <Select
                    data-test-id="selectDropdown"
                    value={this.state.selectedOption}
                    onChange={this.handleReactSelectChange}
                    options={this.state.selectTeachersDropDownList}
                    isDisabled={this.state.nonEditable}
                    placeholder="Select"
                    menuPlacement="auto" 
                    styles={{
                      singleValue: (base) => ({
                        ...base,
                        display: "flex",
                        alignItems: "center",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                        borderRadius: "6px",
                        marginTop: "0px",
                        height:"300px"
                      }),
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderRadius: "8px",
                        border: "1px solid #C0C0C0",
                        fontSize: "16px",
                        fontFamily: "Poppins",
                        height: "50px",
                      }),
                    }}
                    components={{
                      IndicatorSeparator,
                      //@ts-ignore
                      DropdownIndicator,
                      Option,
                      SingleValue,
                    }}
                  />
                }
              </div>
            </Grid>
            <SelectInput
              label="Profile Status"
              name="profileStatus"
              value={this.state.profileStatus}
              onChange={this.handleChange}
              options={[
                { label: "complete", value: "complete" },
                { label: "incomplete", value: "incomplete" },
                { label: "none", value: "none" },
              ]}
              nonEditable={this.state.nonEditable}
              placeholder="Select Profile Status"
              isSmallScreen={this.state.isSmallScreen}
            />

            <Grid item xs={12}>
              <h5 style={webStyles.headtext}>Create Password</h5>
            </Grid>
            <Grid item xs={!this.state.isSmallScreen ? 6 : 12} style={webStyles.passwordInput}>
              <label style={webStyles.inputLabel}>Create Password*</label>
              <input
                type={this.state.enablePasswordField ? "password" : "text"}
                name="password"
                defaultValue={this.state.password}
                style={
                  this.state.password === ""
                    ? webStyles.custominputPlaceholder
                    : webStyles.custominput
                }
                onChange={this.handleChange}
                readOnly={this.state.nonEditable}
                placeholder="Enter New Password"
              />
              <IconButton
                aria-label="toggle password visibility"
                onClick={this.handleClickShowPassword}
                edge="end"
                style={webStyles.iconBtn}
              >
                {this.state.enablePasswordField ? (
                  <VisibilityOutlinedIcon />
                ) : (
                  <VisibilityOffOutlinedIcon />
                )}
              </IconButton>
              {this.state.passwordError && (
                <label
                  data-test-id="passwordError"
                  style={webStyles.error}
                  dangerouslySetInnerHTML={{ __html: this.state.passwordError }}
                />
              )}
            </Grid>
            {!this.state.editId && (
              <Grid item xs={!this.state.isSmallScreen ? 6 : 12} style={webStyles.passwordInput}>
                <label style={webStyles.inputLabel}>Confirm Password*</label>
                <input
                  type={
                    this.state.enableConfirmPasswordField ? "password" : "text"
                  }
                  name="confirmPassword"
                  defaultValue={this.state.confirmPassword}
                  style={
                    this.state.confirmPassword === ""
                      ? webStyles.custominputPlaceholder
                      : webStyles.custominput
                  }
                  onChange={this.handleChange}
                  placeholder="Confirm Password"
                />
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.handleClickShowConfirmPassword}
                  edge="end"
                  style={webStyles.iconBtn}
                >
                  {this.state.enableConfirmPasswordField ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
                <label style={webStyles.error}>
                  {this.state.confirmPasswordError}
                </label>
              </Grid>
            )}
            <Grid item xs={12}>
              <CommonButtons
                editId={this.state.editId}
                handleCancel={this.handleCancel}
                nonEditable={this.state.nonEditable}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    );
  };

  renderDefaultView = () => {
    return (
      <Grid item xs={12} justifyContent="space-between">
        <Box sx={webStyles.searchBoxx} paddingX={"30px"} marginTop={5}>
          <Box>
            <Box sx={webStyles.searchiconbox}>
              <img src={searchIcon} />
            </Box>
            <Box sx={webStyles.searchInput}>
              <input
                data-test-id="search-input"
                style={{...webStyles.filterinput,width:window.innerWidth<960?"100%":webStyles.filterinput.width}}
                placeholder="Search by name or email"
                onChange={(e) => this.handleSearchInput(e)}
              />
              <img
                src={filterIcon}
                style={webStyles.filterIcon}
                onClick={this.handleFilterModalOpen}
              />
            </Box>   
          </Box>         
          <button
            data-test-id="btnAddGroupModal"
            style={webStyles.addUsersBtn1}
            onClick={this.showAddModal}
          >
            Add New User
          </button>
        </Box>
        {this.state.accountsLoading ? (
          <Box style={webStyles.loaderStyle}>
            <CircularProgress style={{ color: "#2B3D7D" }} />
          </Box>
        ) : (
          <>
            <DataTable
              data={this.state.accountsData}
              pageNo={this.state.pageNo}
              handleClick={this.handleClick}
              itemsPerPage={this.state.itemsPerPage}
              showPassword={this.state.showPassword}
            />
            <Box>
              <BasicPagination
                handlePageChange={this.handlePageChange}
                pageLimit={this.state.pageLimit}
                pageNo={this.state.pageNo}
              />
            </Box>
          </>
        )}
      </Grid>
    );
  };

  renderModalFilter = () => {
    return (
      <>
        <Modal open={this.state.filterModal} onClose={this.closeFilterModal}>
          <>
            <Box margin={"20%"}>
              <Box
                style={{
                  backgroundColor:"#fff",
                  borderRadius: "10px",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  display: "flex",
                  flexDirection: "column",
                  height: "50%",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "30%",
                  position:"absolute"
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div />
                  <Typography
                    style={{
                      ...webStyles.headtext,
                      fontWeight: "bold",
                      paddingLeft: "20px",
                      paddingTop: "24px",
                      paddingBottom:"10px",
                      width:"100%",
                      textAlign: "center",
                    }}
                    variant="h1"
                  >
                    Filter
                  </Typography>
                  <img
                    src={closeIcon}
                    onClick={this.closeFilterModal}
                    style={{ paddingBottom: "30px", position:"absolute", right:"9px", top:"16px" }}
                  />
                </Box>
                <div
                  style={{
                    backgroundColor: "#CCCCCC",
                    height: "2.1px",
                    width: "100%",
                  }}
                />
                <Box display={"flex"}>
                  <Box style={webStyles.roleTextStyle}>
                    <Typography style={{ color: "white" }}>Role</Typography>
                  </Box>
                  <Box display={"flex"} flexDirection={"column"} ml={"2rem"} 
                   style={{
                    width:"50%",
                    height: "calc(100% - 150px)",
                    position:"absolute",
                    right: 0, 
                    left: 153, 
                    paddingLeft:"16px",
                    paddingTop:"7px",
                    gap:"14px",
                    borderLeft:"3px solid #cccccc"}}>
                    <Box display={"flex"} alignItems={"center"}>
                      <CheckBox
                        checked={this.state.isCheckBox}
                        onChange={this.handleCheckBoxChange.bind(this,this.state.isCheckBox)}
                      />
                      <div
                        style={{
                          color: "#254050",
                          alignItems: "center",
                          fontSize: "14px",
                          fontFamily: "Poppins",
                          marginLeft:"4px"
                        }}
                        data-testid="checkBoxTitle"
                      >
                        Teacher
                      </div>
                    </Box>
                    <Box display={"flex"} alignItems={"center"}>
                      <CheckBox
                        checked={this.state.isCheckBox2}
                        onChange={this.handleCheckBoxChange2.bind(this,this.state.isCheckBox2)}
                      />
                      <div
                        style={{
                          color: "#254050",
                          alignItems: "center",
                          fontSize: "14px",
                          fontFamily: "Poppins",
                          marginLeft:"4px"
                        }}
                        data-testid="checkBoxTitle"
                      >
                        Student
                      </div>
                    </Box>
                  </Box>
                </Box>
              <Box width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"} mt={"8rem"}
                 style={{
                  paddingTop:"9px",
                  position:"absolute",
                  bottom:0,
                  paddingBottom:"8px",
                  borderTop:"3px solid #cccccc"}}
              >
                <Button
                  style={{
                    width: "170",
                    height: "52",
                    borderRadius: "8px",
                    textAlign: "center",
                    color: "white",
                    fontFamily: "poppins",
                    lineHeight: "16px",
                    fontSize: "18px",
                    letterSpacing: "0.11px",
                    textTransform: "capitalize",
                    backgroundImage:
                      "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
                  }}
                  onClick={this.handleFilterAccounts}
                >
                   {this.state.accountsLoading? <Box style={{...webStyles.loaderStyle}}><CircularProgress /></Box> : 'Apply'}
                </Button>
              </Box>
              </Box>
            </Box>
          </>
        </Modal>
      </>
    );
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    const roleValue = this.state.role
      ? "Add" + " " + this.state.role
      : "Accounts";
    const headerTitle =
      this.state.editId && this.state.role ? "Profile Details" : roleValue;
    const isTeacher = this.state.role.toLocaleLowerCase() === "teacher";
    const isStudent = this.state.role.toLocaleLowerCase() === "student";
    let componentToRender;
    switch (true) {
      case isTeacher:
        componentToRender = this.renderTeacherForm();
        break;
      case isStudent:
        componentToRender = this.renderStudentForm();
        break;
      default:
        componentToRender = this.renderDefaultView();
        break;
    }
    return (
      <ThemeProvider theme={theme}>
        <Grid container style={webStyles.container}>
          <Grid item {...(!this.state.isSmallScreen && { xs: 2 })}>
            {!this.state.isSmallScreen && <Sidebar
              activeTab={this.state.activeTab}
              handleTabClick={this.handleTabClick}
            />}
          </Grid>
          <Grid {...(!this.state.isSmallScreen && { xs: 10 })} style={webStyles.rightWrapper}>
            <>
              <AppHeader title={headerTitle} />
              {componentToRender}
              <Popover
                open={Boolean(this.state.anchorEl)}
                anchorEl={this.state.anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <div style={webStyles.dropdownmore}>
                  <button
                    data-test-id="btnShowDetails"
                    onMouseEnter={() => this.handleButtonHover(1)}
                    onMouseLeave={() => this.handleButtonHover(null)}
                    style={
                      this.state.activeButton === 1
                        ? webStyles.moreBtnMenuActive
                        : webStyles.moreBtnMenus
                    }
                    onClick={this.handleShowDetails}
                  >
                    <EventNoteOutlinedIcon style={webStyles.menumoreicon} />
                    View Details
                  </button>
                  <button
                    data-test-id="btnEditGroup"
                    onMouseEnter={() => this.handleButtonHover(2)}
                    onMouseLeave={() => this.handleButtonHover(null)}
                    style={
                      this.state.activeButton === 2
                        ? webStyles.moreBtnMenuActive
                        : webStyles.moreBtnMenus
                    }
                    onClick={this.handleEditGroup}
                  >
                    <BorderColorOutlinedIcon />
                    Edit Profile
                  </button>
                  <button
                    data-test-id="btnDeleteGroup"
                    onMouseEnter={() => this.handleButtonHover(3)}
                    onMouseLeave={() => this.handleButtonHover(null)}
                    style={
                      this.state.activeButton === 3
                        ? webStyles.moreBtnMenuActive
                        : webStyles.moreBtnMenus
                    }
                    onClick={() => this.showHideDeleteAccountModal()}
                  >
                    <DeleteOutlineIcon />
                    {configJSON.textDeleteProfile}
                  </button>
                  <button
                    data-test-id="btnShowPassword"
                    onMouseEnter={() => this.handleButtonHover(4)}
                    onMouseLeave={() => this.handleButtonHover(null)}
                    style={
                      this.state.activeButton === 4
                        ? webStyles.moreBtnMenuActive
                        : webStyles.moreBtnMenus
                    }
                    onClick={() => this.handleShowPassword()}
                  >
                    <VisibilityOutlinedIcon />
                    Show/Hide Password
                  </button>
                </div>
              </Popover>
              <Modal open={this.state.isVisibleModal} onClose={this.hideModal}>
                <Box sx={webStyles.modalsStyles}>
                  <Typography
                    variant="h4"
                    style={webStyles.modalHeadText}
                    color="secondary"
                  >
                    Add New User
                  </Typography>
                  <img
                    style={webStyles.closeIcon}
                    src={closeIcon}
                    onClick={this.hideModal}
                  />
                  <Typography variant="h5" style={webStyles.selectRoleText}>
                    Select Role
                  </Typography>
                  <Box sx={webStyles.boxRow}>
                    <Box
                      data-test-id="role-select-teacher"
                      sx={{
                        ...webStyles.boxTech,
                        ...(this.state.selectedRole === "teacher" &&
                          webStyles.boxActive),
                      }}
                      onClick={() => this.handleSelectRole("teacher")}
                    >
                      <img src={teacherIcon} style={webStyles.boxIcon} />
                      <Typography variant="h6" style={webStyles.textColor}>
                        Teacher
                      </Typography>
                    </Box>
                    <Box
                      data-test-id="role-select-student"
                      sx={{
                        ...webStyles.boxTech,
                        ...(this.state.selectedRole === "student" &&
                          webStyles.boxActive),
                      }}
                      onClick={() => this.handleSelectRole("student")}
                    >
                      <img src={studentIcon} style={webStyles.boxIcon} />
                      <Typography variant="h6" style={webStyles.textColor}>
                        Student
                      </Typography>
                    </Box>
                  </Box>
                  <button
                    data-test-id="submitRoleBtn"
                    style={webStyles.nextsbtn}
                    onClick={this.handleSubmitRole}
                  >
                    Next
                  </button>
                </Box>
              </Modal>
            </>
          </Grid>
        </Grid>
        {this.renderModalFilter()}
        <Modal
          open={this.state.isVisibleDeleteAccountModal}
          onClose={this.hideDeleteAccountModal}
        >
          <Box sx={webStyles.modalStyle}>
            <img src={imageDeletedIcon} />
            <Typography variant="h4" style={webStyles.modalHeadText}>
              Delete Profile
            </Typography>
            <Box sx={webStyles.modalRowViewStyle}>
              Are you sure you want to delete the profile of "
              {this.state.fullName}"?
            </Box>
            <Paper style={webStyles.dropdownListContainer} />
            <Box sx={webStyles.modalButtonViewStyle}>
              <button
                data-test-id="btnHideDeleteAccountModal"
                onClick={this.hideDeleteAccountModal}
                style={webStyles.closeBtn}
              >
                {configJSON.textClose}
              </button>
              <button
                data-test-id="btnHandleRemoveAccountsToGroup"
                color="primary"
                onClick={() => this.deleteGroup()}
                style={webStyles.deletebtn}
              >
                {configJSON.textDelete}
              </button>
            </Box>
          </Box>
        </Modal>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  container: {
    height: "100%",
    overflowX: "hidden" as OverflowXProperty,
  },
  rightWrapper: {
    height: "100%",
    overflowY: "scroll" as OverflowXProperty,
  },
  modalStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 500,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 4,
    gap: "20px",
    borderRadius: "16px",
  },
  modalRowViewStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "24px",
    fontFamily: "Poppins",
    margin: "0px 80px",
    textAlign: "center" as TextAlignProperty,
  },
  closeBtn: {
    border: "1px solid #979797",
    display: "flex",
    borderRadius: "6px",
    alignItems: "center",
    width: "200px",
    justifyContent: "center",
    fontSize: "16px",
    color: "#000",
    height: "40px",
    backgroundColor: "#f5f5f5",
    fontFamily: "Poppins",
    cursor: "pointer",
  },
  deletebtn: {
    border: "none",
    display: "flex",
    height: "40px",
    borderRadius: "6px",
    alignItems: "center",
    width: "200px",
    justifyContent: "center",
    fontSize: "16px",
    color: "#fff",
    backgroundColor: "#fe575a",
    fontFamily: "Poppins",
    cursor: "pointer",
  },
  dropdownViewStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 280,
    justifyContent: "space-between",
    padding: 10,
    border: "1px solid #ccc",
    borderRadius: 5,
  },
  modalButtonViewStyle: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 0px",
    gap: "10px",
  },
  tableViewStyle: {
    width: "100%",
    overflow: "hidden",
  },
  tableContainerStyle: {
    maxHeight: 600,
  },
  tableButtonViewStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  headerButtonViewStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  secondButtonViewStyle: {
    marginLeft: 10,
  },
  btnAddAccounts: {
    backgroundColor: "#005500",
    marginLeft: 10,
  },
  btnDeleteAccounts: {
    backgroundColor: "#990000",
    marginLeft: 10,
  },
  checkCircleRed: {
    height: 10,
    width: 10,
    backgroundColor: "red",
    borderRadius: 5,
  },
  innerTableBox: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  arrow: {
    height: 20,
    width: 20,
    resizeMode: "contain",
  },
  dropdownListContainer: {
    maxHeight: 200,
    overflow: "auto",
    width: 300,
  },
  errorMsg: {
    color: "red",
    margin: 10,
  },
  logo: {
    width: "260px",
  },
  listul: {
    width: "85%",
  },
  searchBoxx: {
    display: "flex",
    gap: "4px",
    position: "relative",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  filterinput: {
    width: "538px",
    height: "52px",
    borderRadius: "8px",
    border: "1px solid rgba(43,61,125,0.39)",
    background: "#fff",
    paddingLeft: "40px",
    fontFamily: "Poppins",
    "&::placeholder": {
      fontFamily: "Poppins",
      color: "#2b3d7d",
      fontSize: "16px",
    },
  },

  loaderStyle: {
    display: "flex",
    width: "100%",
    height: "60%",
    justifyContent: "center",
    alignItems: "center",
  },

  searchiconbox: {
    position: "absolute",
    top: "13px",
    left: "40px",
  },
  searchInput: {
    display: "flex",
    gap: "10px",
  },
  mt: {
    marginTop: "15px",
  },
  profilebtn: {
    background: gradientBackground,
    border: "none",
    borderRadius: "5px",
    color: "#fff",
    width: "180px",
    height: "50px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    cursor: "pointer",
  },
  addUsersBtn: {
    background: gradientBackground,
    border: "none",
    borderRadius: "5px",
    color: "#fff",
    width: "100px",
    height: "50px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    cursor: "pointer",
  },
  addUsersBtn1: {
    background: gradientBackground,
    border: "none",
    borderRadius: "5px",
    color: "#fff",
    width: "181px",
    height: "50px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    cursor: "pointer",
  },
  cancelsBtn: {
    border: "1px solid #2B337D",
    backgroundColor: "#fff",
    borderRadius: "5px",
    color: "#000",
    width: "100px",
    height: "50px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    marginRight: "15px",
    cursor: "pointer",
  },
  nextsbtn: {
    background: gradientBackground,
    border: "none",
    borderRadius: "5px",
    color: "#fff",
    width: "82%",
    height: "50px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    cursor: "pointer",
    marginBottom: "10px",
  },
  modalsStyles: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "12px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 600,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    p: 4,
  },
  boxRow: {
    display: "flex",
    gap: "30px",
    justifyContent: "space-evenly",
    marginBottom: "40px",
  },
  boxTech: {
    display: "flex",
    gap: "10px",
    flexDirection: "column",
    width: "230px",
    height: "145px",
    borderRadius: "10px",
    border: "1px solid #dedde4",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  boxActive: {
    border: "1px solid #2b3d7d",
    background: "#eef2ff",
  },
  modalHeadText: {
    fontFamily: "Poppins",
    fontSize: "28px",
    fontWeight: 700,
  },
  boxIcon: {
    width: "60px",
    height: "60px",
  },
  custominput: {
    borderRadius: "8px",
    border: "1px solid #C0C0C0",
    padding: "8px 15px",
    marginTop: "10px",
    display: "block",
    width: "100%",
    minHeight: "50px",
    fontSize: "16px",
    // '.MuiInputBase-input': {
    //   background: 'red'
    // },
  },
  custominputPlaceholder: {
    borderRadius: "8px",
    border: "1px solid #C0C0C0",
    padding: "8px 15px",
    marginTop: "10px",
    display: "block",
    width: "100%",
    minHeight: "50px",
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#2f3d51",
  },
  customSelectPlaceholder: {
    borderRadius: "8px",
    border: "1px solid #C0C0C0",
    padding: "8px 15px",
    marginTop: "10px",
    display: "block",
    width: "100%",
    minHeight: "50px",
    appearance: "none" as AppearanceStyle,
    background: `url("https://cdn-icons-png.flaticon.com/512/60/60995.png")`,
    backgroundSize: "4%",
    backgroundPosition: "right .75rem center",
    backgroundRepeat: "no-repeat",
    gpaddinRight: "15px",
    lineHeight: "30px",
    fontSize: "18px",
    color: "#2f3d51",
    opacity: "0.6",
  },
  custominputDisable: {
    borderRadius: "8px",
    border: "1px solid #C0C0C0",
    padding: "8px 15px",
    marginTop: "10px",
    display: "block",
    width: "100%",
    minHeight: "50px",
    fontSize: "16px",
    background: "#eee",
  },
  customSelect: {
    borderRadius: "8px",
    border: "1px solid #C0C0C0",
    padding: "8px 15px",
    marginTop: "10px",
    display: "block",
    width: "100%",
    minHeight: "50px",
    fontSize: "16px",
    appearance: "none" as AppearanceStyle,
    background: `url("https://cdn-icons-png.flaticon.com/512/60/60995.png")`,
    backgroundSize: "4%",
    backgroundPosition: "right .75rem center",
    backgroundRepeat: "no-repeat",
    paddingRight: "15px",
    lineHeight: "30px",
  },
  inputStyle: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    label: {
      fontFamily: "Roboto",
      fontSize: "14px",
    },
  },
  uploadrow: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
  },
  uploadimg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    overflow: "hidden",
    // border: '1px solid #dedde4',
    background: "rgba(51,141,157,0.1)",
  },
  headtext: {
    fontSize: "32px",
    color: "#2B3D7D",
    margin: 0,
    fontFamily: "Poppins",
    textTransform: "capitalize" as TextTransformProperty,
  },
  roleTextStyle: {
    fontFamily: "Poppins",
    margin: 0,
    backgroundImage:
      "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
    fontSize: "32px",
    textTransform: "capitalize" as TextTransformProperty,
    width: "185px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50px",
  },
  textColor: {
    color: "#2B3D7D",
    fontFamily: "Poppins",
  },
  selectRoleText: {
    fontSize: "22px",
    fontWeight: 500,
    fontFamily: "Poppins",
    color: "#0a033c",
    marginBottom: "40px",
  },
  datatable: {
    padding: "0px",
    borderRadius: "8px",
    boxShadow: "0px 0px 3px 1px rgba(52,146,159,0.16)",
    margin: "30px 30px 0px",
    width: "auto",
  },
  thead: {
    backgroundColor: "#fdf4dc",
  },
  theading: {
    fontFamily: "Poppins",
    color: "#202020",
    fontSize: "16px",
    fontWeight: 500,
    textAlign: "center" as TextAlignProperty,
  },
  tdetail: {
    fontFamily: "Poppins",
    color: "#2c2c2e",
    fontSize: "14px",
    fontWeight: 400,
    textAlign: "center" as TextAlignProperty,
    textTransform: "capitalize" as TextTransformProperty,
  },
  tdetailBreak: {
    wordBreak: "break-all" as WordBreakProperty,
    width: "130px",
    fontFamily: "Poppins",
    color: "#2c2c2e",
    fontSize: "14px",
    fontWeight: 400,
    textAlign: "center" as TextAlignProperty,
  },
  dropdownmore: {
    display: "block",
    gap: "10px",
    borderRadius: "6px",
    padding: "15px 0",
    width: "max-content",
  },
  moreBtnMenus: {
    fontSize: "16px",
    color: "#1c1c1e",
    fontWeight: 400,
    display: "flex",
    gap: "10px",
    backgroundColor: "#fff",
    border: "none",
    width: "100%",
    marginBottom: "0px",
    padding: "12px",
    cursor: "pointer",
    fontFamily: "Poppins",
  },
  menumoreicon: {
    fontSize: "24px",
  },
  moreBtnMenuActive: {
    background: gradientBackground,
    fontSize: "16px",
    color: "#fff",
    fontWeight: 400,
    display: "flex",
    gap: "10px",
    height: "45px",
    alignItems: "center",
    border: "none",
    width: "100%",
    marginBottom: "0px",
    padding: "12px",
    cursor: "pointer",
    fontFamily: "Poppins",
  },
  filterIcon: {
    cursor: "pointer",
  },

  closeIcon: {
    position: "absolute" as PositionStyle,
    right: "20px",
    top: "20px",
    cursor: "pointer",
  },
  actionBtn: {
    textAlign: "center" as TextAlignProperty,
    cursor: "pointer",
  },
  customTextArea: {
    resize: "none" as ResizeStyle,
  },
  passwordInput: {
    position: "relative" as PositionRelative,
  },
  iconBtn: {
    position: "absolute" as PositionStyle,
    top: "48px",
    right: "25px",
  },
  error: {
    color: "red",
    fontSize: "12px",
    marginTop: "10px",
    display: "block",
    fontFamily: "Poppins",
  },
  inputLabel: {
    fontFamily: "Poppins",
  },
  imageText: {
    fontFamily: "Poppins",
    color: "#282829",
    fontSize: "12px",
    fontWeight: 400,
    marginTop: "20px",
  },
  btnWrapper: {
    display: "flex",
    justifyContent: "end",
  },
};

// Customizable Area End
