import React from "react";
import { Box, Typography, Button,Modal,Switch  } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { closeModalIcon,downloadIcon,savedIcon } from "./assets";
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "472px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    padding:"48px 24px 46px",
    "&.saved-lesson-popup":{
      width:"309px",
      padding:"48px 15px",
      "& .image-wrapper":{
        marginBottom:"20px",
      },
      "& .popupContent":{
        margin:0,
      },
      "& .saveHeading":{
        fontSize:"16px",
        fontWeight:"400",
        color:"#282829",
        margin:"0",
      },
    },
    "& .close-modal":{
      display:"block",
      position: "absolute",
      top: "0",
      right: "0",
    },
    "& .cross-icon":{
      cursor:"pointer"
    }
  },
  heading: {
    fontFamily: "Poppins",
    fontSize: "20px",
    color: "#2C2C2E",
    fontWeight: 500,
    lineHeight:"1.5",
    textAlign:"center",
    padding:"0 15px",
  },
  popupContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom:"40px",
    "& .image-wrapper":{
      marginBottom:"15px",
    }
  },
  btnLayout: {
    display: "flex",
    justifyContent: "center",
    "& button + button":{
      marginLeft:"16px",
    }
  },
  siteBtn:{
    height: "52px",
    lineHeight: "52px",
    border: "1px solid #2B3D7D",
    color: "#2B3D7D",
    minWidth: "52px",
    width: "auto",
    padding: "0 15px",
    fontSize: "16px",
    fontWeight: 500,
    transition:"all 0.5s",
    textTransform:"unset",
    "&:hover":{
      backgroundColor:"#1e2b58",
      color:"#FFF"
    },
    "&.fill-btn":{
      color:"#FFF",
      backgroundColor:"#2B3D7D",
      "&:hover":{
        backgroundColor:"#1e2b58",
      },
    }
  },
  saveDriveBlock:{
    width:"100%",
    display:"flex",
    borderRadius:"8px",
    border:"1px solid #E5E5EA",
    marginBottom:"40px",
    "& .download":{
      flex:"0 0 55px",
      maxWidth:"55px",
      borderLeft:"1px solid #E5E5EA",
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
    },
    "& .switch-block":{
      flex:"1",
      width:"100%",
      padding:"18px",
      "& .switch-inner":{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        "& p":{
          fontFamily: "Poppins",
          fontSize: "14px",
          color: "#2B3D7D",
          fontWeight: 400,
          lineHeight:"1.42",
        },

      },
    }
  },
  saveHeading: {
    fontFamily: "Poppins",
    fontSize: "24px",
    lineHeight:"1.6",
    textAlign:"center",
    padding:"0 15px",
    marginBottom:"10px",
  },
}));

const ToggleSwitch = withStyles((theme) => ({
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
    backgroundColor:"white"
  },
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    "& .MuiSwitch-track":{
      boxSizing: "border-box"
    }
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "grey",
  },
  checked: {},
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        boxSizing: "border-box"
      },
    },
  },
}))(Switch);

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const LeaveLessonModal = (props: any) => {
  const { open, handleClose,exitLesson,exitAndSaveLesson,isQuiz,showSuccess,isGuest } = props;
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    if (isChecked) {
      props.checkSignIn();
    }    
  };
  
  return (
    <Modal
        open={open}
    >
      <Box style={modalStyle} 
       className={`${classes.paper} ${showSuccess ? 'saved-lesson-popup' : ''}`}
      >
        <a data-test-id="closeLeaveLessonModal" onClick={handleClose} title="close modal" className="close-modal"><img src={closeModalIcon} alt="Modal close icon" className="cross-icon"/></a>
        <Box className={`${classes.popupContent} popupContent`}>
          {showSuccess ?
          <> 
          <Box className="image-wrapper">
           <img src={savedIcon} alt="Saved"/>
           </Box>
        
          <Typography className={`${classes.saveHeading} saveHeading`}>
             Lesson Saved Successfully!
          </Typography>
         </>
          :
          <>
          {!isGuest && <Box className={classes.saveDriveBlock}>
            <Box className="switch-block">
              <Box className="switch-inner">
                <Typography>Save to Google Drive</Typography>
                <Box className="switch-wrapper">
                <ToggleSwitch data-test-id="toggle" checked={checked} onChange={handleSwitchChange} name="checkedA" />             
                </Box>
              </Box>
            </Box>
          </Box>}
              <Typography className={classes.heading}>
              {isGuest?"Are you sure you want to exit Lesson?":`Do you want to save your work from this ${isQuiz?"quiz":"lesson"}?`}              
              </Typography></>}
        </Box>
          {!showSuccess && <Box className={classes.btnLayout}>
            <Button
              data-testid="cancelBtn"
              className={`${classes.siteBtn} border-btn`}
              onClick={exitLesson}
              >
             {isGuest?"Exit Session":"Exit Without Saving"}
            </Button>
            <Button
              data-test-id="saveLessonBtn"
              className={`${classes.siteBtn} fill-btn`}
              onClick={() => (isGuest ? handleClose() : exitAndSaveLesson(checked))}>
              {isGuest?"Cancel":"Save & Exit"}
            </Button>
        </Box>}
      </Box>
    </Modal>
  );
};

export default LeaveLessonModal;
