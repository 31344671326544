import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, defaultImage } from "./assets";
import { MyClassesState, Roles, MY_CLASSES_VIEWS, CLASS_OPTIONS, REPORT_OPTIONS, StudentLesson } from "../../../components/src/types"
import { debounce } from "lodash";
import { noClassImg } from "../../catalogue/src/assets";
import { removeStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  role: string|null;
  myClassesState:MyClassesState;
  pageNo:number,
  pageLimit:number,
  pageLimitStudent:number,
  pageNoStudent:number,
  destination:string;
  isDeleteSuccess:boolean;
  isReminderSuccess:boolean;
  isLoading:boolean;
  studentClassListData: any;
  studentClassList: any[];
  selectedClassData: any;
  pageNoLesson:number;
  pageLimitLesson:number;
  pageNoStudentLesson:number;
  pageLimitStudentLesson:number;
  searchInputStudentName:string;
  studentApiError:boolean;
  openReportModal:boolean;
  pageNoAssignment:number;
  pageLimitAssignment:number;
  pageNoQuiz:number;
  pageLimitQuiz:number;
  openNoMobileModal:boolean;
  errorMsg:string;
  showError:boolean
  isLimitModalOpen: boolean;
  internalDate:any,
  liveLessonData:any[];
  liveQuizData:any[];
  filteredStudentLessonData:any[];
  apiCounts:number;
   // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ContentManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getClassListApiCallId: string = ""
  apiCreateClassCallId: string = ""
  getStudentListByClassApiCallId: string = ""
  createStudentApiCallId: string = ""
  getStudentApiCallId: string = ""
  deleteStudentApiCallId: string = ""
  sendReminderApiCallId: string = ""
  updateStudentApiCallId: string = ""
  getFormClassListApiCallId: string = ""
  getLessonListByClassApiCallId: string = ""
  getStudentLessonListByClassApiCallId: string = ""
  getAssignmentListByClassApiCallId: string = ""
  getQuizListByClassApiCallId: string = ""
  getliveQuizListByClassApiCallId:string=""
  locations: any[] = []
  // Customizable Area End
  // Customizable Area Start
  studentClassListApiCallId: string = "";
  deliverLessonApiId: string = "";
  deliverQuizApiId: string = "";
  deleteClassApiCallId:string = "";
    handleClick: any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
      
    ];
    this.openReportModal = this.openReportModal.bind(this);
    this.showReportView = this.showReportView.bind(this);
    this.showSummaryReportListView = this.showSummaryReportListView.bind(this);
    this.closeReportModal = this.closeReportModal.bind(this);

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      role: "",
      myClassesState: {
        selectedView: MY_CLASSES_VIEWS.MY_CLASSES,
        classList: [],
        searchInputClassName: "",
        searchInputLessonName: "",
        searchInputQuizName: "",
        searchInputAssignmentName: "",
        searchInputStudentLessonName: "",
        studentList: [{
          id: "",
          fullName: "",
          email: "",
          password: "",
          creationDate: "",
          profileStatus: "",
          classes: [],
        }],
        studentDetails: {},
        selectedClass: "",
        selectedStudent: {
          id: "",
          fullName: "",
          email: "",
          password: "",
          classes: [],
          creationDate: "",
          profileStatus: ""
        },
        formClassList: [],
        lessonList: [],
        studentLessonData: [],
        teacherId: "",
        assignmentList: [],
        quizList: []
      },
      pageNo:1,
      pageLimit:1,
      pageNoStudent:1,
      pageLimitStudent:1,
      destination:"",
      isDeleteSuccess:false,
      isReminderSuccess:false,
      isLoading:false,
      pageNoLesson:1,
      pageLimitLesson:1,
      pageNoStudentLesson:1,
      pageLimitStudentLesson:1,
      searchInputStudentName:"",
      selectedClassData: {},
      studentClassList: [],
      studentClassListData: [],
      studentApiError:false,
      openReportModal:false,
      pageNoAssignment:1,
      pageLimitAssignment:1,
      pageNoQuiz:1,
      pageLimitQuiz:1,
      openNoMobileModal:false,
      errorMsg:"",
      isLimitModalOpen: false,
      showError:false,
      internalDate:null,
      liveLessonData:[],
      liveQuizData:[],
      filteredStudentLessonData:[],
      apiCounts:1
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message)
    
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    } 
    // Customizable Area Start
    const apiCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiCallId != null &&
      this.studentClassListApiCallId === apiCallId
    ) {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson?.errors && responseJson?.data) {
      this.setState({
        studentClassList: responseJson?.data,
        studentClassListData: responseJson?.data?.attributes?.classes,
      }
      )
      this.setState((prevState) => ({
        myClassesState: {
          ...prevState.myClassesState,
          teacherId: responseJson.data.attributes.teacher_id,
        },
      }));
    } else {
      this.setState({
        studentClassList: [],
        studentClassListData: [],
      }
      )
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.parseApiCatchErrorResponse(errorResponse);
    }
  }
   // Customizable Area End
    // Customizable Area Start
    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    let error = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    const callId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    this.handleGetClassListResponse(callId, response, error)
    this.handleGetStudentListByClassResponse(callId, response, error)
    this.handleCreateOrUpdateStudentResponse(callId, response, error)
    this.handleViewStudentResponse(callId, response, error)
    this.handleDeleteStudentResponse(callId, response, error)
    this.handleSendReminderResponse(callId, response, error)
    this.handleGetFormClassListResponse(callId, response, error)
    this.handleCreateClassResponse(callId, response, error)
    this.handleGetLessonListByClassResponse(callId, response, error)
    this.handleGetStudentLessonListByClassResponse(callId, response, error)
    this.updateLessonresponse(callId,response,error)
    this.handleGetAssignmentListByClassResponse(callId, response, error)
    this.handleGetQuizListByClassResponse(callId, response, error)
    this.updateClassresponse(callId, response)
    this.handleGetStudentQuizListByClassResponse(callId,response,error)
    this.setState(
      { 
        isLoading: this.state.apiCounts==1?false:this.state.isLoading,
        apiCounts: 1
     })
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    if (window.localStorage.getItem("role") === Roles.TEACHER) {
      this.getClassList();
    }
     // Customizable Area Start
     if (window.localStorage.getItem("role") === Roles.STUDENT) {
      this.getStudentClasslistData();
    }
    // Customizable Area End
    this.setState({
      role: window.localStorage.getItem("role")
    })
    const data = this.props.navigation.getParam("data")
    if(data==='myclasses') return
    else{
      this.showClassDetailView({},JSON.parse(decodeURIComponent(data)))
    }
  }
  getClassList(): boolean {
    this.setState({ isLoading: true })
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getClassListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getClassList}?per_page=9&class_name=${this.state.myClassesState.searchInputClassName}&page=${this.state.pageNo}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area Start
  getStudentClasslistData(): boolean {
    this.setState({isLoading:true})
    let id = window.localStorage.getItem("id")

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.studentClassListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStudentClassList}?search=${this.state.myClassesState.searchInputClassName}&student_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
  getFormClassList(): boolean {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFormClassListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getClassList}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getStudentListByClass(page=0): boolean {
    this.setState({ isLoading: true })
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStudentListByClassApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStudentListByClass}?class_id=${this.state.myClassesState.selectedClass}&per_page=9&page=${page?page:this.state.pageNoStudent}&full_name=${this.state.searchInputStudentName}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getLessonListByClass(page = 1): boolean {
    this.setState({ isLoading: true })
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLessonListByClassApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getLessonListByClass}?search=${this.state.myClassesState.searchInputLessonName}&class_id=${this.state.myClassesState.selectedClass}&teacher_id=${this.state.myClassesState.teacherId}&per_page=10&page=${this.state.pageNoLesson}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getStudentLessonListByClass(): boolean {
    this.setState({ isLoading: true })
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStudentLessonListByClassApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getLessonListByClass}?search=${this.state.myClassesState.searchInputStudentLessonName}&teacher_id=${this.state.myClassesState.teacherId}&class_id=${this.state.myClassesState.selectedClass}&is_live=${true}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  createStudent(data: any): boolean {
    const student = {
      full_name: data.fullName,
      date_of_birth: data.birthDate,
      gender: data.gender,
      email: data.email,
      full_phone_number: data.phone,
      new_password: data.fieldPassword,
      confirm_password: data.fieldPassword,
      class_id: data.classId,
      profile_status: data.profileStatus
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(student)
    );
    this.createStudentApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createStudent}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  updateStudent(data: any): boolean {
    const student: any = {
      full_name: data.fullName,
      date_of_birth: data.birthDate,
      gender: data.gender,
      profile_status: data.profileStatus,
      email: data.email,
      full_phone_number: data.phone,
      class_id: data.classId,
    }
    if (data.currentPassword && data.fieldPassword && data.confirmPassword) {
      student["current_password"] = data.currentPassword;
      student["new_password"] = data.fieldPassword;
      student["confirm_password"] = data.confirmPassword;
    }

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(student)
    );

    this.updateStudentApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateStudent}?id=${data.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  viewStudent(id: string): boolean {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStudentApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.viewStudent}?account_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  deleteStudent(id: string): boolean {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteStudentApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteStudent}?account_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETE
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  sendReminder(id: string) {
    const studentId = {
      student_id: id
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(studentId)
    );
    this.sendReminderApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sendReminder}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }
  setClassList=(responseJson:any)=>{
    const classList = responseJson.data.map((data: any) => {      
      return {
        id: data.id,
        name: data.attributes.class_name,
        studentCount: data.attributes.student_count,
        image: data.attributes.attachment ? data.attributes.attachment : noClassImg,
        classCode: data.attributes.class_code
      }
    })
    this.setState((prevState) => ({
      myClassesState: {
        ...prevState.myClassesState,
        classList: classList,
        teacherId: responseJson.data[0].attributes.teacher_id,
      },
      pageLimit: responseJson.meta.total_page
    }));
  }
  handleGetClassListResponse(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: string) {
    if (apiRequestCallId != null && apiRequestCallId === this.getClassListApiCallId) {
      if(responseJson.data){
        if (responseJson.data.length===0) {
          this.setState((prevState) => ({
            myClassesState: {
              ...prevState.myClassesState,
              classList: [],
            },
            pageLimit: 0
          }));
          return
        }
        if (responseJson.data[0]) {
          this.setClassList(responseJson)
        }
        else {
          this.parseApiErrorResponse(responseJson);
        }
      }
      if(responseJson.message === 'Classes not found'){
        this.setState((prevState) => ({
          myClassesState: {
            ...prevState.myClassesState,
            classList: [],
          },
          pageLimit: 0,
        }));
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  handleGetFormClassListResponse(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: string) {
    if (apiRequestCallId != null && apiRequestCallId === this.getFormClassListApiCallId) {
      if (responseJson.data[0]) {
        const classList = responseJson.data.map((data: any) => {
          return {
            id: data.id,
            name: data.attributes.class_name,
          }
        })
        this.setState((prevState) => ({
          myClassesState: {
            ...prevState.myClassesState,
            formClassList: classList,
          }
        }));
      }
      if (!responseJson.data[0]) {
        this.setState((prevState) => ({
          myClassesState: {
            ...prevState.myClassesState,
            classList: [],
          }
        }));
      }
      else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  handleGetStudentListByClassResponse(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: string) {
    if (apiRequestCallId != null && apiRequestCallId === this.getStudentListByClassApiCallId) {
      if (responseJson.message) {
        this.setState((prevState) => ({
          myClassesState: {
            ...prevState.myClassesState,
            studentList: [],
          },
          pageLimitStudent: 0
        }));
        return
      }
      if (responseJson.data[0]) {
        const studentList = responseJson.data.map((data: any) => {
          return {
            id: data.id,
            fullName: data.attributes.full_name,
            email: data.attributes.email,
            password: data.attributes.user_password,
            creationDate: data.attributes.date_created,
            profileStatus: data.attributes.profile_status,
            phone: data.attributes.full_phone_number,
            gender: data.attributes.gender,
            birthDate: data.attributes.date_of_birth,
            classes: data.attributes.classes.map((classData: any) => {
              return {
                id: classData.id,
                name: classData.attributes.class_name
              }
            })
          }
        })
        this.setState((prevState) => ({
          myClassesState: {
            ...prevState.myClassesState,
            studentList: studentList,
          },
          pageLimitStudent: responseJson.meta.total_page
        }));
      }
      else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  handleViewStudentResponse(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: string) {
    if (
      apiRequestCallId != null &&
      apiRequestCallId === this.getStudentApiCallId
    ) {
      if (responseJson.errors) {
        return;
      }
      if (responseJson.data) {
        const { data } = responseJson
        const student = {
          id: data.id,
          fullName: data.attributes.full_name,
          email: data.attributes.email,
          password: data.attributes.password,
          classes: [this.state.myClassesState.selectedClass],
          creationDate: data.attributes.date_created,
          profileStatus: data.attributes.profile_status,
          phone: data.attributes.full_phone_number,
          gender: data.attributes.gender,
          birthDate: data.attributes.date_of_birth
        }
        this.setState(
          (prevState) => ({
            myClassesState: {
              ...prevState.myClassesState,
              selectedStudent: student,
            }
          }))
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  handleGetLessonListByClassResponse(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: string) {
    if (apiRequestCallId != null && apiRequestCallId === this.getLessonListByClassApiCallId) {
      if (responseJson?.message) {
        this.setState((prevState) => ({
          myClassesState: {
            ...prevState.myClassesState,
            lessonList: [],
          },
          pageLimitLesson: 0
        }));
        return
      }
      if (responseJson?.data?.length > 0) {
        const lessonList = responseJson?.data.map((data: any) => {
          return {
            id: data.id,
            lessonName: data.attributes.lesson_name,
            lessonCode: data.attributes.lesson_code,
            totalStage: data.attributes.total_stages || 0,
          }
        })
        this.setState((prevState) => ({
          myClassesState: {
            ...prevState.myClassesState,
            lessonList: lessonList,
          },
          pageLimitLesson: responseJson.meta.total_page
        }));
      }
      else {
        if (responseJson?.errors === 'Lessons not found') {
          this.setState((prevState) => ({
            ...prevState,
            isLoading: false,
            myClassesState: {
              ...prevState.myClassesState,
              lessonList: [],
            },
            pageLimitLesson: 0
          }))
        }
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  handleGetStudentLessonListByClassResponse(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: string) {
    if (apiRequestCallId != null && apiRequestCallId === this.getStudentLessonListByClassApiCallId) {
      if (responseJson?.message) {
        this.setState((prevState) => ({
          ...prevState,
          myClassesState: {
            ...prevState.myClassesState,
            studentLessonData: [],
          },
          pageLimitStudentLesson: 0
        }));
        return
      }
      if (responseJson?.data?.length > 0) {
        const lessonList = responseJson?.data.map((lessonData: any) => {
          return {
            id: lessonData.id,
            name: lessonData.attributes.lesson_name,
            code: lessonData.attributes.lesson_code,
            status: lessonData.attributes.lesson_status,
            live: lessonData.attributes.live_lesson,
            stages: lessonData.attributes.total_stages,
          }
        });
        this.setState((prevState) => ({
          ...prevState,
          liveLessonData:lessonList,
        }),()=>{
          this.handleLiveSession()
        }
      );
      }
      else {
        if (responseJson?.errors === 'Lessons not found') {
          this.setState((prevState) => ({
            ...prevState,
            myClassesState: {
              ...prevState.myClassesState,
              studentLessonData: [],
            },
            pageLimitStudentLesson: 0
          }))
        }
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  updateLessonresponse=(apiRequestCallId: string,
    responseJson: any,
    errorReponse: string)=>{
      if (apiRequestCallId != null && apiRequestCallId === this.deliverLessonApiId) {
        this.enableLimitSnackbar(responseJson)
        if(responseJson.data){
          if(responseJson.data.attributes.live_lesson){
            this.props.navigation.navigate("LiveStreaming", {
              id:responseJson.data.attributes.lesson_code
            });
          }         
        }
      }
      if (apiRequestCallId != null && apiRequestCallId === this.deliverQuizApiId) {
        if(responseJson.data){
          if(responseJson.data.attributes.live_quiz){
            this.props.navigation.navigate("LiveStreaming", {
              id:responseJson.data.attributes.quiz_code
            });
          }         
        } 
        this.enableLimitSnackbar(responseJson)
      }

  }

  enableLimitSnackbar = (responseJson: {error: string}) => {
    if(responseJson && (responseJson.error === 'Request size exceeds the 2 GB limit.')){
      this.setState({
        isLimitModalOpen: true,
      })
    } 
  }

  updateClassresponse=(apiRequestCallId: string,
    responseJson: any)=>{
     if (apiRequestCallId != null && apiRequestCallId === this.deleteClassApiCallId) {
        if(responseJson.errors){
          this.setState({
            errorMsg:responseJson.errors,
            showError:true
          })
        }
        else{
          this.getClassList()
        }
       
      }  
    
  }

  handleDeleteStudentResponse(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: string) {
    if (
      apiRequestCallId != null &&
      apiRequestCallId === this.deleteStudentApiCallId
    ) {
      if (responseJson.errors) {
        return;
      }
      if (responseJson.id) {
        this.setState({ isDeleteSuccess: true })
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  handleSendReminderResponse(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: string) {
    if (
      apiRequestCallId != null &&
      apiRequestCallId === this.sendReminderApiCallId
    ) {
      if (responseJson.errors) {
        return;
      }
      if (responseJson.data) {
        this.setState({ isReminderSuccess: true })
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  handleCreateOrUpdateStudentResponse(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: string) {
    if (
      apiRequestCallId != null &&
      (apiRequestCallId === this.createStudentApiCallId || apiRequestCallId === this.updateStudentApiCallId)
    ) {
      if(responseJson && (responseJson.error === 'Request size exceeds the 2 GB limit.')){
        this.setState({
          isLimitModalOpen: true,
        })
      }
      if(responseJson.errors){
        if(responseJson.errors.email) {
          this.setCreateUpdateStudentModal(false);
          alert("The email has already been taken")
          }
          return
      }
      if (responseJson) {
        this.setCreateUpdateStudentModal(true);
        this.getStudentListByClass();
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  reminderCleanup() {
    this.setState({ isReminderSuccess: false })
  }
  deleteCleanup(id: string) {
    if (this.state.isDeleteSuccess) {
      const updateStudentList = this.state.myClassesState.studentList.filter(
        (student) => parseInt(student.id) !== parseInt(id)
      )
      this.setState(
        (prevState) => ({
          myClassesState: {
            ...prevState.myClassesState,
            studentList: updateStudentList,
          },
          isDeleteSuccess: false,
        }))
    }
  }
  debouncedSearchClassName = debounce(() => {
    this.getClassList();
  }, 1000);
  handleSearchClassName = (value: any, data: any) => {
    this.setState(
      (prevState) => ({
        myClassesState: {
          ...prevState.myClassesState,
          searchInputClassName: value,
        },
        pageNo: 1
      }),
      () => {
        this.debouncedSearchClassName();
      }
    );
  };

  debouncedSearchStudentClassName = debounce(() => {
    this.getStudentClasslistData();
  }, 1000);
  handleSearchStudentClassName = (event: any, data: any) => {
    const value = event?.target?.value || "";
    this.setState(
      (prevState) => ({
        myClassesState: {
          ...prevState.myClassesState,
          searchInputClassName: value,
        },
        pageNo: 1
      }),
      () => {
        this.debouncedSearchStudentClassName();
      }
    );
  };

  debouncedSearchLessonName = debounce(() => {
    this.getLessonListByClass();
  }, 1000);
  handleSearchLessonName = (value: any, data: any) => {
    this.setState(
      (prevState) => ({
        myClassesState: {
          ...prevState.myClassesState,
          searchInputLessonName: value,
        },
        pageNoLesson:1,
      }),
      () => {
        this.debouncedSearchLessonName();
      }
    );
  };
  debouncedSearchStudentLessonName = debounce(() => {
    this.getStudentLessonListByClass();
  }, 1000);
  handleSearchStudentLessonName = (event: any) => {
    const value = event?.target?.value || "";
  
  const filteredData = this.state.myClassesState.studentLessonData.filter((item) =>
    item.name.toLowerCase().includes(value)
  );
    this.setState(
      (prevState) => ({
        myClassesState: {
          ...prevState.myClassesState,
          searchInputStudentLessonName: value,
        },
        filteredStudentLessonData:filteredData
      })
    );
  };

  handlePageChangeClassList(event: any, page: number) {
    this.setState({
      pageNo: page
    },
      () => {
        this.getClassList();
      })
  }
  handlePageLessonList(event: any, page: number) {
    this.setState({
      pageNoLesson: page
    },
      () => {
        this.getLessonListByClass(page);
      })
  }
  handlePageStudentLessonList(event: any, page: number) {
    this.setState({ pageNoStudentLesson: page }, () => {
      this.getStudentLessonListByClass();
    });
  }
  showClassDetailView(e: any, data: any) {
    const foundItem = this.state.studentClassListData.find((item:any) => item.id === data.id);
    this.updateBreadcrumb(data)
    this.setState(
      (prevState) => ({
        selectedClassData: foundItem,
        myClassesState: {
          ...prevState.myClassesState,
          selectedView: MY_CLASSES_VIEWS.CLASS_DETAILS,
          selectedClass: data.id,
          teacherId: foundItem?.attributes?.teacher_id || prevState.myClassesState.teacherId,
        },
      })
    );
    window.localStorage.setItem("classId", data.id)
  }
  showStudentListView(data: any) {
    if (data) {      
      if (data.destination === MY_CLASSES_VIEWS.STUDENT_LIST) {
        this.getStudentListByClass()
        this.updateBreadcrumb(data)
        this.setState(
          (prevState) => ({
            myClassesState: {
              ...prevState.myClassesState,
              selectedView: MY_CLASSES_VIEWS.STUDENT_LIST,
            },
            pageNoStudent: 1
          })
        );
      }
      if (data.destination === MY_CLASSES_VIEWS.CLASS_LIBRARY) {
        this.updateBreadcrumb(data)
        this.setState(
          (prevState) => ({
            myClassesState: {
              ...prevState.myClassesState,
              selectedView: MY_CLASSES_VIEWS.CLASS_LIBRARY,
            },
          })
        );
      }
      if (data.destination === MY_CLASSES_VIEWS.REPORT) {
        this.updateBreadcrumb(data)
        this.setState(
          (prevState) => ({
            myClassesState: {
              ...prevState.myClassesState,
              selectedView: MY_CLASSES_VIEWS.REPORT,
            },
          })
        );
      }
      if (data.destination === MY_CLASSES_VIEWS.ASSIGNMENTS) {
        this.handleParamsNavigation("TeacherLibraryPractice", {
          type: "Assignments",
          id: this.state.myClassesState.selectedClass
        });
      }
    } else {
      this.setState(
        (prevState) => ({
          myClassesState: {
            ...prevState.myClassesState,
            selectedView: MY_CLASSES_VIEWS.STUDENT_LIST,
          },
          pageNoStudent: 1
        })
      );
    }
  }

  navigateToAssignTasks = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "AssingnNewTask");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  showStudentLessonListView(data: any){
    if (data) {
      if (data.destination === MY_CLASSES_VIEWS.JOIN_LIVE_SESSION) {
        this.updateBreadcrumb(data);
        this.setState(
          (prevState) => ({
            ...prevState,
            myClassesState: {
              ...prevState.myClassesState,
              selectedView: MY_CLASSES_VIEWS.JOIN_LIVE_SESSION,
              searchInputStudentLessonName: "",
              studentLessonData: [],
            },
            pageNoStudentLesson: 1,
            pageLimitStudentLesson: 1,
          }),
          () => {
            this.setState({
              apiCounts:2
            })
            this.getStudentLessonListByClass();
            this.getliveQuizListByClass()
          }
        );
      }
    }
  }

  showLessonListView(data: any) {
    if (data) {
      if (data.destination === CLASS_OPTIONS.LESSONS) {
        this.getLessonListByClass()
        this.updateBreadcrumb(data)
        this.setState(
          (prevState) => ({
            myClassesState: {
              ...prevState.myClassesState,
              selectedView: CLASS_OPTIONS.LESSONS,
            },
            pageNoLesson: 1
          })
        );
      }
      else if (data.destination === CLASS_OPTIONS.QUIZZES){
        this.updateBreadcrumb(data)
        this.getQuizListByClass()
        this.setState(
          (prevState) => ({
            myClassesState: {
              ...prevState.myClassesState,
              selectedView: CLASS_OPTIONS.QUIZZES,
            },
            pageNoQuiz: 1
          })
        );
      }
      else if (data.destination === CLASS_OPTIONS.ASSIGNMENTS){
        this.updateBreadcrumb(data)
        this.getAssignmentListByClass()
        this.setState(
          (prevState) => ({
            myClassesState: {
              ...prevState.myClassesState,
              selectedView: CLASS_OPTIONS.ASSIGNMENTS,
            },
            pageNoAssignment: 1
          })
        );
      }
    }
  }
  showAddStudentView(toggle: string, id: string) {
    this.getFormClassList();
    if (toggle == "EDIT") {
      const studentDetails = this.state.myClassesState.studentList.find(student => {
        return student.id === id
      })
      this.setState(
        (prevState) => ({
          myClassesState: {
            ...prevState.myClassesState,
            selectedView: MY_CLASSES_VIEWS.ADD_STUDENT,
            studentDetails: studentDetails
          },
        })
      );
    } else {
      this.setState(
        (prevState) => ({
          myClassesState: {
            ...prevState.myClassesState,
            selectedView: MY_CLASSES_VIEWS.ADD_STUDENT,
            studentDetails: {}
          },
        })
      );
    }
  }
  updateBreadcrumb(data: any) {
    const { view, breadcrumbDisplayName, destination } = data
    const location = {
      renderView: view,
      breadcrumbDisplayName: breadcrumbDisplayName
    }
    this.setState({
      destination: destination,
      searchInputStudentName: ""
    })
    this.locations.push(location)
  }
  updateStateForRedirection(selectedView: string = "", destination: string = "") {
    this.setState(
      (prevState) => ({
        searchInputStudentName: "",
        myClassesState: {
          ...prevState.myClassesState,
          selectedView: selectedView,
          searchInputLessonName: "",
          searchInputQuizName: "",
          searchInputAssignmentName: ""
        },
        destination: destination ? destination : prevState.destination
      })
    );
  }
  redirect(renderView: string) {
    if (renderView === MY_CLASSES_VIEWS.CLASS_DETAILS) {
      const location = this.locations[this.locations.length - 1]
      const location2 = this.locations[this.locations.length - 2]
      if(renderView!==location.renderView){
        const tempData = this.locations.slice(0,1);
        this.locations = tempData;
        this.updateStateForRedirection(
          MY_CLASSES_VIEWS.CLASS_DETAILS,
          location2.breadcrumbDisplayName
        );
      }else{
        this.locations.pop();
        this.updateStateForRedirection(
          MY_CLASSES_VIEWS.CLASS_DETAILS,
          location.breadcrumbDisplayName
        );
      }
    }
    if (renderView === MY_CLASSES_VIEWS.MY_CLASSES) {
      this.locations = [];
      this.updateStateForRedirection(MY_CLASSES_VIEWS.MY_CLASSES);
    }
    if (renderView === MY_CLASSES_VIEWS.CLASS_LIBRARY) {
      const location = this.locations[this.locations.length - 1]
      this.locations.pop();
      this.updateStateForRedirection(
        MY_CLASSES_VIEWS.CLASS_LIBRARY,
        location.breadcrumbDisplayName
      );
    }
    if (renderView === MY_CLASSES_VIEWS.REPORT) {
      const location = this.locations[this.locations.length - 1]
      this.locations.pop();
      this.updateStateForRedirection(
        MY_CLASSES_VIEWS.REPORT,
        location.breadcrumbDisplayName
      );
    }
  }
  createClass(data: any) {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const formData = new FormData()
    formData.append("class_name", data.className ? data.className : "");
    formData.append("start_date", data.start_date ? data.start_date : "");
    formData.append("end_date", data.end_date ? data.end_date : "");
    if (data.attachment)
      formData.append("attachment", data.attachment);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    this.apiCreateClassCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createClass}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleCreateClassResponse(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: string) {
    if (apiRequestCallId != null && apiRequestCallId === this.apiCreateClassCallId) {
      if (responseJson.errors?.length > 0) {
        return
      }
      if(responseJson && (responseJson.error === 'Request size exceeds the 2 GB limit.')){
        this.setState({
          isLimitModalOpen: true,
        })
      }
      if (responseJson) {
        this.getClassList()
      }
      else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  handlePageChangeStudent(event: any, page: number) {
    this.setState({
      pageNoStudent: page,
    });
    this.getStudentListByClass(page);
  }

  debouncedSearchStudent = debounce(() => {
    this.getStudentListByClass()
  }, 1000);
  handleSearchStudent = (value: any, data: any) => {
    this.setState(
      (prevState) => ({
        searchInputStudentName: value,
        pageNoStudent:1
      }),
      () => {
        this.debouncedSearchStudent();
      }
    );
  };
  
  redirectToLiveLessonStreamView(e:any, id: any){
    this.deliverLesson(Number(id))
  }
  setCreateUpdateStudentModal(value:boolean){
    this.setState({
      studentApiError:value
    })
  }
  showReportView(e: any, data: any) {
    this.updateBreadcrumb(data)
    this.setState(
      (prevState) => ({
        myClassesState: {
          ...prevState.myClassesState,
          selectedView: MY_CLASSES_VIEWS.REPORT,
        },
      })
    );
  }
  openReportModal(){
    this.setState({
      openReportModal:true
    })
    removeStorageData("startDateRange")
    removeStorageData('endDateRange')
  }
  closeReportModal(){
    this.setState({
      openReportModal:false,
      internalDate: null
    })
  }
  showSummaryReportListView(e: any, data: any) {
    this.updateBreadcrumb(data)
    this.setState(
      (prevState) => ({
        myClassesState: {
          ...prevState.myClassesState,
          selectedView:data.destination== "Detailed Report"?REPORT_OPTIONS.DETAILED_REPORT:REPORT_OPTIONS.SUMMARTY_REPORT,
        },
      })
    );
  }
  
  deliverLesson = (id:number) => {
    this.setState({
      isLoading:true
    })
   
    const header = {
      "Content-Type":configJSON.validationApiContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const body={
        "id": id,
        "live_lesson": true
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deliverLessonApiId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateLiveLession}`
    );
        
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getAssignmentListByClass(page = 1): boolean {
    this.setState({ isLoading: true })
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssignmentListByClassApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.fetchAssignmentList}?search=${this.state.myClassesState.searchInputAssignmentName}&class_id=${this.state.myClassesState.selectedClass}&teacher_id=${this.state.myClassesState.teacherId}&per_page=10&page=${this.state.pageNoAssignment}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleGetAssignmentListByClassResponse(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: string) {
    if (apiRequestCallId != null && apiRequestCallId === this.getAssignmentListByClassApiCallId) {
      if (responseJson?.message) {
        this.setState((prevState) => ({
          myClassesState: {
            ...prevState.myClassesState,
            assignmentList: [],
          },
          pageLimitAssignment: 0
        }));
        return
      }
      if (responseJson?.assignments?.data?.length > 0) {
        const assignmentList = responseJson?.assignments?.data.map((data: any) => {
          return {
            id: data.id,
            assignmentName: data.attributes.assignment_name,
            assignmentStatus: data.attributes.assignment_status,
          }
        })
        this.setState((prevState) => ({
          myClassesState: {
            ...prevState.myClassesState,
            assignmentList: assignmentList,
          },
          pageLimitAssignment: responseJson.meta.total_page
        }));
      }
      else {
        if (responseJson?.errors === 'Assignments not found') {
          this.setState((prevState) => ({
            ...prevState,
            isLoading: false,
            myClassesState: {
              ...prevState.myClassesState,
              assignmentList: [],
            },
            pageLimitAssignment: 0
          }))
        }
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  debouncedSearchAssignmentName = debounce(() => {
    this.getAssignmentListByClass();
  }, 1000);

  handleSearchAssignmentName = (value: any, data: any) => {
    this.setState(
      (prevState) => ({
        myClassesState: {
          ...prevState.myClassesState,
          searchInputAssignmentName: value,
        },
        pageNoAssignment:1,
      }),
      () => {
        this.debouncedSearchAssignmentName();
      }
    );
  };

  handlePageAssignmentList(event: any, page: number) {
    this.setState({
      pageNoAssignment: page
    },
    () => {
      this.getAssignmentListByClass(page);
    })
  }

  getQuizListByClass(page = 1): boolean {
    this.setState({ isLoading: true })
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQuizListByClassApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.fetchQuizList}?search=${this.state.myClassesState.searchInputQuizName}&class_id=${this.state.myClassesState.selectedClass}&teacher_id=${this.state.myClassesState.teacherId}&per_page=10&page=${this.state.pageNoQuiz}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleGetQuizListByClassResponse(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: string) {
    if (apiRequestCallId != null && apiRequestCallId === this.getQuizListByClassApiCallId) {
      if (responseJson?.message) {
        this.setState((prevState) => ({
          myClassesState: {
            ...prevState.myClassesState,
            quizList: [],
          },
          pageLimitQuiz: 0
        }));
        return
      }
      if (responseJson?.data?.length > 0) {
        const quizList = responseJson?.data.map((data: any) => {
          return {
            id: data.id,
            quizName: data.attributes.quiz_name,
            quizCode: data.attributes.quiz_code,
            totalStage: data?.attributes?.total_stage || 0,
          }
        })
        this.setState((prevState) => ({
          myClassesState: {
            ...prevState.myClassesState,
            quizList: quizList,
          },
          pageLimitQuiz: responseJson.meta.total_page
        }));
      }
      else {
        if (responseJson?.errors === 'Quizes list not found') {
          this.setState((prevState) => ({
            ...prevState,
            isLoading: false,
            myClassesState: {
              ...prevState.myClassesState,
              quizList: [],
            },
            pageLimitQuiz: 0
          }))
        }
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  debouncedSearchQuizName = debounce(() => {
    this.getQuizListByClass();
  }, 1000);

  handleSearchQuizName = (value: any, data: any) => {
    this.setState(
      (prevState) => ({
        myClassesState: {
          ...prevState.myClassesState,
          searchInputQuizName: value,
        },
        pageNoQuiz:1,
      }),
      () => {
        this.debouncedSearchQuizName();
      }
    );
  };

  handlePageQuizList(event: any, page: number) {
    this.setState({
      pageNoQuiz: page
    },
      () => {
        this.getQuizListByClass(page);
      })
  }

  deliverQuiz = (quizId:number) => {
    this.setState({
      isLoading:true
    })
   
    const header = {
      "Content-Type":configJSON.validationApiContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const body={
        "quiz_id": quizId,
        "live_quiz": true
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deliverQuizApiId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateQuiz}`
    );
        
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PATCH
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  
  }

  handleOpenNoMobileModal = () => {
    this.setState({
      openNoMobileModal:true
    })
  };

  handleCloseNoMobileModal = () => {
    this.setState({
      openNoMobileModal:false
    })
  };
  deleteClass=(id:number)=> {
    this.setState({isLoading:true})
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
      this.deleteClassApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.deleteClassApi}?id=${id}`
      );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETE
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleCloseError = () => {
    this.setState({showError:false})
  }

  handleNavigation = (name:string) => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), name);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  handleParamsNavigation = (name:string, params: any) => {
    window.localStorage.setItem("searchInputClassName", this.state?.myClassesState?.searchInputClassName);
    this.props.navigation.navigate(name, params);
  };

  handleCloseLimitSnackbar = () => {
    this.setState({
      isLimitModalOpen: false,
    })
  }

  setInternalDate = (date:any) => {
    this.setState({
      internalDate:date
    })
  }
  getliveQuizListByClass(page = 1): boolean {
    this.setState({ isLoading: true })
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getliveQuizListByClassApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.fetchQuizList}?search=${this.state.myClassesState.searchInputStudentLessonName}&class_id=${this.state.myClassesState.selectedClass}&teacher_id=${this.state.myClassesState.teacherId}&is_live=${true}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleGetStudentQuizListByClassResponse(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: string) {
    if (apiRequestCallId != null && apiRequestCallId === this.getliveQuizListByClassApiCallId) {
     
      if (responseJson?.data?.length > 0) {
        const quizList = responseJson?.data.map((quizData: any) => {
          return {
            id: quizData.id,
            name: quizData.attributes.quiz_name,
            code: quizData.attributes.quiz_code,
            status: quizData.attributes.status,
            live: quizData.attributes.live_quiz,
            stages: quizData.attributes.total_stage,
          }
        });
        this.setState((prevState) => ({
          ...prevState,
          liveQuizData:quizList,
          }),()=>{
            this.handleLiveSession()
          });
      }
      else {
        if (responseJson?.errors === 'Quizes list not found') {
          this.setState((prevState) => ({
            ...prevState,
            liveQuizData:[]
          }))
        }
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  handleLiveSession=()=>{
    let combinedArray : StudentLesson[]= [];
    combinedArray = [...this.state.liveLessonData, ...this.state.liveQuizData]
    this.setState((prevState) => ({
      ...prevState,
      myClassesState: {
        ...prevState.myClassesState,
        studentLessonData: combinedArray,
      },
      filteredStudentLessonData:combinedArray
}));
  }
  // Customizable Area End
}
