export const navLogo = require("../assets/nav_logo.png")
export const accountsIcon = require("../assets/accounts_icon.svg")
export const accountsIconActive = require("../assets/accounts_icon_active.svg")
export const dashboardIconActive = require("../assets/dashboard_icon_active.svg")
export const peopleManagementIcon = require("../assets/peopleManagement_icon.svg")
export const reportsIcon = require("../assets/reports_icon.svg")
export const reportsActiveIcon = require("../assets/reports_icon_active.svg")
export const userGroupsIcon = require("../assets/userGroups_icon.svg")
export const calenderIcon = require("../assets/calender_icon.svg")
export const libraryIcon = require("../assets/library_icon.svg")
export const notification = require("../assets/notification.svg");
export const profile = require("../assets/userimg.png");
export const calenderIconActive = require("../assets/calender_active.svg");
export const profileSettingActive = require("../assets/profile_setting_active.svg");
export const profileSettingInactive = require("../assets/profile_setting_inactive.svg");
export const userGroupsIconActive = require("../assets/usergroups-active.svg");
export const profileIcon = require("../assets/profileIcon.svg");
export const chnagePasswordIcon = require("../assets/chnagePasswordIcon.svg");
export const logoutIcon = require("../assets/logoutIcon.svg");
export const peopleManagementActive = require("../assets/people_management_active.svg");
export const userLibraryActive = require("../assets/user_library_active.svg");
export const closeIcon= require("../assets/image_Close.svg")
export const studentIcon= require("../assets/student_icon.svg")
export const teacherIcon= require("../assets/teacher_icon.svg")
export const searchIcon = require("../assets/searchIcon.png")
export const feebackInactive = require("../assets/feedback_inactive.svg")
export const supportInactive = require("../assets/support_inactive.svg")
export const join_live_class = require("../assets/join_live_class.svg")
export const join_live_class_active = require("../assets/join_live_class_active.svg")
export const engagemoIcon = require("../assets/engagemo_icon.png");
export const errorIcon = require("../assets/error_icon.png");
export const eSymbol = require("../assets/e_symbol.png");
export const groupTick = require("../assets/group_tick.png");
export const taskIcon = require("../assets/view_mytasks.svg")
export const taskActiveIcon = require("../assets/view_mytasks.png")
export const trashIcon = require("../assets/image_delete.svg")
export const closeModalIcon = require("../assets/close-icon.svg")
export const downloadIcon = require("../assets/download-icon.svg")
export const AssignTaskIcon = require("../assets/view_assign_tasks.svg")
export const mydownloads = require("../assets/download.png")
export const downloadIconInactive = require("../assets/download_icon_inactive.svg")
export const AssignTaskDeActive = require("../assets/assignTaskIcon.svg")
export const closeNotificationIcon = require("../assets/view_close.svg")
export const notificationMockImg = require("../assets/mock_notification_img.svg")
export const imageLogoutDark = require("../assets/image_logout_dark.svg")
export const viewDenied = require("../assets/view_denied.svg")
export const groupLogo = require("../assets/group_logo.svg")
export const backtohome = require("../assets/button_back.svg")
export const discardIcon = require("../assets/discard_icon.svg")
export const successIcon = require("../assets/tick_icon.svg")
export const lessonLibIcon = require("../assets/image_Group_group2.svg")
export const assigmenTsave = require("../assets/assignment_save.svg")
export const viewassignment = require("../assets/view_assignment_symbol.svg")
export const viewassignment2 = require("../assets/view_assignment_symbol_.svg")
export const savedIcon = require("../assets/savedIcon.svg")
export const crossicon1=require("../assets/crossicon1.png")
export const viewUpload = require("../assets/view_upload.svg")
export const deleteComment = require("../assets/group_comments.svg");
export const copyicon=require("../assets/copyicon.png")
export const noVideo = require("../assets/noVideo.jpeg")
export const logo = require("../assets/logo.svg");

