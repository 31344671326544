import React from "react";
import { Box, Typography, Grid, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GenericButton from "../../../../components/src/GenericButton.web";
const useStyles = makeStyles((theme) => ({
  vdheader: {
    alignItems: "center",
    width: "100%",
  },
  vdpopupContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#2C2C2E",
    fontWeight: 400,
    marginBottom: "10px",
  },
  vdsubtitle: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#7E7D7D",
    fontWeight: 400,
    width: "180px",
    overflow: "hidden",
    whiteSpace: "normal",
    wordBreak: "break-word",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      width: "100%",
    },
  },
  paper: {
    position: "absolute",
    width: "891px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display: "flex",
    justifyContent: "center",
    aignItems: "center",
    maxHeight:"95%",
    overflowY:"scroll",
    padding: "15px 0",
      [theme.breakpoints.down("sm")]: {
      width: "80vw",
      padding: "10px",
    },
  },
  vdcontainer: {
    position: "relative",
  },
  vdheading: {
    fontFamily: "Poppins",
    fontSize: "24px",
    color: "#2B3D7D",
    fontWeight: 500,
    marginBottom: "20px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  vdcrossIcon: {
    position: "absolute",
    top: "10px",
    right: "10px",
  },
  detailWrapper: {
    padding:"60px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  }
  
}));
function getModalStyle() {
    const top = 50;
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
interface Classes{
    id:string,
    name:string
  }
const ViewStudentDetailModal = (props: any) => {
  const { handleCloseModal,contextMenuData } = props;
  const dataList = [
    { title: "Student Name", value: contextMenuData.name},
    { title: "Class", value: contextMenuData.studentData.classes.map((item:Classes)=>item.name).join(", ")},
    { title: "Date Created", value: contextMenuData.studentData.dateCreated },
    { title: "Profile Status", value: contextMenuData.studentData.profileStatus },
    { title: "Gender", value: contextMenuData.studentData.gender },
    { title: "Date of Birth", value: contextMenuData.studentData.birthDate },
    { title: "Contact Number", value: contextMenuData.studentData.phone },
    { title: "Email ID", value: contextMenuData.studentData.email },
  ];
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  return (
    <Box style={modalStyle} className={classes.paper}>
      <Box className={classes.vdcontainer}>
        <Box className={classes.vdpopupContent}>
          <Box className={classes.vdheader}>
            <Typography className={classes.vdheading}>
              Team Member Details
            </Typography>
            <Divider />
          </Box>

          <Box className={classes.detailWrapper}>
            <Grid container spacing={3}>
              {dataList.map((data: any) => (
                <Grid item md={3}>
                  <Typography className={classes.title}>
                    {data.title}
                  </Typography>
                  <Typography className={classes.vdsubtitle}>
                    {data.value}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
          <GenericButton
           data-test-id="close-modal"
            type="COLORED"
            label="Close"
            handleClick={() => handleCloseModal()}
            customStyle={{ width: "165px", height: "50px" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ViewStudentDetailModal;
