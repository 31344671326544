import React from "react";
import { Box, Typography } from "@material-ui/core";
import { searchIcon } from "../assets";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(() => ({
  searchbarBox: {
    display: "flex",
    gap: "4px",
    position: "relative",
    marginTop: "20px",
  },
  searchbarIconbox: {
    position: "absolute",
    top: "13px",
    left: "10px",
  },
  searchFilterinput: {
    width: "538px",
    height: "52px",
    borderRadius: "8px",
    border: "1px solid rgba(43,61,125,0.39)",
    background: "#fff",
    paddingLeft: "40px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    "@media (max-width: 600px)": {
        width:"100%"
      },
  },
  searchbarTitle: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 600,
    color: "#000000",
  },
}));
const Searchbar = (props: any) => {
  const { handleSearchInput, searchInput, role } = props;
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.searchbarTitle}>
        {role === "teacher" ? "Teachers" : "Students"}
      </Typography>
      <Box className={classes.searchbarBox}>
        <Box className={classes.searchbarIconbox}>
          <img src={searchIcon} alt="Search Icon" />
        </Box>
        <input
          className={classes.searchFilterinput}
          placeholder={
            role === "teacher"
              ? "Search by teacher name"
              : "Search by student name"
          }
          onChange={(e) => handleSearchInput(e, role)}
          value={searchInput}
        />
      </Box>
    </>
  );
};

export default Searchbar;
