// Customizable Area Start
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Modal,Typography} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";


const useStyles = makeStyles(() => ({
  modalInnerBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    width: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));

interface CustomLoaderProps {
  text: string
}
const WebLoader = (props: CustomLoaderProps) => {  
  const classes = useStyles();
  return (
    <Modal open={true} data-test-id="loading-modal-upload">
      <Box className={classes.modalInnerBox}>
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} >
          <CircularProgress />
          <Typography style={{textAlign:"center", marginTop:"10px",marginLeft:"12px"}}>
          {props.text}
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default WebLoader;
// Customizable Area End
