import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleCloseModal: any;
  open: {
    show: boolean,
    type: string,
    id: any,
    name: string,
    itemType:string
  };
  handleShowNotesIcon: any;
  selectedType:string;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  role: string | null;
  showDeletePopUp: boolean;
  showSaveNotesSuccessModal: boolean,
  notesList: any,
  lessonName:string,
  itemType:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiAddNotesId: string = "";
  apiGetNoteDataCallId: string = "";
  apiUpdateNoteDataId: string = "";
  apiDeleteNoteCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      role: "",
      showDeletePopUp: false,
      showSaveNotesSuccessModal: false,
      notesList: {
        note: ""
      },
      lessonName:"",
      itemType:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let error = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const callId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      this.handleCreateNotesResponse(callId, response, error)
      this.handleNotesForLessonResponse(callId, response, error)
      this.handleUpdateNotesResponse(callId,response,error);
      this.handleDeleteNoteResponse(callId, response,error);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  componentDidUpdate = (prevProps: Props, prevState: S) => {
    if (this.props.open.type !== prevProps.open.type && this.props.open.type === "Edit") {
      this.getNotesData();
    }
  };

  addNotes(id: any, notes: string) {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let body;
    if(this.props.open.itemType==="lesson"){
      body = {
        lesson_id: id,
        note: notes
      }
    }else if(this.props.open.itemType==="quiz"){
      body = {
        quiz_id: id,
        note: notes
      }
    }else if(this.props.open.itemType==="assignment"){
      body = {
        assignment_id: id,
        note: notes
      }
    }else if(this.props.open.itemType==="folder" && this.props.selectedType==="Lessons"){
      body = {
        lesson_folder_id: id,
        note: notes
      }
    }else if(this.props.open.itemType==="folder" && this.props.selectedType==="Quizzes"){
      body = {
        quiz_folder_id: id,
        note: notes
      }
    }else {
      body = {
        assignment_folder_id: id,
        note: notes
      }
    }
     
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    this.apiAddNotesId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addNotesApi}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleCreateNotesResponse(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: string) {
    if (
      apiRequestCallId != null &&
      (apiRequestCallId === this.apiAddNotesId)
    ) {
      if (responseJson.data) {
        this.setState({ lessonName:this.props.open.name})
        this.handleOpenSavedSuccessModal()
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  handleOpenDeleteModal = () => {
    this.props.handleCloseModal()
    this.setState({ showDeletePopUp: true, lessonName:this.props.open.name,itemType:this.props.open.itemType })
  }

  handleCloseDeleteModal = () => {
    this.setState({ showDeletePopUp: !this.state.showDeletePopUp,lessonName:"",itemType:"" });
    this.setState((prevState) => ({
      notesList: {
        ...prevState.notesList,
        note: "",
      }
    }));
  };
  
  handleOpenSavedSuccessModal = () => {
    this.props.handleCloseModal()
    this.setState({ showSaveNotesSuccessModal: true})
  }

  handleCloseSavedSuccessPopUp = () => {
    this.setState({ showSaveNotesSuccessModal: !this.state.showSaveNotesSuccessModal,lessonName:"" });
    this.setState((prevState) => ({
      notesList: {
        ...prevState.notesList,
        note: "",
      }
    }));
    this.props.handleShowNotesIcon()
  };

  handleChangeNotesInput = (data: string) => {
    this.setState((prevState) => ({
      notesList: {
        ...prevState.notesList,
        note: data,
      }
    }));
  }

  async componentDidMount() {
    super.componentDidMount();
    if (window.localStorage.getItem("authToken") === null) {
      this.props.navigation.navigate("EmailAccountLogin");
      return;
    }
    this.setState({
      role: window.localStorage.getItem("role"),
    })
  }
  getNotesData() {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetNoteDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getNotesApiEndPoint}`
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updateNoteData(id: any, notes: string) {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const body = {
      id: id,
      note: notes
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    this.apiUpdateNoteDataId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addNotesApi}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  deleteNoteApi() {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteNoteCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteNoteApiEndPoint}?id=${this.state.notesList.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETE
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleReturnLessonId = (note:any) => {
    return note.attributes.lesson_id || note.attributes.lesson_folder_id || note.attributes.quiz_id || note.attributes.assignment_id || note.attributes.assignment_folder_id || note.attributes.quiz_folder_id
  }

  handleNotesForLessonResponse(apiRequestCallId: string, responseJson: any, errorReponse: any) {
    if (
      apiRequestCallId != null &&
      apiRequestCallId === this.apiGetNoteDataCallId
    ) {
      if (responseJson) {
        const noteData = responseJson.data.filter((obj: any) => this.handleReturnLessonId(obj) === Number(this.props.open.id))
        this.setState({
          notesList: {
            id: noteData[0].id,
            note: noteData[0].attributes.note
          }
        });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  handleUpdateNotesResponse(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: string) {
    if (
      apiRequestCallId != null &&
      (apiRequestCallId === this.apiUpdateNoteDataId)
    ) {
      if (responseJson.data) {
        this.setState({ lessonName:this.props.open.name})
        this.handleOpenSavedSuccessModal()
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  handleDeleteNoteResponse(
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: string) {
    if (
      apiRequestCallId != null &&
      (apiRequestCallId === this.apiDeleteNoteCallId)
    ) {
      if (responseJson.message==="Delete Successfully.") {
        this.handleCloseDeleteModal()
        this.props.handleShowNotesIcon()
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

 handleCloseAddEditModal = ()=>{
  this.props.handleCloseModal()
  if(!this.state.showSaveNotesSuccessModal){
    this.setState((prevState) => ({
      notesList: {
        ...prevState.notesList,
        note: "",
      }
    }));
   }
  }
  // Customizable Area End
}
