import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import StaticCanvas from "../../../../components/src/StaticCanvas.web";
import HighlighterList from "../../../annotations/src/Teacher/components/HighlighterList.web";
const useStyles = makeStyles(() => ({
  title: {
    marginBottom: "20px",
    fontSize: "18px",
    color: "#2C2C2E",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
  titleCanvas: {
    paddingTop:"60px"
  },
  description: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 400,
  },
  addTextLayout: {
    display: "flex",

    padding: "30px 60px",
    marginBottom: "60px"
  },
  canvasContainer:{
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    position:"relative"
  },
  addTextLayoutD: {
    padding: "30px 60px",
  },
  descWithCanvas: {
    paddingTop:"90px",
    paddingLeft: "25px"
  }
}));
const AddTextLive = (props: any) => {
  const { stage,updateHighlightToFirebase,highlightJson,isStudent,highlightJsonDesc } = props;
  const classes = useStyles();
  const [showTooltip, setShowTooltip] = React.useState<boolean>(false);
  const [annotateIsTitle, setAnnotateIsTitle] = React.useState<boolean>(false);
  const [tooltipPos, setTooltipPos] = React.useState({ x: 0, y: 0 });
  const [highlightId,setHighlightId]=React.useState(0)

  const iscanvasExist = stage.attributes.canvas_data
  let hasCanvasDataTitle = undefined; 
  let hasCanvasDataDesc = undefined; 
  let canvasSize = undefined; 
  let canvasDescSize = undefined; 

  if(iscanvasExist){
    const parsedData = typeof iscanvasExist == "object"?iscanvasExist:JSON.parse(iscanvasExist)
    hasCanvasDataTitle = parsedData.canvas
    hasCanvasDataDesc = parsedData.AddTextCanvas
    if(parsedData.canvasSize)
    {
      canvasSize = JSON.parse(parsedData.canvasSize)
    }
    if(parsedData.addTextCanvasSize)
    {
      canvasDescSize = JSON.parse(parsedData.addTextCanvasSize)
    }
  }

  const handleMouseUp = (isTitle:boolean) => {
    const selection = window.getSelection();
    if (!selection || selection.isCollapsed) {
      setShowTooltip(false);
      return;
    }
    const text = selection.toString();
    if (text) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      const scrollContainer = document.querySelector(`.${classes.canvasContainer}`);
      const scrollX = scrollContainer ? scrollContainer.scrollLeft : 0;
      const scrollY = scrollContainer ? scrollContainer.scrollTop : 0;
      const tooltipWidth = 640; 
      let tooltipX = rect.left + window.scrollX - tooltipWidth + scrollX;
      let tooltipY = rect.top + window.scrollY - 50 + scrollY;
      const windowWidth = window.innerWidth;
      if (tooltipX + tooltipWidth > windowWidth) {
        tooltipX = windowWidth - tooltipWidth - 10;
      }
  
      if (tooltipX < 0) {
        tooltipX = 10;
      }
  
      setShowTooltip(true);
      setAnnotateIsTitle(isTitle);
      setTooltipPos({ 
        x: tooltipX, 
        y: tooltipY
      });
    } else {
      setShowTooltip(false);
    }
  };


  const updateHighlightStorage = (data:any) => {
    const storageKey = annotateIsTitle?"liveAnnotation":"liveAnnotationDesc"
    let highlightItems:any=  window.localStorage.getItem(storageKey);
    highlightItems = highlightItems ? JSON.parse(highlightItems) : [];
    highlightItems.push(data);
    window.localStorage.setItem(storageKey, JSON.stringify(highlightItems));
    updateHighlightToFirebase(JSON.stringify(highlightItems),annotateIsTitle)
  };

  const applyHighlight = (color:string) => {

    const selection = window.getSelection();
    if (!selection ||!selection.rangeCount || selection.isCollapsed) {
      setShowTooltip(false);
      return;
    }
    const contentElement:any=document.getElementById(annotateIsTitle?"addTextTitle":"addTextDesc")
    const text=selection.toString()
    const range = selection.getRangeAt(0);
    const preRange=document.createRange();
    preRange.setStart(contentElement,0)
    preRange.setEnd(range.startContainer, range.startOffset)
    const startOffset=preRange.toString().length;
    const endOffset=startOffset+ text.length
    const selectedContent = range.extractContents();
    const spans = selectedContent.querySelectorAll('span');
    spans.forEach((span:any) => {
      const parent = span.parentNode;
      const textNode = document.createTextNode(span.textContent);
      parent.insertBefore(textNode, span);
      parent.removeChild(span);
    });
    const highlightSpan = document.createElement('span');
    highlightSpan.style.backgroundColor = color;
    highlightSpan.appendChild(selectedContent);
    highlightSpan.setAttribute('data-highlight-id', `highlight-${highlightId}`);
    highlightSpan.appendChild(selectedContent);
    range.insertNode(highlightSpan);
    let data={
      id: `highlight-${highlightId}`,
      lesson_markup_tools_id: stage.id,
      highlight_text: text,
      colour_code: color,
      offset: startOffset,
      end_offset: endOffset,
    }
    updateHighlightStorage(data); 
    setHighlightId(highlightId+1)
    selection.removeAllRanges();
    setShowTooltip(false);
  }

  const findTextNode = (parentNode: any, offset: number) => {
    if(!parentNode) return null;
    let currentOffset = 0;
  
    const traverseNodes = function (node: any): any {
        if (node.nodeType === Node.TEXT_NODE) {
        const nextOffset = currentOffset + node.textContent.length;
        if (offset >= currentOffset && offset <= nextOffset) {
          return { node: node, offset: offset - currentOffset };
        }
        currentOffset = nextOffset;
      } else {
        for (let child of node.childNodes) {
          const result = traverseNodes(child);
          if (result) return result;
        }
      }
      return null;
    };
    return traverseNodes(parentNode);
    
  }

  const applyHighlightDirectly= (highlight:any,isTitle:boolean) => {
    const contentElement = document.getElementById(isTitle?"addTextTitle":"addTextDesc");
      const startTextNode = findTextNode(contentElement, highlight.offset);
      const endTextNode = findTextNode(contentElement, highlight.offset + highlight.highlight_text.length);
  
      if (startTextNode && endTextNode) {
        const range = document.createRange();
        range.setStart(startTextNode.node, startTextNode.offset);
        range.setEnd(endTextNode.node, endTextNode.offset);
        
        const highlightSpan = document.createElement('span');
        highlightSpan.style.backgroundColor = highlight.colour_code;
       
        highlightSpan.setAttribute('data-highlight-id', highlight.id);
        highlightSpan.textContent=range.toString()
        range.deleteContents();
        range.insertNode(highlightSpan);
    }
  };

  const handleTitleUp = () => {
    handleMouseUp(true)
  }
  const handleDescUp = () => {
    handleMouseUp(false)
  }

  useEffect(()=>{
    if(isStudent) return
    
    const contentElement = document.getElementById("addTextTitle");
    contentElement?.addEventListener('mouseup', handleTitleUp);
    const descElement = document.getElementById("addTextDesc");
    descElement?.addEventListener('mouseup', handleDescUp);
      return () => {
        contentElement?.removeEventListener('mouseup', handleTitleUp);
        descElement?.removeEventListener('mouseup', handleDescUp);
      };
  },[])
  
  useEffect(()=>{
    if(!isStudent) return
    if(highlightJson && highlightJson!=""){
      try{
        const highlightItems = JSON.parse(highlightJson) as any[]
        highlightItems.forEach((item)=>applyHighlightDirectly(item,true))
      }
      catch{

      }
    }
  },[highlightJson])

  useEffect(()=>{
    if(!isStudent) return
    if(highlightJsonDesc && highlightJsonDesc!=""){
      try{
        const highlightItems = JSON.parse(highlightJsonDesc) as any[]
        highlightItems.forEach((item)=>applyHighlightDirectly(item,false))
      }
      catch{

      }
    }
  },[highlightJsonDesc])

  return (
    <>
    {  hasCanvasDataTitle && <Box style={{position:"absolute",width:"100%",height:"100%",}}><StaticCanvas canvasData={hasCanvasDataTitle} id="canvasId" canvasSize={canvasSize}/></Box>}
    <Box className={classes.addTextLayout}>
      <Box id="addTextTitle">
        <Box
          className={classes.title}
          dangerouslySetInnerHTML={{ __html: stage.attributes.title }}
        />
      </Box>
    </Box>
    <Box style={{position:"relative",width:"100%",height:"100%"}}  data-test-id="handleShowResultId">
    {  hasCanvasDataDesc && <Box style={{position:"absolute",width:"100%",height:"100%",}}>
      <StaticCanvas canvasData={hasCanvasDataDesc} id="canvasId2" canvasSize={canvasDescSize} isFull={true}/>
      </Box>}
    <Box className={`${classes.addTextLayoutD} ${hasCanvasDataDesc && classes.descWithCanvas}`}>
      <Box id="addTextDesc">
        <Box
          className={classes.description}
          dangerouslySetInnerHTML={{ __html: stage.attributes.description.toString().replace(/\n/g, '<br>') }}
        />
      </Box>
    </Box>
    </Box>
    <Box 
      data-test-id="open-annotation">
        {showTooltip && 
        (
          <div
            style={{
              left: `${tooltipPos.x}px`,
              top: `${tooltipPos.y}px`,
              zIndex:2
            }}
          >
            <HighlighterList
              data-test-id="annotation"
              applyHighlight={applyHighlight}
              tooltipPos={tooltipPos}
             />
          </div>
        )
        }
    </Box>
  </>
  );
};

export default AddTextLive;
