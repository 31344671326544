import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import moment from "moment";
import { EventType } from "./types";

export const configJSON = require("../../config.js");

export interface Props {
  navigation: any;
  id: string;
}
interface CalendarEvent {
  id?: number;
  event_type?: string;
  teacher_class_id?: number;
  lesson_folder_id?: number;
  lesson_id?: string | number | null;
  start_date?: string;
  start_time?: string;
  due_date?: string;
  due_time?: string;
  account_id?: number;
  created_at?: string;
  updated_at?: string;
  quiz_id?: number | null;
  quiz_folder_id?: number;
  start_time_utc?: string;
  due_time_utc?: string;
  lesson_name?: string;
  quiz_name?: string;
  teacher_classes_id?: number
  teacher_id?: number
  assignment_name?: string
  end_date?: string
  details?: string
  add_to_calendar?: boolean
  end_time?: string | null
  status?: string
  assignment_status?: string
  shared?: boolean
  shared_library_name?: string | null
  shared_library_type?: string | null
  downloaded?: boolean
  count?: string | number | null
}



interface UserData {
  full_name: string;
  calendar_events: CalendarEvent[];
  assignment_events: { data: any[] };
}

interface Calendar {
  name: string;
  events: any[];
}
interface S {
  calendarData: Calendar;
  loading: boolean;
  isSmallScreen: boolean;
  selectedDate: Date;
}

interface SS {
  id: any;
}

export default class TeacherCalendarController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiCalendarEventsCallId: string = "";
  apiDeleteEventCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      calendarData: {
        name: "",
        events: [],
      },
      selectedDate: new Date(),
      loading: true,
      isSmallScreen: window.innerWidth <= 600,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.apiCalendarEventsCallId) {
      this.handleCalendarEventsResponse(responseJson);
    } else if (apiRequestCallId === this.apiDeleteEventCallId) {
      this.handleDeleteEventResponse();
    }
  }

  handleCalendarEventsResponse(responseJson: UserData) {
    const { assignment_events, calendar_events, full_name } = responseJson;

    const quizEvents = this.processQuizEvents(calendar_events);
    const assignEvents = this.processAssignmentEvents(assignment_events?.data);
    const lessonsEvents = this.processLessonEvents(calendar_events);
    const assignedQuizEvents = this.processAssignedQuizEvents(assignment_events?.data);

    if (quizEvents || lessonsEvents || assignEvents) {
      const events = [...quizEvents, ...assignEvents, ...lessonsEvents, ...assignedQuizEvents];
      this.setState({
        calendarData: {
          name: full_name,
          events: this.refactorEventsBasedOnOverlap(events),
        },
      });
    }
  }

  processQuizEvents(calendar_events: any[]) {
    return calendar_events
      ?.filter((quiz) => quiz.event_type === "quiz")
      ?.map((quiz) => {
        return {
          id: quiz.id,
          title: quiz.quiz_name,
          start: moment(new Date(this.handleGetTimeUtc(quiz?.start_time_utc))).toDate(),
          end: moment(new Date(this.handleGetTimeUtc(quiz?.due_time_utc))).toDate(),
          startDate: moment(new Date(this.handleGetTimeUtc(quiz?.start_time_utc))).format('YYYY-MM-DD'),
          endDate: moment(new Date(this.handleGetTimeUtc(quiz?.due_time_utc))).format('YYYY-MM-DD'),
          startTime: moment(new Date(this.handleGetTimeUtc(quiz?.start_time_utc))).format('hh:mm A'),
          endTime: moment(new Date(this.handleGetTimeUtc(quiz?.due_time_utc))).format('hh:mm A'),
          teacherClass: quiz.teacher_class_id,
          quizFolderId: quiz.quiz_folder_id,
          quizId: quiz.quiz_id,
          type: "quiz",
        };
      });
  }


  handleGetTimeUtc = (timeUtc:string)=>{
    const timeDt = timeUtc?.split("+")[0]
    return timeDt
  }

  processAssignmentEvents(assignment_events: any[]) {
    return assignment_events.filter((quiz: any) => quiz.attributes.assignment.data !== null)?.map((quiz) => {
      return {
        id: quiz.id,
        title: quiz.attributes.assignment.data.attributes.assignment_name,
        start: moment(new Date(this.handleGetTimeUtc(quiz?.attributes?.start_time_utc))).toDate(),
        end: moment(new Date(this.handleGetTimeUtc(quiz?.attributes?.due_time_utc))).toDate(),
        startTime: moment(new Date(this.handleGetTimeUtc(quiz?.attributes?.start_time_utc))).format('hh:mm A'),
        endDate: moment(new Date(this.handleGetTimeUtc(quiz?.attributes?.due_time_utc))).format('YYYY-MM-DD'),
        endTime: moment(new Date(this.handleGetTimeUtc(quiz?.attributes?.due_time_utc))).format('hh:mm A'),
        startDate: moment(new Date(this.handleGetTimeUtc(quiz?.attributes?.start_time_utc))).format('YYYY-MM-DD'),
        teacherClass: quiz.attributes.teacher_classes_id,
        description: quiz.attributes.assignment.data.attributes.details,
        type: EventType.ASSIGNMENT,
      };
    });
  }

  processLessonEvents(calendar_events: any[]) {
    return calendar_events
      ?.filter((lesson) => lesson.event_type === "lesson")
      ?.map((lesson) => {
        return {
          id: lesson.id,
          title: lesson.lesson_name,
          start: moment(new Date(this.handleGetTimeUtc(lesson?.start_time_utc))).toDate(),
          end: moment(new Date(this.handleGetTimeUtc(lesson?.due_time_utc))).toDate(),
          startDate: moment(new Date(this.handleGetTimeUtc(lesson?.start_time_utc))).format('YYYY-MM-DD'),
          endDate: moment(new Date(this.handleGetTimeUtc(lesson?.due_time_utc))).format('YYYY-MM-DD'),
          startTime: moment(new Date(this.handleGetTimeUtc(lesson?.start_time_utc))).format('hh:mm A'),
          endTime: moment(new Date(this.handleGetTimeUtc(lesson?.due_time_utc))).format('hh:mm A'),
          teacherClass: lesson.teacher_class_id,
          lessonFolderId: lesson.lesson_folder_id,
          lessonId: lesson.lesson_id,
          type: "lesson",
        };
      });
  }

  handleDeleteEventResponse() {
    const id = this.props.navigation.getParam("id");
    this.getCalendarEvents(Number(id));
  }

  // Function to check if an event spans multiple days
  isMultiDayEvent(event: any) {
    const eventEndDate = moment(event.end).toDate();
    const eventStartDate = moment(event.start).toDate();
    return eventStartDate.getDate() !== eventEndDate.getDate();
  }
  doEventsOverlap(event1: { start: string, end: string }, event2: { start: string, end: string }) {
    return event1.start < event2.end && event1.end > event2.start;
  }
  removeMultiDayEvents(eventsData: any) {
    const events = [];
    const multiDayEvents = [];
    for (let eventData of eventsData) {
      if (!this.isMultiDayEvent(eventData)) {
        eventData["overlap"] = false;
        eventData["multiDayEvent"] = true;
        multiDayEvents.push(eventData);
        continue; // Skip further processing for multi-day events
      } else {
        eventData["overlap"] = true;
        eventData["multiDayEvent"] = false;
        events.push(eventData);
      }
    }
    return { multiDayEvents, events };
  }
  refactorEventsBasedOnOverlap(eventsData: any) {
    const { multiDayEvents, events } = this.removeMultiDayEvents(eventsData);

    const mainArr = [...events, ...multiDayEvents];
    for (let [i, event] of mainArr.entries()) {
      if (event.multiDayEvent === true) continue;
      let overlaps = false;
      for (let [j, eventj] of mainArr.entries()) {
        if (i !== j && this.doEventsOverlap(event, eventj)) {
          overlaps = true;
          break; // No need to check further if there's an overlap
        }
      }

      if (event?.multiDayEvent && !event?.overlap) {
        event["overlap"] = true;
        event["multiDayEvent"] = false;
      } else {
        event["overlap"] = false;
        event["multiDayEvent"] = true;
      }
    }
    return [...mainArr];
  }


  async componentDidMount() {
    if (window.localStorage.getItem("authToken") === null) {
      this.props.navigation.navigate("EmailAccountLogin");
      return;
    }

    if (window?.location?.pathname) {
      let id: any = window.location.pathname?.match(/\d+/);
      this.getCalendarEvents(Number(id[0]));
    }
  }

  handleSetDate = (date: Date) => {
    this.setState({ selectedDate: date })
  }

  getCalendarEvents = (id: number, date: Date = this.state.selectedDate) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(
      2,
      "0"
    );
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${year}/${month}/${day}`;
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCalendarEventsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.calendarEventsGetUrl}?id=${id}&current_date=${formattedDate}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleDeleteEvent(id: number, type: string) {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteEventCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.calendarEventDeletetUrl}?type=${type}&id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.Delete
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }


  handleResize = () => {
    this.setState({ isSmallScreen: window.innerWidth <= 600 });
  };

  handleColorEvents = (event: { type: string }) => {
    if (event.type === 'lesson') {
      return "#007AFF"
    } else if (event.type === 'quiz') {
      return "#39C6B5"
    } else {
      return "#FF9500"
    }
  }
  processAssignedQuizEvents(assignment_events: any[]) {
    return assignment_events.filter((quiz: any) => quiz.attributes.quiz.data !== null)?.map((quiz) => {
      return {
        id: quiz.id,
        title: quiz.attributes.quiz.data.attributes.quiz_name,
        start: moment(new Date(this.handleGetTimeUtc(quiz?.attributes?.start_time_utc))).toDate(),
        end: moment(new Date(this.handleGetTimeUtc(quiz?.attributes?.due_time_utc))).toDate(),
        startTime: moment(new Date(this.handleGetTimeUtc(quiz?.attributes?.start_time_utc))).format('hh:mm A'),
        endDate: moment(new Date(this.handleGetTimeUtc(quiz?.attributes?.due_time_utc))).format('YYYY-MM-DD'),
        endTime: moment(new Date(this.handleGetTimeUtc(quiz?.attributes?.due_time_utc))).format('hh:mm A'),
        startDate: moment(new Date(this.handleGetTimeUtc(quiz?.attributes?.start_time_utc))).format('YYYY-MM-DD'),
        teacherClass: quiz.attributes.teacher_classes_id,
        description: quiz.attributes.quiz.data.attributes.details,
        type: EventType.QUIZ,
      };
    });
  }
}


