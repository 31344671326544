import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Account, Group, GroupAccount } from "./types";
import { debounce } from "lodash";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  selectedOption: any;
  selectTeachersDropDownList:[]
  accountsLoading: boolean,
  name: string;
  createdAt: string;
  updatedAt: string;
  editMode: boolean;
  token: string;
  groupList: Group[];
  isVisibleModal: boolean;
  isVisibleAddAccountModal: boolean;
  isVisibleDeleteAccountModal: boolean;
  isCheckBox: boolean;
  isCheckBox2: boolean;
  finalAccountFilter1:boolean;
  finalAccountFilter2:boolean;
  accountsData: Account[];
  modalAccData: Account[];
  selectedAccounts: GroupAccount[];
  dropdownAccountStatus: boolean;
  fieldError: boolean;
  activeTab: string;
  role: string,
  imagePreview: string;
  fileInput: any;
  fullName: string;
  email: string;
  gender: string;
  institution: string;
  summary: string;
  schoolAdmin: string;
  password: string;
  confirmPassword: string;
  date_of_birth: string;
  course: string;
  full_phone_number: string;
  grade: string;
  teacher: string;
  profileStatus: string;
  selectedFile: File | string | null;
  previewUrl: string | null;
  deleteId: string;
  editId: string;
  editRole: string;
  teachersDropDownList:[]
  pageNo:number
  selectedRole: string;
  pageLimit: number;
  enablePasswordField: boolean;
  enableConfirmPasswordField: boolean;
  fullNameError: string;
  passwordError: string;
  confirmPasswordError: string;
  emailError: string;
  institutionError: string;
  activeSchoolAdmin: string;
  nonEditable: boolean;
  searchInput: string;
  anchorEl: any;
  activeButton: number | null;
  filterModal: boolean;
  itemsPerPage: number;
  showPassword: any;
  isSmallScreen: boolean;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class AccountGroupsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getGroupsApiCallId = "";
  getAccountsApiCallId = "";
  postGroupApiCallId = "";
  putGroupApiCallId = "";
  deleteGroupApiCallId = "";
  postAddAccountsApiCallId = "";
  postRemoveAccountsApiCallId = "";
  getLoggedInUserApiCalId = "";
  getTeachersListApiCallId = "";
  filterAccountsApiCall: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      accountsLoading: true,
      selectedOption: null,
      id: "0",
      name: "",
      createdAt: "",
      updatedAt: "",
      editMode: false,
      token: "",
      groupList: [],
      isVisibleModal: false,
      isVisibleAddAccountModal: false,
      isVisibleDeleteAccountModal: false,
      accountsData: [],
      modalAccData: [],
      dropdownAccountStatus: false,
      selectedAccounts: [],
      fieldError: false,
      activeTab: "Accounts",
      role: "",
      imagePreview: '',
      fileInput: React.createRef(),
      fullName: "",
      email: "",
      gender: "male",
      institution: "",
      summary: "",
      schoolAdmin: "",
      password: "",
      confirmPassword: "",
      date_of_birth: "",
      course: "",
      full_phone_number: "",
      grade: "",
      teacher: "",
      profileStatus: "complete",
      selectedFile: null,
      previewUrl: null,
      deleteId: "",
      isCheckBox2: false,
      isCheckBox: false,
      finalAccountFilter1: false,
      finalAccountFilter2: false,
      editId: "",
      editRole: "",
      teachersDropDownList:[],
      selectTeachersDropDownList: [],
      pageNo:1,
      selectedRole: "",
      pageLimit: 1,
      enablePasswordField: true,
      enableConfirmPasswordField: true,
      fullNameError: "",
      passwordError: "",
      confirmPasswordError: "",
      emailError: "",
      institutionError: "",
      activeSchoolAdmin: "",
      nonEditable: false,
      searchInput: "",
      anchorEl: null,
      itemsPerPage: 10,
      filterModal: false,
      activeButton: null,
      showPassword: {},
      isSmallScreen: window.innerWidth <= 600
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();  
    this.getLoggedInUser()
    this.getTeachersList()
    this.getAccounts()
    window.addEventListener('resize', this.handleResize);
  }

  renderGetGroups=(message: Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson?.errors && responseJson?.data) {
      this.setState({
        groupList: responseJson.data.sort(
          (a: Group, b: Group) => parseInt(a.id) - parseInt(b.id)
        ),
      });
    } else {

      this.showAlert("Alert", "No Data");
      this.setState({ groupList: [] });

      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.parseApiCatchErrorResponse(errorResponse);
    }
  }

  renderPostGroups=(message: Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson?.errors && responseJson?.data) {
      this.setState({ isVisibleModal: false });
      this.setState({role: ""})
      this.getAccounts(this.state.token)
      this.setState({
        fullName: "",
        email: "",
        institution: "",
        summary: "",
        password: "",
        confirmPassword: "",
        gender: "",
      })
    } else {
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      responseJson?.errors?.email[0] && alert("The email has already been taken")
      this.parseApiCatchErrorResponse(errorResponse);
    }
  }

  filterTeacherList = (list: any) => {
    return list.filter((item: Account)=> {return item.attributes.role === 'Teacher'}).map((teacher: any) => {
      return { value:teacher.id, label:teacher.attributes.full_name, image: teacher.attributes.image };
    });
  }

  renderGetAccounts=(message: Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson?.errors && responseJson?.data) {
      const data = responseJson.data.map((account: Account) => {
        return { ...account, isSelected: false };
      });
      this.setState({
        accountsData: data,
        pageLimit: responseJson.meta.total_pages,
        accountsLoading: false, filterModal:false,
      });
    } else {
      this.setState({ accountsData: [] });
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.parseApiCatchErrorResponse(errorResponse);
      // this.getAccounts(token)
    }
    this.setState({
      accountsLoading: false
    })
  }

  renderGetTeacherslist=(message: Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson?.errors && responseJson?.data) {
      const reactSelectTeachersData = this.filterTeacherList(responseJson.data)
    this.setState({
      selectTeachersDropDownList: reactSelectTeachersData
    });
    if(reactSelectTeachersData.length > 0) {
      this.setState({
        selectedOption: null
      })
    }
    } else {
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.parseApiCatchErrorResponse(errorResponse);
    }
  }

  renderPutGroups=(message: Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson?.errors && responseJson?.data) {
 
      this.setState({ isVisibleModal: false });
      this.setState({role: ""})
      this.getAccounts(this.state.token)
    } else {
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.parseApiCatchErrorResponse(errorResponse);
    }
  }

  renderDeleteGroups=(message: Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (!responseJson?.errors && responseJson) {
      this.getAccounts(this.state.token);
      this.setState({isVisibleDeleteAccountModal: false})
    } else {
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.parseApiCatchErrorResponse(errorResponse);
    }
  }

  renderPostAccounts=(message: Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson?.errors && responseJson?.data) {
      this.getGroups(this.state.token);
      this.setState({ isVisibleAddAccountModal: false });
      this.setState({role: ""})
    } else {
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.parseApiCatchErrorResponse(errorResponse);
    }
  }

  renderRemoveAccounts=(message: Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson?.errors && responseJson?.data) {
      this.getGroups(this.state.token);
      this.setState({ isVisibleDeleteAccountModal: false });
    } else {
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.parseApiCatchErrorResponse(errorResponse);
    }
  }

  renderGetLoggedInUser=(message: Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (!responseJson?.errors && responseJson?.data) {
      this.setState({
        activeSchoolAdmin: responseJson.data.attributes.full_name
      });
    } else {    
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.parseApiCatchErrorResponse(errorResponse);
    }
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    // Condition for fetching group list.
    {getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getGroupsApiCallId != null &&
      this.getGroupsApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) && 
      this.renderGetGroups(message)}

    // Condition for creating new group.

    {getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postGroupApiCallId != null &&
      this.postGroupApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    &&
      this.renderPostGroups(message)
    }

     // Condition for updating group details.
    {getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.putGroupApiCallId != null &&
      this.putGroupApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      &&
    this.renderPutGroups(message)}

    // Condition for deleting group.

    {getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.deleteGroupApiCallId != null &&
      this.deleteGroupApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      &&
    this.renderDeleteGroups(message)}

    // Condition for fetching user account list
  
    {getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAccountsApiCallId != null &&
      this.getAccountsApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      &&
      this.renderGetAccounts(message)
    }
    // Condition for adding account to the group

    {getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postAddAccountsApiCallId != null &&
      this.postAddAccountsApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      &&
    this.renderPostAccounts(message)}
    // Condition for removing account to the group

    {getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postRemoveAccountsApiCallId != null &&
      this.postRemoveAccountsApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      &&
    this.renderRemoveAccounts(message)}

    {getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getLoggedInUserApiCalId != null &&
      this.getLoggedInUserApiCalId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      &&
      this.renderGetLoggedInUser(message)
    }

    {getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTeachersListApiCallId != null &&
      this.getTeachersListApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      &&
      this.renderGetTeacherslist(message)
    }

  };

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  isStringNullOrBlank = (str: string) => {
    return str === null || str.length === 0;
  };

  hideModal = () => {
    this.setState({ isVisibleModal: !this.state.isVisibleModal });
  };

  closeFilterModal = () =>{
    this.setState({filterModal:false})
  }

  handleFilterAccounts= ()=>{
    this.setState({accountsLoading: true, pageNo:1})
    this.getAccounts();    
  }

  handleCheckBoxChange = (isCheckBox:boolean)=>{
    this.setState({isCheckBox: !isCheckBox})
    if(!this.state.isCheckBox){
      this.setState({finalAccountFilter1:true})
    }else{
      this.setState({finalAccountFilter1:false})
    }
  }

  handleCheckBoxChange2 = (isCheckBox2:boolean)=>{
    this.setState({isCheckBox2: !isCheckBox2})
    if(!this.state.isCheckBox2){
      this.setState({finalAccountFilter2:true})
    }else{
      this.setState({finalAccountFilter2:false})
    }
  }

  hideAddAccountModal = () => {
    this.setState({ isVisibleAddAccountModal: false });
  };

  hideDeleteAccountModal = () => {
    this.setState({ isVisibleDeleteAccountModal: false });
  };

  showAddModal = () => {
    this.setState({
      name: "",
      editMode: false,
      fieldError: false,
      isVisibleModal: !this.state.isVisibleModal,
      finalAccountFilter1: false,
      finalAccountFilter2: false,
      pageNo:1,
    });
  };

  handleInputName = (name: string) => {
    this.setState({ name, fieldError: false });
  };

  showHideAddAccountModal = () => {
    this.setState({
      isVisibleAddAccountModal: !this.state.isVisibleAddAccountModal,
      editMode: true,
    });
  };

  handleAddAccounts = (group: Group) => {
    let accArr: any[] = [];
    if (group.attributes.accounts.length > 0) {
      accArr = this.state.accountsData.filter(
        (ar) =>
          !group.attributes.accounts.find((rm) => rm.id.toString() === ar.id)
      );
    } else {
      accArr = this.state.accountsData;
    }

    this.setState({
      id: group.id,
      name: group.attributes.name,
      modalAccData: accArr,
    });
    this.showHideAddAccountModal();
  };

  handleEditGroup = () => {
    this.setState({role: this.state.editRole})
    this.setState({nonEditable : false})
    this.handleClose()
  };  

  handleShowDetails = () => {
    this.setState({role: this.state.editRole})
    this.setState({nonEditable : true})
    this.handleClose()
  }

  handleShowPassword = () => {
    const rowId = this.state.deleteId
    this.setState(prevState => ({
      showPassword: {
        ...prevState.showPassword,
        [rowId]: !prevState.showPassword[rowId] 
      }
    }));
  }

  showHideDeleteAccountModal = () => {
    this.setState({
      isVisibleDeleteAccountModal: !this.state.isVisibleDeleteAccountModal,
      editMode: true,
    });
    this.handleClose()
  };

  handleDeleteAccounts = (group: Group) => {
    const accData = group.attributes.accounts.map((account: GroupAccount) => {
      return { ...account, isSelected: false };
    });
    this.setState({
      id: group.id,
      name: group.attributes.name,
      selectedAccounts: accData,
    });
    this.showHideDeleteAccountModal();
  };

  handleDeleteAccountSelect = (groupAccountId: number) => {
    const newData = this.state.selectedAccounts.map((account: GroupAccount) => {
      if (account.id === groupAccountId) {
        return { ...account, isSelected: !account.isSelected };
      }
      return account;
    });
    this.setState({ selectedAccounts: newData });
  };

  handleGetGroups = () => {
    this.getGroups(this.state.token);
  };

  renderFormData=()=>{
    const formData= new FormData();
    formData.append("gender", this.state.gender); 
    formData.append("new_password",  this.state.password); 
    formData.append("email", this.state.email); 
    formData.append("full_name", this.state.fullName); 
    if(!this.state.editId){
      formData.append("confirm_password", this.state.confirmPassword); 
    }
    
    if (this.state.role.toLocaleLowerCase() === "teacher") 
    { 
     this.state.selectedFile && formData.append('image', this.state.selectedFile)
    formData.append("institution", this.state.institution); 
      formData.append("summary", this.state.summary); 
  } else { 
    formData.append("institution", "IIT"); 
    formData.append("full_phone_number", this.state.full_phone_number); 
    formData.append("course", this.state.course); 
    formData.append("profile_status", this.state.profileStatus); 
    formData.append("grade_id", this.state.grade); 
     formData.append("date_of_birth", this.state.date_of_birth); 
    if(this.state.selectedOption)
      {formData.append("teacher_id", this.state.selectedOption.value); }
    }
  return formData
  }

  // Function to fetch the group list from API
  getGroups = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGroupsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.groupsApiEndPoint}`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  //Function to add new group and send it to API
  addGroup = () => {
      const header = {
          token: localStorage.getItem("authToken")
      };
      const formData= this.renderFormData();

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.postGroupApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        this.state.role.toLocaleLowerCase() === "teacher"?
        configJSON.teacherApiEndPoint:
        configJSON.studentApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    
  };

  // Function to edit group and send it to API
  editGroup = () => {
    if (this.isStringNullOrBlank(this.state.fullName)) {
      this.setState({ fieldError: true });
    } else {
      const header = {
        token: localStorage.getItem("authToken"),
      };
      
      const formData= this.renderFormData();
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.putGroupApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.editAccountApiEndPoint + `${this.state.editId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

    // Function to delete group and send it to API
    deleteGroup = () => {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: localStorage.getItem("authToken"),
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.deleteGroupApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.deleteApiMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.deleteAccountApiEndPoint + `${this.state.deleteId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
  handleRoleIdFunc = () =>{
    if(this.state.finalAccountFilter1){
      return `&role_id=1`
    }else if(this.state.finalAccountFilter2){
      return `&role_id=2&is_student=${true}`
    }else{
      return '&role_id='
    }
  }

  // Function to fetch the accounts list from API
  getAccounts = (token: string='', pageNo:number=1) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAccountsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksAccountsApiEndPoint}?page=${pageNo}&per_page=10&search=${this.state.searchInput}${this.handleRoleIdFunc()}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTeachersList = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTeachersListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // configJSON.teachersListApiEndPoint
      `${configJSON.teachersListApiEndPoint}?role_id=1`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getLoggedInUser = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getLoggedInUserApiCalId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.loggedInUserApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to save accounts to the group and send it to API
  handleSaveAccountsToGroup = (groupId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };
    const accountIds: string[] = [];
    this.state.modalAccData.map((account: Account) => {
      if (account.isSelected) {
        accountIds.push(account.id);
      }
    });
    const httpBody = {
      account_ids: accountIds,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postAddAccountsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.groupsApiEndPoint + "/" + `${groupId}` + "/add_accounts"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  handleTabClick = (tabName:string) => {
    this.setState({
      activeTab: tabName,
    }, () => {
      if(this.state.activeTab === "Dashboard"){
        this.props.navigation.navigate("Dashboard")
      }
    });
  };

  handleSelectRole = (selectedRole: string) => {
    this.setState({selectedRole: selectedRole})
  }

  handleSubmitRole=()=>{
    this.setState({role: this.state.selectedRole})
    if(this.state.selectedRole === "teacher"){
      this.setState({
        fullName: "",
        email: "",
        institution: "",
        summary: "",
        password: "",
        confirmPassword: "",
        gender: "",
        editId:"",
        imagePreview: "",
        selectedFile: null,
        nonEditable: false
      })
    }else{
      this.setState({
        fullName: "",
        email: "",
        password: "",
        confirmPassword: "",
        gender: "",
        date_of_birth: "",
        course:"",
        full_phone_number: "",
        grade:"",
        profileStatus:"",
        editId:"",
        nonEditable: false
      })
    }
    this.hideModal()
  }

  handleFileChange = (event:any) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      this.setState({
        selectedFile: event.target.files[0]
      });
      const reader = new FileReader();
      reader.onload = () => {
        this.setState({ imagePreview: reader.result as string });
      };
      reader.readAsDataURL(selectedFile);
    }
  };

 

  handleChange = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    const { name, value } = event.target;
    if(name==="date_of_birth"){
      const datePattern = /^\d{4}-\d{2}-\d{2}$/; 
      const match = value.match(datePattern);
      if(!match){
        return false
      }
    }
    this.setState((prevState) => ({
      ...prevState,
      [name as string]: value,
    }));   
  };

  handleClick = (event:any,row: Account) => {
    this.setState({ anchorEl: event.target });
    this.setState({deleteId: row?.id});
    this.setState({ editId: row?.id, editRole: row?.attributes.role });
    this.setState({fullName: row?.attributes.full_name})
    this.setState({email: row?.attributes.email!})
    this.setState({gender: row?.attributes.gender!})
    this.setState({summary: row?.attributes.summary!})
    this.setState({schoolAdmin: this.state.activeSchoolAdmin})
    this.setState({password: row?.attributes.password!})
    this.setState({institution: row?.attributes.institution!})
    this.setState({date_of_birth: row?.attributes.date_of_birth!})
    this.setState({course: row?.attributes.course!})
    this.setState({full_phone_number: row?.attributes.full_phone_number!})
    this.setState({grade: row?.attributes.grade_id!})
    this.setState({profileStatus: row?.attributes.profile_status!})
    this.setState({imagePreview: row?.attributes.image!})
    this.setState({selectedOption: this.state.selectTeachersDropDownList.filter((data: any) => data.value == row.attributes.teacher_id)[0]})
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  validatePassword = () => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    if (!this.isStringNullOrBlank(this.state.password)) {
      if (!passwordRegex.test(this.state.password)) {
        this.setState({ passwordError: `Please enter a valid password.<br/>Your password must Include at least :<br/>- 8 characters length<br/>- One upper case character (A-Z)<br/>- One lower case character (a-z)<br/>- One digit (0-9)<br/>- One Symbol/Special character (!,#,$,etc)` });
        return false;
      }
    } else {
      this.setState({ passwordError: configJSON.errorPasswordFieldRequired });
      return false;
    }
  
    if (!this.state.editId && this.state.password !== this.state.confirmPassword) {
      this.setState({ passwordError: configJSON.errorPasswordMismatch });
      return false;
    }
  
    this.setState({ passwordError: "" });
    return true;
  }
  validateEmail(email:string) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  validateForm=()=>{
    let isValid= true;

    if(this.isStringNullOrBlank(this.state.fullName)){
      this.setState({fullNameError: "Full name is required!"})
      isValid= false;
    }else{
      this.setState({fullNameError: ""})
    }

    isValid = this.validatePassword() && isValid;

    if(!this.state.editId && this.isStringNullOrBlank(this.state.confirmPassword)){
      this.setState({confirmPasswordError: configJSON.errorConfirmPasswordFieldRequired})
      isValid= false;
    }

    else{
      this.setState({confirmPasswordError: ""})
    }
    if(this.isStringNullOrBlank(this.state.email)){
      this.setState({emailError: "Email is required!"})
      isValid= false;
    
    }
    else if (!this.validateEmail(this.state.email)) {
      this.setState({ emailError: "Enter a valid email id!" });
      isValid = false;
    } 
    else{
      this.setState({emailError: ""})
    }

    if(this.isStringNullOrBlank(this.state.institution) && this.state.role.toLocaleLowerCase() === "teacher"){
      this.setState({institutionError: "Institution name is required!"})
      isValid= false;
    }else{
      this.setState({institutionError: ""})
    }
    return isValid
  }


  handleFormSubmit = (values: any) => {
    values.preventDefault(); 
    if(this.state.nonEditable){
      this.setState({nonEditable: false})
      return false
    }
    const isValid = this.validateForm()
    
    if(isValid){
    if (this.state.editId) {
      this.editGroup();
    } else {
      this.addGroup();
    }
  }
  }

  handlePageChange(event: any, page: number){
      this.setState({
        pageNo:page
      })
      this.getAccounts(this.state.token, page)
    }

    handleFilterModalOpen = () =>{
      this.setState({filterModal: true})
    }
  handleCancel=()=>{
      this.setState({role: ""})
    }  

    handleClickShowPassword = () => {
      this.setState({
        enablePasswordField: !this.state.enablePasswordField,
      });
    };
    handleClickShowConfirmPassword = () => {
      this.setState({
        enableConfirmPasswordField: !this.state.enableConfirmPasswordField,
      });
    };

    debouncedSearch = debounce(() => {
      this.getAccounts(this.state.token, this.state.pageNo);
    }, 1000); 

    handleSearchInput(event: any) {
      this.setState(
        {
          searchInput: event.target.value,
          pageNo: 1
        },
        () => {
          this.debouncedSearch()
        }
      );
    }

    handleReactSelectChange = (selectedOption: any) => {
      this.setState({ selectedOption }, () =>
        {}
      );
    };

    handleButtonHover = (buttonId: number | null) => {
      this.setState({ activeButton: buttonId });
    }  
  
    handleResize = () => {
      this.setState({ isSmallScreen: window.innerWidth <= 600 });
    };
  
    async componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }
  // Customizable Area End
}
