import React from "react";
import { Box, Grid, useMediaQuery } from "@material-ui/core";
import Sidebar from "../../../../components/src/Sidebar.web";
import AppHeaderWeb from "../../../../components/src/AppHeader.web";
import { MY_CLASSES_VIEWS, STUDENT_DASHBOARD_VIEWS } from "../../../../components/src/types";
import Loader from "../../../../components/src/Loader.web";
import MyClasses from "./Pages/MyClasses.web";
import ClassDetails from "./Pages/ClassDetails.web";
import StudentLessonList from "./Pages/StudentLessonList.web";

const StudentContentManagement = (props: any) => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const {
    selectedClass,
    selectedView,
    searchInputClassName,
    searchInputStudentLessonName,
  } = props.myClassesState;
  const {
    isLoading,
    classList,
    locations,
    destination,
    pageNoStudentLesson,
    pageLimitStudentLesson,
    redirect,
    showClassDetailView,
    showStudentListView,
    showStudentLessonListView,
    handleSearchStudentClassName,
    handleSearchStudentLessonName,
    handlePageStudentLessonList,
    handleNavigation,
    handleParamsNavigation,
    filteredStudentLessonData
  } = props;
  const renderSwitch = (selectedView: string) => {
    if (selectedView === MY_CLASSES_VIEWS.MY_CLASSES) {
      return isLoading ? (
        <Loader loading={isLoading} />
      ) : (
        <MyClasses
          dataList={classList}
          searchInput={searchInputClassName}
          handleSearchClassName={handleSearchStudentClassName}
          showClassDetailView={showClassDetailView} 
        />
      );
    } else if (selectedView === MY_CLASSES_VIEWS.CLASS_DETAILS) {
      return (
        <ClassDetails
          locations={locations}
          destination={destination}
          selectedClass={selectedClass}
          showStudentListView={showStudentListView}
          showStudentLessonListView={showStudentLessonListView}
          redirect={redirect}
          handleClick={showClassDetailView}
          handleNavigation={handleNavigation}
          handleParamsNavigation={handleParamsNavigation}
        />
      );
    } else if (selectedView === MY_CLASSES_VIEWS.JOIN_LIVE_SESSION) {
      return (
        <StudentLessonList
          loading={isLoading}
          locations={locations}
          destination={destination}
          tableSearchInput={searchInputStudentLessonName}
          handleSearchInputTable={handleSearchStudentLessonName}
          pageNoPractice={pageNoStudentLesson}
          pageLimitPractice={pageLimitStudentLesson}
          handlePracticePageChange={handlePageStudentLessonList}
          redirect={redirect}
          filteredStudentLessonData={filteredStudentLessonData}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <Box>
      <Grid container style={{ overflowX: "hidden", height: "100%" }}>
        <Grid item {...(!isSmallScreen && { xs: 2 })}>
          {!isSmallScreen && <Sidebar />}
        </Grid>
        <Grid item {...(!isSmallScreen && { xs: 10 })} style={{ overflowY: "scroll", height: "100%" }}>
          <Grid item md={12}>
            <AppHeaderWeb title={STUDENT_DASHBOARD_VIEWS.DASHBOARD} />
          </Grid>
          <Grid item md={12}>
            {renderSwitch(selectedView)}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StudentContentManagement;
