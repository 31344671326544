import React from "react";
import { Box, Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import StaticCanvas from "../../../../../../../components/src/StaticCanvas.web";
const useStyles = makeStyles(() => ({
  title: {
    fontFamily: "Poppins",
    fontSize: "22px",
    fontWeight: "bolder",
    color: "#2C2C2E"
  },
  titleBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  stageTitle: {
    paddingLeft: "25px",
    paddingRight: "25px",
    marginTop: "180px",
    whiteSpace: "pre-wrap"
  },
  optionHead: {
    fontFamily:"Poppins",
    padding: "15px"
  },
  optionPollbody: {
    display: "flex",
    gap: "10px",
    padding: "15px",
    justifyContent: "center",
    alignItems: "center",
    width: "calc(100% - 30px)"
  },
  optionPollinput: {
    flex:"1 0 0",
    border: "1px solid rgba(43,61,125,0.39)",
    height: "58px",
    fontSize: "16px",
    background: "#fff",
    fontFamily: "Poppins",
    fontWeight: 400,
    paddingLeft:"15px"
  },
}));

const PollPreviewScreen = (props: any) => {
  const {currentStage}=props
  const classes = useStyles();
  const hasCanvasData = currentStage.attributes.canvas_data;
  let canvasSize: string | undefined;
  let jsonCanvas: object | undefined;

  if (hasCanvasData) {

      const parsedData = JSON.parse(hasCanvasData);
      canvasSize = parsedData.canvasSize;
      jsonCanvas = parsedData.canvas;

  }
  const backgroundStyle = {
    height: '100vh',
    backgroundSize: 'cover',
    backgroundImage: `linear-gradient(rgba(255,255,255,0.6), rgba(255,255,255,0.6)), url(${currentStage.attributes.background_image?.url})`,
    backgroundPosition: 'center',
  };
  return (
    <div id="content">
      <div className={classes.titleBox}>
        <Typography className={classes.title} 
        dangerouslySetInnerHTML={{
          __html: currentStage.attributes.questions[0].question.question,
         }}
        />
      </div>
      {  jsonCanvas && <Box style={{width:"90%",position:"absolute"}}>
  <StaticCanvas canvasData={jsonCanvas} id="canvasId" canvasSize={canvasSize?JSON.parse(canvasSize):undefined}/>
  </Box>}
      <Box className={classes.stageTitle} style={backgroundStyle}>
      {currentStage.attributes.questions[0].options.map((option: any, index: number) => (
              <Box>
                <span className={classes.optionHead}>{`Option ${index + 1}`}</span>
                <div className={classes.optionPollbody} key={option.id}>
                  <input
                    className={classes.optionPollinput}
                    type="text"
                    disabled
                    placeholder={`Answer ${index + 1} here`}
                    value={option.answer}
                  />
                </div>
              </Box>
            ))}
      </Box>
      
    </div>
  );
};

export default PollPreviewScreen;
