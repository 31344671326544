import React from "react";
import { Box, Typography, Container, Grid } from "@material-ui/core";
import { footerLogo, facebook, instagram, twitter } from "../assets";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  heading: {
    color: "#2B3D7D",
    fontFamily: "Poppins",
    fontWeight: 600,
    marginBottom: "30px",
  },
  footerLayout: {
    display: "flex",
    justifyContent: "space-between",
  },
  footerBg: {
    background: "#323548",
    marginTop: "100px",
    padding: "80px 0",
    [theme.breakpoints.down("sm")]: {
      padding: "30px 0",
    },
  },
  link: {
    textDecoration: "none",
    color: "#FFF",
    display: "block",
    paddingBottom: "20px",
    fontSize: "18px",
    fontFamily: "Poppins",
  },
  socialLink: {
    marginRight: "20px",
  },
  socialLinkLayout: {
    display: "flex",
    paddingTop: "110px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20px",
      paddingTop: "20px",
      display: "flex",
      justifyContent: "center",
    },
  },
  followText: {
    color: "#FFF",
    fontSize: "18px",
    fontFamily: "Poppins",
    marginRight: "20px",
  },
  linkBox: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  linkHeading: {
    textDecoration: "none",
    color: "#FFF",
    display: "block",
    paddingBottom: "20px",
    fontSize: "18px",
    fontFamily: "Poppins",
    fontWeight:600
  },
  footerLogo: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
}));
interface Link {
  id: number;
  s_no:number;
  link_name: string;
  link: string;
  created_at: string;
  updated_at: string;
  link_type: string;
}
interface IProps {
  importantLinks1: Link[];
  importantLinks2: Link[];
  importantLinks3: Link[];
  nonImportantLinks: Link[];
  socialLinks: Link[];
}
const Footer = ({
  importantLinks1,
  importantLinks2,
  importantLinks3,
  nonImportantLinks,
  socialLinks,
}: IProps) => {

  const classes = useStyles();
  const arr = [importantLinks1, importantLinks2, importantLinks3];
  const [facebooksocial, instagramsocial, twittersocial] = socialLinks;
  const titles = ["Our Platform", "Support", "Follow Us"]; 
  return (
    <Box className={classes.footerBg}>
      <Container>
        <Grid container>
          <Grid item md={3} sm={12} xs={12}>
            <Box className={classes.footerLogo}>
              <img src={footerLogo} />
            </Box>
          </Grid>

          <Grid item md={9} sm={12} xs={12}>
            <Grid container>
              <Grid item md={3} sm={6} xs={12}>
                <Box className={classes.linkBox}>
                  {nonImportantLinks.map((siteLink: any, i: number) => (
                    <a className={classes.link} href={siteLink.link} key={i}>
                      {siteLink.link_name}
                    </a>
                  ))}
                </Box>
              </Grid>
              {arr.map((importantLinksSection, i) => (
                <Grid item md={3} sm={6} xs={12} key={i}>
                  <Box className={classes.linkBox}>
                    <Typography className={classes.linkHeading}>
                    {titles[i]}
                    </Typography>
                    {importantLinksSection.map(
                      (impLink: any, index: number) => (
                        <a
                          className={classes.link}
                          href={impLink.link}
                          key={index}
                        >
                          {impLink.link_name}
                        </a>
                      )
                    )}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
