import React from "react";
import { ACTIONS } from "../../../../components/src/types";
import AddTextLive from "./AddTextLive.web";
import MultiMediaLive from "./MultiMediaLive.web";
import StageWithQuestionLive from "./StageWithQuestionLive.web";
const LiveScreen = (props: any) => {
  const { currentStage, showQuestion,stageType,handleMedia,updateHighlightToFirebase } = props;
  const renderStage = () => {
    if (!currentStage) return;
    if (stageType === ACTIONS.ADD_TEXT) {
      return <AddTextLive stage={currentStage} updateHighlightToFirebase={updateHighlightToFirebase}/>;
    } else if (
      stageType === ACTIONS.MULTIMEDIA
    ) {
      return <MultiMediaLive stage={currentStage} handleMedia={handleMedia}/>;
    }else if(
      stageType === ACTIONS.MCQ||
      stageType === ACTIONS.ADD_WORD_CLOUD||
      stageType === ACTIONS.KWL_CHART ||
      stageType === ACTIONS.WH_CHART ||
      stageType === ACTIONS.SEED_DISCUSSION ||
      stageType === ACTIONS.T_CHART ||
      stageType === ACTIONS.VENN_DIAGRAM ||
      stageType === ACTIONS.OPEN_ENDED_QUESTION ||
      stageType === ACTIONS.ADD_WORD_CLOUD ||
      stageType === ACTIONS.ADD_MATCHING_Q ||
      stageType === ACTIONS.POLLING_Q
    ){
      return <StageWithQuestionLive currentStage={currentStage} showQuestion={showQuestion} stageType={stageType}/>
    }
  };
  return (
    <>
      <div style={{position:"relative",height:"100%",width:"100%"}}>{renderStage()}</div>
    </>
  );
};

export default LiveScreen;
