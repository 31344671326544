import React from "react";
import {
  Box,
  Typography,
  FormControl,
  TextField,
  Grid,
  NativeSelect,
  InputBase,
} from "@material-ui/core";
import {
  makeStyles,
  withStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { getModalStyle } from "../../../../ContentManagement/src/Teacher/components/utils";
import { uploadIcon } from "../../assets";
import GenericButton from "../../../../../components/src/GenericButton.web";
import { useDropzone } from "react-dropzone";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import StorageProvider from "../../../../../framework/src/StorageProvider";
const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontSize: "22px",
    color: "#2C2C2E",
    fontWeight: 600,
    marginBottom: "40px",
  },
  image: {
    marginBottom: "15px",
  },
  paper: {
    position: "absolute",
    width: "760px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display: "flex",
    justifyContent: "center",
    aignItems: "center",
    padding: "35px 0",
  },
  crossIcon: {
    position: "absolute",
    top: "30px",
    right: "20px",
    cursor:"pointer"
  },
  popupContent: {
    display: "flex",
    flexDirection: "column",
  },
  container: {
    width: "100%",
    padding: "0 50px",
  },
  inputLabel: {
    padding: "10px 0",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    color: "#2C2C2E",
  },
  error: {
    fontFamily: "Poppins",
    fontSize: "12px",
    color: "#FE575A",
    fontWeight: 400,
  },
  errorLayout: {
    display: "flex",
  },
  inputValue: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    color: "#2C2C2E",
  },
  attachmentBtn: {
    width: "100%",
    height: "180px",
    backgroundColor: "#C0C0C0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor:"pointer"
  },
  uploadText: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    color: "#2C2C2E",
    textTransform: "capitalize",
  },
  uploadSubText: {
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 400,
    color: "#2C2C2E",
    textTransform: "capitalize",
  },
  btnTextLayout: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  buttonLayout: {
    display: "flex",
    justifyContent: "right",
    marginTop: "30px",
  },
  dateFont: {
    "& .MuiInputBase-input": {
      color: "currentColor",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
    },
  },
  dateFontPlaceholder: {
    "& .MuiInputBase-input": {
      color: "#C0C0C0",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
    },
  },
  previewImage: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
}));

export const BootstrapInputInactive = withStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #C0C0C0",
      fontSize: "18px",
      fontFamily: "Poppins",
      padding: "10px 26px 10px 12px",
      minHeight: "35px",
      color: "#C0C0C0",
      "&:focus": {
        borderRadius: 4,
        borderColor: "#2B3D7D",
        backgroundColor: "unset",
      },
    },
  })
)(InputBase);

export const BootstrapInputActive = withStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #C0C0C0",
      fontSize: "18px",
      fontFamily: "Poppins",
      padding: "10px 26px 10px 12px",
      minHeight: "35px",
      color: "currentColor",
      "&:focus": {
        borderRadius: 4,
        borderColor: "#2B3D7D",
        backgroundColor: "unset",
      },
    },
  })
)(InputBase);

const CreationModal = (props: any) => {
  const { handleCloseModal, formClassList, createLesson, selectedOption, createAssignment, createQuiz } = props;
  const [modalStyle] = React.useState(getModalStyle);
  const initialValues = {
    optionName: "",
    class: "",
    startDate: "",
    endDate: "",
    objective: "",
  };
  const [errors, setErrors] = React.useState<any>({});
  const [formData, setFormData] = React.useState<any>(initialValues);
  const classes = useStyles();
  const [image, setImage] = React.useState<any>(null);
  const [preview, setPreview] = React.useState<any>(null);
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const validationRules: any = {
      optionName: /^.+$/,
    };
    if (!value.match(validationRules[name])) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: "Invalid input",
      }));
    } else {
      setErrors((prevErrors: any) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const mandatoryFieldsPresent = () => {
    let result = true;
    if (!formData.optionName ||/^\s*$/.test(formData.optionName)) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ["optionName"]: "Invalid input",
      }));
      result = false;
    }
    if (!formData.class) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ["class"]: "Invalid input",
      }));
      result = false;
    }
    if (!formData.endDate) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ["endDate"]: "End date is required!",
      }));
      result = false;
    }
    if (!formData.startDate) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ["startDate"]: "Start date is required!",
      }));
      result = false;
    }
    result = IsDateRequirementsValid(result)
    return result;
  };
  const IsDateRequirementsValid = (result:boolean) => {

    if (formData.startDate && formData.endDate) {
      const dateParts = formData.startDate.split("/");
      const eddateParts = formData.endDate.split("/");
      const startDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 
      const endDate = new Date(+eddateParts[2], eddateParts[1] - 1, +eddateParts[0]); 
      if(startDate>endDate){
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          ["dates"]: "Start date should not be greater than end date!",
        }));
        result = false;
      }
    }
    return result
  };
  const handleSubmit = () => {
    if (!mandatoryFieldsPresent()) return;
    if (Object.keys(errors).length) return;
    formData.attachment = image;
    if(selectedOption==="Lesson"){
      StorageProvider.set("pageTypeData", "Lesson");
      createLesson(formData);
    }else if(selectedOption==="Assignment"){
      StorageProvider.set("pageTypeData", "Assignment");
      createAssignment(formData)
    }
    else if(selectedOption==="Quiz"){
      StorageProvider.set("pageTypeData", "quizName");
      createQuiz(formData)
    }
    handleCloseModal();
  };

  const onDrop = React.useCallback((acceptedFiles: any) => {
    const file = new FileReader();
    file.onload = function() {
      setImage(acceptedFiles[0]);
      setPreview(file.result);
    };
    file.readAsDataURL(acceptedFiles[0]);
  }, []);
  const handleDateChange = (date: any, dateType: string) => {
    setErrors((prevErrors: any) => {
      const newErrors = { ...prevErrors };
      delete newErrors["dates"];
      return newErrors;
    });
    const formattedDate: string = moment(date).format("DD/MM/YYYY");
    handleChange({ target: { name: dateType, value: formattedDate } });
  };
  const initDate = (dateType: string) => {
    return formData[dateType]
      ? moment(formData[dateType], "DD/MM/YYYY", true).toDate()
      : undefined;
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
    },
  });
  return (
    <Box style={modalStyle} className={classes.paper}>
      <Box className={classes.container}>
        <Box className={classes.popupContent}>
          <Typography className={classes.title}>
            Create {selectedOption}
          </Typography>
          <ClearIcon onClick={handleCloseModal} className={classes.crossIcon} />
          <Grid container spacing={4}>
            <Grid item md={6}>
              <FormControl fullWidth variant="outlined">
                <label htmlFor="optionName" className={classes.inputLabel}>
                  {`${selectedOption} Name*`}
                </label>
                <TextField
                  id="optionName"
                  name="optionName"
                  data-test-id={"option-name"}
                  fullWidth
                  placeholder={`${selectedOption} Name`}
                  variant="outlined"
                  onChange={handleChange}
                  value={formData.optionName}
                  className={classes.inputValue}
                />
              </FormControl>
              {Boolean(errors.optionName) && (
                <Box className={classes.errorLayout}>
                  <Typography
                    className={classes.error}
                    dangerouslySetInnerHTML={{
                      __html: `${selectedOption} name is required!`,
                    }}
                  />
                </Box>
              )}
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth variant="outlined">
                <label className={classes.inputLabel} htmlFor="class">
                  Class*
                </label>
                <NativeSelect
                  id="class"
                  name="class"
                  value={formData.class}
                  onChange={handleChange}
                  data-test-id = 'nativeEventID'
                  input={
                    formData.class ? (
                      <BootstrapInputActive />
                    ) : (
                      <BootstrapInputInactive />
                    )
                  }
                >
                  {!formData.class && <option value="">Select</option>}
                  {formClassList.map((teacherClass: any) => (
                    <option
                      key={teacherClass.id}
                      value={teacherClass.id}
                      className={classes.inputValue}
                    >
                      {teacherClass.name}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
              {Boolean(errors.class) && (
                <Box className={classes.errorLayout}>
                  <Typography
                    className={classes.error}
                    dangerouslySetInnerHTML={{
                      __html: `Class is required!`,
                    }}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item md={12}>
              <FormControl fullWidth variant="outlined">
                <label className={classes.inputLabel} htmlFor="summary">
                  {selectedOption} Objective
                </label>
                <TextField
                  id="objective"
                  name="objective"
                  fullWidth
                  placeholder={`A short ${selectedOption} objective`}
                  variant="outlined"
                  multiline
                  rows={4}
                  onChange={handleChange}
                  value={formData.objective}
                  InputProps={{
                    className: classes.inputValue,
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <FormControl fullWidth variant="outlined">
                <label htmlFor="startDate" className={classes.inputLabel}>
                  Start Date
                </label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container>
                    <KeyboardDatePicker
                      id="startDate"
                      name="startDate"
                      variant="dialog"
                      data-test-id={"startDate"}
                      format="dd/MM/yyyy"
                      inputVariant="outlined"
                      onChange={(e) => handleDateChange(e, "startDate")}
                      value={initDate("startDate")}
                      labelFunc={() =>
                        formData.startDate || "Select Start Date"
                      }
                      className={
                        initDate("startDate")
                          ? classes.dateFont
                          : classes.dateFontPlaceholder
                      }
                      fullWidth
                      minDate={new Date()}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
                {Boolean(errors.startDate) && (
                <Box className={classes.errorLayout}>
                  <Typography
                    className={classes.error}
                    dangerouslySetInnerHTML={{
                      __html: `${errors.startDate}`,
                    }}
                  />
                </Box>
              )}
              </FormControl>
            </Grid>
         
            <Grid item md={6}>
              <FormControl fullWidth variant="outlined">
                <label
                  htmlFor="endDate"
                  className={classes.inputLabel}
                >
                  End Date
                </label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container>
                    <KeyboardDatePicker
                      id="endDate"
                      name="endDate"
                      variant="dialog"
                      data-test-id={"endDate"}
                      format="dd/MM/yyyy"
                      inputVariant="outlined"
                      minDate={initDate("startDate")}
                      onChange={(e) => handleDateChange(e, "endDate")}
                      value={initDate("startDate")}
                      labelFunc={() => formData.endDate || "Select End Date"}
                      className={
                        initDate("endDate")
                          ? classes.dateFont
                          : classes.dateFontPlaceholder
                      }
                      fullWidth
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
                 {Boolean(errors.endDate) && (
                <Box className={classes.errorLayout}>
                  <Typography
                    className={classes.error}
                    dangerouslySetInnerHTML={{
                      __html: `${errors.endDate}`,
                    }}
                  />
                </Box>
              )}
              </FormControl>
            </Grid>
          </Grid>
          {Boolean(errors.dates) && (
                <Box className={classes.errorLayout}>
                  <Typography
                    className={classes.error}
                    dangerouslySetInnerHTML={{
                      __html: `${errors.dates}`,
                    }}
                  />
                </Box>
              )}
          <Grid container spacing={4}>
            <Grid item md={12}>
              <FormControl fullWidth variant="outlined">
                <label className={classes.inputLabel} htmlFor="attachment">
                  Attachment
                </label>
                <Box {...getRootProps()}>
                  <input {...getInputProps()} data-test-id="attachment"/>
                  <Box className={classes.attachmentBtn}>
                    {!preview ? (
                      <Box className={classes.btnTextLayout}>
                        <img src={uploadIcon} />
                        <Typography className={classes.uploadText}>
                          Upload a file
                        </Typography>
                        <Typography className={classes.uploadSubText}>
                          Drag and drop files here
                        </Typography>
                      </Box>
                    ) : (
                      <img src={preview} className={classes.previewImage}/>
                    )}
                  </Box>
                </Box>
              </FormControl>
            </Grid>
          </Grid>
          <Box className={classes.buttonLayout}>
            <GenericButton
              type="NORMAL"
              label="Cancel"
              handleClick={handleCloseModal}
              customStyle={{ width: "182px" }}
            />
            <GenericButton
              data-test-id="submit-btn"
              type="COLORED"
              label={`Add ${selectedOption}`}
              handleClick={handleSubmit}
              customStyle={{ width: "182px" }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CreationModal;
