import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Typography, Box } from "@material-ui/core";
import ProfileInfoList from "./ProfileInfoList.web";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
const useStyles = makeStyles((theme) => ({
  tabs: {
    "&.MuiTabs-root": {
      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
      borderRadius: "8px",
      border: "0.2px solid #2B3D7D",
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "&.MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "center",
      marginTop: "20px",
    },
  },
  tab: {
    "&.MuiTab-root": {
      color: "#2B3D7D",
      fontWeight: 500,
      fontSize: "20px",
      width: "218px",
      textTransform: "capitalize",
      borderRadius: "8px",
      "@media (max-width: 600px)": {
        width:"50%"
      },
    },
    "&.MuiTab-root.Mui-selected": {
      fontWeight: 600,
      color: "#FFFFFF",
      textTransform: "capitalize",
      backgroundImage:
        "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
      fontSize: "20px",
      borderRadius: "8px",
      width: "218px",
      "@media (max-width: 600px)": {
        width:"50%"
      },
    },
  },
  tabBar: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 600px)": {
      marginLeft:"20px",
      width:"90%"
    },
  },
}));
function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const PeopleManagement = (props: any) => {
  const {
    redirect,
    teachers,
    students,
    handlePageChange,
    pageLimit,
    handleSearchInput,
    searchInput,
    deleteProfile,
    isLoading,
    isDeleteSuccess,
    deleteProfileError,
    deleteCleanup,
    pageNo,
    switchToStudentView,
    switchToTeacherView,
    tabSelected
  } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(tabSelected);

  React.useEffect(() => {
    setValue(tabSelected);
  }, [tabSelected]);

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue === 0) {
      switchToTeacherView();
    } else {
      switchToStudentView();
    }
    setValue(newValue);
  };
  return (
    <Box>
      <Box className={classes.tabBar}>
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="simple tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: "unset",
            },
          }}
          className={classes.tabs}
        >
          <Tab className={classes.tab} label="Teachers" {...a11yProps(0)} />
          <Tab className={classes.tab} label="Students" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ProfileInfoList
          redirect={redirect}
          handlePageChange={handlePageChange}
          pageLimit={pageLimit}
          handleSearchInput={handleSearchInput}
          searchInput={searchInput}
          profileData={teachers}
          deleteProfile={deleteProfile}
          role="teacher"
          isLoading={isLoading}
          isDeleteSuccess={isDeleteSuccess}
          deleteProfileError={deleteProfileError}
          deleteCleanup={deleteCleanup}
          pageNo={pageNo}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ProfileInfoList
          redirect={redirect}
          handlePageChange={handlePageChange}
          pageLimit={pageLimit}
          handleSearchInput={handleSearchInput}
          searchInput={searchInput}
          profileData={students}
          deleteProfile={deleteProfile}
          role="student"
          isLoading={isLoading}
          isDeleteSuccess={isDeleteSuccess}
          deleteProfileError={deleteProfileError}
          deleteCleanup={deleteCleanup}
          pageNo={pageNo}
        />
      </TabPanel>
    </Box>
  );
};

export default PeopleManagement;
