import React from "react";
import { ListItemText,Modal } from "@material-ui/core";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../components/src/StyledMenu.web";
import {
    viewDetailOptionActive,
    viewDetailOptionInactive,
    editOptionActive,
    editOptionInactive,
    deleteOptionActive,
    deleteOptionInactive,
    passwordOptionActive,
    passwordOptionInactive,
  } from "../assets";
import ViewStudentDetailModal from "./ViewStudentDetailModal.web";
import DeleteStudentModal from "./DeleteStudentModal.web";
import DeleteProfileSuccess from "./DeleteProfileSuccess.web";
const TeacherCustomListEllipses = (props: any) => {
  const {
    anchorEl,
    openContextMenu,
    handleCloseContextMenu,
    handleOpenViewDetailModal,
    handleCloseViewDetailModal,
    viewDetailModal,
    contextMenuUserData,
    deleteStudentProfileApi,
    isDeleteSuccess,
    handleCloseDeleteSuccess,
    openDeleteStudentModal,
    handleOpenDeleteProfileModal,
    handleCloseDeleteProfileModal,
    showAddStudentForm,
    togglePasswordVisibility
  } = props;
  const viewDetailImageRef=React.useRef<any>();
  const editImageRef=React.useRef<any>();
  const deleteImageRef=React.useRef<any>();
  const passwordImageRef=React.useRef<any>();
  return (
    <>
      <StyledMenu
        id="customized-menu"
        data-testid="customized-menu"
        anchorEl={anchorEl}
        open={openContextMenu}
        onClose={handleCloseContextMenu}
      >
        <StyledMenuItem
        data-test-id="view-classes-option"
         onClick={(e)=>{
          handleCloseContextMenu(e)
          handleOpenViewDetailModal()
         }}
          onMouseEnter={(e)=> viewDetailImageRef.current && (viewDetailImageRef.current.src = viewDetailOptionActive)}
          onMouseLeave={(e) => viewDetailImageRef.current && (viewDetailImageRef.current.src = viewDetailOptionInactive)}
        >
          <img
           src={viewDetailOptionInactive}
           ref={viewDetailImageRef}
           style={{ marginRight: "10px" }}
          />
          <ListItemText
            primary="View Details"
            color="#48484A"
          />
        </StyledMenuItem>
        <StyledMenuItem
        data-testid="edit-profile-option"
        onMouseEnter={(e)=> editImageRef.current && (editImageRef.current.src = editOptionActive)}
        onMouseLeave={(e) => editImageRef.current && (editImageRef.current.src = editOptionInactive)}
        onClick={() => showAddStudentForm("EDIT", contextMenuUserData.id)}
        >
        <img
           src={editOptionInactive}
           ref={editImageRef}
           style={{ marginRight: "10px" }}
          />
          <ListItemText
            primary="Edit Profile"
            color="#48484A"
          />
        </StyledMenuItem>
        <StyledMenuItem
        data-testid="delete-profile-option"
         onClick={(e)=>{
          handleCloseContextMenu(e)
          handleOpenDeleteProfileModal()
         }}
         onMouseEnter={(e)=> deleteImageRef.current && (deleteImageRef.current.src = deleteOptionActive)}
         onMouseLeave={(e) => deleteImageRef.current && (deleteImageRef.current.src = deleteOptionInactive)}
         >
          <img
           src={deleteOptionInactive}
           ref={deleteImageRef}
           style={{ marginRight: "10px" }}
          />
          <ListItemText
            primary="Delete Profile"
            color="#48484A"
          />
        </StyledMenuItem>
        <StyledMenuItem
        data-testid="password-option"
         onMouseEnter={(e)=> passwordImageRef.current && (passwordImageRef.current.src = passwordOptionActive)}
         onMouseLeave={(e) => passwordImageRef.current && (passwordImageRef.current.src = passwordOptionInactive)}
         onClick={() => togglePasswordVisibility(contextMenuUserData.id)}
        >
          <img
           src={passwordOptionInactive}
           ref={passwordImageRef}
           style={{ marginRight: "10px" }}
          />
          <ListItemText
            primary="Show/Hide Password"
            color="#48484A"
          />
        </StyledMenuItem>
      </StyledMenu>
      <Modal
          data-testid="view-modal"
          open={viewDetailModal}
          onClose={handleCloseViewDetailModal}
          >
             <ViewStudentDetailModal
              handleCloseModal={handleCloseViewDetailModal}
              contextMenuData={contextMenuUserData}
            />
      </Modal>
      <Modal
          data-testid="delete-modal"
          open={openDeleteStudentModal}
          onClose={handleCloseDeleteProfileModal}
          >
             <DeleteStudentModal
              handleCloseModal={handleCloseDeleteProfileModal}
              contextMenuData={contextMenuUserData}
              deleteStudentProfileApi={deleteStudentProfileApi}
            />
      </Modal>
      <Modal
          data-testid="delete-modal"
          open={isDeleteSuccess}
          onClose={handleCloseDeleteSuccess}
          >
             <DeleteProfileSuccess
              handleCloseModal={handleCloseDeleteSuccess}
              contextMenuData={contextMenuUserData}
            />
      </Modal>
    </>
  );
};

export default TeacherCustomListEllipses;
