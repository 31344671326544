import React from "react";
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { EventType } from "../../SchoolAdmin/TeacherCalendar/types";
import moment from "moment";
import { lessons, quizzes } from "../../assets";
import momentTz from "moment-timezone"

export const configJSON = require("../../config.js");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}
interface TeacherCalendar {
  name: string;
  events: any[];
}
interface S {
  calendarData: TeacherCalendar;
  selectedDate: Date;
  loading: boolean;
  setOpen: boolean;
  anchorElFromIcon: any;
  openDeleteEvent: boolean;
  anchorEl: any;
  openContextMenu: boolean;
  openContextMenuFromIcon: boolean;
  isOpenEditMenu: boolean;
  openCreateEventModal: boolean;
  openTeacherLessonModal: boolean;
  teacherClassesData: any[];
  teacherClassActiveTab: boolean;
  selectedTeacherClass: string;
  setTeacherQuizeName: string;
  teacherSearchQuery: any;
  folderSearchQuery: string;
  lessonAssignmentSearchQuery: string;
  quizAssignmentSearchQuery: string;
  teacherPage: number;
  foldersList: any;
  quizFoldersList: any;
  selectedTeacherAssignmentList: any[];
  selectedQuizAssignmentList: any[];
  teacherAssignentId: string;
  teacherFolderId: number;
  teacherQuizFolderId: number;
  currentTeacherLocation: string;
  currentClassObj: any;
  renderTeacher: string;
  selectedTeacherId: number;
  eventOptions: any[];
  isNextBtnDisabled: boolean;
  isNextLessonDisabled: boolean;
  openSaveEventModal: boolean;
  selectedSaveEventTime: string;
  selectedSaveEventDate: string;
  lessonFolderName: string;
  openTeacherBreadCrumb: string;
  teacherLessonFolderId: number;
  quizFolderDirectId: number;
  teacherLessonId: number;
  teacherQuizId: number;
  isNextBtnAssigmentDisabled: boolean;
  selectedStartDateEvent: string;
  selectedStartTimeEvent: string;
  selectedDueDateEvent: string;
  selectedDueEventTime: string;
  isSameDueTime: boolean;
  isSameStartDueTime: boolean;
  isEditSameStartDueTime: boolean;
  editStartDateEvent: string;
  editStartTimeEvent: string;
  editDueDateEvent: string;
  editDueEventTime: string;
  editTeacherFolderId: string
  editTeacherId: string;
  editQuizFolderId: string;
  editQuizId: string;
  validStartDateEvent: boolean;
  editValidStartDateEvent: boolean;
  editValidDueDateEvent: boolean;
  isStartDueDateEvent: boolean;
  isEditStartDueDateEvent: boolean;
  isStartDueTimeEvent: boolean;
  isEditStartDueTimeEvent: boolean;
  validStartTimeEvent: boolean;
  validDueDateEvent: boolean;
  validDueEventTime: boolean;
  editValidDueEventTime: boolean,
  selectedEventType: string;
  successEventModal: boolean;
  successEditEventModal: boolean;
  selectedTeacherDirectLesson: any;
  isValidateCreateEvent: boolean;
  isClassNotFound: boolean;
  selectedTeacherFolderId: number;
  successMessageEvent: string;
  eventsArr: any[];
  eventPropsObj: any;
  editTeacherEventObj: any;
  editEventTitle: string;
  editEditTitleValidate: boolean;
  editEditClassValidate: boolean;
  isValidateEditEvent: boolean;
  editEventClass: any;
  editEventObjective: string;
  editEventLoader: boolean;
  isStartDateClass: boolean;
  isDueDateClass: boolean;
  imageRefEditEvents: React.RefObject<HTMLImageElement>;
  imageRefDeleteEvents: React.RefObject<HTMLImageElement>;
  isSmallScreen: boolean;
  showLessonFolder:any;
  isOpenSubFolder:boolean;
  isOpenFolder:boolean;
  lessonSubFolderName:string;
  isSubFolder:boolean;
  showQuizFolder:any;
  isFolderLoading: boolean,
  isSubFolderLoading: boolean,
}

interface SS {
  id: any;
}

export default class TeacherCalendarController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiCalendarEventCallId: string = "";
  apiDeleteEventCallId: string = "";
  getClassesDataApiCallId: string = "";
  getFoldersListApiCallId: string = "";
  getQuizFoldersListApiCallId: string = "";
  apiCreateEventsCallId: string = "";
  getFoldersLessonListApiCallId: string = "";
  getFoldersQuizListApiCallId: string = "";
  apiEditEventsCallId: string = "";
  showLessonFolderApiCallId: string = "";
  getSharedClassesDataApiCallId:string="";
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);

    this.state = {
      selectedDate: new Date(),
      loading: false,
      calendarData: {
        events: [],
        name: "",
      },
      setOpen: false,
      anchorElFromIcon: null,
      openDeleteEvent: false,
      anchorEl: null,
      openContextMenu: false,
      openContextMenuFromIcon: false,
      isOpenEditMenu: false,
      openCreateEventModal: false,
      openTeacherLessonModal: false,
      teacherClassesData: [],
      currentClassObj: null,
      selectedQuizAssignmentList: [],
      teacherClassActiveTab: false,
      selectedTeacherClass: "CLASS",
      editTeacherEventObj:{},
      setTeacherQuizeName: "",
      teacherSearchQuery: "",
      lessonAssignmentSearchQuery: "",
      teacherPage: 0,
      foldersList: {},
      quizFoldersList: {},
      selectedTeacherAssignmentList: [],
      teacherAssignentId: "",
      currentTeacherLocation: "Select Class",
      renderTeacher: "Select Class",
      selectedTeacherId: 0,
      eventOptions: [
        {
          name: "Lesson",
          image: lessons,
          action: "Lesson",
          key: "lesson",
        },
        {
          name: "Quiz",
          image: quizzes,
          action: "Quiz",
          key: "quiz",
        },
      ],
      isNextBtnDisabled: true,
      isNextLessonDisabled: true,
      teacherFolderId: 0,
      openSaveEventModal: false,
      selectedSaveEventTime: "",
      selectedSaveEventDate: "",
      lessonFolderName: "",
      openTeacherBreadCrumb: "Select Class",
      teacherLessonFolderId: 0,
      quizFolderDirectId: 0,
      teacherLessonId: 0,
      teacherQuizId: 0,
      isNextBtnAssigmentDisabled: true,
      selectedStartDateEvent: "",
      selectedStartTimeEvent: "",
      selectedDueDateEvent: "",
      selectedDueEventTime: "",
      selectedEventType: "",
      editStartDateEvent: '',
      editStartTimeEvent: '',
      editDueDateEvent: '',
      editDueEventTime: '',
      editTeacherFolderId: '',
      editTeacherId: '',
      editQuizFolderId: '',
      editQuizId: '',
      successEventModal: false,
      successEditEventModal: false,
      selectedTeacherDirectLesson: null,
      validStartDateEvent: false,
      validStartTimeEvent: false,
      validDueDateEvent: false,
      validDueEventTime: false,
      editValidDueEventTime: false,
      isValidateCreateEvent: false,
      isClassNotFound: false,
      folderSearchQuery: "",
      selectedTeacherFolderId: 0,
      successMessageEvent: "",
      teacherQuizFolderId: 0,
      quizAssignmentSearchQuery: '',
      eventsArr: [],
      eventPropsObj: null,
      isStartDueDateEvent: false,
      editValidStartDateEvent: false,
      editValidDueDateEvent: false,
      isEditStartDueDateEvent: false,
      editEventTitle: '',
      editEditTitleValidate: false,
      editEditClassValidate: false,
      isValidateEditEvent: false,
      editEventClass: 'Select Class',
      editEventObjective: '',
      editEventLoader: false,
      isStartDueTimeEvent: false,
      isEditStartDueTimeEvent: false,
      isSameDueTime: false,
      isSameStartDueTime: false,
      isEditSameStartDueTime: false,
      isStartDateClass: false,
      imageRefEditEvents: React.createRef<HTMLImageElement>(),
      imageRefDeleteEvents: React.createRef<HTMLImageElement>(),
      isDueDateClass: false,
      isSmallScreen: window.innerWidth <= 600,
      showLessonFolder:{},
      isOpenSubFolder:false,
      isOpenFolder: false,
      lessonSubFolderName:"",
      isSubFolder:false,
      showQuizFolder:{},
      isFolderLoading: false,
      isSubFolderLoading: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.setState({ loading: false });
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.handleCalendarEventsGetAPI(apiRequestCallId, responseJson)
    this.handleClassesData(apiRequestCallId, message);
    this.handleSharedClassesData(apiRequestCallId, message);
    this.handleFolderClassesData(apiRequestCallId, message);
    this.handleCreateEventApiData(apiRequestCallId, message);
    this.handleDeleteEventApiData(apiRequestCallId, message);
    this.handleQuizFolderClassesData(apiRequestCallId, message);
    this.handleGetFoldersLessonListApiData(apiRequestCallId, message);
    this.handleGetFoldersQuizListApiData(apiRequestCallId, message)
    this.handleEditEventApiData(apiRequestCallId, message)
  }

  handleCalendarEventsGetAPI = (apiRequestCallId:any, responseJson:any)=>{
    if (
      apiRequestCallId != null &&
      apiRequestCallId === this.apiCalendarEventCallId
    ) {
      const assignmentsData: any[] = responseJson?.assignment_events?.data;
      const lessonsData: any[] = responseJson?.calendar_events?.data;
      const quizEvents = lessonsData
        ?.filter((quiz: any) => quiz.attributes.event_type === EventType.QUIZ)
        .map((quiz: any) => {
          if (quiz.attributes.event_type === EventType.QUIZ) {
           
            return {
              type: EventType.QUIZ,
              id: quiz.id,
              description: quiz?.attributes?.quiz?.details,
              quiz: quiz?.attributes?.quiz,
              end: momentTz(new Date(this.handleGetTimeUtc(quiz.attributes.due_time_utc))).toDate(),
              endDate: momentTz(new Date(this.handleGetTimeUtc(quiz.attributes.due_time_utc))).format('YYYY-MM-DD'),
              startTime: momentTz(new Date(this.handleGetTimeUtc(quiz.attributes.start_time_utc))).format('hh:mm A'),
              title: quiz.attributes?.quiz?.quiz_name,
              teacherClass: quiz?.attributes?.teacher_class_id,
              startDate: momentTz(new Date(this.handleGetTimeUtc(quiz.attributes.start_time_utc))).format('YYYY-MM-DD'),
              endTime: momentTz(new Date(this.handleGetTimeUtc(quiz.attributes.due_time_utc))).format('hh:mm A'),
              quizFolderId: quiz?.attributes?.quiz_folder_id,
              quizId: quiz?.attributes?.quiz_id,
              start: momentTz(new Date(this.handleGetTimeUtc(quiz.attributes.start_time_utc))).toDate(),
              eventCardType:"calendarEvent"
            }
        }
      });
        
      const assignEvents = assignmentsData?.filter((quiz:any) => quiz.attributes.assignment.data !== null)?.map((quiz: any) => {

            return {
              endTime: momentTz(new Date(this.handleGetTimeUtc(quiz.attributes.due_time_utc))).format('hh:mm A'),
              id: quiz.id,
              title: quiz.attributes.assignment.data.attributes.assignment_name,
              start: momentTz(this.handleGetTimeUtc(quiz.attributes.start_time_utc)).toDate(),
              end: momentTz(new Date(this.handleGetTimeUtc(quiz.attributes.due_time_utc))).toDate(),
              startDate: momentTz(this.handleGetTimeUtc(quiz.attributes.start_time_utc)).format('YYYY-MM-DD'),
              description: quiz.attributes.assignment.data.attributes.details,
              teacherClass: quiz.attributes.teacher_class_id,
              assignment: quiz.attributes.assignment,
              startTime: momentTz(this.handleGetTimeUtc(quiz.attributes.start_time_utc)).format('hh:mm A'),
              endDate: momentTz(new Date(this.handleGetTimeUtc(quiz.attributes.due_time_utc))).format('YYYY-MM-DD'),
              assignFolderId: quiz?.attributes?.assignment_folder_id,
              type: EventType.ASSIGNMENT,
              eventCardType:"assignEvent"
            }
        });
      const assignedQuizEvents = assignmentsData
      ?.filter((event:any) => event.attributes.quiz.data !== null)?.map((event: any) => {
          return {
          endTime: momentTz(new Date(this.handleGetTimeUtc(event.attributes.due_time_utc))).format('hh:mm A'),
          startDate: momentTz(new Date(this.handleGetTimeUtc(event.attributes.start_time_utc))).format('YYYY-MM-DD'),
          id: event.id,
          title: event.attributes.quiz.data.attributes.quiz_name,
          start: momentTz(new Date(this.handleGetTimeUtc(event.attributes.start_time_utc))).toDate(),
          end: momentTz(new Date(this.handleGetTimeUtc(event.attributes.due_time_utc))).toDate(),
          description: event.attributes.quiz.data.attributes.details,
          teacherClass: event.attributes.teacher_class_id,
          assignment: event.attributes.assignment,
          startTime: momentTz(new Date(this.handleGetTimeUtc(event.attributes.start_time_utc))).format('hh:mm A'),
          endDate: momentTz(new Date(this.handleGetTimeUtc(event.attributes.due_time_utc))).format('YYYY-MM-DD'),
          assignFolderId: event.attributes.assignment_folder_id,
          type: EventType.QUIZ,
          eventCardType:"assignEvent"
        };
      });

      const lessonsEvents: any[] = lessonsData
        ?.filter(
          (lesson: any) => lesson.attributes.event_type === EventType.LESSON
        )
        ?.map((lesson: any) => {
          return {
            id: lesson.id,
            title: lesson.attributes.lesson.lesson_name,
            start: momentTz(new Date(this.handleGetTimeUtc(lesson.attributes.start_time_utc))).toDate(),
            end: momentTz(new Date(this.handleGetTimeUtc(lesson.attributes.due_time_utc))).toDate(),
            startDate: momentTz(new Date(this.handleGetTimeUtc(lesson.attributes.start_time_utc))).format('YYYY-MM-DD'),
            endDate: momentTz(new Date(this.handleGetTimeUtc(lesson.attributes.due_time_utc))).format('YYYY-MM-DD'),
            startTime: momentTz(new Date(this.handleGetTimeUtc(lesson.attributes.start_time_utc))).format('hh:mm A'),
            endTime: momentTz(new Date(this.handleGetTimeUtc(lesson.attributes.due_time_utc))).format('hh:mm A'),
            lessonFolderId: lesson.attributes.lesson_folder_id,
            lesson: lesson.attributes.lesson,
            lessonId: lesson.attributes.lesson_id,
            description: lesson.attributes.lesson.lesson_objective,
            teacherClass: lesson.attributes.teacher_class_id,
            type: EventType.LESSON,
            eventCardType:"calendarEvent"
          }
        });

      
      if (
        lessonsEvents !== undefined ||
        quizEvents !== undefined ||
        assignEvents !== undefined||assignedQuizEvents!==undefined
      ) {
        const events = [...lessonsEvents, ...quizEvents, ...assignEvents,...assignedQuizEvents];
        this.setState({
          calendarData: {
            name: responseJson.full_name,
            events: this.refactorEventsBasedOnOverlap(events),
          },
          loading: false,
        });
      }
      if (
        responseJson?.message === "Events Not found" ||
        !lessonsEvents ||
        !quizEvents ||
        !assignEvents
      ) {
        this.setState({
          calendarData: {
            name: responseJson?.full_name,
            events: this.refactorEventsBasedOnOverlap([]),
          },
        });
        this.parseApiErrorResponse(responseJson);
      }
    }
  }

  handleEditEventApiData = (apiRequestCallId: any, message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiEditEventsCallId !== null &&
      this.apiEditEventsCallId === apiRequestCallId
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson?.errors && responseJson) {
        this.setState({
          isOpenEditMenu: false,
          editEventLoader: false,
          successEditEventModal: true,
          isEditSameStartDueTime: false,
        })
        this.getTeacherCalendarEventsApi();
      }
    }
  };

  handleGetFoldersLessonListApiData = (apiRequestCallId: any, message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getFoldersLessonListApiCallId !== null &&
      this.getFoldersLessonListApiCallId === apiRequestCallId
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson?.errors && responseJson) {
        const lessons = responseJson.items
    .filter((item:any) => item.data.type === 'lesson')
    .map((lesson:any) => ({
      data: lesson.data,
    }));
    
  const folders = responseJson.items
    .filter((item:any) => item.data.type === 'lesson_folder') 
    .map((folder:any) => ({
      data: folder.data,
  }));
        this.setState({
            showLessonFolder:{folders:folders,lessons:lessons},
            isSubFolderLoading:false
        });
      } else {
        this.setState({
          isSubFolderLoading: false
        })
      }
    }
  };

  handleGetTimeUtc = (startTimeUtc:string)=>{
    const startTime = startTimeUtc?.split("+")[0]
    return startTime
  }

  handleGetFoldersQuizListApiData = (apiRequestCallId: any, message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getFoldersQuizListApiCallId !== null &&
      this.getFoldersQuizListApiCallId === apiRequestCallId
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson?.errors && responseJson) {
        const quiz = responseJson.items
          .filter((item: any) => item.data.type === 'quizzes')
          .map((quiz: any) => ({
            data: quiz.data,
          }));

        const folders = responseJson.items
          .filter((item: any) => item.data.type === 'folder')
          .map((folder: any) => ({
            data: folder.data,
          }));
        this.setState({
            showQuizFolder:{folders:folders,quizzes:quiz},
            isSubFolderLoading:false
        });
      } else {
        this.setState({isSubFolderLoading:false})
      }
    }
  };

  handleCreateEventApiData = (apiRequestCallId: any, message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiCreateEventsCallId !== null &&
      this.apiCreateEventsCallId === apiRequestCallId
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson?.errors && responseJson?.data) {
        if (responseJson?.data?.attributes?.event_type === "lesson") {
          this.setState({
            successMessageEvent:
              responseJson?.data?.attributes?.lesson?.lesson_name,
          });
        } else {
          this.setState({
            successMessageEvent:
              responseJson?.data?.attributes?.quiz?.quiz_name,
          });
        }
        this.setState(
          {
            successEventModal: true,
            openSaveEventModal: false,
            openTeacherLessonModal: false,
            selectedStartDateEvent: "",
            selectedStartTimeEvent: "",
            isSameDueTime: false,
            isSameStartDueTime: false,
            selectedDueDateEvent: "",
            selectedDueEventTime: "",
            selectedTeacherId: 0,
            teacherFolderId: 0,
            teacherLessonId: 0,
            teacherLessonFolderId: 0,
            quizFolderDirectId: 0,
            openTeacherBreadCrumb: "Select Class",
            selectedTeacherClass: "CLASS",
            isValidateCreateEvent: false,
            teacherQuizFolderId: 0,
            teacherQuizId: 0,
            folderSearchQuery: "",
            teacherSearchQuery: "",
            lessonAssignmentSearchQuery: "",
            quizAssignmentSearchQuery: '',
            currentClassObj:null,
            isStartDueTimeEvent: false,
            isStartDateClass: false,
            isDueDateClass: false,
          },
          () => {
            this.getTeacherCalendarEventsApi();
          }
        );
      }
    }
  };

  handleClassesData = (apiRequestCallId: any, message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getClassesDataApiCallId != null &&
      this.getClassesDataApiCallId === apiRequestCallId
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson?.errors && responseJson?.data) {
        this.setState({
          teacherClassesData: responseJson?.data,
          loading: false,
          isClassNotFound: false,
        });
      } else if (responseJson?.message === "Classes not found") {
        this.setState({
          teacherClassesData: [],
          loading: false,
          isClassNotFound: true,
        });
      }
    }
  };

  getSharedClassesData(): boolean {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSharedClassesDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSharedListing}?search=${this.state.teacherSearchQuery}`
    );
  
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleSharedClassesData(apiRequestCallId: string, message: any) {
    if (
      apiRequestCallId != null &&
      apiRequestCallId === this.getSharedClassesDataApiCallId
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson?.errors && responseJson?.lessons?.data) {

        const sharedLessonsResponseData = responseJson?.lessons?.data;
        const formattedSharedLessonsResponseData = sharedLessonsResponseData?.map((lesson:any) => {
          const { attributes: {teacher_class: { data: { id, type, attributes}} }} = lesson;
          return {
            id,
            type,
            attributes
          }
        }).filter((lesson1:any, i:number, arr:any) => arr.findIndex((lesson2:any) => (lesson2.id === lesson1.id)) === i);
        this.setState({
          teacherClassesData: formattedSharedLessonsResponseData,
          loading: false,
          isClassNotFound: false,
        });
      } else if (responseJson?.message === "Classes not found") {
        this.setState({
          teacherClassesData: [],
          loading: false,
          isClassNotFound: true,
        });
      }
    }
  }
  handleFolderClassesData = (apiRequestCallId: any, message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getFoldersListApiCallId != null &&
      this.getFoldersListApiCallId === apiRequestCallId
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson?.errors) {
        if(this.state.teacherClassActiveTab) {
          const sharedLessonsFolderList = responseJson;
          sharedLessonsFolderList.lessons.data = responseJson?.lessons?.data?.filter((lesson:any) => lesson?.attributes?.shared) || [];
          this.setState({ foldersList: sharedLessonsFolderList, loading: false, isFolderLoading: false });
        }else{
          this.setState({ foldersList: responseJson, loading: false, isFolderLoading: false });
        }
      } else {
        this.setState({
          foldersList: { lesson_folders: [], lessons: [], loading: false },
          isFolderLoading: false
        });
      }
    }
  };

  handleDeleteEventApiData = (apiRequestCallId: any, message: any) => {
    if (
      this.apiDeleteEventCallId !== null &&
      this.apiDeleteEventCallId === apiRequestCallId &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.message === "Delete Successfully") {
        this.setState({ openDeleteEvent: false,openContextMenuFromIcon: false, anchorElFromIcon: null,  }, () => {
          this.getTeacherCalendarEventsApi();
        });
      }
    }
  };

  handleQuizFolderClassesData = (apiRequestCallId: any, message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getQuizFoldersListApiCallId != null &&
      this.getQuizFoldersListApiCallId === apiRequestCallId
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson?.errors) {
        if(this.state.teacherClassActiveTab){
          const sharedQuizzesFolderList = responseJson;
          sharedQuizzesFolderList.quizzes.data = responseJson?.quizzes?.data?.filter((quiz:any) => quiz?.attributes?.shared) || [];
          this.setState({ quizFoldersList: sharedQuizzesFolderList, loading: false, isFolderLoading: false });
        }else{
          this.setState({ quizFoldersList: responseJson, loading: false, isFolderLoading: false });
        }
      } else {
        this.setState({
          quizFoldersList: { quiz_folders: [], quizzes: [], loading: false },
          isFolderLoading: false
        });
      }
    }
  };

  async componentDidMount() {
    this.getTeacherCalendarEventsApi();
    this.getClassesData()
  }
  // Function to check if an event spans multiple days
  isMultiDayEvent(event: {end:string, start:string}) {
    const eventEndDate = moment(event.end).toDate();
    const eventStartDate = moment(event.start).toDate();
    return eventStartDate.getDate() !== eventEndDate.getDate();
  }
  // Function to check if two events overlap
  doEventsOverlap(event1: any, event2: any) {
    return event1?.start < event2?.end && event1?.end > event2?.start;
  }
  removeMultiDayEvents(eventsData: any) {
    const multiDayEvents = [];
    const events = [];

    for (let eventData of eventsData) {
      if (!!this.isMultiDayEvent(eventData)) {
        eventData["multiDayEvent"] = true;
        eventData["overlap"] = false;
        multiDayEvents.push(eventData);
        continue; // Skip further processing for multi-day events
      } else {
        eventData["multiDayEvent"] = false;
        eventData["overlap"] = true;
        events.push(eventData);
      }
    }
    return { multiDayEvents, events };
  }
  refactorEventsBasedOnOverlap(eventsData: any) {
    // Iterate through the events to categorize them
    const { multiDayEvents, events } = this.removeMultiDayEvents(eventsData);

    const mainArr = [...events, ...multiDayEvents];
    for (let [i, event] of mainArr.entries()) {
      if (event.multiDayEvent === true) continue;
      let overlaps = false;
      // Compare the current event with all other mainArr
      for (let [j, eventj] of mainArr.entries()) {
        if (i !== j && this.doEventsOverlap(event, eventj)) {
          overlaps = true;
          break; // No need to check further if there's an overlap
        }
      }

      if (event?.multiDayEvent && !event?.overlap) {
        event["overlap"] = true;
        event["multiDayEvent"] = false;
      } else {
        event["overlap"] = false;
        event["multiDayEvent"] = true;
      }
    }
    return [...mainArr];
  }

  handleOpenCreateEventModal = () => {
    this.setState({ openCreateEventModal: true });
  };

  handleCloseEventModal = () => {
    this.setState({ openCreateEventModal: false });
  };

  handleDateChange = (date: Date) => {
    this.setState({ selectedDate: date });
    this.setState({ setOpen: false }, () => {
      this.getTeacherCalendarEventsApi();
    });
  };

  handleOpenEditMenu = () => {
    this.setState({ isOpenEditMenu: true, openContextMenuFromIcon: false, anchorElFromIcon: null, })
  }

  handleOpen = () => {
    this.setState({ setOpen: true });
  };

  handleClose = () => {
    this.setState({ setOpen: false });
  };

  handleOpenDeleteEvent = (event: React.MouseEvent<{}>) => {
    event.preventDefault();
    this.setState({ openDeleteEvent: true });
  };

  handleTeacherClassTabs = (status: boolean, type:string) => {
    if(this.state.openTeacherBreadCrumb === 'Select Class'){
      this.setState({ teacherClassActiveTab: status, loading: true, });
      // this.getQuizData(value)
      if(type === 'Shared Library'){
        this.getSharedClassesData();
      }else {
        this.getClassesData();
      }
    }
  };
  setTeacherQuizeName = (value: any) => {
    if (
      this.state.selectedTeacherClass === "FOLDER" &&
      this.state.selectedEventType === "quiz"
    ) {
      this.setState(
        {
          folderSearchQuery: value,
        },
        () => {
          this.getQuizFoldersData();
        }
      );
    }

    if (
      this.state.selectedTeacherClass === "FOLDER" &&
      this.state.selectedEventType === "lesson"
    ) {
      this.setState(
        {
          folderSearchQuery: value,
        },
        () => {
          this.getFoldersData();
        }
      );
    }

    if (this.state.selectedTeacherClass === "CLASS" && !this.state.teacherClassActiveTab) {
      this.setState(
        {
          teacherSearchQuery: value,
        },
        () => {
          this.getClassesData();
        }
      );
    }
    if (this.state.selectedTeacherClass === "CLASS" && this.state.teacherClassActiveTab) {
      this.setState(
        {
          teacherSearchQuery: value,
        },
        () => {
          this.getSharedClassesData();
        }
      );
    }

    if (
      this.state.selectedTeacherClass === "ASSIGNMENTS" &&
      this.state.selectedEventType === "quiz"
    ) {
      this.setState(
        {
          quizAssignmentSearchQuery: value,
        },
        () => {
          this.getQuizDataAssignments();
        }
      );
    }

    if (
      this.state.selectedTeacherClass === "ASSIGNMENTS" &&
      this.state.selectedEventType === "lesson"
    ) {
      this.setState(
        {
          lessonAssignmentSearchQuery: value,
        },
        () => {
          this.getFoldersLessonData();
        }
      );
    }
  };

  handleFolders = (item2: any) => {
    if (item2.type === "lesson_folder") {
      this.setState({
        teacherFolderId: item2?.id,
        lessonFolderName: item2.attributes.folder_name,
        isNextLessonDisabled: false,
        teacherLessonId: 0,
        openTeacherBreadCrumb: "My Folder",
        selectedTeacherDirectLesson: null,
      },
      ()=>{
        this.getFoldersLessonData()
      }
    );
    }
  };

  handleLessonFolders = (item2: any) => {
    if (item2.type === "lesson") {
      this.setState({
        teacherLessonId: item2?.id,
        selectedTeacherDirectLesson: item2,
        lessonFolderName: item2.attributes.folder_name,
        isNextLessonDisabled: false,
        teacherFolderId: 0,
      });
    }
  };

  handleQuizFolders = (item2: any) => {
    if (item2?.attributes?.folder_type === "quiz") {
      this.setState({
        teacherQuizFolderId: item2?.id,
        lessonFolderName: item2.attributes.folder_name,
        isNextLessonDisabled: false,
        teacherQuizId: 0,
        openTeacherBreadCrumb: "My Folder",
        selectedTeacherDirectLesson: null,
      },()=>{
        this.getQuizDataAssignments()
      });
    }
  };

  handleQuizLessonFolders = (item2: any) => {
    if (item2.type === "quizzes") {
      this.setState({
        teacherQuizId: item2?.id,
        selectedTeacherDirectLesson: item2,
        lessonFolderName: item2.attributes.folder_name,
        isNextLessonDisabled: false,
        teacherQuizFolderId: 0,
      });
    }
  };

  handleAssignment = (item: any) => {
    if (item.type === "lesson") {
      this.setState({
        teacherLessonFolderId: item?.id,
        isNextBtnAssigmentDisabled: false,
        selectedTeacherDirectLesson: item,
      });
    }
  };

  handleQuizAssignment = (item: any) => {
    if (item?.type === "quizzes") {
      this.setState({
        quizFolderDirectId: item?.id,
        isNextBtnAssigmentDisabled: false,
      });
    }
  };

  handleNextSaveLesson = () => {
    if (
      this.state.foldersList?.lessons?.data?.length > 0 ||
      this.state.selectedQuizAssignmentList?.length > 0
    ) {
      this.setState({ openSaveEventModal: true,
        isOpenFolder:false,isOpenSubFolder:false,isSubFolder:false
       });
    }
  };

  handleLinkTeacherClick = (
    isOpen: string,
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    event.preventDefault();
    this.setState({ openTeacherBreadCrumb: isOpen });
    if (isOpen === "Select Class") {
      this.setState(
        {
          foldersList: [],
          selectedTeacherClass: "CLASS",
          isNextBtnDisabled: true,
          selectedTeacherId: 0,
          isNextBtnAssigmentDisabled: true,
          teacherFolderId: 0,
          teacherLessonId: 0,
          teacherQuizFolderId: 0,
          teacherQuizId: 0,
          folderSearchQuery: "",
          teacherSearchQuery: "",
          lessonAssignmentSearchQuery: "",
          quizAssignmentSearchQuery: '',
          isOpenFolder:false,
        isOpenSubFolder:false,
        isSubFolder:false
        },
        () => {
          if(!this.state.teacherClassActiveTab){
            this.getClassesData();
          }else {
            this.getSharedClassesData();
          }
        }
      );
    } else if (isOpen === "My Folder") {
      this.setState({
        selectedTeacherClass: "FOLDER",
        teacherLessonId: 0,
        teacherFolderId: 0,
        teacherLessonFolderId: 0,
        quizFolderDirectId: 0,
        isNextBtnAssigmentDisabled: true,
        openTeacherBreadCrumb: "My Folder",
        teacherQuizFolderId: 0,
        teacherQuizId: 0,
        folderSearchQuery: "",
        teacherSearchQuery: "",
        lessonAssignmentSearchQuery: "",
        quizAssignmentSearchQuery: '',
        isOpenFolder:false,
        isOpenSubFolder:false,
        isSubFolder:false
      });
    } else if (isOpen === "My SubFolder") {
      this.setState({
        selectedTeacherClass: "ASSIGNMENTS",
        teacherLessonId: 0,
        teacherFolderId: 0,
        teacherLessonFolderId: 0,
        quizFolderDirectId: 0,
        isNextBtnAssigmentDisabled: true,
        openTeacherBreadCrumb: "My SubFolder",
        teacherQuizFolderId: 0,
        teacherQuizId: 0,
        folderSearchQuery: "",
        teacherSearchQuery: "",
        lessonAssignmentSearchQuery: "",
        quizAssignmentSearchQuery: '',
        isOpenSubFolder:false,
        isSubFolder:false
      });
    }
  };

  handleCloseSuccessModal = () => {
    this.setState({ successEventModal: false });
  };

  handleEditCloseSuccessModal = () => {
    this.setState({ successEditEventModal: false });
  };

  handleSaveCloseEventModal = () => {
    this.setState({
      openSaveEventModal: false,
      openTeacherLessonModal: false,
      openCreateEventModal: false,
      selectedTeacherId: 0,
      selectedStartDateEvent: "",
      selectedStartTimeEvent: "",
      isSameDueTime: false,
      isSameStartDueTime: false,
      selectedDueDateEvent: "",
      selectedDueEventTime: "",
      teacherFolderId: 0,
      teacherLessonId: 0,
      teacherLessonFolderId: 0,
      quizFolderDirectId: 0,
      openTeacherBreadCrumb: "Select Class",
      selectedTeacherClass: "CLASS",
      isNextBtnDisabled: true,
      isNextLessonDisabled: true,
      isNextBtnAssigmentDisabled: true,
      selectedTeacherDirectLesson: null,
      isValidateCreateEvent: false,
      teacherQuizFolderId: 0,
      teacherQuizId: 0,
      currentClassObj:null,
    });
  };

  handleSaveCloseEditEventModal = () => {
    this.setState({ isOpenEditMenu: false, editEventLoader: false,isEditSameStartDueTime: false,isEditStartDueDateEvent: false, isEditStartDueTimeEvent: false})
  }

  initStartDateVal = () => {
    return this.state.selectedStartDateEvent
      ? moment(this.state.selectedStartDateEvent, "DD/MM/YYYY", true).toDate()
      : undefined;
  };

  initDueDateVal = () => {
    return this.state.selectedDueDateEvent
      ? moment(this.state.selectedDueDateEvent, "DD/MM/YYYY", true).toDate()
      : undefined;
  };

  editStartDateVal = () => {
    return this.state.editStartDateEvent
      ? moment(this.state.editStartDateEvent, "DD/MM/YYYY", true).toDate()
      : undefined;
  };

  editDueDateVal = () => {
    return this.state.editDueDateEvent
      ? moment(this.state.editDueDateEvent, "DD/MM/YYYY", true).toDate()
      : undefined;
  };

  editStartTimeVal = () => {
    return this.state.editStartTimeEvent
      ? moment(this.state.editStartTimeEvent, "hh:mm A").toDate()
      : undefined;
  };

  editDueTimeVal = () => {
    return this.state.editDueEventTime
      ? moment(this.state.editDueEventTime, "hh:mm A").toDate()
      : undefined;
  };

  initStartTimeVal = () => {
    return this.state.selectedStartTimeEvent
      ? moment(this.state.selectedStartTimeEvent, "hh:mm A").toDate()
      : undefined;
  };

  initDueTimeVal = () => {
    return this.state.selectedDueEventTime
      ? moment(this.state.selectedDueEventTime, "hh:mm A").toDate()
      : undefined;
  };

  renderSearchInputType = () => {
    if (this.state.teacherSearchQuery) {
      return this.state.teacherSearchQuery;
    } else if (this.state.lessonAssignmentSearchQuery) {
      return this.state.lessonAssignmentSearchQuery;
    }else if (this.state.quizAssignmentSearchQuery) {
      return this.state.quizAssignmentSearchQuery
    }
    else if (this.state.folderSearchQuery) {
      return this.state.folderSearchQuery;
    } else {
      return "";
    }
  };

  handleEditTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ editEventTitle: event.target.value });
  };

  handleEditClassChange = (event: React.ChangeEvent<{
    name?: string;
    value: unknown;
  }>) => {
    event.preventDefault()
    this.setState({ editEventClass: event.target.value });
  };

  handleEditEventObjective = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ editEventObjective: event.target.value });
  }

  validateDate = (dateStr:any) => {
    const dateToValidate = moment(dateStr, 'DD/MM/YYYY', true);
    if (!dateToValidate.isValid()) {
      return false;
    }
    return dateToValidate.isBetween(this.state.currentClassObj?.attributes?.start_date, this.state.currentClassObj?.attributes?.end_date, 'days', '[]');
  };

  validateStartDate = (dateStr:any) => {
    const dateToValidate = moment(dateStr, 'DD/MM/YYYY', true);
    if (!dateToValidate.isValid()) {
      return false;
    }
    return dateToValidate.isSameOrAfter(this.state.currentClassObj?.attributes?.start_date, 'day');
  };

  validateEndDate = (dateStr:any) => {
    const dateToValidate = moment(dateStr, 'DD/MM/YYYY', true);
    if (!dateToValidate.isValid()) {
      return false;
    }
    return dateToValidate.isSameOrBefore(this.state.currentClassObj?.attributes?.end_date, 'day');
  };

  handleIsStartEndDateTime=(startDateTime:any, endDateTime:any) =>{
    if(startDateTime.isAfter(endDateTime)){
      this.setState({isStartDueDateEvent: false,isSameStartDueTime: false})
    }
  }

  handleSaveStartDateChange = (date: any) => {
    let endDateTime = moment(this.state.selectedDueDateEvent, "DD/MM/YYYY");
    let startDateTime = moment(this.state.selectedStartDateEvent, "DD/MM/YYYY");
    const formattedDate: string = moment(date).format("DD/MM/YYYY");
    this.setState({ selectedStartDateEvent: formattedDate, isStartDueDateEvent: false,isEditStartDueDateEvent: false });
    if(this.validateStartDate(formattedDate)){
      this.setState({ isStartDateClass: false,isStartDueTimeEvent: false })
      this.handleIsStartEndDateTime(startDateTime, endDateTime)
    }
  };

  handleSaveDueDateChange = (date: any) => {
    let startDateTime = moment(this.state.selectedStartDateEvent, "DD/MM/YYYY");
    const formattedDate: string = moment(date).format("DD/MM/YYYY");
    this.setState({ selectedDueDateEvent: formattedDate });
    let endDateTime = moment(this.state.selectedDueDateEvent, "DD/MM/YYYY");
    if(this.validateEndDate(formattedDate)){
      this.handleIsStartEndDateTime(startDateTime, endDateTime)
      this.setState({ isDueDateClass: false,isStartDueTimeEvent: false, isStartDueDateEvent: false })
    }
  };

  handleStartTimeChange = (time: any) => {
    let formatted = moment(time).format("hh:mm A");
    this.setState({ selectedStartTimeEvent: formatted, isSameStartDueTime: false});
  };

  handleSameTimeValidate = () =>{
    if(this.state.selectedStartDateEvent === this.state.selectedDueDateEvent && this.state.selectedStartTimeEvent === this.state.selectedDueEventTime){
      return true;
    }
  }


  handleEditDueDateChange = (date: any) => {
    const formattedDate: string = moment(date).format("DD/MM/YYYY");
    this.setState({ editDueDateEvent: formattedDate, isEditSameStartDueTime: false, isEditStartDueDateEvent: false});
  };

  handleEditStartDateChange = (date: any) => {
    const formattedDate: string = moment(date).format("DD/MM/YYYY");
    this.setState({ editStartDateEvent: formattedDate, isEditSameStartDueTime: false,isEditStartDueDateEvent:false});
  };

  handleDueTimeChange = (event: any) => {
    let formatted = moment(event).format("hh:mm A");
    this.setState({ selectedDueEventTime: formatted, isStartDueTimeEvent: false, isSameStartDueTime: false});
  };

  handleEditStartTimeChange = (time: any) => {
    let formatted = moment(time).format("hh:mm A");
    this.setState({ editStartTimeEvent: formatted, isEditSameStartDueTime: false, isEditStartDueTimeEvent: false });
  };

  handleEditDueTimeChange = (event: any) => {
    let formatted = moment(event).format("hh:mm A");
    this.setState({ editDueEventTime: formatted,isEditStartDueTimeEvent: false, isEditSameStartDueTime: false });
  };

  handleCloseDeleteEvent = () => {
    this.setState({ openDeleteEvent: false, anchorElFromIcon: null, openContextMenuFromIcon: false });
  };

  handleOpenContextMenu = (event: any) => {
    event.preventDefault();
    this.setState({ anchorElFromIcon: event.currentTarget });
    this.setState({ openContextMenu: true });
  };

  handleCloseContextMenu = (event: any) => {
    event.preventDefault();
    this.setState({ anchorEl: null });
    this.setState({ openContextMenu: false });
  };

  handleEditTeacherClassesData = (eventProps:any)=>{
    return this.state.teacherClassesData.find((val:any)=>(Number(val?.id) ===eventProps?.event?.teacherClass))
  }

  handleOpenContextMenuFromIcon = (eventProps: any, event: React.MouseEvent<{}>,) => {
    this.setState({
      editStartDateEvent: moment(eventProps?.event?.startDate).format("DD/MM/YYYY"),
      editStartTimeEvent: eventProps?.event?.startTime,
      editDueDateEvent: moment(eventProps?.event?.endDate).format("DD/MM/YYYY"),
      editDueEventTime: eventProps?.event?.endTime,
    });

    this.setState({
      openContextMenuFromIcon: !this.state.openContextMenuFromIcon,
      eventPropsObj: eventProps,
      anchorElFromIcon: event.currentTarget,
      editEventTitle: eventProps?.event?.title,
      editEventClass: eventProps?.event?.teacherClass,
      editTeacherEventObj: this.handleEditTeacherClassesData(eventProps),
      editEventObjective: eventProps?.event?.description,
      editTeacherFolderId: eventProps?.event?.lessonFolderId,
      editTeacherId: eventProps?.event?.lessonId,
      editQuizFolderId: eventProps?.event?.quizFolderId,
      editQuizId: eventProps?.event?.quizId,
    });
  };

  handleEditEventTypeCheck = () => {
    if (this.state.eventPropsObj?.event?.type === 'lesson') {
      return "Lesson";
    } else if (this.state.eventPropsObj?.event?.type === 'quiz') {
      return "Quiz";
    }else{
      return "Assignment"
    }
  }

  handleCloseContextMenuFromIcon = (event: any) => {
    event.preventDefault();
    this.setState({ anchorElFromIcon: null });
    this.setState({ openContextMenuFromIcon: false });
  };

  handleTeacherLessonModal = () => {
    this.setState({
      openTeacherLessonModal: false,
      teacherClassesData: [],
      selectedTeacherClass: "CLASS",
      selectedTeacherId: 0,
      selectedTeacherFolderId: 0,
      openTeacherBreadCrumb: "Select Class",
      folderSearchQuery: "",
      teacherSearchQuery: "",
      teacherQuizFolderId: 0,
      teacherQuizId: 0,
      isSubFolder:false,
      isOpenFolder:false,
      isOpenSubFolder:false,
      teacherClassActiveTab: false,
    });
  };

  getTeacherClasses(event: any, classId: any) {
    this.setState(
      {
        currentTeacherLocation: classId?.attributes?.class_name,
        currentClassObj:classId,
        renderTeacher: "My Library",
        selectedTeacherId: classId.id,
        selectedTeacherFolderId: classId?.attributes?.teacher_id,
        isNextBtnDisabled: false,
        lessonFolderName: "",
      },
      () => {
        if (this.state.selectedEventType === "lesson") {
          this.getFoldersData();
        } else if (this.state.selectedEventType === "quiz") {
          this.getQuizFoldersData();
        }
      }
    );
  }

  handleSelectEventOptions = (option: any) => {
    this.setState({
      openTeacherLessonModal: true,
      openCreateEventModal: false,
      selectedTeacherClass: "CLASS",
      loading: true,
      selectedEventType: option?.key,
    });
    this.getClassesData();
  };

  handleTeacherPreviousModal = () => {
    if (this.state.selectedTeacherClass === "CLASS") {
      this.setState({
        openTeacherLessonModal: false,
        openCreateEventModal: true,
        selectedTeacherId: 0,
        isNextBtnDisabled: true,
        teacherSearchQuery: "",
        teacherClassActiveTab: false,
        teacherClassesData: [],
      });
    } else if (this.state.selectedTeacherClass === "FOLDER") {
      this.setState({
        openTeacherLessonModal: true,
        selectedTeacherClass: "CLASS",
        selectedTeacherId: 0,
        isNextBtnDisabled: true,
        teacherSearchQuery: "",
      });
    }
  };

  handleNextPageFolder = () => {
    this.setState({
      selectedTeacherClass: "FOLDER",
      openTeacherBreadCrumb: "My Class",
      teacherSearchQuery: "",
      folderSearchQuery: "",
    });
  };
  handleClassPreviousModal = () => {
    this.setState(
      {
        selectedTeacherClass: "CLASS",
        isNextLessonDisabled: true,
        teacherLessonId: 0,
        teacherFolderId: 0,
        selectedTeacherId: 0,
        openTeacherBreadCrumb: "Select Class",
        isNextBtnDisabled: true,
        teacherSearchQuery: "",
        teacherQuizFolderId: 0,
        teacherQuizId: 0,
      },
      () => {
        if(!this.state.teacherClassActiveTab){
          this.getClassesData();
        }
      }
    );
  };

  handleNextSubLessonPage = () => {
    if (this.state.selectedTeacherDirectLesson !== null) {
      this.setState({
        openSaveEventModal: true,
        openTeacherLessonModal: false,
        openCreateEventModal: false,
        teacherSearchQuery: "",
        folderSearchQuery: "",
      });
    } else if (
     !this.state.isOpenSubFolder
    ) {
      this.setState({
        openTeacherLessonModal: true,
        openSaveEventModal: false,
        selectedTeacherClass: "ASSIGNMENTS",
        teacherSearchQuery: "",
        folderSearchQuery: "",
        isOpenFolder: true
      });
    }else if (
      this.state.isOpenSubFolder===true
    ) {
      this.setState({
        openTeacherLessonModal: true,
        openSaveEventModal: false,
        selectedTeacherClass: "SUBFOLDER",
        teacherSearchQuery: "",
        folderSearchQuery: "",
        isSubFolder:true
      });
    }
  };

  handleFolderPreviousModal = () => {
    this.setState({
      selectedTeacherClass: "ASSIGNMENTS",
      teacherLessonFolderId: 0,
      quizFolderDirectId: 0,
      isNextBtnAssigmentDisabled: true,
      isNextLessonDisabled: true,
      teacherFolderId: 0,
      teacherLessonId: 0,
      selectedTeacherDirectLesson: null,
      openTeacherBreadCrumb: "My Folder",
      teacherSearchQuery: "",
      folderSearchQuery: "",
      teacherQuizFolderId: 0,
      teacherQuizId: 0,
      lessonAssignmentSearchQuery: "",
      isOpenSubFolder:false,
      isSubFolder:false,
    });
  };
  handleSubFolderPreviousModal = () => {
    this.setState({
      selectedTeacherClass: "FOLDER",
      teacherLessonFolderId: 0,
      quizFolderDirectId: 0,
      isNextBtnAssigmentDisabled: true,
      isNextLessonDisabled: true,
      teacherFolderId: 0,
      teacherLessonId: 0,
      selectedTeacherDirectLesson: null,
      openTeacherBreadCrumb: "My Library",
      teacherSearchQuery: "",
      folderSearchQuery: "",
      teacherQuizFolderId: 0,
      teacherQuizId: 0,
      lessonAssignmentSearchQuery: "",
      isOpenFolder:false
    });
  };
  getTeacherCalendarEventsApi() {
    this.setState({loading:true})
    const year = this.state.selectedDate.getFullYear();
    const month = String(this.state.selectedDate.getMonth() + 1).padStart(
      2,
      "0"
    );
    const day = String(this.state.selectedDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCalendarEventCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTeacherEventsApi}?current_date=${formattedDate}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleDeleteEvent = (id: number, type: string) => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteEventCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteTeacherCalendarEvents}?type=${type}&id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.Delete
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getClassesData = () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getClassesDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.teacherSearchQuery
        ? `${configJSON.getClassesDataApi}?class_name=${this.state.teacherSearchQuery}`
        : configJSON.getClassesDataApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getFoldersData = () => {
    this.setState({ isFolderLoading: true });
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken"),
    };

    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFoldersListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getLessonsFoldersListApi}?class_id=${this.state.selectedTeacherId}&teacher_id=${this.state.selectedTeacherFolderId}&search=${this.state.folderSearchQuery}&type=full_list`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getQuizFoldersData = () => {
    this.setState({isFolderLoading: true})
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getQuizFoldersListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getQuizFoldersListApi}?class_id=${this.state.selectedTeacherId}&teacher_id=${this.state.selectedTeacherFolderId}&search=${this.state.folderSearchQuery}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getFoldersLessonData = () => {
    this.setState({isSubFolderLoading: true})
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFoldersLessonListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showLessonsFolder}?folder_id=${this.state.teacherFolderId}&search=${this.state.lessonAssignmentSearchQuery}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getQuizDataAssignments = () => {
    this.setState({isSubFolderLoading:true})
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("authToken"),
    };
    
                        
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.getFoldersQuizListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showQuizFolder}?quiz_folder_id=${this.state.teacherQuizFolderId}&search=${this.state.quizAssignmentSearchQuery}`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleLessonId = () => {
    if (this.state.teacherLessonFolderId) {
      return this.state.teacherLessonFolderId;
    } else if (this.state.teacherLessonId) {
      return this.state.teacherLessonId;
    }
  };

  isValidCheckCreateCalendarEvents = () => {
    let startTime = moment(this.state.selectedStartTimeEvent, "hh:mm A")
    let endTime = moment(this.state.selectedDueEventTime, "hh:mm A")

    let startDateTime = moment(this.state.selectedStartDateEvent, "DD/MM/YYYY");
    let endDateTime = moment(this.state.selectedDueDateEvent, "DD/MM/YYYY");
    
    let flag = false;
    if (!this.state.selectedDueDateEvent?.length) {
      this.setState({ validDueDateEvent: true });
      flag = true;
    } else if (!this.state.selectedStartTimeEvent?.length) {
      this.setState({ validStartTimeEvent: true });
      flag = true;
    } else if (!this.state.selectedDueEventTime?.length) {
      this.setState({ validDueEventTime: true });
      flag = true;
    } else if (!this.state.selectedStartDateEvent?.length) {
      this.setState({ validStartDateEvent: true });
      flag = true;
    }else if (startTime.isAfter(endTime) && startDateTime.isSame(endDateTime)) {
      this.setState({ isStartDueTimeEvent: true })
      flag = true;
    }else if(this.handleSameTimeValidate()){
      this.setState({ isSameStartDueTime: true })
      flag = true
    }
    else if(startDateTime.isAfter(endDateTime)){
      this.setState({isStartDueDateEvent: true})
      flag = true;
    }
    return flag;
  };

  handleQuizId = () => {
    if (this.state.quizFolderDirectId) {
      return this.state.quizFolderDirectId;
    } else if (this.state.teacherQuizId) {
      return this.state.teacherQuizId;
    }
  };

  createTeacherCalendarEvents() {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("authToken"),
    };

    const currentTimeZone = momentTz.tz.guess();

    const localTimeZone={currentTime:currentTimeZone, startTime: this.state.selectedStartDateEvent, dueTime: this.state.selectedDueEventTime}
    
    localStorage.setItem("currentTime",JSON.stringify(localTimeZone))

    if (this.isValidCheckCreateCalendarEvents()) {
      this.setState({ isValidateCreateEvent: true, teacherClassActiveTab: false, teacherClassesData: [] });
      return;
    }

    const httpBody = {
      teacher_class_id: this.state.selectedTeacherId,
      event_type: this.state.selectedEventType,
      lesson_id: this.handleLessonId(),
      lesson_folder_id: this.state.teacherFolderId,
      quiz_id: this.handleQuizId(),
      start_date: this.state.selectedStartDateEvent,
      start_time: this.state.selectedStartTimeEvent,
      quiz_folder_id: this.state.teacherQuizFolderId,
      due_time: this.state.selectedDueEventTime,
      due_date: this.state.selectedDueDateEvent,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCreateEventsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeacherEventsApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  handleEditNameDataFilter = () => {
    if (this.state.eventPropsObj?.event?.type === 'lesson') {
      return { lesson_name: this.state.editEventTitle }
    }
  }

  handleEditQuizNameDataFilter = () => {
    if (this.state.eventPropsObj?.event?.type === 'quiz') {
      return { quiz_name: this.state.editEventTitle }
    }else if(this.state.eventPropsObj?.event?.type==='assignment'){
      return { assignment_name: this.state.editEventTitle }
    }
  }

  handleEditObjectiveDataFilter = () => {
    if (this.state.eventPropsObj?.event?.type === 'lesson') {
      return { lesson_objective: this.state.editEventObjective };
    }
  }

  handleEditQuizObjectiveFilter = () => {
    if (this.state.eventPropsObj?.event?.type === 'quiz') {
      return { details: this.state.editEventObjective };
    }else if(this.state.eventPropsObj?.event?.type==='assignment'){
      return {details: this.state.editEventObjective}
    }
  }

  handleSameTimeValidateEditEvent = () =>{
    if(this.state.editStartTimeEvent === this.state.editDueEventTime && this.state.editStartDateEvent === this.state.editDueDateEvent){
      return true;
    }
  }

  isEditValidationEvents = () => {
    let startTime = moment(this.state.editStartTimeEvent, "hh:mm A");
    let endTime = moment(this.state.editDueEventTime, "hh:mm A")
    let startDateTime = moment(this.state.editStartDateEvent, "DD/MM/YYYY");
    let endDateTime = moment(this.state.editDueDateEvent, "DD/MM/YYYY");

    let flag = false;
     
    if (!this.state.editDueEventTime.length) {
      this.setState({ editValidDueEventTime: true });
      flag = true;
    } 
    else if (this.state.editEventClass === 'Select Class') {
      this.setState({ editEditClassValidate: true });
      flag = true;
    }else if(!this.state.editEventTitle.length){
      this.setState({editEditTitleValidate: true})  
      flag = true
    }else if (!this.state.editStartDateEvent?.length) {
      this.setState({ editValidStartDateEvent: true });
      flag = true;
    }else if (this.handleStartDueTimeEdit(startTime, endTime, startDateTime, endDateTime)) {
      this.setState({ isEditStartDueTimeEvent: true });
      flag = true;
    }
    else if(this.handleSameTimeValidateEditEvent()){
      this.setState({ isEditSameStartDueTime: true })
      flag = true
    }
    if(this.handleEditDueDateValid(this.state.editDueDateEvent)){
      this.setState({editValidDueDateEvent: true})  
      flag = true
    }else  if (this.handleSameDateEdit(startDateTime, endDateTime)) {
      this.setState({ isEditStartDueDateEvent: true })
      flag = true;
    }
    return flag;
  };

  handleStartDueTimeEdit=(startTime:any, endTime:any, startDateTime:any, endDateTime:any)=>{
    return startTime.isAfter(endTime) && startDateTime.isSame(endDateTime)
  }

  handleEditDueDate = (startDateTime:any,endDateTime:any)=>{
    let flag = false;
    
    return flag;
  }
  
  handleEditDueDateValid = (date:string) =>{
    return !date.length;
  }

  handleSameDateEdit = (startDateTime:any, endDateTime:any)=>{
    return moment(startDateTime).isAfter(moment(endDateTime))
  }
  
  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleEditEventTypeCheckSubmit = ()=>{
    if(this.state.eventPropsObj?.event?.type === 'lesson'){
      return {event_type: 'lesson'} 
    }else if(this.state.eventPropsObj?.event?.type === 'quiz'){
      return {event_type: 'quiz'} 
    }else if(this.state.eventPropsObj?.event?.type ==='assignment'){
      return {assign_task_id: this.state.eventPropsObj?.event?.id}
    }
  }


  handleEditClassEvent = ()=>{
    if(this.state.eventPropsObj?.event?.type === 'lesson' || this.state.eventPropsObj?.event?.type === 'quiz'){
      return {teacher_class_id: this.state.editEventClass}
    }else if(this.state.eventPropsObj?.event?.type ==='assignment'){
      return {teacher_classes_id: this.state.editEventClass}
    }
  }

  handleEditDueDateEvent = ()=>{
    if(this.state.eventPropsObj?.event?.type === 'lesson' || this.state.eventPropsObj?.event?.type === 'quiz'){
      return {due_date: this.state.editDueDateEvent,}
    }else if(this.state.eventPropsObj?.event?.type ==='assignment'){
      return {end_date: this.state.editDueDateEvent,}
    }
  }

  handleEditDueTimeEvent = ()=>{
    if(this.state.eventPropsObj?.event?.type === 'lesson' || this.state.eventPropsObj?.event?.type === 'quiz'){
      return {due_time: this.state.editDueEventTime}
    }else if(this.state.eventPropsObj?.event?.type ==='assignment'){
      return {end_time: this.state.editDueEventTime}
    }
  }

  changeDateFormat = (dateStr: string) => {
    const [day, month, year] = dateStr.split('/');
    return `${year}/${month}/${day}`;
  }

  editTeacherCalendarEvents() {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("authToken"),
    };
    this.setState({ editEventLoader: true });
    if (this.isEditValidationEvents()) {
      this.setState({ isValidateEditEvent: true,editEventLoader: false });
      return;
    }

    let httpBody;
    let taskType;
    const { eventPropsObj } = this.state;
    let type = eventPropsObj && eventPropsObj.event && eventPropsObj.event.type;
    let eventCardType = eventPropsObj && eventPropsObj.event && eventPropsObj.event.eventCardType;
    if(type === 'assignment'){
      httpBody = {
        ...this.handleEditClassEvent(),
        start_date: this.changeDateFormat(this.state.editStartDateEvent),
        start_time: this.state.editStartTimeEvent,
        due_date: this.changeDateFormat(this.state.editDueDateEvent),
        due_time: this.state.editDueEventTime,
        associated_data: {
          start_date: this.changeDateFormat(this.state.editStartDateEvent),
          start_time: this.state.editStartTimeEvent,
          end_date: this.changeDateFormat(this.state.editDueDateEvent),
          end_time: this.state.editDueEventTime,
          ...this.handleEditQuizNameDataFilter(),
          ...this.handleEditQuizObjectiveFilter(),
        }
      };

      taskType = 'assign_task';

    } else if(type==='lesson'){
      httpBody = {
        ...this.handleEditClassEvent(),
        start_date: this.changeDateFormat(this.state.editStartDateEvent),
        start_time: this.state.editStartTimeEvent,
        due_date: this.changeDateFormat(this.state.editDueDateEvent),
        due_time: this.state.editDueEventTime,
        ...this.handleEditEventTypeCheckSubmit(), 
        "lesson_id": this.state.editTeacherId,
        "quiz_id": this.state.editQuizId,
        "quiz_folder_id": this.state.editQuizFolderId,
        associated_data: {
          ...this.handleEditNameDataFilter(),
          ...this.handleEditQuizNameDataFilter(),
          ...this.handleEditObjectiveDataFilter(),
          ...this.handleEditQuizObjectiveFilter(),
        }
      };

      taskType = 'calendar_event';

    }else if (type === 'quiz') {
      httpBody = {
        ...this.handleEditClassEvent(),
        start_date: this.changeDateFormat(this.state.editStartDateEvent),
        due_date: this.changeDateFormat(this.state.editDueDateEvent),
        due_time: this.state.editDueEventTime,
        start_time: this.state.editStartTimeEvent,
        ...this.handleEditEventTypeCheckSubmit(),
        "lesson_id": this.state.editTeacherId,
        "quiz_folder_id": this.state.editQuizFolderId,
        "quiz_id": this.state.editQuizId,
        associated_data: {
          ...this.handleEditNameDataFilter(),
          ...this.handleEditQuizNameDataFilter(),
          ...this.handleEditObjectiveDataFilter(),
          ...this.handleEditQuizObjectiveFilter(),
        }
      };
    
      if (eventCardType === 'assignEvent') {
        taskType = 'assign_task';
      } else if (eventCardType === 'calendarEvent') {
        taskType = 'calendar_event';
      }
    }
      
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiEditEventsCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.editEventsAPiEndPoint + `/${this.state.eventPropsObj?.event?.id}?type=${taskType}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleResize = () => {
    this.setState({ isSmallScreen: window.innerWidth <= 600 });
  };
  handleSubFolders = (item2: any) => {
    if (item2.type === "lesson_folder") {
      this.setState({
        selectedTeacherAssignmentList: item2.attributes?.lessons?.data,
        teacherFolderId: item2?.id,
        lessonSubFolderName: item2.attributes.folder_name,
        isNextLessonDisabled: false,
        teacherLessonId: 0,
        openTeacherBreadCrumb: "My SubFolder",
        isOpenSubFolder:true
      }
    );
    }
  };
  handleQuizSubFolders = (item2: any) => {
    if (item2.type === "folder") {
      this.setState({
        selectedQuizAssignmentList: item2.attributes?.quizzes?.data,
        teacherQuizFolderId: item2?.id,
        lessonSubFolderName: item2.attributes.folder_name,
        isNextLessonDisabled: false,
        teacherLessonId: 0,
        openTeacherBreadCrumb: "My SubFolder",
        isOpenSubFolder:true
      }
    );
    }
  };
}

