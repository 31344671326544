Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.assignmentApiEndpoint = "bx_block_assessmenttest/assignment_markups/assignment_markup_tools"
exports.markAsCompletedApiEndPoint = "bx_block_livestreaming/live_lesson/mark_assignment_as_completed?assignment_id=";
exports.sendFeedbackApiEndPoint = "bx_block_assessmenttest/assignment_markups/create_assignment_markup_tool_feedback";
exports.sendMainFeedbackApiEndPoint = "bx_block_livestreaming/live_lesson/mark_assignment_feedback_as_completed"
exports.getFeedbackApiEndPoint = "bx_block_assessmenttest/assignment_markups/assignment_markup_tool_feedbacks?assignment_markup_tool_id=";
exports.getQuizDataApiEndPoint = "bx_block_livestreaming/live_quiz/show_quiz_for_student";
exports.submitQuizAnswerEndPoint = "bx_block_content_management/quizs/submit_quiz_answer";
exports.markQuizAsCompletedEndPoint = "bx_block_livestreaming/live_lesson/mark_as_completed";
exports.getQuizAnswersEndPoint = "bx_block_livestreaming/live_quiz/show_quiz_for_user?quiz_markup_tool_id=";
exports.getQuizQuestionFeedback = "bx_block_questionbank/quiz_markups/quiz_markup_tool_feedbacks?quiz_markup_tool_id=";
exports.sendQuizQuestionFeedback = "bx_block_questionbank/quiz_markups/create_quiz_markup_tool_feedback";
exports.sendOverallFeedbackEndPoint = "bx_block_livestreaming/live_lesson/mark_feedback_as_completed";
exports.markAsCompletedAMethod = "POST";
exports.patchMethod = "PATCH";
exports.postApiMethodType = "POST";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AssessmentTest";
exports.labelBodyText = "AssessmentTest Body";
exports.btnExampleTitle = "CLICK ME";
exports.markAsCompleted = "Mark as Completed"
exports.getHeadersDataEndPoint = "bx_block_tasks/tasks";
exports.txtPsgQuestion = "Text / Passage Question"
exports.daysLeft = "Days Left";
exports.modalText = "Answers Submitted Successfully.";
exports.classTxt = "Class";
exports.dueDateTxt = "Due Date";
exports.statusTxt = "Status";
exports.matchingQuestionTitle = "You can drag the options in column 'B' with the correct matching. Match column 'A' with its corrected pairs of column 'B'";
exports.headerFeedbackTxt = "Feedback";
exports.sendFeedbackTxt = "Send Feedback";
exports.createStudentLibraryAssignment = "bx_block_content_management/assignments/create_student_assignment_library"
exports.send="Send"
// Customizable Area End