import React from "react";
import { Box, Typography,TextField, InputAdornment, Button  } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const configJSON = require("../../../config");
import { copyLink,viewClose } from "../../../assets";
import { Roles } from "../../../../../../components/src/types";
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "670px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display: "flex",
    justifyContent: "center",
    padding: "25px 0",
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      padding: "15px 0",
    },
  },
  popupContent: {
    display: "flex",
    flexDirection: "column",
    padding: "0 25px",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.down('xs')]: {
      padding: "0 15px",
    },
  },
  title: {
    fontFamily: "Poppins",
    fontSize: "20px",
    color: "#202020",
    fontWeight: 600,
    marginBottom: "15px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "18px",
    },
  },
  subtitle: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#202020",
    fontWeight: 400,
    width: "390px",
    marginBottom: "20px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "14px",
      width: "100%",
    },
  },
  crossIcon: {
    position: "absolute",
    top: "25px",
    right: "15px",
    cursor: "pointer",
    [theme.breakpoints.down('xs')]: {
      top: "15px",
      right: "10px",
    },
  },
  btnLayout: {
    display: "flex",
    justifyContent: "end",
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  copyLink: {
    backgroundImage:
      "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
    color: "#FFF",
    borderRadius: "10px",
    width: "134px",
    padding: "12px",
    textTransform: "none",
    fontFamily: "Poppins",
    fontSize: "16px",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      marginTop: "10px",
    },
  },
  cancelBtn: {
    borderRadius: "10px",
    width: "134px",
    padding: "12px",
    textTransform: "none",
    border: "1px solid #3F526D",
    color: "#3F526D",
    marginRight: "20px",
    fontFamily: "Poppins",
    fontSize: "16px",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      marginRight: "0",
    },
  },
  linkBox: {
    marginBottom: "25px",
    backgroundColor: "rgb(238, 238, 238)",
    borderRadius: "4px",
    "& .MuiInputBase-root": {
      color: "#2B3D7D",
      "& fieldset": {
        border: "1px solid rgb(192, 192, 192)",
      },
      "&:hover fieldset": {
        borderColor: "rgb(192, 192, 192)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgb(192, 192, 192)",
      },
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    cursor: "pointer",
  },
  link: {
    color: "#2B3D7D",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    "&:hover": {
      borderBottom: "none",
    },
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const ShareLinkModal = (props: any) => {
  const { handleCloseModal, role,shareLink, contextMenuData } = props;
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  const handleCopyLink = () => {
    let copyText: any = document.getElementById("linkInput");
    copyText.select();
    navigator.clipboard.writeText(copyText.value);
  };
  const renderText = () => {
    let baseText = "";
  
    if (role === Roles.SUB_ADMIN) {
      baseText = configJSON.shareLinkDescription;
    } else {
      if (contextMenuData.itemType === "lesson") {
        baseText = "Share this lesson with others.";
      } else if (contextMenuData.itemType === "assignment") {
        baseText = "Share this assignment with others.";
      } else{
        baseText = "Share this quiz with others.";
      }
    }
  
    return baseText;
  };
  
  return (
    <Box style={modalStyle} className={classes.paper}>
      <img src={viewClose} onClick={handleCloseModal} className={classes.crossIcon}/>
      <Box className={classes.popupContent}>
        <Typography className={classes.title}>Share Link</Typography>
        <Typography className={classes.subtitle}>
          {renderText()}
        </Typography>
        <TextField
          id="linkInput"
          className={classes.linkBox}
          value={shareLink}
          variant="outlined"
          InputProps={{
            className: classes.link,
            startAdornment: (
              <InputAdornment position="start">
                <img src={copyLink} />
              </InputAdornment>
            ),
          }}
        />
        <Box className={classes.btnLayout}>
          <Button className={classes.cancelBtn} onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button className={classes.copyLink} onClick={handleCopyLink}>
            Copy Link
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ShareLinkModal;
