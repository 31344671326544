Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cflivestreamingsubfeature";
exports.labelBodyText = "cflivestreamingsubfeature Body";

exports.btnExampleTitle = "CLICK ME";
exports.getStudentDetails="account_block/accounts/logged_user"
exports.GET="GET"
exports.submitAnswerApiEndPoint = "bx_block_livestreaming/live_lesson/submit_lesson_answer"
exports.POST = "POST";
exports.fetchLessonStagesApi="/bx_block_cfcoursecreation23/lesson_markups/lesson_markup_tools"
exports.getQuizStagesApi = "bx_block_questionbank/quiz_markups/quiz_markup_tools";
exports.studentLibraryCreateApiEndPoint = "bx_block_content_management/lessons/create_student_lesson_library"
exports.studentPollingQuetionApiEndPoint="bx_block_livestreaming/live_lesson/show_polling_question_answers"
exports.studentGetAnswerApiEndPoint="bx_block_livestreaming/live_lesson/student_answers_list"
exports.wordclouAnswersApiEndPoint="bx_block_livestreaming/live_lesson/word_cloud_submitted_answers"
exports.submitQuizAnswerApiEndPoint="bx_block_content_management/quizs/submit_quiz_answer"
exports.quizAnswerApiEndPoint="bx_block_content_management/quizs/show_quiz_answers"
exports.quizPollingAnswerApiEndPoint="bx_block_content_management/quizs/show_quiz_polling_question_answers"
exports.quizScoreApiEndPoint="bx_block_questionbank/quiz_markups/show_scores"
exports.uploadChatFileEndpoint="bx_block_livestreaming/class/upload_file"
exports.quizLibraryCreateApiEndPoint="bx_block_content_management/quizs/create_student_quiz_library"
exports.WebAPIKey=  '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'
exports.WebClienctID = '580372478236-u49s5ehcgpqih5eptqi79kuur5ma9ouf.apps.googleusercontent.com'
exports.webClientId = '580372478236-u49s5ehcgpqih5eptqi79kuur5ma9ouf.apps.googleusercontent.com'
exports.putMethod = "PUT";
// Customizable Area End