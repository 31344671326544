import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Divider,
  TextField,
  Dialog,
  DialogContent,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import { backIcon, closeIcon, starFilledIcon, starIcon, tickIcon, webLogo } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import { SuccessSnackbar } from "../../../components/src/CustomSuccessPopup.web";
// Customizable Area End

import MultipageForms2Controller, {
  Props,
  configJSON,
} from "./MultipageForms2Controller";

export default class MultipageForms2 extends MultipageForms2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderRatings = () => {
    return (
      <Box style={webStyle.starMainBox}>
          {[...Array(5)].map((_, index) => (
            <img
              data-test-id="btnRating"
              key={index}
              src={index < this.state.rating ? starFilledIcon : starIcon}
              alt={`${index + 1} star`}
              onClick={() => this.handleRatingClick(index)}
              style={{...webStyle.starIcon, width: this.state.isSmallScreen ? '48px': '40px', height: this.state.isSmallScreen ? '48px' : '40px'}}
            />
          ))}
          </Box>
    );
  }

  renderInputBoxes = () => {
    return (
      <Box style={{...webStyle.topInputBox, flexDirection: this.state.isSmallScreen ? "column" : "row"}}>
            <Box style={{width: this.state.isSmallScreen ? '100%' : '280px'}} >
              <InputsLabel>{configJSON.yourNameInputLabel}</InputsLabel>
              <InputField  
                data-test-id="inputnamechange"
                placeholder={configJSON.yourNameInputLabel}
                value={this.state.name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleInputChange('name',event.target.value)}
                variant='outlined'
                error={!!this.state.errors.name}
                required
                fullWidth
              />
              {this.state.errors.name && <ErrorMessage style={webStyle.absolute}>
                  {this.state.errors.name}
              </ErrorMessage>}
              {(this.state.errors.name || this.state.errors.email) && <Box style={webStyle.errorHalfInput}></Box>}
            </Box>
            <Box style={{width: this.state.isSmallScreen ? '100%' : '280px', marginTop: this.state.isSmallScreen ? '28px' : ''}} >
              <InputsLabel>{configJSON.emailIdInputLabel}</InputsLabel>
              <InputField  
                data-test-id="inputemailchange"
                placeholder={configJSON.emailIdInputLabel}
                value={this.state.email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleInputChange('email',event.target.value)}
                variant='outlined'
                error={!!this.state.errors.email}
                required
                fullWidth
              />
              {this.state.errors.email && <ErrorMessage style={webStyle.absolute}>
                  {this.state.errors.email}
              </ErrorMessage>}
              {(this.state.errors.email || this.state.errors.name) && <Box style={webStyle.errorHalfInput}></Box>}
            </Box>
          </Box>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <SuccessSnackbar
          message={'Your 2GB storage limit has been reached. Please delete few files to refresh your limit.'} 
          open={this.state.isLimitModalOpen} 
          onClose={this.handleCloseLimitSnackbar} 
          type={'error'}
      />
        <Box style={{...webStyle.mainBox, padding: this.state.isSmallScreen ? "32px 10px 40px" : "32px 88px 40px 88px"}}>
          <img onClick={this.handleGoToHome} style={webStyle.backIcon} src={backIcon} alt="" />
          <img style={{...webStyle.webLogo, width: this.state.isSmallScreen ? '230px' :'294.78px'}} src={webLogo} alt="" />
          <Typography style={webStyle.feedbackTitle}>{configJSON.feedbackFormTxt}</Typography>
          <Typography style={webStyle.feedbackDesc}>{configJSON.feedbackDescTxt}</Typography>
          <Typography style={webStyle.overAllRatingTxt}>{configJSON.overAllRatingTxt}</Typography>
          {this.renderRatings()}
          {this.state.errors.rating && <ErrorMessage style={{textAlign: 'center', marginTop: '10px'}} >
              {this.state.errors.rating}
          </ErrorMessage>}
          <Divider style={webStyle.divider} />
          {this.renderInputBoxes()}
          <Box style={webStyle.inputBox}>
            <InputsLabel>{configJSON.overallCommentInputLabel}</InputsLabel>
            <InputFieldLongHeight
              multiline
              rows={4}
              data-test-id="inputoverallcommentchange"
              placeholder={configJSON.enterCommentsPlaceholder}
              value={this.state.overallComment}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleInputChange('overallComment',event.target.value)}
              variant='outlined'
              fullWidth
            />
              {this.state.errors.overallComment && <ErrorMessage>
                  {this.state.errors.overallComment}
              </ErrorMessage>}
          </Box>

          <Box style={webStyle.inputBox}>
            <InputsLabel>{configJSON.thingsLikeLabel}</InputsLabel>
            <InputFieldLongHeight  
              multiline
              rows={4}
              data-test-id="inputthingLikechange"
              placeholder={configJSON.enterCommentsPlaceholder}
              value={this.state.thingLike}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleInputChange('thingLike',event.target.value)}
              variant='outlined'
              error={!!this.state.errors.thingLike}
              required
              fullWidth
            />
            {this.state.errors.thingLike && <ErrorMessage>
                  {this.state.errors.thingLike}
              </ErrorMessage>}
          </Box>

          <Box style={webStyle.inputBox}>
            <InputsLabel>{configJSON.improvementLabel}</InputsLabel>
            <InputFieldLongHeight  
              multiline
              rows={4}
              data-test-id="inputimprovementSugchange"
              placeholder={configJSON.enterCommentsPlaceholder}
              value={this.state.improvementSug}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleInputChange('improvementSug',event.target.value)}
              variant='outlined'
              error={!!this.state.errors.improvementSug}
              required
              fullWidth
            />
            {this.state.errors.improvementSug && <ErrorMessage>
                  {this.state.errors.improvementSug}
              </ErrorMessage>}
          </Box>

          <button data-test-id='btnSubmitForm' style={webStyle.submitBtn} onClick={this.submitFeedbackForm}>{configJSON.submitTxt}</button>
        </Box>

        <BootstrapDialog
                onClose={this.handleCloseModal}
                aria-labelledby="customized-dialog-title"
                open={this.state.isModalOpen}
            >
                <IconBtn
                    aria-label="close"
                    data-test-id='btnCloseModal'
                    onClick={this.handleCloseModal}
                >
                   <img src={closeIcon} alt="close_icon" />
                </IconBtn>
                
                <DialogContent dividers>
                    <img style={webStyle.tickIcon} src={tickIcon} alt="" />
                    <Typography style={webStyle.thanksForSubmitForm} >{configJSON.thanksForSubmitForm}</Typography>
                    <Typography style={webStyle.responseRecorded} >{configJSON.responseRecorded}</Typography>
                    <Typography data-test-id="btnGoToHome" style={webStyle.backToHome} onClick={this.handleGoToHome} >{configJSON.backToHome}</Typography>
                </DialogContent>
            </BootstrapDialog>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainBox: {
    maxWidth: '760px',
    boxSizing: 'border-box' as 'border-box',
    margin: '40px auto 40px auto',
    borderTop: '4px solid #2B3D7D',
    borderBottom: '4px solid rgba(43, 61, 125, 0.12)',
    boxShadow: '0px 0px 80px 0px #00000014',
    position: 'relative' as 'relative'
  },
  backIcon: {
    cursor: 'pointer',
    position: 'absolute' as 'absolute',
    top: '20px',
    left: '30px',
  },
  webLogo: {
    height: '74px',
    display: 'block',
    margin: 'auto',
    marginBottom: '40px',
  },
  feedbackTitle: {
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '32px',
    color: '#2B3D7D',
    textAlign: 'center' as 'center',
    letterSpacing: '2.3px'
  },
  feedbackDesc: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#282829',
    marginTop: '12px',
    textAlign: 'center' as 'center',
    letterSpacing: '1.3px'
  },
  overAllRatingTxt: {
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '24px',
    color: '#282829',
    marginTop: '40px',
    textAlign: 'center' as 'center',
    letterSpacing: '1.7px'
  },
  starMainBox: {
    marign: 'auto',
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '21px',
  },
  starIcon: {
    width: '48px',
    height: '48px',
    cursor: 'pointer',
  },
  divider: {
    margin: '40px 0 40px 0',
  },
  topInputBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '28px',
  },
  inputBoxHalf: {
    width: '280px',
  },
  inputBox: {
    marginBottom: '28px',
  },
  absolute:{
    position: 'absolute' as 'absolute',
  },
  errorHalfInput:{
    marginBottom: '28px',
  },
  submitBtn: {
    border: 'none',
    width: '131px',
    height: '48px',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '14px',
    color: '#FFFFFF',
    background: 'linear-gradient(132.79deg, #2B3D7D 31.28%, #39C6B3 181.36%)',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  tickIcon: {
    width: '60px',
    height: '60px',
    display: 'block',
    margin: 'auto',
  },
  thanksForSubmitForm: {
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: 600,
    color: '#282829',
    marginTop: '20px',
    textAlign: 'center' as 'center',
  },
  responseRecorded: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
    color: '#282829',
    marginTop: '12px',
    textAlign: 'center' as 'center',
  },
  backToHome: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
    color: '#2B3D7D',
    marginTop: '38px',
    textAlign: 'center' as 'center',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
};

export const InputField = styled(TextField)({
  marginBottom: "0px",
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#C0C0C0",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#C0C0C0",
    },
  },
  "& .MuiInputBase-root":{
    backgroundColor: "#FFFFFF",
    height: '48px',
    margin: 'auto',
    borderRadius: '8px',
    fontSize: '14px',
    fontFamily: 'Poppins',
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#C0C0C0",
    borderWidth: "1px",
    borderRadius: "8px"
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px 16px 10px 16px",
    color: '#2C2C2E',
    fontFamily: 'Poppins',
    "&::placeholder": {
      color: '#2C2C2E',
      fontSize: "14px",
      fontFamily: 'Poppins',
    },
    "&:not(:placeholder-shown)": {
      color: '#2C2C2E',
      fontFamily: 'Poppins',
    },
  },
  "& .MuiInputBase-input[type='date']": {
    lineHeight: "19.2px",
    textTransform:'uppercase',
    color:'red'
  }
  , "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171"
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#DC2626",
    fontSize: "12px",
    fontFamily: "Lato",
    fontWeight: 400,
    lineHeight: "18px"
  },
  "& .MuiFormHelperText-contained": {
    marginLeft: "0px",
    marginRight: "0px"
  },
  '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':{
    border:"1px solid #FFFFFF",
  }
});

export const InputFieldLongHeight = styled(TextField)({
  marginBottom: "0px",
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#C0C0C0",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#C0C0C0",
      borderWidth: "1px",
    },
  },
  "& .MuiInputBase-root":{
    fontFamily: 'Poppins',
    height: '88px',
    margin: 'auto',
    borderRadius: '8px',
    backgroundColor: "#FFFFFF",
    fontSize: '14px',
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderRadius: "8px",
    borderColor: "#C0C0C0",
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderColor: "#CBD5E1",
  },
  "& .MuiOutlinedInput-input": {
    fontFamily: 'Poppins',
    color: '#2C2C2E',
    paddingLeft: '2px',
    paddingTop: "17px",
    "&::placeholder": {
      fontSize: "14px",
      fontFamily: 'Poppins',
      color: '#2C2C2E',
    },
    "&:not(:placeholder-shown)": {
      fontFamily: 'Poppins',
      color: '#2C2C2E',
    },
  },
  "& .MuiInputBase-input[type='date']": {
    color:'red',
    textTransform:'uppercase',
    lineHeight: "19.2px",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    fontFamily: "Lato",
    color: "#DC2626",
  }, 
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171"
  },
  
  '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':{
    border:"1px solid #FFFFFF",
  },
  "& .MuiFormHelperText-contained": {
    marginRight: "0px",
    marginLeft: "0px",
  },
});

export const InputsLabel = styled(InputLabel)({
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '14px',
  color: '#2C2C2E',
  // textTransform: 'capitalize',
  marginBottom: '12px',
});

const ErrorMessage = styled(Typography)({
  fontFamily: 'Poppins',
  fontSize: '12px',
  fontWeight: 400,
  color: '#DC2626',
  marginTop: '4px',
});

const BootstrapDialog = styled(Dialog) ({
  "& .MuiDialog-paper": {
    width: '420px',
    borderRadius: "12px",
    boxSizing: 'border-box',
  },
  '& .MuiDialogContent-root': {
      paddingTop: '48px',
      paddingBottom: '56px',
  },
});

const IconBtn = styled(IconButton)({
  position: 'absolute',
  right: 8,
  top: 8,
});
// Customizable Area End
