import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";

interface AnswerObject {
  answer: string;
}

interface Answer {
  topic?: string;
  answer: string;
  correct_answer?: boolean | string;
  teacher_correct_answer?: string;
}
interface StudentAnswer {
  id: string;
    type: string;
    attributes: {
        assignment_stage_id: number;
        account_id: number;
        assignment_id: number;
        correct_answer: boolean | null;
        answers: Answer[][];
        created_at: string;
        updated_at: string;
    };
}

interface StudentAnswerForCondition {
  type: string;
  id: string;
  attributes: {
      assignment_id: number;
      assignment_stage_id: number;
      updated_at: string;
      correct_answer: boolean | null;
      account_id: number;
      answers: AnswerForCondition[];
      created_at: string;
    };
}
interface AnswerForCondition {
  answer: string;
  topic: string;
  teacher_correct_answer: string;
  correct_answer: boolean | null;
}

interface AssignmentMarkupTool {
  type: string;
  id: string;
  attributes: {
    title: string | null;
    stage_number: string;
    assignment_stage_id: number;
    assignments_id: number;
    countdown_timer: number | null;
    videos_url: (string | null)[];
    description: (string | { topic: string; answer: string })[] | null;
    images: (string | { id: number; url: string })[] | null;
    question: string | null;
    assignment_sub_stage_name: string[] | null;
    videos: (string | { id: number; url: string })[] | null;
    pairs: Pair[];
    background_image: string | null;
    questions: Question[] | null;
    assignment_stage_name: string;
    canvas_data: any;
  };
}

interface Question {
  question: {
    question: string;
    id: number;
    updated_at: string;
    created_at: string;
    assignment_markup_tools_id: number;
  };
  options: Option[];
  question_background_image: string | null;
}

interface Pair {
  id: number;
  pair_answer: string;
  quiz_markup_tools_id: number | null;
  pair_question: string;
  pair_correct_answer: string;
  lesson_markup_tools_id: number | null;
  order_no: number;
  assignment_markup_tools_id: number;
  updated_at: string;
  created_at: string;
}

interface Option {
  id: number;
  answer: string;
  updated_at: string;
  assignment_mcq_questions_id: number;
  order_no: number | null;
  marked_as_right_answer: boolean;
  created_at: string;
}

interface AssignmentResponse {
  data: AssignmentMarkupTool[];
  meta: {
    assignment_name: string;
  };
}
interface Questionss {
  question?: string;
  questionId?: string;
  isSubmit?: boolean;
  answer?: string;
}

interface GetAnswerStyleDataAnswer {
  topic: string;
  answer: string;
  correct_answer: boolean | null;
  teacher_correct_answer: string;
}

interface GetAnswerStyleDataStudentAnswer {
  type: string;
  id: string;
  attributes: {
    account_id: number;
    assignment_id: number;
    created_at: string;
    correct_answer: boolean | null;
    assignment_stage_id: number;
    updated_at: string;
    answers: GetAnswerStyleDataAnswer[];
  };
}

interface GetAnswerStyleData {
  base: AssignmentMarkupTool;
  answers?: GetAnswerStyleDataStudentAnswer[];
  subStage?: AssignmentMarkupTool;
}

interface FeedbackAttributes {
  description: string;
  assignment_mcq_question_id: number;
  account_id: number;
  created_at: string;
  updated_at: string;
}

interface Feedback {
  id: string;
  type: "assignment_question";
  attributes: FeedbackAttributes;
}

export const configJSON = require("./config");

export interface Props {
  navigation: any,
}
// Customizable Area End

export interface S {
    // Customizable Area Start
    txtSavedValue: string;
    txtInputValue: string;
    slectedOption: Questionss;
    allData: AssignmentMarkupTool[];
  enableField: boolean;
  newData: string[];
  rightArray: string[];
  dragSomethingSubmit: boolean;
  dragItem: null | number;
  totalStep: null | number;
  dragSomething: boolean;
  dragOverItem: null | number;
  currentStep: number;
  authToken: string;
  isSubmitForChart: boolean;
  inputValues: {
    [keys: string]: string;
  };
  allSelectedOption: { answer: string; isSubmit: boolean }[];
  submitedAnswer: StudentAnswer[]
  isSubmitWordCloud: boolean;
  inputValuesForChart: {
    topic?: string; answer: string
  }[];
  openEndedText: {
    topic: string; answer: string; isSubmit: boolean
  };
  pairsMatching: { topic: string; answer: string | null }[];
 
  trueAnswerForMcq: string | boolean;
  openModel: boolean;
  feedbackText: string;
  feedbackData: Feedback[];
  assignmentId: string | number;
  assignmentStatus: string;
  assignmentDueDate: string;
  assignmentName: string;
  className: string;
  studentId: string | number;
  currentQuestionId: number | string | undefined;
  isMainFeedModalOpen: boolean;
  isAlertModalOpen: boolean;
  allDataLoading: boolean; 
  prevPagePath: string;
  prevPageId: string;
  feedbackPlaceHolder: string;
  isLimitModalOpen: boolean;
  assign_task_id: string;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    
    // Customizable Area End
}

export default class AssessmentTableController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getCountryApiId:string="";
    getStudentAssignmentId:string="";
    sendQuestionFeedbackApiCallId: string = "";
    getQuestionFeedbackApiCallId: string = "";
    sendMainFeedbackApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
      this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.NavigationPayLoadMessage),
        getName(MessageEnum.CountryCodeMessage)
      ];
      this.receive = this.receive.bind(this);
      runEngine.attachBuildingBlock(this, this.subScribedMessages);

      this.state = {
        txtSavedValue: "A",
        txtInputValue: "",
        newData: ["true", "false", "not sure"],
        slectedOption: {
          questionId: "",
          question: "",
          answer: "",
          isSubmit: false,
        },
        enableField: false,
        rightArray: [],
        dragItem: null,
        dragSomething: false,
        dragOverItem: null,
        allData: [],
        openEndedText: { topic: "", answer: "", isSubmit: false },
        dragSomethingSubmit: false,
        currentStep: 0,
        totalStep: null,
        authToken: "",
        allSelectedOption: [],
        isSubmitForChart: false,
        inputValuesForChart: [],
        inputValues: {},
        isSubmitWordCloud: false,
        openModel: false,
        pairsMatching: [],
        feedbackText:"",
        feedbackData: [],
        trueAnswerForMcq:"",
        submitedAnswer:[],
        assignmentId: '',
        assignmentStatus: 'Pending',
        assignmentDueDate: '13 Days Left',
        assignmentName: '',
        className: 'Class 1A Maths',
        studentId: '',
        currentQuestionId: '',
        isMainFeedModalOpen: false,
        isAlertModalOpen: false,
        allDataLoading: false,
        prevPagePath: 'AssingnNewTask',
        prevPageId: '',
        feedbackPlaceHolder: '',
        isLimitModalOpen: false,
        assign_task_id: '',
        };
        // Customizable Area End


    }

    async receive(_from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
          let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
    
          this.showAlert(
            "Change Value",
            "From: " + this.state.txtSavedValue + " To: " + value
          );
    
          this.setState({ txtSavedValue: value });
        }
    
        // Customizable Area Start
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.getStudentAssignmentId === apiRequestCallId) {
        this.setState({
          submitedAnswer: responseJson.data
        }, () => { this.getStudentAnswer() })

      } else if (this.getCountryApiId === apiRequestCallId) {
        this.getApiData(responseJson)

      } else if(this.sendQuestionFeedbackApiCallId === apiRequestCallId){

        if(responseJson?.data){
          this.setState({
            openModel: false,
            feedbackText: '',
          })
        }
        if(responseJson && (responseJson.error === 'Request size exceeds the 2 GB limit.')){
          this.enableLimitSnackbar();
        }

      } else if (this.getQuestionFeedbackApiCallId === apiRequestCallId){
        if(responseJson && responseJson.data && responseJson.data.length > 0){
        this.setState({feedbackText: responseJson?.data?.[responseJson?.data.length - 1]?.attributes?.description})
        }
        else {
          this.setState({
            feedbackPlaceHolder: 'Write your feedback here'
          })
        }
      } else if(this.sendMainFeedbackApiCallId === apiRequestCallId){
        let feedbackMsg = responseJson && responseJson.message
        if(feedbackMsg === 'Please give all the feedback'){
          this.handleOpenAlertModal();
        }
        else if(feedbackMsg === 'Done'){
          this.handleOpenSuccessModal();
        } else if(responseJson && (responseJson.error === 'Request size exceeds the 2 GB limit.')){
          this.enableLimitSnackbar();
        }
      }
        // Customizable Area End
    }

    // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();

    let taskAssignmentData = await getStorageData('taskAssignmentData');
    taskAssignmentData = JSON.parse(taskAssignmentData)
    const pathname = window.location.pathname;
    const parts = pathname.split('/');

    const studentId = parts[2];
    const assignmentId = parts[3];
    let attributes = taskAssignmentData?.attributes?.assignment?.data?.attributes;
    const assignmentStatusFind=taskAssignmentData?.attributes.assign_student_tasks.find((obj:any)=>obj.student_id==Number(studentId))
    this.setState({
      assignmentDueDate: attributes && attributes.days_left,
      assignmentStatus: assignmentStatusFind.status,
      className: attributes && attributes.class_name,
    })

    const auth = await getStorageData("authToken");

    this.setState({
      authToken: auth
    })
    

    this.setState({assignmentId: assignmentId, studentId: studentId})

    this.handleCountryApi(auth);

    const prevPagePath = await getStorageData("prevPagePath");
    const prevPageId = await getStorageData("prevPageId");

    if(prevPagePath && prevPageId)
    this.setState({
        prevPagePath: prevPagePath,
        prevPageId: prevPageId,
    })


    const role = await getStorageData("role");

    if(role !== "Teacher"){
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLogin");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }


    let taskAssignmentDataId = await getStorageData('taskAssignmentDataId');
    taskAssignmentDataId = JSON.parse(taskAssignmentDataId)

    this.setState({
      assign_task_id: taskAssignmentDataId,
    })
  }

  enableLimitSnackbar = () => {
    this.setState({ isLimitModalOpen: true })
  }

  setData = (data: Pair[]) => {

    if (this.state.rightArray.length < 1) {
      this.setState({
        rightArray: data.map((value) => value.pair_answer),
        pairsMatching: data.map(pair => ({ topic: pair.pair_question, answer: pair.pair_answer }))

      })
    }

  };

   handleMultimediaPollingMCQAddText = (datas: Answer[][] | undefined, correctAnswer: string | null | boolean) => {
    this.setState({
        slectedOption: {
            questionId: "",
            question: "",
            answer: datas && datas[0][0] ? datas[0][0].answer : "",
            isSubmit: false,
        },
        trueAnswerForMcq: correctAnswer || ""
    });
};

 handleWordCloud = (datas: Answer[][] | undefined) => {
  if (!datas || !Array.isArray(datas) || !Array.isArray(datas[0])) {
    this.setState({ inputValues: {} });
    return;
  }
  const answersObject = datas[0].reduce((acc: { [key: string]: string }, answerObj: Answer, index: number) => {
    if (answerObj && typeof answerObj.answer === 'string') {
        acc[`answer${index + 1}`] = answerObj.answer;
    }
        return acc;
    }, {});

    this.setState({ inputValues: answersObject });
};

 handleChartQuestions = (datas: Answer[][] | undefined) => {
  if (datas) {
      const flattenedAnswers = datas.flat().map(a => ({ answer: a.answer }));
      this.setState({ inputValuesForChart: flattenedAnswers });
  } else {
      this.setState({ inputValuesForChart: [] });
  }
};


 handleMatchingQuestion = (datas: Answer[][] | undefined) => {
    
};

 handleOpenEndedQuestion = (datas: Answer[][] | undefined) => {
    if (datas && datas[0] && datas[0][0]) {
        this.setState({
            openEndedText: {
                topic: datas[0][0].topic || "",
                answer: datas[0][0].answer || "",
                isSubmit: false
            }
        });
    } else {
        this.setState({
            openEndedText: {
                topic: "",
                answer: "",
                isSubmit: false
            }
        });
    }
};


getStudentAnswer = () => {
  const { allData, currentStep, submitedAnswer } = this.state;
  const stepData = allData[currentStep].attributes.assignment_stage_name;
  const currentAssignmentId = allData[currentStep].attributes.assignment_stage_id;
  const trueData = submitedAnswer?.find((value) => value.attributes.assignment_stage_id === currentAssignmentId);
  const datas = trueData?.attributes.answers;
  const correctAnswer = trueData?.attributes.correct_answer ?? null;

  this.updateStateBasedOnStep(stepData, datas, correctAnswer);
};

updateStateBasedOnStep = (stepData: string, datas: Answer[][] | undefined, correctAnswer: string | null | boolean) => {
  switch (stepData) {
      case "add_multimedia":
      case "polling_question":
      case "MCQ":
      case "add_text":
          this.handleMultimediaPollingMCQAddText(datas, correctAnswer);
          break;

      case "add_word_cloud":
        this.handleWordCloud(datas);
          break;

      case "kwl_chart":
      case "t_chart":
      case "seed_discussion":
      case "venn_diagram":
      case "wh_chart":
        this.handleChartQuestions(datas);
          break;

      case "matching_question":
        this.handleMatchingQuestion(datas);
          break;

      case "open_ended_question":
        this.handleOpenEndedQuestion(datas);
          break;

      default:
          break;
  }
};




  handleCountryApi = (auth: string) => {
    this.setState({allDataLoading: true});
    
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: auth
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.getCountryApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.assignmentApiEndpoint + `?assignment_id=${this.state.assignmentId}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }



  getApiData = (responseJson: AssignmentResponse): void => {

    const validData = responseJson.data.filter(item => {const stageN = Number(item.attributes.stage_number);
      return !isNaN(stageN);
    });

    this.setState({
      allDataLoading: false,
      totalStep: validData.length,
      allData: responseJson.data,
      assignmentName: responseJson?.meta?.assignment_name
    }, () => { this.handelStudentApiget() });
  };

  handelNext = () => {
    if (this.state.currentStep < this.state.allData.length - 1) {
      this.setState(prevState => ({
        currentStep: prevState.currentStep + 1
      }), () => { this.getStudentAnswer() });
    }
    this.setState({
      slectedOption: {
        questionId: "",
        question: "",
        answer: "",
        isSubmit: false
      },
      dragSomething: false,
      dragSomethingSubmit: false,
      isSubmitWordCloud: false,
      isSubmitForChart: false,
      inputValuesForChart: [],
      openEndedText: { topic: "", answer: "", isSubmit: false },
    })

  }


  handelBack = () => {
    this.setState(prevState => ({
      currentStep: Math.max(prevState.currentStep - 1, 0)
    }));
    this.setState({
      slectedOption: {
        questionId: "",
        question: "",
        answer: "",
        isSubmit: false
      },
      dragSomething: false,
      dragSomethingSubmit: false,
      isSubmitWordCloud: false,
      isSubmitForChart: false,
      inputValuesForChart: [],
      openEndedText: { topic: "", answer: "", isSubmit: false },
    })
  };

  handelStudentApiget = () => {

    const { authToken } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };



    const verifyCodeSignMes = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/student_answer/show_assignment_answers?student_id=${this.state.studentId}&assignment_id=${this.state.assignmentId}`
    );
    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getStudentAssignmentId = verifyCodeSignMes.messageId;
    runEngine.sendMessage(verifyCodeSignMes.id, verifyCodeSignMes);
  };


  setTypeForChart = (dataType: string) => {
    let types;

    if (dataType === "t_chart") {
      types = "T-Chart Question"
    } else if (dataType === "kwl_chart") {
      types = "KWL Chart Question"
    } else {
      types = "Seed Discussion Question"
    }
    return types
  };

  addFeedback = (questionId?: number | string | undefined) => {
    this.setState({ openModel: true, currentQuestionId: questionId }, () => {
      this.getFeedbackForQuestion();
    });
  }


  handleClose = () => {
    this.setState({ openModel: false, feedbackText: '', feedbackPlaceHolder: '' });
  };

  handelFeedbackWrite = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      feedbackText: event.target.value
    })
  }

  handleBackToViewList = () => {
      const { prevPagePath, prevPageId } = this.state;
      this.props.navigation.navigate(prevPagePath, { id: prevPageId })
  }

  doesAnswerMatch = (answer: string | string[] | undefined, targetAnswer: string) => {
    if (!answer) {
      return false; 
    }
    if (Array.isArray(answer)) {
      return answer.includes(targetAnswer);
    }
    return answer === targetAnswer;
  };


  getMcqPollingStyle = (valueAnswer: string, data: GetAnswerStyleData, type: string | undefined) => {
    const teacherAnswer = data.answers?.[0]?.attributes?.answers?.[0]?.teacher_correct_answer;
    const studentAnswer = data.answers?.[0]?.attributes?.answers?.[0]?.answer;

    if (this.doesAnswerMatch(teacherAnswer, valueAnswer) || (type !== "MCQ" && this.doesAnswerMatch(studentAnswer, valueAnswer))) {
        return {
          width: "92%", background: "#00A52C", borderRadius: "20px", position: "relative" as "relative",
          margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#fff",
          cursor: "pointer", border: "2px solid #00A52C",
          fontFamily: "Poppins", lineHeight: "16px"
        }
    } else if (valueAnswer !== teacherAnswer && valueAnswer !== studentAnswer) {
        return {
          width: "92%", border: "1px solid #2B3D7D", borderRadius: "20px",
          margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#282829",
          cursor: "pointer",
          fontFamily: "Poppins", lineHeight: "16px"
        };
    } else {
        return {
          width: "92%", border: "1px solid #FF0000", borderRadius: "20px",
          margin: "20px 0px", padding: "15px", fontSize: "14px", fontWeight: 500, color: "#fff",
          position: "relative" as "relative",
          background: "#FF0000",
          cursor: "pointer",
          fontFamily: "Poppins", lineHeight: "16px"
        }
    }
};

  handleCloseAlertModal = () => {
    this.setState({isAlertModalOpen: false})
  }

  handleOpenAlertModal = () => {
    this.setState({isAlertModalOpen: true})
  }

  handleOpenSuccessModal = () => {
    this.setState({isMainFeedModalOpen: true})
  }

  handleCloseSuccessModal = () => {
    this.setState({isMainFeedModalOpen: false})

    const { prevPagePath, prevPageId } = this.state;
    this.props.navigation.navigate(prevPagePath, { id: prevPageId })
  }

  handleSendFeedbackValidation = (is_complete_now: boolean) => {
    this.handleSendMainFeedback(is_complete_now);
  }

  handleSendQuestionFeedback = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.authToken
    };

    const body = {
      "assignment_markup_tool_id": this.state.currentQuestionId,
      "description": this.state.feedbackText, 
      "student_id": this.state.studentId,
    }

    const sendQuestionFeedback = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    sendQuestionFeedback.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendFeedbackApiEndPoint
    );

    sendQuestionFeedback.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    sendQuestionFeedback.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );

    sendQuestionFeedback.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    this.sendQuestionFeedbackApiCallId = sendQuestionFeedback.messageId;

    runEngine.sendMessage(sendQuestionFeedback.id, sendQuestionFeedback);
  }

  getFeedbackForQuestion = () => {

    const { authToken, currentQuestionId } = this.state;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };

    const verifyCodeSignMes = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getFeedbackApiEndPoint}${currentQuestionId}&student_id=${this.state.studentId}`
    );

    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    verifyCodeSignMes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.getQuestionFeedbackApiCallId = verifyCodeSignMes.messageId;

    runEngine.sendMessage(verifyCodeSignMes.id, verifyCodeSignMes);
  }

  handleSendMainFeedback = (is_complete_now: boolean) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.authToken
    };

    const body = {
      "markup_tools_id": this.state.allData?.[0]?.id,
      "student_id": this.state.studentId,
      "assignment_id": this.state.assignmentId,
      "is_complete_now": is_complete_now,
      "assign_task_id": this.state.assign_task_id,
    }

    const sendMainFeedback = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    sendMainFeedback.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendMainFeedbackApiEndPoint
    );

    sendMainFeedback.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    sendMainFeedback.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethod
    );

    sendMainFeedback.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    this.sendMainFeedbackApiCallId = sendMainFeedback.messageId;

    runEngine.sendMessage(sendMainFeedback.id, sendMainFeedback);
  }

  handleDoneAfterSendFeedback = () => {
      const { prevPagePath, prevPageId } = this.state;
      this.props.navigation.navigate(prevPagePath, { id: prevPageId })
  }

  handleWordCloudValue = (answers: StudentAnswerForCondition[], index: number) => {
    return answers && answers[answers.length - 1] && 
    answers[answers.length - 1].attributes && 
    answers[answers.length - 1].attributes.answers && 
    answers[answers.length - 1].attributes.answers[index] && 
    answers[answers.length - 1].attributes.answers[index].answer
  }

  getInnerHtml = (answers: StudentAnswerForCondition[], currentIndex: number) => {
    let validAnswer = answers && answers[answers.length - 1] && answers[answers.length - 1].attributes && 
    answers[answers.length - 1].attributes  && answers[answers.length - 1].attributes.answers && 
    answers[answers.length - 1].attributes.answers[currentIndex] && 
    answers[answers.length - 1].attributes.answers[currentIndex].answer

    return validAnswer || ''
  }

  handleEmptyTitle = (text: string | null) => {
    return text || ''
  }

  handleCloseSnack  = () => {
    this.setState({
      isLimitModalOpen: false,
    })
  }

parseCanvasData = (data: any, key: string) => {
  if (!data) return null;
  try {
    return typeof data[key] === "string" ? JSON.parse(data[key]) : data[key];
  } catch (error) {
    return null;
  }
};

textQuestionConditionCanva = (textData: GetAnswerStyleData) => {
  const parseCanvasDataSafely = (data: any, key: string) => {
    try {
      const parsedData = typeof data === "object" ? data : JSON.parse(data || "{}");
      return this.parseCanvasData(parsedData, key);
    } catch (error) {
      return null;
    }
  };

  const canvassData = textData.base?.attributes?.canvas_data || "{}";
  const canvass = parseCanvasDataSafely(canvassData, "canvas");
  const canvassSize = typeof canvassData === "object" ? canvassData.canvasSize : null;

  const descCanvass = parseCanvasDataSafely(canvassData, "AddTextCanvas");
  const descCanvassSize = typeof canvassData === "object" ? canvassData.addTextCanvasSize : null;

  const canvassDataForSub = textData.subStage?.attributes?.canvas_data || "{}";
  const canvassSub = parseCanvasDataSafely(canvassDataForSub, "canvas");
  const canvassSizeSub = typeof canvassDataForSub === "object" ? canvassDataForSub.canvasSize : null;

  return { canvass, canvassSize, descCanvass, descCanvassSize, canvassSub, canvassSizeSub };
};

canvasImageCommonCondition = (data: AssignmentMarkupTool) => {
  let canvas_data = data?.attributes?.canvas_data;

  let parsedCanvasData;

  if (typeof canvas_data === "object") {
    parsedCanvasData = canvas_data;
  } else {
    if (canvas_data) {
      parsedCanvasData = JSON.parse(canvas_data);
    } else {
      parsedCanvasData = {};
    }
  }

  let myCanva = null; 

  if (parsedCanvasData?.canvas) {
    if (typeof parsedCanvasData.canvas === "string") {
      myCanva = JSON.parse(parsedCanvasData.canvas);
    } else {
      myCanva = parsedCanvasData.canvas;
    }
  }

  let canvaSize = parsedCanvasData?.canvasSize || null;

  return { myCanva, canvaSize };
};
canvasConditionParsed = (data: AssignmentMarkupTool) => {
  const canvas_datas = data?.attributes?.canvas_data;

  let parsed_data;
  
  if (canvas_datas && typeof canvas_datas === "object") {
      parsed_data = canvas_datas; 
  } else if (typeof canvas_datas === "string") {
      try {
          parsed_data = JSON.parse(canvas_datas); 
      } catch (error) {
          return { canvasData: null, canvasDataSize: null }; 
      }
  } else {
      parsed_data = {}; 
  }

  const canvasData = parsed_data?.canvas ? JSON.parse(parsed_data.canvas) : null;
  const canvasDataSize = parsed_data?.canvasSize || null;

  return { canvasData, canvasDataSize };
};

    // Customizable Area End
}
