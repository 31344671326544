import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'];
const SCOPES = "profile email";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  selectedRole: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  loginError: string | undefined;
  isVisibleModal: boolean;
  selectedRole: string;
  name: string;
  confirmPassword: string;
  nameError: string;
  emailError: string;
  passwordError: string;
  confirmPasswordError: string;
  enableConfirmPasswordField: boolean;
  signupError: string | undefined;
  incomingAccessToken: string;
  source: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  subTitle: string = "";
  labelSubTitle: string = "";
  apiEmailSignUpCallId: string = "";
  createAccountAPICallId: any = "";
  checkAccountAPICallId: any = "";
  showAccessApi: string="";
  showAccessForQuizApi:string="";
  showAccessForAssignmentApi:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      loading: false,
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      loginError: "",
      isVisibleModal: false,
      selectedRole: "Teacher",
      name: "",
      confirmPassword: "",
      nameError: "",
      emailError: "",
      passwordError: "",
      confirmPasswordError: "",
      enableConfirmPasswordField: true,
      signupError: "",
      incomingAccessToken: "",
      source: ""
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    this.subTitle = configJSON.subTitle;
    this.loadAuth2()
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.updateStateBasedOnRememberMe()
    // this.handleClientLoad();
    // Customizable Area End
  }

  // Customizable Area Start

  loadAuth2=async()=> {
    const gapi = await import('gapi-script').then((pack) => pack.gapi);
    //@ts-ignore
   gapi?.load("auth2", () => {
      //@ts-ignore
      gapi.auth2.init({ client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID });
    });
  }
  checkRememberMe(){
    if(this.state.checkedRememberMe){
      window.localStorage.setItem("rememberMe","true")
    }else{
      window.localStorage.removeItem("rememberMe")
    }
  }
  updateStateBasedOnRememberMe(){
    const rememberMe = window.localStorage.getItem("rememberMe");
    let emailField:string="";
    let passwordField:string="";
    let rememberMeField:boolean=false;
    if(rememberMe) {
      const user : any = window.localStorage.getItem("saveUserInfo"); 
      let userObj:any;
      if(user){
        userObj=JSON.parse(user)
      }
      const email = userObj.id
      const password = userObj.password
      if(email!=null && password!=null){
        emailField= email
        passwordField= password
        rememberMeField=true
      }
    }
    this.setState({
      email:emailField,
      password:passwordField,
      checkedRememberMe:rememberMeField
    })
  }

  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  handleClickShowConfirmPassword = () => {
    this.setState({
      enableConfirmPasswordField: !this.state.enableConfirmPasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  setName = (text: string) => {
    this.setState({
      name: text,
    });
  };

  setConfirmPassword = (text: string) => {
    this.setState({
      confirmPassword: text,
    });
  };

  handleSubmit=()=>{
    this.setState({loading: true})
    if(
      this.state.email.length === 0 &&
      this.state.password.length === 0
      ){
        this.setState({loginError: configJSON.errorEnterCredentials})
        this.setState({loading: false})
        return false;
      }
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
      ) {
        this.setState({loginError: configJSON.errorEmailNotValid})
        this.setState({loading: false})
        return false;
      }
      
      if (this.state.password === null || this.state.password.length === 0) {
        this.setState({loginError: configJSON.errorPasswordNotValid})
        this.setState({loading: false})
        return false;
      }
      
    this.doEmailLogIn()
  }

  validateForm=()=>{
    let isValid= true;

    if(this.state.name === "" &&
    this.state.name.length === 0){
      this.setState({nameError: configJSON.errorNameRequired})
      this.setState({loading: false})
      isValid= false;
    }else{
      this.setState({nameError: ""})
    }

    if(this.state.email === "" &&
    this.state.email.length === 0){
      this.setState({emailError: configJSON.errorEmailRequired})
      this.setState({loading: false})
      isValid= false;
    }else{
      this.setState({emailError: ""})
    }

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    if(this.state.password && !passwordRegex.test(this.state.password)){ 
      this.setState({passwordError: `Please enter a valid password.<br/>Your password must Include at least :<br/>- 8 characters length<br/>- One upper case character (A-Z)<br/>- One lower case character (a-z)<br/>- One digit (0-9)<br/>- One Symbol/Special character (!,#,$,etc)`})
      this.setState({loading: false})
      isValid= false;
    }else if(this.state.password === null || this.state.password.length === 0){
      this.setState({passwordError: configJSON.errorPasswordRequired})
      this.setState({loading: false})
      isValid= false;
    }else{
      this.setState({passwordError: ""})
    }

    if(this.state.confirmPassword === null || this.state.confirmPassword.length === 0){
      this.setState({confirmPasswordError: configJSON.errorConfirmPasswordRequired})
      this.setState({loading: false})
      isValid= false;
    }else if(this.state.password !== this.state.confirmPassword){
      this.setState({confirmPasswordError: "Passwords do not match"})
      this.setState({loading: false})
      isValid= false;
    }else{
      this.setState({confirmPasswordError: ""})
    }
    return isValid
  }

  handleFormSubmit=()=>{
    this.setState({loading: true})
    const isValid= this.validateForm()
    if (isValid) {
      this.doSignUp()
    }
  }

  handleClickLogo=()=>{
    this.props.navigation.navigate("HomePage");
  }

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  showSelectRoleModal = (source: string) => {
    this.setState({ isVisibleModal: true, source: source });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: !this.state.isVisibleModal });
  };

  handleSelectRole = (selectedRole: string) => {
    this.setState({selectedRole: selectedRole})
  }

  handleSubmitRole=()=>{
    if(this.state.source === "signup"){
      this.setState({
        name: "",
        confirmPassword: "",
        enablePasswordField: true
      })
      window.history.pushState(null, '', "/login/signup");
      this.hideModal()
    }else{
      this.loginWithGoogle()
      this.hideModal()
    }
  }

  goToLogin=()=>{
    this.setState({
      name: "",
      confirmPassword: "",
      enablePasswordField: true
    })
    window.location.href = "/login"
  }

  doSignUp(): boolean {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      name: this.state.name,
      email: this.state.email,
      role_id: this.props.selectedRole === 'Teacher' ? 1 : 2,
      password: this.state.password,
      confirm_password: this.state.confirmPassword,
    };
    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailSignUpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signupAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  loginWithGoogle(): boolean {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };
    const data = {
        type: "social_account",
        attributes: {
          social_type: "google",
          role_id: this.state.selectedRole === "Teacher" && 1 || 
                this.state.selectedRole ===  "Student" && 2,
          access_token: this.state.incomingAccessToken
        }
      };
  
      const httpBody = {
        data: data
      };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createAccountAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.googleLoginUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  checkAccountExistence() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.checkAccountAPICallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.checkAccountUrl}?access_token=${
        this.state.incomingAccessToken
      }`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  renderGoogleLogin=(responseJson: any, errorReponse:any, message: Message)=>{
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      this.saveLoggedInUserData(responseJson);
      window.localStorage.setItem("role",responseJson.account.data.attributes.role)
      window.location.replace("dashboard");
    } else if (responseJson && responseJson.errors) {
      this.parseApiErrorResponse(responseJson);
    } else {
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  renderCheckAccount=(responseJson: any, errorReponse:any, message: Message)=>{
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      this.saveLoggedInUserData(responseJson);
      window.localStorage.setItem("role",responseJson.meta.role_id)
      window.location.replace("dashboard");
    } else if (responseJson && responseJson.errors) {
      this.parseApiErrorResponse(responseJson);
    } else if (responseJson && responseJson.message === "Account not found") {
      this.showSelectRoleModal("google-login")
    }else {
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  renderSignUp=(responseJson: any, errorReponse:any)=>{
    if (responseJson && responseJson.data) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      window.location.href= "/login"
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
      this.setState({signupError: responseJson.errors[0].account})
      this.setState({loading: false})
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  
  renderLogin = async(responseJson: any, errorReponse:any)=>{
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      window.localStorage.setItem("role",responseJson.meta.role_id) 
      window.localStorage.setItem("id",responseJson.meta.id)
      this.checkRememberMe()
      this.saveLoggedInUserData(responseJson);
      this.sendLoginSuccessMessage();

      const currentPath = await getStorageData("shareRoute");      
      const sharedPathRegex = /^\/library\/shared\/(Lessons|Quizzes|Assignments)\/(\d+)$/;
      const match = currentPath  && currentPath.match(sharedPathRegex);
     
        if (match) {
          const item = match[1]
          const id = match[2];
          setStorageData("sharedId", id);
          setStorageData("item",item)
          const sharedId = await getStorageData("sharedId")
          const sharedItem = await getStorageData("item")
          if (sharedId && sharedItem) {
           this.getAccessData(sharedId,sharedItem)
          }
      } else {
        this.props.navigation.navigate("Dashboard");
      }
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
      this.setState({loginError: responseJson.errors[0].failed_login})
      this.setState({loading: false})
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  renderValidation=(responseJson: any, errorReponse:any)=>{
    let arrayholder = responseJson.data;

    if (arrayholder && arrayholder.length !== 0) {
      let regexData = arrayholder[0];

      if (regexData && regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  }

  stopLoading = () => {
    this.setState({ loading: false });
  };

  doGoogleLogin=async()=>{
    try {
      const gapi = await import('gapi-script').then((pack) => pack.gapi);
      //@ts-ignore
      const auth2 = gapi?.auth2.getAuthInstance();
      //@ts-ignore
      const user: any= await auth2.signIn({ prompt: "consent" });
      this.setState({incomingAccessToken: user.xc.access_token})
      this.checkAccountExistence()
    } catch (error) {
      runEngine.debugLog(error);
    }
  }

  initClient = async () => {
    const gapi = await import('gapi-script').then((pack) => pack.gapi);
    gapi.client.init({
      apiKey: process.env.GREACT_APP_GOOGLE_API_KEY,
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      discoveryDocs: DISCOVERY_DOCS,
      scope: SCOPES,
    }).then(() => {
      gapi.auth2.getAuthInstance().isSignedIn.listen(()=>{});
    });
  };

  handleClientLoad = () => {
    gapi.load('client:auth2', this.initClient);
  };

  getAccessData =  (id: string | null, item:string) => {
    const header = {
      token: window.localStorage.getItem("authToken"),
      "Content-Type": configJSON.validationApiContentType
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    if (item === "Lessons") {
      this.showAccessApi = requestMessage.messageId
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.checkAccessForLesson}?id=${id}`
      );
    } else if (item === "Quizzes") {
      this.showAccessForQuizApi = requestMessage.messageId
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.checkAccessForQuiz}?id=${id}`
      );
    } else {
      this.showAccessForAssignmentApi = requestMessage.messageId
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.checkAccessForAssignment}?id=${id}`
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  renderIsAccess(responseJson: any, errorReponse: any,apiRequestCallId:any) {
    if (
      apiRequestCallId != null &&
      apiRequestCallId === this.showAccessApi || apiRequestCallId === this.showAccessForQuizApi || apiRequestCallId ===this.showAccessForAssignmentApi
    ) {
    if (responseJson.error || responseJson.permission_errors) {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "AccessDenied");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    } else if (responseJson.data) {
      const sharedId = window.localStorage.getItem("sharedId")
      const item = window.localStorage.getItem("item")
      if(sharedId){
        window.location.replace(`/library/shared/${item}/${sharedId}`);

      }
    }
  }
  }
  
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.stopLoading()
    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );
        
      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });

        //@ts-ignore
        this.txtInputEmailProps.value = userName;

        //@ts-ignore
        this.txtInputPasswordProps.value = password;

        this.CustomCheckBoxProps.isChecked = true;
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {

        {
          (apiRequestCallId === this.validationApiCallId &&
          responseJson !== undefined) &&
            this.renderValidation(responseJson,errorReponse);
        }

        {
          apiRequestCallId === this.apiEmailLoginCallId &&
            this.renderLogin(responseJson,errorReponse);
        }

        {
          apiRequestCallId === this.apiEmailSignUpCallId &&
            this.renderSignUp(responseJson,errorReponse);
        }

        {
          apiRequestCallId === this.checkAccountAPICallId &&
            this.renderCheckAccount(responseJson,errorReponse, message);
        }

        {
          apiRequestCallId === this.createAccountAPICallId &&
            this.renderGoogleLogin(responseJson,errorReponse, message);
        }
          this.renderIsAccess(responseJson, errorReponse,apiRequestCallId);
      }
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  goToForgotPassword() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
