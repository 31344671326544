import React, { useEffect, useState } from "react";
import ButtonsLayout from "../components/ButtonsLayout.web";
import StageLayout from "../components/StageLayout.web";
import EditLayout from "../components/EditLayout.web";
import { Grid, Box } from "@material-ui/core";
import { ACTIONS } from "../../../../../components/src/types";
import { makeStyles } from "@material-ui/core/styles";
import { zoomIn, zoomOut, drag, nextPage, backPage,activeDragIcon } from "../../assets";
import MultimediaPreviewScreen from "../../../../../blocks/catalogue/src/Teachers/components/TeacherSelectedPractice/PreviewScreens/MultimediaPreviewScreen.web";
import OpenEndedPreview from "../../../../../blocks/catalogue/src/Teachers/components/TeacherSelectedPractice/PreviewScreens/OpenEndedPreview.web";
import McqPreviewPage from "../../../../../blocks/catalogue/src/Teachers/components/TeacherSelectedPractice/PreviewScreens/McqPreviewPage.web";
import PollPreviewScreen from "../../../../../blocks/catalogue/src/Teachers/components/TeacherSelectedPractice/PreviewScreens/PollPreviewScreen.web";
import WordCloudPreview from "../../../../../blocks/catalogue/src/Teachers/components/TeacherSelectedPractice/PreviewScreens/WordCloudPreview.web";
import MatchingQuesPreview from "../../../../../blocks/catalogue/src/Teachers/components/TeacherSelectedPractice/PreviewScreens/MatchingQuesPreview.web";
import ChartPreview from "../../../../../blocks/catalogue/src/Teachers/components/TeacherSelectedPractice/PreviewScreens/ChartPreview.web";
import VennDiagramPreview from "../../../../catalogue/src/Teachers/components/TeacherSelectedPractice/PreviewScreens/VennDiagramPreview.web";
import PreviewStagePage from "../../../../catalogue/src/Teachers/components/TeacherSelectedPractice/PreviewScreens/PreviewStagesPage.web";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import StorageProvider from "../../../../../framework/src/StorageProvider"

interface Pair {
  id: number;
  pair_question: string;
  pair_answer: string;
}

interface Question {
  options: string[];
}

interface Position{
  x:number,
  y:number
}

interface InitialState {
  questions: Question[];
}

const useStyles = makeStyles(() => ({
  previewArea: {
    fontFamily: "Poppins",
    height: "784px",
    width: "100%",
    marginTop: "20px",
    display: "flex",
    borderRadius: "8px",
    boxShadow: "rgba(52, 146, 159, 0.16) 0px 0px 3px 1px",
    flexDirection: "column",
    gap: "25px",
    backgroundColor:"#FFFFFF"
  },
  breadcrumbContainer: {
    padding : "20px",
    display : "flex",
    alignItems : "center"
  },
  breadcrumbsStyle: {
    color: "#757575",
    cursor: "pointer",
    fontSize: "24px",
    fontFamily: "Poppins",
    fontWeight: 500
  },
  breadcrumbDocStyle: {
    color: "#000000",
    cursor: "pointer",
    fontSize: "24px",
    fontFamily: "Poppins",
    fontWeight: 500  
  },
  toolbar: {
    height: "52px",
    width: "100%",
    backgroundColor: "#FDF4DC",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexShrink:0
  },
  imgList: {
    display: "flex",
    flexDirection: "row",
    gap: "32px",
    marginRight: "35px",
    alignItems:"center"
  },
  paginationLayout: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "64px"
  },
  pageNo: {
    backgroundColor: "#FFFFFF",
    width: "75px",
    display: "flex",
    justifyContent: "center",
    gap: "12px",
    margin:"10px"
  },
  canvasContainer: {
    overflow: 'auto',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  annotationContainer: {
    position: 'absolute',
    transform: 'translateY(10px)',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: "rgba(52, 146, 159, 0.16) 0px 0px 3px 1px",
    borderRadius: '6px',
    paddingTop:'6px',
    paddingBottom:'6px'
  },
  annotationItem: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '4px 8px',
    '&:hover': {
      backgroundColor: '#f4f4f4',
    },
    '& img': {
      marginRight: '8px',
    }
  }
}));
const Lesson = (props: any) => {
  const {
    redirectToDashboard,
    stages,
    addTextStage,
    addChartStage,
    lessonName,
    addMultiMediaStage,
    updateMultiMediaStage,
    addWordCloudStage,
    addMcqStage,
    addPollStage,
    addOpenEndedStage,
    selectedView,
    addMatchingQStage,
    loading,
    deleteStageCallBack,
    reorderStage,
    buttonType,
    getStages,
    saveModalOpen,
    handleDoublePointActive,
    navigation,
    handleOpenEndedChange,
    openEndedState,
    handleOpenEndedClick,
    handleMcqClick,
    handleMatchClick,
    handlePollClick,
    mcqSettingState,
    updateQuizMusic,
    gDrivefile,
    addingModal,
    setAddingModal,
    gdriveModal,
    setGDriveModal,
    setUploadModal,
    uploadModal,
    setOldId,
    oldUserId,
    setAssignOldId,
    assignUserId,
    getFilesGdrive,
    mistmatchTypeErr,
    resetError,
    innerModalOpen,
    setInnerModal
  } = props;
  const [action, setAction] = React.useState("");
  const [previewAction, setPreviewAction] = React.useState("")
  const [previewPageNum, setPreviewPageNum] = React.useState(0)
  const [currentStage, setCurrentStage] = React.useState<{attributes:{videos_url : string[] , videos : string},id:unknown}>()
  const [title, setTitle] = React.useState<any>("");
  const [canvas, setCanvas] = React.useState<any>("");
  const [status, setStatus] = React.useState<boolean>(false);
  const [addTextState, setAddTextState] = React.useState<any>();
  const [chartState, setChartState] = React.useState<any>({});
  const [multiMediaState, setMultiMediaState] = React.useState<any>({});
  const [vennState, setVennState] = React.useState<any>({});
  const [multiMediaData, setMultiMediaData] = React.useState();
  const [editMultiMediaId, seteditMultiMediaId] = React.useState("");
  const [addWordCloudState, setAddWordCloudState] = React.useState({});
  const [enteriesCount,setEnteriesCount] = React.useState<any>("")
  const [preview,setPreview]=React.useState<any>(null)
  const [mcqState, setMcqState] = React.useState<any>({});
  const [pollState, setPollState] = React.useState<any>({});
  const [openendedState, setopenendedState] = React.useState<object|null>({});
  const [matchquestionState, setmatchquestionState] = React.useState<object|null>({});
  const [addOpenEndedTextState,setAddOpenEndedTextState] = React.useState<any>("");
  const[mcqfile,setMcqfile]=React.useState<any>();
  const[pollfile,setPollfile]=React.useState<any>();
  const[openEndedQuestionfile,setOpenEndedQuestionfile]=React.useState<any>();
  const [pairs, setPairs] = React.useState<Pair[]>([]);
  const [pairCount, setPairCount] = React.useState<number>(0);
  const [ isNew, setIsNew ] = useState(false);
 const [parentStage,setParentStage] = useState(0);
 const [stageId,setStageId] = useState(0);
 const [createdAction, setCreatedAction] = React.useState("");
 const [isPreviewClicked, setIsPreviewClicked] = React.useState(false) 
 const [zoomLevel, setZoomLevel] = React.useState<number>(1);
 const [position, setPosition] = React.useState<Position>({ x: 0, y: 0 });
 const [isDrag,setIsDrag]= React.useState<boolean>(false);
 const [mainStages,setMainStages] = React.useState([]);
 const [currentStageNumber,setCurrentStageNumber] = React.useState("1");
 const [openCanvas, setOpenCanvas] = React.useState<any>(false);
 const [hasCanvasItem, setHasCanvasItem] = React.useState<boolean>(false);
 const [isSaveDisabled,setIsSaveDisabled] = React.useState<boolean>(false);

  const getInputBoxesCount = (actionName: string) => {
    switch (actionName) {
      case ACTIONS.KWL_CHART:
        return 3;
      case ACTIONS.T_CHART:
        return 2;
      case ACTIONS.SEED_DISCUSSION:
        return 4;
      case ACTIONS.WH_CHART:
        return 6;
      default:
        return 1;
    }
  };
  const initState = (actionName: string) => {
    setChartState({
      question: "",
      description: new Array(getInputBoxesCount(actionName)).fill({ topic: "", answer: "" })
    });
    setVennState({
      question: "",
      sectionA: "",
      sectionB: "",
      sectionC: "",
    });
    setAddTextState("");
    setAddWordCloudState({
      question:"",
      description:[],
      backgroundImage:""
    });
    setMcqState({ questions: [{options: []}]})
    setPollState({ questions: [{options: []}]})
    setopenendedState({ questions: [{options: []}]})
    setmatchquestionState({ questions: [{options: []}]})
    setAddOpenEndedTextState("");
  };

  const getSubStageAlphabet = (curIndex: number) => {
    let result = '';
    let num = curIndex + 1;
    while (num > 0) {
        let remainder = num % 26;
        if (remainder === 0) {
            remainder = 26;
        }
        result = String.fromCharCode(64 + remainder) + result;
        num = Math.floor((num - remainder) / 26);
    }

    return result;
  };

  const setStageNumber = (index:number) => {
    const currentPStage = stages[index]
    const stageNum = currentPStage.attributes.stage_number 
    if(/^\d+$/.test(stageNum))
    {
      setCurrentStageNumber((mainStages.findIndex((curStage:any)=>curStage.id==currentPStage.id)+1).toString())
    }
    else{
      const parentStageNum = stageNum.toString().match(/^\d+/)[0];
      const subStages = stages.filter((curStage:any)=>
        curStage.attributes.stage_number.toString().match(/^\d+/)[0] == parentStageNum
        && curStage.attributes.stage_number != parentStageNum
      )
      const mainStageIndex = (mainStages.findIndex((curStage:any)=>curStage.attributes.stage_number==parentStageNum)+1).toString()
      setCurrentStageNumber(mainStageIndex+getSubStageAlphabet(subStages.findIndex((curStage:any)=>curStage.id==currentPStage.id)))
    }
  }

  const handlePrevPage=()=>{
    if(previewPageNum > 0){
      setPreviewPageNum(previewPageNum-1)
    }
    setZoomLevel(1)
    setPosition({x: 0, y: 0})
    setIsDrag(false)
  }

  const getCaptitalizedTitle = (selectedView: string | undefined): string => {
    if (selectedView === "QUIZ") {
      return "Quizze";
    }
  
    return selectedView
      ? selectedView.charAt(0).toUpperCase() + selectedView.substring(1).toLowerCase()
      : "";
  };

  const handleNextPage=()=>{
    if(previewPageNum < (stages.length - 1)){
      setPreviewPageNum(previewPageNum+1)
    }
    setZoomLevel(1)
    setPosition({x: 0, y: 0})
    setIsDrag(false)
  }

  const removeCanvasData=()=>{
    StorageProvider.remove('canvasdata')
    StorageProvider.remove('canvasSize')
    localStorage.removeItem("addTextCanvasdata")
    localStorage.removeItem("addTextCanvasSize")
  }
  
  const loadState = (stage: any) => { 
    const { stageType,id } = stage;
    setStageId(id)
    if (stageType === ACTIONS.ADD_TEXT) {
      setAddTextState(stage.attributes.description[0]);
      
    } else if (
      stageType === ACTIONS.KWL_CHART ||
      stageType === ACTIONS.T_CHART ||
      stageType === ACTIONS.SEED_DISCUSSION ||
      stageType === ACTIONS.WH_CHART
    ) {
      setChartState({
        question: stage.attributes.question,
        description: stage.attributes.description,
      });
    }else if (
      stageType === "add_multimedia" ){
        setMultiMediaData(stage.attributes) 
    }else if(stageType===ACTIONS.VENN_DIAGRAM){
      setVennState({
        question: stage.attributes.question,
      })
    } else if (stageType===ACTIONS.ADD_WORD_CLOUD){
      setEnteriesCount(stage.attributes.description?.length)
      setPreview(stage.attributes.background_image?.url)
      setAddWordCloudState({
        question: stage.attributes.question,
        description: stage.attributes.description,
        backgroundImage:stage.attributes.background_image
      });
    } else if (stageType===ACTIONS.ADD_MCQ || stageType==="MCQ"){
      setPreview(stage.attributes.background_image?.url)
      setMcqState(stage.attributes)
    }
    else if (stageType===ACTIONS.ADD_POLL ||stageType=== "polling_question"){
      setPreview(stage.attributes.background_image?.url)
      setPollState(stage.attributes)
      
    }
    else if (stageType===ACTIONS.OPEN_ENDED_QUESTION ||stageType=== "open_ended_question"){
      setPreview(stage.attributes.background_image?.url)
      setopenendedState(stage.attributes)
      setAddOpenEndedTextState(stage.attributes.description)
    }
    else if (stageType === ACTIONS.ADD_MATCHING_Q) {
      let stagePairs = stage.attributes.pairs || stage.attributes.matching_pairs
      setPreview(stage.attributes.background_image?.url)
      setPairs(stagePairs);
      setPairCount(stagePairs.length);
      setmatchquestionState(stage.attributes)
    }
  };
  //New Stage
  const handleActionChange = (actionName: string,parentStage: number = 0,isNewCreated:boolean = false) => {
    setOpenCanvas(false)
    setHasCanvasItem(false)
    setIsNew(true)
    setAction(actionName);
    !isNewCreated && setCreatedAction(actionName);
    setTitle("");
    setCanvas("")
    setStatus(false)
    initState(actionName);
    setEnteriesCount("")
    setPreview(null)
    setPairs([])
    setPairCount(0)
    setParentStage(parentStage)
    setStageId(0)
    removeCanvasData()
    handleMatchClick({
      checkedA: false,
      checkedB: false,
      checkedC: false
    })
  };

  //Existing stage
  const selectStage = (id: number) => {
    setPreview(null)
    if (!id) {
      setAction("");
      setCreatedAction("");
      setTitle("");
      setCanvas("")
      setStatus(false)
      setParentStage(0)
      setStageId(0)
      removeCanvasData()
      setOpenCanvas(false)
      setHasCanvasItem(false)
      return;
    }
    const stage = stages.find((obj: any) => obj.id === id);
      handleOpenEndedClick({
        checkedA: stage.attributes.point,
        checkedB: stage.attributes.double_point,
        checkedC: false
      })
      handleMcqClick({
        checkedA: stage.attributes.point,
        checkedB: stage.attributes.double_point,
        checkedC: false
      })
      handleMatchClick({
        checkedA: stage.attributes.point,
        checkedB: stage.attributes.double_point,
        checkedC: false
      })
      handlePollClick({
        checkedA: stage.attributes.point,
        checkedB: stage.attributes.double_point,
        checkedC: false
      })

      
    setAction(stage.stageType);
    setCreatedAction(stage.stageType);
    setTitle(stage.title);
    if(typeof stage.attributes.canvas_data=="object"){

      setCanvas(stage.attributes.canvas_data)
    }
    else if(stage.attributes.canvas_data){
      setCanvas(JSON.parse(stage.attributes.canvas_data))
    }
    else{
      setCanvas("")
      removeCanvasData()
    }
    setStatus(true)
    setIsNew(false)
    setMcqState(null)
    setPollState(null);
    setopenendedState(null);
    setmatchquestionState(null);
    setAddOpenEndedTextState(null);
    loadState(stage);
    seteditMultiMediaId(stage.id)
    setParentStage(0)
  };


  const renderLayout = () => {
    if (previewAction === ACTIONS.ADD_TEXT) {
      return (
        <PreviewStagePage currentStage={currentStage} />
      );
    } else if (previewAction === ACTIONS.MULTIMEDIA || previewAction === "add_multimedia") {
      return (
        <MultimediaPreviewScreen multiMediaData={currentStage} loading={loading} />
      )
    } else if (previewAction === ACTIONS.OPEN_ENDED_QUESTION) {
      return (
        <OpenEndedPreview currentStage={currentStage} />
      )
    } else if (previewAction === ACTIONS.MCQ) {
      return (
        <McqPreviewPage currentStage={currentStage} />
      )
    } else if (previewAction === "polling_question") {
      return (
        <PollPreviewScreen currentStage={currentStage} />
      )
    } else if (previewAction === ACTIONS.ADD_WORD_CLOUD) {
      return (
        <WordCloudPreview currentStage={currentStage} />
      )
    } else if (previewAction === ACTIONS.ADD_MATCHING_Q) {
      return (<MatchingQuesPreview currentStage={currentStage} />)
    } else if (previewAction === ACTIONS.KWL_CHART ||
      previewAction === ACTIONS.T_CHART ||
      previewAction === ACTIONS.SEED_DISCUSSION ||
      previewAction === ACTIONS.WH_CHART) {
      return (<ChartPreview currentStage={currentStage} />)
    } else if(previewAction ===ACTIONS.VENN_DIAGRAM){
      return(
        <VennDiagramPreview currentStage={currentStage}/>
      )
    }

    else {
      return (<></>)
    }
  };


  const handleZoomOut = () => {
    setZoomLevel(zoomLevel - 0.1);
  };

  const posRef = React.useRef(position);
  posRef.current = position;
  const handleZoomIn = () => {
      setZoomLevel(zoomLevel + 0.1);
  };


  useEffect(()=>{
    if (stages && stages.length > previewPageNum) {
      setPreviewAction(stages[previewPageNum]?.stageType);
      setCurrentStage(stages[previewPageNum]);
      setStageNumber(previewPageNum)
    }
  },[previewPageNum])

  useEffect(()=>{
    if (stages && stages.length > previewPageNum) {
      setPreviewAction(stages[previewPageNum]?.stageType);
      setCurrentStage(stages[previewPageNum]);
    }
    const latestStageData = stages?.length > 0 ? stages[stages.length - 1] : null;
    if(buttonType == 'save'){
      setStageId(latestStageData?.id ?? 0)
    }
    else{
        handleActionChange("",0,true)
    } 
    setMainStages(stages.filter((stage:any)=>/^\d+$/.test(stage.attributes.stage_number)))
  },[stages])

  const classes = useStyles();

  const disabledBtn = () => {
    setIsPreviewClicked(false)
  }

  return (
    <Box>
      {isPreviewClicked ? <Box>
        <Box className={classes.breadcrumbContainer}>
          <Box className={classes.breadcrumbsStyle} data-test-id = "disabledBtnTestId" onClick={disabledBtn}>{`${getCaptitalizedTitle(selectedView,)}`}s</Box> 
          <NavigateNextIcon className={classes.breadcrumbsStyle} fontSize="large" /> 
          <Box className={classes.breadcrumbDocStyle}>Documents</Box> 
        </Box>
        <Box className={classes.previewArea}>
          <Box className={classes.toolbar}>
            <Box className={classes.paginationLayout}>
              Page
              <div className={classes.pageNo}>
                <img
                  src={backPage}
                  onClick={handlePrevPage}
                  style={{ cursor: "pointer" }}
                  data-test-id="prev-page" 
                />
                {currentStageNumber}
                <img 
                src={nextPage} 
                onClick={handleNextPage} 
                style={{ cursor: "pointer" }}
                data-test-id="next-page" 
                />
              </div>
              {mainStages.length}
            </Box>
            <Box className={classes.imgList}>
              <img
               src={zoomOut} 
               onClick={handleZoomOut}
               data-test-id="zoom-out"
               style={{cursor:"pointer"}}
               />
              <img 
               src={zoomIn}
               onClick={handleZoomIn}
               data-test-id="zoom-in"
               style={{cursor:"pointer"}}
               />
            </Box>
          </Box>
          <Box style={{overflow: 'hidden',height:"100%"}}>
            <div
              className={classes.canvasContainer}
              id="canvasContainer"
            >
              <div
                style={{
                  transform: `scale(${zoomLevel}) translate(${position.x}px, ${position.y}px)`,
                  transformOrigin: '0 0',
                  cursor: isDrag ? "grab" : "default",
                }}
                data-test-id="start-drag"
              >
                <Box
                  data-test-id="open-annotation"
                  >
                  {renderLayout()}
                </Box>
              </div>
            </div>
          </Box>
        </Box>
      </Box> : <Grid container>
        <Grid item md={2}>
          <StageLayout
            stages={stages}
            selectStage={selectStage}
            data-test-id="stage-layout"
            handleActionChange={handleActionChange}
            deleteStageCallBack={deleteStageCallBack}
            reorderStage={reorderStage}
            selectedView={selectedView}
            handleActiveSiled={props.handleActiveSiled}
            handleDoublePointActive={handleDoublePointActive}
            handleOpenEndedChange={handleOpenEndedChange}
          />
        </Grid>
        <Grid item md={8}>
          <EditLayout
            handleActiveSiled={props.handleActiveSiled}
            openEndedState={openEndedState}
            handleDoublePointActiveSiled={props.handleDoublePointActiveSiled}
            handleOpenEndedChange={handleOpenEndedChange}
            stages={stages}  
            redirectToDashboard={redirectToDashboard}
            action={action}
            addTextStage={addTextStage}
            addChartStage={addChartStage}
            lessonName={lessonName}
            isNew={isNew}
            title={title}
            canvas={canvas}
            status={status}
            setTitle={setTitle}
            addTextState={addTextState}
            setAddTextState={setAddTextState}
            setChartState={setChartState}
            chartState={chartState}
            multiMediaData={multiMediaData}
            multiMediaState={multiMediaState}
            setMultiMediaState={setMultiMediaState}
            data-test-id="edit-layout"
            vennState={vennState}
            setVennState={setVennState}
            editMultiMediaId={editMultiMediaId}
            updateMultiMediaStage={updateMultiMediaStage}
            addWordCloudState={addWordCloudState}
            setAddWordCloudState={setAddWordCloudState}
            addWordCloudStage={addWordCloudStage}
            enteriesCount={enteriesCount}
            setEnteriesCount={setEnteriesCount}
            preview={preview} 
            setPreview={setPreview}
            addMcqStage={addMcqStage}
            addPollStage={addPollStage}
            mcqState={mcqState}  
            pollState={pollState}   
            openendedState={openendedState}  
            matchquestionState={matchquestionState}
            setopenendedState={setopenendedState}
            setmatchquestionState={setmatchquestionState}
            addOpenEndedTextState={addOpenEndedTextState}  
            setAddOpenEndedTextState={setAddOpenEndedTextState}
            addOpenEndedStage={addOpenEndedStage}
            setMcqfile={setMcqfile}
            mcqfile={mcqfile}
            setPollfile={setPollfile}
            pollfile={pollfile}
            setOpenEndedQuestionfile={setOpenEndedQuestionfile}
            openEndedQuestionfile={openEndedQuestionfile}
            selectedView={selectedView}
            pairs={pairs}
            setPairs={setPairs}
            pairCount={pairCount}
            setPairCount={setPairCount}
            addMatchingQStage={addMatchingQStage}
            loading={loading}
            parentStage={parentStage}
            stageId={stageId}
            createdAction={createdAction}
            handleActionChange={handleActionChange}
            isActive={props.isActive}
            isDoublePointActve={props.isDoublePointActve}
            handleDoublePointActive={handleDoublePointActive}
            handleMcqClick={handleMcqClick}
            handleMcqChange={props.handleMcqChange}
            mcqSettingState={mcqSettingState}
            handleMatchChange={props.handleMatchChange}
            handleMatchClick={props.handleMatchClick}
            handlePollClick={props.handlePollClick}
            handlePollChange={props.handlePollChange}
            matchSetState={props.matchSetState}
            pollSettingState={props.pollSettingState}
            openCanvas={openCanvas}
            setOpenCanvas={setOpenCanvas}
            hasCanvasItem={hasCanvasItem}
            setHasCanvasItem={setHasCanvasItem}
            setIsSaveDisabled={setIsSaveDisabled}
            isSaveDisabled={isSaveDisabled}
          />
        </Grid>
        <Grid item md={2}>
          <ButtonsLayout
            handleActionChange={handleActionChange}
            data-test-id="buttons-layout"
            addMultiMediaStage={addMultiMediaStage}
            setMcqfile={setMcqfile}
            setPollfile={setPollfile}
            setOpenEndedQuestionfile={setOpenEndedQuestionfile}
            selectedView={selectedView}
            saveModalOpen={saveModalOpen} 
            getStages={getStages}  
            setIsPreviewClicked={setIsPreviewClicked}  
            stages={stages}     
            navigation={navigation} 
            handleDoublePointActive={handleDoublePointActive}
            handleOpenEndedClick={props.handleOpenEndedClick}
            handleMcqClick={handleMcqClick}
            handlePollClick={props.handlePollClick}
            handleMatchClick={props.handleMatchClick}
            updateQuizMusic={updateQuizMusic}
            isMusic={props.isMusic}
            gDrivefile={gDrivefile}
            addingModal={addingModal}
            setAddingModal={setAddingModal}
            gdriveModal={gdriveModal}
            setGDriveModal={setGDriveModal}
            setUploadModal={setUploadModal}
            uploadModal={uploadModal}
            setOldId = {setOldId}
            oldUserId= {oldUserId}
            setAssignOldId={setAssignOldId}
            assignUserId={assignUserId}
            getFilesGdrive={getFilesGdrive}
            mistmatchTypeErr={mistmatchTypeErr}
            resetError={resetError}
            innerModalOpen={innerModalOpen}
            setInnerModal={setInnerModal}
            setOpenCanvas={setOpenCanvas}
            setHasCanvasItem={setHasCanvasItem}
            setCanvas={setCanvas}
            setStatus={setStatus}
            removeCanvasData={removeCanvasData}
            />
        </Grid>
      </Grid> }
    </Box>
  );
};

export default Lesson;
