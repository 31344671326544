import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { searchIcon } from "./assets";
import { Box } from "@material-ui/core";
const useStyles = makeStyles(() => ({
  searchbarBox: {
    display: "flex",
    gap: "4px",
    position: "relative",
  },
  searchbarIconbox: {
    position: "absolute",
    top: "13px",
    left: "10px",
  },
  searchFilterinput: {
    width: "538px",
    height: "52px",
    borderRadius: "8px",
    border: "1px solid rgba(43,61,125,0.39)",
    background: "#fff",
    paddingLeft: "40px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    '@media (max-width: 1032px)': {
      width: '100%'
    },
    '@media (max-width: 808px)': {
      width: '100%'
    },
    '@media (max-width: 600px)': {
      width: '100%'
    }
  },
}));
const SearchInput = (props: any) => {
  const { placeholder, searchInput, handleSearchInput, data,dataTestId } = props;
  const classes = useStyles();
  return (
    <Box className={classes.searchbarBox}>
      <Box className={classes.searchbarIconbox}>
        <img src={searchIcon} alt="Search Icon" />
      </Box>
      <input
      data-test-id={dataTestId}
        className={classes.searchFilterinput}
        placeholder={placeholder}
        onChange={(e) => {
          handleSearchInput && handleSearchInput(e.target.value, data)}
        }
        value={searchInput}
      />
    </Box>
  );
};

export default SearchInput;
