import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  TextField,
  Typography,
  Switch,
  Popover, 
  IconButton, 
  Grid, 
  Divider,
  Modal,
  styled
} from "@material-ui/core";
import { addMatchingQShuffleicon, addMatchingQAddicon,whiteSetTimer, settingBtn, timer, pointsIcon, addBackground, whiteAddBackground, blueTimer, whitePoints, whiteDoublePoint, whiteMusic, blueDoublePoint, blueMusicBtn, cancelBtn } from "../../assets";
import React, { useEffect, useState } from "react";
import AddBackgroundImageModal from "../../../../CFWordCloud/src/Teacher/components/AddBackgroundImageModal.web";
import GenericButton from "../../../../../components/src/GenericButton.web";
const crypto = require("crypto");
import StorageProvider from "../../../../../framework/src/StorageProvider";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { minutesDec, minutesInc, secondsDec, secondsInc } from "../../../../CfCourseCreation23/src/Teacher/components/Mcq.web";

interface Pair {
  id: number;
  pair_question: string;
  pair_answer: string;
  order_no: number;
}


const handleTimeScroll = (
  event: React.WheelEvent<HTMLInputElement>,
  type: string,
  minutes: number,
  seconds: number,
  setMinutes: Function,
  setSeconds: Function
) => {
  const delta = getDelta(event);
  clearPreviousTimeout();
  
    handleScroll(type, delta, minutes, seconds, setMinutes, setSeconds);
  
};

const FirstDivider = (setModalOpen: { (value: React.SetStateAction<boolean | undefined>): void; (value: boolean): void; },setAnchorEl: { (value: React.SetStateAction<HTMLButtonElement | null>): void; (value: null): void; },setIsHovered: { (value: React.SetStateAction<boolean>): void; (value: boolean): void; (value: boolean): void; },isHovered: boolean) =>{
  const classes = useStyles();
  return(
    <Box className={classes.elementPopup}>
    <Box className={classes.itemsPops}
    data-test-id="isHoverTesxtId"
      onClick={()=>handleModalOpen(setModalOpen,setAnchorEl)}
      onMouseEnter={()=>handleMouseEnter(setIsHovered)}
      onMouseLeave={()=>handleMouseLeave(setIsHovered)}>
      <img src={isHovered ? whiteAddBackground : addBackground}/>
      <Typography className={classes.popupHeading} >Add Background Image</Typography>
    </Box>
  </Box>
  )
}


const SecondDivision = (setIsModalOpen: { (value: React.SetStateAction<boolean>): void; (value: boolean): void; } ,setAnchorEl: { (value: React.SetStateAction<HTMLButtonElement | null>): void; (value: null): void; } ,setIsTimerHovered: { (value: boolean): void; (value: boolean): void; } ,isTimerHovered: boolean) =>{
  const classes = useStyles();
  return(
  <Box className={classes.elementPopup}>
    <Box className={classes.itemsPops}
      data-test-id="handlemodalTestId"
      onClick={() => handleModalTimerOpen(setIsModalOpen, setAnchorEl)}
      onMouseEnter={() => handleTimerEnter(setIsTimerHovered)}
      onMouseLeave={() => handleTimerLeave(setIsTimerHovered)}>
      <img src={isTimerHovered ? timer : blueTimer} />
      <Typography className={classes.popupHeading}>Add Countdown Timer</Typography>
    </Box>
  </Box>
  )
}

const getDelta = (event: React.WheelEvent<HTMLInputElement>): number => {
  return event.deltaY > 0 ? 1 : -1;
};

const clearPreviousTimeout = () => {
  let timehandleout = null;
  if (timehandleout !== null) {
    clearTimeout(timehandleout);
  }
};

const pageType = async (setpageTypeData:(data: string) => void) => {
  let quizName = await StorageProvider.get("pageTypeData")
  setpageTypeData(quizName)
}

const handleModalTimewrClose = (setIsModalOpen: (isOpen: boolean) => void): void => {
  setIsModalOpen(false)
}

const handleScroll = (
  type: string,
  delta: number,
  minutes: number,
  seconds: number,
  setMinutes: Function,
  setSeconds: Function
) => {
  if (shouldResetMinutes(delta, type, minutes)) {
    resetMinutes(setMinutes, delta);
  } else if (shouldResetSeconds(delta, type, seconds)) {
    resetSeconds(setMinutes, setSeconds, delta);
  } else {
    updateTime(type, delta, setMinutes, setSeconds);
  }
};

const SettingBtn = (isTimerSet: boolean,timeSet: {minutes:string,seconds:string},handleClick: React.MouseEventHandler<HTMLButtonElement>,pageTypeData:string | undefined ) => {
  const classes = useStyles();
  return(
    <>
    {pageTypeData == "quizName" &&  <Box className={classes.settingBtn} >
      <Box>
        {isTimerSet&&
         <Box className={classes.timerMainBox}>
        <Box className={classes.firstTimer}>
        <img className={classes.timerImgStyle}src={whiteSetTimer} />
        </Box>
          <Box className={classes.secondTimer}>
            <Typography className={classes.btnStyle}>{timeSet.minutes}</Typography>
            <Typography className={classes.btnStyle}>{`:${timeSet.seconds}`}</Typography>
          </Box>
        </Box>
        }
       </Box>         
        <IconButton data-test-id = "handleClickTestId" onClick={handleClick}>
          <img src={settingBtn} />
        </IconButton>
      </Box>}
    </>
  )
}

const shouldResetMinutes = (delta: number, type: string, minutes: number): boolean => {
  return (delta === 1 && type === "minutes" && minutes === 59) ||
    (delta === -1 && type === "minutes" && minutes === 0);
};

export const resetMinutes = (setMinutes: Function, delta: number) => {
  setMinutes((prevMinutes: number) => (delta === 1 ? 0 : 59));
};

const shouldResetSeconds = (delta: number, type: string, seconds: number): boolean => {
  return (delta === 1 && type === "seconds" && seconds === 59) ||
    (delta === -1 && type === "seconds" && seconds === 0);
};

export const resetSeconds = (
  setMinutes: Function,
  setSeconds: Function,
  delta: number
) => {
  setMinutes((prevMinutes: number) => (prevMinutes + delta + 60) % 60);
  setSeconds((prevSeconds: number) => (delta === 1 ? 0 : 59));
};

const updateTime = (
  type: string,
  delta: number,
  setMinutes: Function,
  setSeconds: Function
) => {
  if (type === "minutes") {
    setMinutes((prevMinutes: number) => Math.min(Math.max(prevMinutes + delta, 0), 59));
  } else {
    setSeconds((prevSeconds: number) => Math.min(Math.max(prevSeconds + delta, 0), 59));
  }
};

const SecondOptions = (formattedSeconds: {},seconds:  number  ,formattedTimes: {},minutes: number,setMinutes: Function,setSeconds: Function) => {
  return(
    <div className="timeSection">
      <ArrowDropUpIcon 
        style={{width:'32px', height: '32px'}}
        data-test-id='matchingSecondsInc'
        onClick={()=> secondsInc(seconds, minutes, setSeconds, setMinutes)}
      />
      <Typography className="passiveTime">{formattedSeconds}</Typography>
      <input
        className="inpt"
        id="minute"
        min={0}
        max={59}
        value={seconds < 10 ? "0" + seconds : seconds}
        onWheel={(event) => handleTimeScroll(event, "seconds", minutes, seconds, setMinutes, setSeconds)}
        disabled
      />
      <Typography className="passiveTime">{formattedTimes}</Typography>
      <ArrowDropDownIcon 
        style={{width:'32px', height: '32px'}}
        data-test-id='matchingSecondsDec'
        onClick={()=> secondsDec(seconds, minutes, setSeconds, setMinutes)}
      />
    </div>
  )
}


const InPutName = (seconds:  number  ,minutes: number,setMinutes: Function,setSeconds: Function,formattedMinutes: {},formattedSecondMinutes: {}) =>{
  return(
    <div className="timeSection">
      <ArrowDropUpIcon 
        style={{width:'32px', height: '32px'}}
        data-test-id='matchingMinutesInc'
        onClick={()=> minutesInc(minutes, setMinutes)}
      />
      <Typography className="passiveTime">{formattedMinutes}</Typography>
      <input
        data-test-id="handleScrollTestId"
        className="inpt"
        id="hour"
        min={0}
        max={59}
        value={minutes < 10 ? "0" + minutes : minutes}
        onWheel={(event) => handleTimeScroll(event, "minutes", minutes, seconds, setMinutes, setSeconds)}
        disabled
      />
      <Typography className="passiveTime">{formattedSecondMinutes}</Typography>
      <ArrowDropDownIcon 
        style={{width:'32px', height: '32px'}}
        data-test-id='matchingMinutesDec'
        onClick={()=> minutesDec(minutes, setMinutes)}
      />
    </div>
  )
}

const handleCloseModal = (setModalOpen: (value: boolean) => void) => {
  setModalOpen(false);
}


const MainDiv = styled("div")({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: "10px",
  padding: "40px",
  height:"auto",
  borderRadius: "10px",
  backgroundColor: "#fff",
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "400px",
  width: "calc(100% - 40px)",
  "& .closIcon": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "10px",
    right: "10px",
    width: "44px",
    height: "44px",
    cursor: "pointer"
  },
  "& .pickerModalTitle": {
    color: "#2B3D7D",
    fontSize: "20px",
    textTransform: "capitalize"
  },
  "& .timeSectionWrapper": {
    display: "flex",
    gap: "40px",
    paddingTop:"20px",
    paddingBottom:"40px"
  },
  "& .timeSection": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "10px",
  },
  "& .inpt": {
    width: "40px",
    fontSize: "17px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "none",
    textAlign: "center",
    borderTop: "1px solid rgb(45, 53, 92)",
    borderBottom: "1px solid rgb(45, 53, 92)",
    cursor:'pointer',
    "&:disabled": {
      backgroundColor: "white",
      color: "rgb(45, 53, 92)",
    },
  },
  "& .setimerBtn": {
    padding:"10px 0px 10px 0px",
    width: "100%",
    backgroundColor: "rgb(45, 53, 92)",
    color: "#fff",
    textTransform: "capitalize"
  }
});

interface TimeSet {
  minutes: string;
  seconds: string;
}

const handleModalTimerOpen = (setIsModalOpen: { (value: React.SetStateAction<boolean>): void; (value: boolean): void; }, setAnchorEl: { (value: React.SetStateAction<HTMLButtonElement | null>): void; (value: null): void; }) => {
  setIsModalOpen(true)
  setAnchorEl(null)
}

const ToggleSwitch = withStyles((theme) => ({
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
    backgroundColor:"white"
  },
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    "& .MuiSwitch-track":{
      boxSizing: "border-box"
    }
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "grey",
  },
  checked: {},
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        boxSizing: "border-box"
      },
    },
  },
}))(Switch);

const useStyles = makeStyles((theme) => ({
  normalBtn: {
    borderRadius: "10px",
    padding: "10px",
    textTransform: "none",
    border: "1px solid #3F526D",
    color: "#3F526D",
    fontFamily: "Poppins",
    fontSize: "12px",
    marginBottom: "15px",
    fontWeight: 500,
    justifyContent: "start",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundImage:
        "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
      color: "#FFF",
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
  },
  text: {
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 500,
    marginBottom: "10px",
    color: "#282829",
  },
  textFeild: {
    width: "49%",
    borderRadius: "10px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    color: "#5D5A6F",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "black",
      borderRadius: "10px",
    },
  },
  elementPopup: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 30px",
    cursor: "pointer",
    color: "#324381",
    "&:hover": {
      background: "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
      color :"white",
    }, 
  },
  btnStyle: {
    color: "black"
  },
  timerImgStyle: {
    height: "20px",
    width: "18px"
  },
  addBtn: {
    width: "150px",
    color: "#2B3D7D",
    borderColor: "#2B3D7D",
    borderRadius: "8px",
    textTransform: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 500,
    padding:"10px",
    marginRight:"10px"
  },
  shuffleBtn: {
    width: "150px",
    color: "#2B3D7D",
    borderColor: "#2B3D7D",
    borderRadius: "8px",
    textTransform: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 500,
    padding:"10px"
  },
  shuffleIcon: {
    width: "20px",
    height: "20px",
    marginRight:"10px"
  },
  addIcon: {
    width: "20px",
    height: "20px",
    marginRight:"10px"
  },
  deleteIcon: {
    width: "35px",
    height: "35px",
    borderRadius: "4px",
    backgroundColor: "white",
    color: "rgb(43, 61, 125)",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    fontSize: "20px",
    cursor: "pointer",
    position: "absolute",
    top: "10px",
    right: "10px",
  },
  timerMainBox :{
    display:"flex",
    borderRadius:"40px"
  },
  popupHeading: {
    fontSize: "12px",
    
    fontWeight: 500,
    "&:hover": {
      color: "white"
    }
  },
  editArea: {
    height: "740px",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    backgroundColor: "#FFFFFF",
    marginBottom: "50px",
    overflow: "auto",
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
    position:"relative"
  },
  popupElements: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 30px",
    cursor: "pointer",
    color: "#324381",
    "&:hover": {
      background: "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
      color :"white",
    }, 
  },
  popupItems: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    padding: "15px 0"
  },
  itemsPops: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    padding: "15px 0"
  },
  firstTimer :{
    width: "40px",
    height: "40px",  
    marginTop: "20px",
    borderRadius:"20px 0px 0px 20px",
    justifyContent:"center",
    display:"flex",
    backgroundColor: "#2B3D7D", 
    alignItems:"center",
    alignContent:"center"
  },
  inputLayout: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  settingBtn : {
    position :"absolute",
    right :"10px",
    top :"10px",
    display:"flex",
    zIndex:200
  },
  secondTimer :{
    display:"flex",
    width: "90px", 
    height: "40px", 
    marginTop: "20px",
    borderRadius:"0px 20px 20px 0px",
    alignItems:"center",
    alignContent:"center",
   justifyContent:"center",
   backgroundColor: "white",
  },
  btnLayout: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    marginTop: "40px",
    marginBottom: "50px",
  },
}));


const handleSetTimer = (minutes: number,seconds: number,props: {updateTime: (minutes:string, seconds:string) => void},setTimeset: { (value: React.SetStateAction<TimeSet>): void; (arg0: { minutes: string; seconds: string; }): void; },setIsModalOpen: { (value: React.SetStateAction<boolean>): void; (arg0: boolean): void; },setIsTimerSet: { (value: React.SetStateAction<boolean>): void; (arg0: boolean): void; }) => {    
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  props.updateTime(formattedMinutes, formattedSeconds)
  setTimeset({
    minutes: formattedMinutes,
    seconds: formattedSeconds,
  });
  setIsModalOpen(false)
  setIsTimerSet(true)
}

const AddMatchingQ = (props: any) => {
  const { pairs, setPairs, pairCount, setPairCount, handleMatchChange,matchSetState } = props;
  const classes = useStyles();

  const handleAddOption = () => {
    if (pairs.length < 10) {
      setPairCount(pairCount + 1);
      setPairs([
        ...pairs,
        { id: pairCount, pair_question: "", pair_answer: "", pair_correct_answer: ""},
      ]);
    }
  };
  const handleChange = (e: any, pairId: number) => {
    const updatedPairs = pairs.map((pair: { id: number }) =>
      pair.id === pairId ? { ...pair, [e.target.name]: e.target.value,
        ...(e.target.name === 'pair_answer' ? { pair_correct_answer: e.target.value } : {})
      } : pair
    );
    setPairs(updatedPairs);
  };

  let matchtimeString = props?.matchquestionState?.countdown_timer ? props?.matchquestionState?.countdown_timer : "00:00"
  let matchsplitTime = matchtimeString.split(":");
 
  const secureRandom = (max: number) => {
    const bytes = Math.ceil(Math.log2(max) / 8);
    const buffer = crypto.randomBytes(bytes);
    const randomValue = buffer.readUIntBE(0, bytes);
    return randomValue % max;
  };
  const handleShuffleOption = () => {
    const shuffledAnswers = pairs.map((pair: { pair_answer: any }) => pair.pair_answer);
    
    for (let i = shuffledAnswers.length - 1; i > 0; i--) {
      const j = secureRandom(i + 1);
      [shuffledAnswers[i], shuffledAnswers[j]] = [shuffledAnswers[j], shuffledAnswers[i]];
    }
  
    const shuffledPairs = pairs.map((pair: any, index: any) => ({
      ...pair,
      pair_answer: shuffledAnswers[index], 
      pair_correct_answer: pair.pair_correct_answer, 
    }));
    setPairs(shuffledPairs);
  };
  
  
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isTimerHovered, setIsTimerHovered] = useState(false);
  const [isPointsHovered, setIsPointsHovered] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [isDoubleHovered, setIsDoubleHovered] = useState(false);
  const [isMusicHovered, setIsMusicHovered] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [timeSet, setTimeset] = useState<TimeSet>({ minutes: matchsplitTime[0] ? matchsplitTime[0] : "00", seconds: matchsplitTime[1] ?matchsplitTime[1] : "00"});
  const [modalOpen, setModalOpen] = React.useState<boolean>();
  const [backgroundImage, setBackgroundImage] = React.useState<null>(null);
  const [isTimerSet, setIsTimerSet] = useState<boolean>(props?.matchquestionState?.countdown_timer);
  const [pageTypeData, setpageTypeData] = React.useState<string>();


  useEffect(()=>{
    pageType(setpageTypeData)
  },[]) 

  useEffect(() => {
    props.setBackgroundImage && props.setBackgroundImage(backgroundImage);
  }, [backgroundImage])
  
  const backgroundStyle = {
    height: '100vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `linear-gradient(rgba(255,255,255,0.6), rgba(255,255,255,0.6)), url(${props.preview})`,
  };
  const adjustedMinutes = minutes - 1 > -1 ? (minutes - 1) : 59;
  const formattedMinutes = adjustedMinutes < 10 ? "0" + adjustedMinutes : adjustedMinutes;
  const adjustedSecondMinutes = minutes + 1 < 60 ? (minutes + 1) : 0;
  const formattedSecondMinutes = adjustedSecondMinutes < 10 ? "0" + adjustedSecondMinutes : adjustedSecondMinutes;
  const adjustedSeconds = seconds - 1 > -1 ? (seconds - 1) : 59;
  const formattedSeconds = adjustedSeconds < 10 ? "0" + adjustedSeconds : adjustedSeconds;
  const adjustedTimes = seconds + 1 < 60 ? (seconds + 1) : 0;
  const formattedTimes = adjustedTimes < 10 ? "0" + adjustedTimes : adjustedTimes;
 
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openId = Boolean(anchorEl);
  const settingId = openId ? 'simple-popover' : undefined;

  return (
    <Box className={classes.editArea} style={props.preview ? backgroundStyle : undefined}>
      {SettingBtn(isTimerSet,timeSet,handleClick,pageTypeData )}
      <Popover
        data-test-id="handleCloseTestId"
        id={settingId}
        open={openId}
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            marginLeft: "60px",
          },
        }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {FirstDivider(setModalOpen,setAnchorEl,setIsHovered,isHovered)}
        <Divider />
        {SecondDivision(setIsModalOpen,setAnchorEl,setIsTimerHovered,isTimerHovered)}
        <Divider />
        {ThirdDivision(matchSetState,setIsPointsHovered,isPointsHovered,handleMatchChange)}
        <Divider />
        {FourthDivider(matchSetState,setIsDoubleHovered,isDoubleHovered,handleMatchChange)}
      </Popover>
      {pairs &&
        pairs.length > 0 &&
        pairs.map((pair: Pair) => (
          <Box key={pair.id} sx={{ px: "30px", pt: "30px", marginTop:"15px" }}>
            <Typography className={classes.text}>
              Pair {pair.order_no ? pair.order_no : pair.id + 1}
            </Typography>
            <Box className={classes.inputLayout}>
              <TextField
                name="pair_question"
                value={pair.pair_question}
                data-test-id="question"
                onChange={(e) => handleChange(e, pair.id)}
                placeholder="Enter the Question"
                className={classes.textFeild}
                variant="outlined"
              />
              <TextField
                name="pair_answer"
                value={pair.pair_answer}
                data-test-id="answer"
                onChange={(e) => handleChange(e, pair.id)}
                placeholder="Enter the Answer"
                className={classes.textFeild}
                variant="outlined"
              />
            </Box>
          </Box>
        ))}
      <Box className={classes.btnLayout}>
        <Button
          variant="outlined"
          onClick={handleAddOption}
          className={classes.addBtn}
          data-test-id="addOption"
        >
          <img
            src={addMatchingQAddicon}
            className={classes.addIcon}
            alt="Add Icon"
          />
          Add Option
        </Button>
        <Button
          variant="outlined"
          onClick={handleShuffleOption}
          className={classes.shuffleBtn}
          data-test-id="shuffleOption"
        >
          <img
            src={addMatchingQShuffleicon}
            className={classes.shuffleIcon}
            alt="Shuffle Icon"
          />
          Shuffle Options
        </Button>
      </Box>
      <AddBackgroundImageModal
        data-test-id="closeImageModal"
        open={modalOpen}
        onClose={()=>handleCloseModal(setModalOpen)}
        backgroundImage={backgroundImage}
        setBackgroundImage={setBackgroundImage}
        setPreview={props.setPreview}
      />
      <Modal open={isModalOpen} onClose={()=>handleModalTimerOpen(setIsModalOpen,setAnchorEl)}>
        <MainDiv>
          <img data-test-id="modalTimeTestId" className="closIcon" src={cancelBtn} onClick={()=>handleModalTimewrClose(setIsModalOpen)} />
          <Typography className="pickerModalTitle">Add countdown timer</Typography>
          <Box className="timeSectionWrapper">
              {InPutName(seconds,minutes,setMinutes,setSeconds,formattedMinutes,formattedSecondMinutes)}
              {SecondOptions(formattedSeconds,seconds,formattedTimes,minutes,setMinutes,setSeconds)}
          </Box>
          <GenericButton data-test-id="setTimerTestId" handleClick={()=>handleSetTimer(minutes,seconds,props,setTimeset,setIsModalOpen,setIsTimerSet)} label="Set Timer" type={"COLORED"} customStyle={{width: "300px" }}/>
        </MainDiv>
      </Modal>
    </Box>
  );
};

const FourthDivider = (matchSetState:{checkedB: boolean},setIsDoubleHovered: { (value: React.SetStateAction<boolean>): void; (value: boolean): void; (value: boolean): void; },isDoubleHovered: boolean,handleMatchChange:(value: boolean, name: string) => void ) => {
  const classes = useStyles();
  return(
  <Box className={classes.elementPopup}
    data-test-id="doubleTestId"
    onMouseEnter={() => handleDoublePointEnter(setIsDoubleHovered)}
    onMouseLeave={() => handleDoublePointLeave(setIsDoubleHovered)}>
    <Box className={classes.itemsPops}>
      <img src={isDoubleHovered ? whiteDoublePoint : blueDoublePoint} />
      <Typography className={classes.popupHeading} >Double Points</Typography>
    </Box>
    <ToggleSwitch data-test-id="doublePointChangeTestId" checked={matchSetState?.checkedB ? true : false}  onChange={(event) => handleMatchChange(event.target.checked, event.target.name)}  name="checkedB" />
  </Box>
  )
}

const ThirdDivision = (matchSetState:{checkedA: boolean},setIsPointsHovered: { (value: React.SetStateAction<boolean>): void; (value: boolean): void; } ,isPointsHovered: boolean,handleOpenEndedChange:(value: boolean, name: string) => void) =>{
  const classes = useStyles();
  return(
  <Box className={classes.elementPopup}
    data-test-id="switchpointTestId"
    onMouseEnter={() => handlePointsHovered(setIsPointsHovered)}
    onMouseLeave={() => handlePointsLeave(setIsPointsHovered)}>
    <Box className={classes.itemsPops}>
      <img src={isPointsHovered ? whitePoints : pointsIcon} />
      <Typography className={classes.popupHeading}>Points</Typography>
    </Box>
    <Grid item>
      <ToggleSwitch data-test-id="pointTestId" checked={matchSetState?.checkedA ? true : false} onChange={(event) => handleOpenEndedChange(event.target.checked, event.target.name)}  name="checkedA" />
    </Grid>
  </Box>
  )
}

const handleMouseEnter = (setIsHovered: (value: boolean) => void) => {
  setIsHovered(true);
};

const handlePointsLeave = (setIsPointsHovered: (value: boolean) => void) => {
  setIsPointsHovered(false);
}

const handleTimerEnter = (setIsTimerHovered: (value: boolean) => void) => {
  setIsTimerHovered(true);
};

const handleDoublePointEnter = (setIsDoubleHovered: (value: boolean) => void) => {
   setIsDoubleHovered(true)
}

const handleMusicEnter = (setIsMusicHovered: (value: boolean) => void) => {
  setIsMusicHovered(true)
}

const handleClose = (setAnchorEl: (value: null) => void) => {
  setAnchorEl(null);
};

const handleDoublePointLeave = (setIsDoubleHovered: (value: boolean) => void) => {
  setIsDoubleHovered(false)
}

const handleMusicLeave = (setIsMusicHovered: (value: boolean) => void) => {
  setIsMusicHovered(false)
}

const handleModalOpen = (setModalOpen: (value: boolean) => void,setAnchorEl: (value: null) => void) => {
  setModalOpen(true)
  setAnchorEl(null)
}


const handleMouseLeave = (setIsHovered: (value: boolean) => void) => {
  setIsHovered(false);
};

const handleTimerLeave = (setIsTimerHovered: (value: boolean) => void) => {
  setIsTimerHovered(false);
};

const handlePointsHovered = (setIsPointsHovered: { (value: React.SetStateAction<boolean>): void; }) => {
  setIsPointsHovered(true);
}


export default AddMatchingQ;
