import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Modal, Fade, TableContainer, Table, TableCell, TableBody, TableRow, TableHead } from "@material-ui/core";
import LiveSectionHeader from "../components/LiveSectionHeader.web";
import LiveSectionFooter from "../components/LiveSectionFooter.web";
import ChatSectionHeader from "../components/ChatSectionHeader.web";
import Comments from "../screens/meeting/Components/ChatViewer/Comments.web";
import LiveScreen from "../components/LiveScreen.web";
import { makeStyles } from "@material-ui/core/styles";
import { ACTIONS, CHAT, getQuestionHeader } from "../../../../components/src/types";
import Participants from "../screens/meeting/Components/ParticipantListViewer/Participants.web";
import Question from "../screens/meeting/Components/QuestionViewer/Question.web";
import firebase from "firebase";
import Loader from "../../../../components/src/Loader.web";
import WhiteboardCollaboration from "../../../whiteboardcollaboration/src/WhiteboardCollaboration.web";
import McqQuestion from "../screens/meeting/Components/QuestionViewer/McqQuestion.web";
import MatchingQuestion from "../screens/meeting/Components/QuestionViewer/MatchingQuestion.web";
import CloseIcon from '@material-ui/icons/Close';
import { trueTick, studentProfile, falseTick } from "../assets";
import { AnswerPlayload } from "../LiveStreamingController";
import ChartQuestion from "../screens/meeting/Components/QuestionViewer/ChartQuestion.web";
import OpenEndedQuestion from "../screens/meeting/Components/QuestionViewer/OpenEndedQuestion.web";
import { QuestionPayload, StudentAttribute, StudentAnswer, StudentAttributes } from "../LiveStreamingController";
import CustomWordCloud from "../../../../components/src/CustomWordCloud.web";
import LinearProgress from '@material-ui/core/LinearProgress';
import StaticCanvas from "../../../../components/src/StaticCanvas.web";

const useStyles = makeStyles(() => ({
  liveScreen: {
    height: "82vh",
    backgroundColor: "#F0F0F0",
    overflow: "auto"
  },
  questionScreen: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "95%",
    "&.mcq-show-result-box":{
      height: "90%",
      maxWidth:"448px",
      margin:"0 auto",
      "& .mcq-result-title":{
        width:"100%",
        display:"flex",
      },
      "& .mcq-result-box-inner":{
        width:"100%",
      },
      "& .mcq-result-content-box":{
        padding:"0",
      },
      "& .mcq-question":{
        fontSize:"14px",
      },
      "& .form-control":{
        "& input":{
          color: "#282829",
          fontSize: "14px !important",
          padding: "0 15px !important",
          height: "44px",
        },
        
      },
      "& .correct-answer-input":{
        "& .MuiOutlinedInput-notchedOutline":{
          borderColor:"#34c85a !important"
        }
      }
    }
  },
  questionScreenMatch:{
    justifyContent: "center",
    height: "90%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    "&.mcq-show-result-box":{
      maxWidth:"448px",
      margin:"0 auto",
      height: "90%",
      "& .mcq-result-content-box":{
        padding:"0",
        maxWidth:"352px",
        margin:"0 auto",
      },
      "& .form-control":{
        "& input":{
          fontSize: "14px !important",
          padding: "0 15px !important",
          height: "44px",
          color: "#282829", 
          "& .correctAnswerInput":{
            border: "1px solid #34c85a !important",
          },         
        },
      },      
      "& .mcq-result-title":{
        display:"flex",
        width:"100%",
      },
      "& .mcq-result-box-inner":{
        backgroundColor:"#FFF",
        width:"100%",
      },
      "& .mcq-question":{
        fontSize:"14px",
      },

    }
  },
  questionScreenKWL:{
    padding:"15px 60px",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '90%',
    maxWidth: '90%',
    margin:"0 auto"
  },
  questionScreenPOLL:{
    padding:"15px 60px",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '95%',
    maxWidth:"50%",
    margin:"0px auto"
  },
  questionScreenOE:{
    padding:"15px 60px",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '95%',
  },
  questionScreenWC:{
    padding:"15px 60px",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '95%'
  },
  questionHeading: {
    display: "flex",
    width: "45%",
    marginBottom: "10px"
  },
  showResult: {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Poppins"
  },
  answerOption: {
    border: "1px solid #eaeef0",
    width: "40%",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 2px 8px 0px #00000014",
    padding: "20px 30px 50px 30px",
    maxHeight:"70%",
    overflowY:"auto",
  },
  answerOptionMatch: {
    boxShadow: "0px 2px 8px 0px #00000014",
    margin:"0 auto",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    padding: "20px 30px 50px 30px",
    border: "1px solid #eaeef0",
    overflowY:"auto",
    width: "60%",
    position:"relative"
  },
  answerOptionKwl:{
    boxShadow: "0px 2px 8px 0px #00000014",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    padding: "20px 30px 50px 30px",
    border: "1px solid #eaeef0",
    overflowY:"auto",
  },
  answerOptionOE:{
    boxShadow: "0px 2px 8px 0px #00000014",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    padding: "20px 30px 50px 30px",
    border: "1px solid #eaeef0",
    maxHeight:"70%",
    overflowY:"auto",
    position:"relative"
  },
  answerOptionWC:{
    boxShadow: "0px 2px 8px 0px #00000014",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    padding: "20px 30px 50px 30px",
    border: "1px solid #eaeef0",
    maxHeight:"70%",
    overflowY:"auto",
    width:"90%",
    position:"relative"
  },
  modalName: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paperText: {
    "&.paper-text-new":{
      maxWidth:"972px",
      "&.mcq-box":{
        width:"max-content",
        minWidth:"555px",
      }
    },
    width:"500px",
    maxHeight:"80%",
    padding:"25px",
  },
  paperTextInner:{
    background: "#ffffff",
    padding: "20px 8px 20px 25px",
    borderRadius: "12px"
  },
  showNameStyle: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: "17px"
  },
  showNameText: {
    fontSize: "18px",
    fontWeight: 500,
    fontFamily: "Poppins"
  },
  questionContainer: {
    padding: "12px",
    backgroundColor: "#eff0f5",
    borderRadius: "8px",
    margin: "15px 0",
    position:"relative"
  },
  studentQuestion: {
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Poppins",
    "&.heading-bold":{
      fontWeight:"600",
      marginTop:"5px"
    }
  },
  studentQuestionTitle: {
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Poppins",
  },
  correctQuestion: {
    fontSize: "18px",
    fontWeight: 600,
    // marginLeft: "3px",
    fontFamily: "Poppins"
  },
  mainBoxContainer: {
    display: "flex",
    gap: "15px",
    flexDirection: "column",
    maxHeight:"48vh",
    overflowY:"auto",
  },
  mainStudentContainer: {
    display: "block",
  },
  mainStudentContainerMCQ: {
    display: "flex",
  },
  studentImage: {
    height: "50px",
    width: "50px",
    borderRadius: "25px"
  },
  answerContainer: {
    margin: "5px 0px 0px 12px",
  },
  studentName: {
    fontSize: "18px",
    fontWeight: 500,
    fontFamily: "Poppins"
  },
  studentAnswerContainer: {
    display: "flex",
    alignItems: "center"
  },
  studentAnswerContainerMCQ: {
    display: "flex",
  },
  NameAnswerGrid:{
    padding:"15px 0",
    alignItems:"flex-end",
    display:"flex",
    "& .answer-block":{
      paddingLeft:"30px",
      flex:"1",
      // minWidth:"150px",
      // maxWidth:"150px",
      "& p":{
        color:"#000",
        fontSize:"16px",
        fontFamily: "Poppins",
        lineHeight: 1.7,
        wordBreak:"break-word",
        minWidth:"150px"
      },
      "& .answer-box":{
        display:"flex",
        alignItems:"center",
        "& p":{
          "&.false":{
            color:"red",
          },
          "&.true":{
            color:"green",
          },
          "& span":{
            color: "#555", display: "inline-block", marginRight: "5px"
          }
        },
      }
    },
    "& .answer-blockWC":{
      paddingLeft:"30px",
      flex:"1",
      "& p":{
        color:"#000",
        fontSize:"16px",
        fontFamily: "Poppins",
        lineHeight: 1.7,
        wordBreak:"break-word",
        minWidth:"75px"
      },
      "& .answer-box":{
        display:"flex",
        alignItems:"center",
        "& p":{
          "&.false":{
            color:"red",
          },
          "&.true":{
            color:"green",
          },
          "& span":{
            color: "#555", display: "inline-block", marginRight: "5px"
          }
        },
      }
    },
    "&.heading":{
      "& .answer-block":{
        borderBottom:"1px solid #ddd",
      },
      "& .answer-blockWC":{
        borderBottom:"1px solid #ddd",
      },
      "& p":{
        color:"#000",
      },
      "& .image-name-block":{
        "& .name-image-inner":{
          "& p":{
            marginLeft:"0",
          }
        },
      },
    },
    "& .image-name-block":{
      flex:"0 0 250px",
      maxWidth:"250px",
      "& .name-image-inner":{
        display:"flex",
        alignItems:"center",
        "& p":{
          marginLeft:"10px",
        }
      },
    },
    
  },
  NameAnswerGridRow:{
    display:"flex",
    padding:"15px 0",
    "&.has-more-columns":{
      alignItems:"center",
    },
    "&.heading":{
      borderBottom:"1px solid #ddd",
      "& p":{
        color:"#000",
      },
      "& .image-name-block":{
        "& .name-image-inner":{
          "& p":{
            marginLeft:"0",
          }
        },
      },
    },
    "& .image-name-block":{
      flex:"0 0 250px",
      maxWidth:"250px",
      "& .name-image-inner":{
        display:"flex",
        alignItems:"center",
        "& p":{
          marginLeft:"10px",
        }
      },
    },
    "& .answer-block":{
      flex:"1",
      paddingLeft:"30px",
      // minWidth:"150px",
      // maxWidth:"150px",
      "& p":{
        color:"#000",
        fontSize:"16px",
        fontFamily: "Poppins",
        lineHeight: 1.7,
        wordBreak:"break-word",
        minWidth:"150px"
      },
      "& .answer-box":{
        display:"flex",
        alignItems:"center",
        "& p":{
          "&.false":{
            color:"red",
          },
          "&.true":{
            color:"green",
          },
          "& span":{
            color: "#555", display: "inline-block", marginRight: "5px"
          }
        },
      }
    },
    "& .answer-blockWC":{
      flex:"1",
      paddingLeft:"30px",
      "& p":{
        color:"#000",
        fontSize:"16px",
        fontFamily: "Poppins",
        lineHeight: 1.7,
        wordBreak:"break-word",
        minWidth:"75px"
      },
      "& .answer-box":{
        display:"flex",
        alignItems:"center",
        "& p":{
          "&.false":{
            color:"red",
          },
          "&.true":{
            color:"green",
          },
          "& span":{
            color: "#555", display: "inline-block", marginRight: "5px"
          }
        },
      }
    },
  },
  studentAnswer: {
    fontSize: "18px",
    fontWeight: 500,
    color: "#5f686f",
    fontFamily: "Poppins"
  },
  correctAnswer: {
    fontSize: "18px",
    fontWeight: 500,
    marginLeft: "5px",
    color: "#00d0be",
    fontFamily: "Poppins",
    display:"flex",
    alignItems:"center"
  },
  iNcorrectAnswer: {
    fontSize: "18px",
    fontWeight: 500,
    marginLeft: "5px",
    color: "#ff4d59",
    fontFamily: "Poppins",
    display:"flex",
    alignItems:"center"
  },
  correctImage: {
    height: "20px",
    width: "20px",
    marginLeft: "5px",
    position:"relative",
    top:"4px"
  },
  correctsImage: {
    height: "20px",
    width: "20px"
  },
  modalKwlName: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center"
  },
  paperKwlText: {
    height: "80%",
    width: "100%",
    maxWidth:"972px",
    padding: "20px 25px",
    background: "#ffffff",
    borderRadius: "12px"
  },
  showKwlNameStyle: {
    paddingRight: "17px",
    display: "flex",
    justifyContent: "space-between"
  },
  showKwlNameText: {
    fontWeight: 500,
    fontSize: "18px",
    fontFamily: "Poppins",
  },
  questionKwlContainer: {
    alignItems: "center",
    padding: "12px",
    borderRadius: "8px",
    backgroundColor: "#eff0f5",
    margin: "15px 17px 15px 0px",
    position:"relative"
  },
  mainKwlBoxContainer: {
    display: "flex",
    gap: "15px",
    height: "70%",
    flexDirection: "column",
    overflowY:"auto",
    "& th":{
      "&:first-child":{
        paddingLeft:"0",
      },
      "&:last-child":{
        paddingRight:"0",
      }
    }
  },
  studentKwlQuestion: {
    fontSize: "18px",
    fontWeight: 500,
    color: "#000000"
  },
  studentKwlResult: {
    fontSize: "18px",
    fontWeight: 500,
    color: "#4b5c76",
    verticalAlign:"top",
    display:"flex",
    alignItems:"center"
  },

  studentKwlResultMQ: {
    fontSize: "18px",
    fontWeight: 500,
    color: "#4b5c76",
    verticalAlign:"top",
    paddingTop:"10px"
  },
  studentKwlImage: {
    display: "flex",
    alignItems: "center"
  },
  studentResultTable: {
    "& .MuiTableCell-root": {
      borderBottom: "none"
    },
  },
  chartData: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    height: "100%"
  },
  chartContainer: {
    border: "1px solid #eaeef0",
    width: "90%",
    borderRadius: "10px",
    backgroundColor: "#fff",
    boxShadow: "0px 2px 8px 0px #00000014"
  },
  questionKwlHeading: {
    display: "flex",
    width: "90%",
    marginBottom: "10px"
  },
  studentKwlImageStyle: {
    height: "35px",
    width: "35px",
    borderRadius: "18px"
  },
  imageBox: {
    display:"flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  closeIcon:{
    cursor:"pointer"
  },
  wcMainLayout: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: "30px 60px",
  },
  wcContentBox: {
    width: "620px",
    backgroundColor: "#FFF",
    display: "flex",
    justifyContent: "center",
    boxShadow: "0px 0px 10px 0px #F1F5F9",
    borderRadius: "10px",
    flexDirection: "column",
    padding: "30px 60px",
  },
  wcTitle: {
    fontSize: "14px",
    color: "#282829",
    fontFamily: "Poppins",
    fontWeight: 500,
    marginBottom: "10px",
  },
  pollTitle: {
    fontSize: "14px",
    color: "#282829",
    fontFamily: "Poppins",
    fontWeight: 500,
    marginBottom: "10px",
  },
  wcQuestion: {
    fontSize: "16px",
    color: "#282829",
    fontFamily: "Poppins",
    fontWeight: 600,
    marginBottom: "20px",
  },
  optionLayout: {
    marginBottom: "10px",
    width: "100%"
  },
  pollResultWrapper:{
    display:"flex",
    alignItems:"flex-start",
    "& p":{
      fontSize:"14px",
      fontWeight:"400",
      color:"#282829",
      marginBottom:"6px",
      fontFamily: 'Poppins',
    },
    "&:not(:last-child)":{
      marginBottom:"24px",
    },
    "& .poll-result-left":{
      flex:"0 0 50px",
      maxWidth:"50px",
      "& p":{
        fontSize:"14px",
        fontWeight:"600",
        color:"#282829",
        fontFamily: 'Poppins',
      },
    },
    "& .poll-result-right":{
      flex:"1",
      "& .progressbar-wrapper":{
        "&:not(:last-child)":{
          marginBottom:"24px",
        },
      }
    }
  },
  LinearProgressMain:{
    "& .MuiLinearProgress-root":{
      height:"6",
      borderRadius:"22px",
      backgroundColor:"rgb(43, 61, 125,0.12)",
    },
    "& .MuiLinearProgress-barColorPrimary":{
      backgroundColor: "#34C759", 
      borderRadius: "22px",
    }
  },
  pollingAnswer:{
    color:"2B3D7D",
    paddingLeft:"6px",
    display:"flex",
    alignItems:"center"
  },
  studentNamesBox:{
    overflowY:"auto"
  },
  wordCloudHeading:{
    display:"flex",
    justifyContent:"space-between"
  },
  wordCloudEntries:{
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Poppins",
  }
}));
const TeacherLiveStreaming = (props: any) => {
  const {
    stages,
    currentStage,
    messages,
    room,
    prevStage,
    nextStage,
    showQuestion,
    setShowQuestion,
    participants,
    lessonName,
    className,
    role,
    leaveLesson,
    parentStage,
    screen,
    setScreen,
    isDescHide,
    handleShowResult,
    rightAnswerTick,
    isOpenResult,
    handleStudentResult,
    isStudentResult,
    studentResultList,
    rightKwlTick,
    isStudentKwlData,
    showResults,
    showNames,
    studentName,
    question,
    answer,
    kWLChartQuestion,
    isMatching,
    isMatching_question,
    matchingQuestion,
    isOpenName,
    wordsAnswer,
    pollResult,
    lessonStartTime,
    isQuiz,
    handleMedia,
    getLiveStreamCanvas,
    saveCanvas,
    subStages,
    showCommentsIcon,
    readComments,
    updateHighlightToFirebase,
    showLoader
  } = props;
  const classes = useStyles();
  const [chatOption, setChatOption] = useState<string>(CHAT.QUESTION);
  const [fullscreen, setFullScreen] = useState<boolean>(false);
  const [height, setHeight] = useState<number>(0);
  const [startQuiz, setStartQuiz] = useState<boolean>(false)
  const stageType = isQuiz?currentStage?.attributes.quiz_stage_name:currentStage?.attributes.lesson_stage_name as ACTIONS
  const parentStageType = isQuiz?currentStage?.attributes.quiz_stage_name:parentStage?.attributes.lesson_stage_name as ACTIONS
  const chartTypes = [ACTIONS.KWL_CHART,ACTIONS.T_CHART,ACTIONS.WH_CHART,ACTIONS.VENN_DIAGRAM,ACTIONS.SEED_DISCUSSION]
  const commanModalOpen = [...chartTypes,ACTIONS.ADD_WORD_CLOUD,ACTIONS.ADD_MATCHING_Q].includes(stageType) && isOpenName
  const otherModalOpen = [ACTIONS.MCQ,ACTIONS.ADD_MCQ,ACTIONS.POLLING_Q,ACTIONS.ADD_POLL,ACTIONS.OPEN_ENDED_QUESTION].includes(stageType) && isOpenName && isStudentResult
  const backgroundImage=currentStage?.attributes.background_image?.url && isOpenResult && stageType==ACTIONS.ADD_WORD_CLOUD

  useEffect(()=>{
    setStartQuiz(false)
  },[currentStage])

  useEffect(()=>{
    if(showQuestion && isQuiz) setTimeout(setStartQuiz,3000,true)
  },[showQuestion])

  const renderChatContent = () => {
    if (chatOption === CHAT.QUESTION) {
      return (
        <Question
          currentStage={currentStage}
          showQuestion={showQuestion}
          setShowQuestion={setShowQuestion}
          rightAnswerTick={rightAnswerTick}
          stageType={stageType}
          subStages={subStages}
        />
      );
    } else if (chatOption === CHAT.PARTICIPANTS) {
      return <Participants participants={participants} />;
    } else {
      return <Comments messages={messages} />;
    }
  };
  const handleChatHeaderOption = (option: string) => {
    setChatOption(option);
  };

  const getQuestionType=()=> {
    return getQuestionHeader(stageType ?? "" as ACTIONS)
  }

  const kwlStudentData = () => {
    return(
      rightKwlTick ? (
        <Box className={classes.questionScreenKWL}>
            <Box className={classes.questionHeading}>
              <Typography className={classes.showResult}>{showResults}</Typography>
            </Box>
            <Box className={classes.answerOptionKwl}>
              <ChartQuestion currentStage={currentStage} rightKwlTick={rightKwlTick} studentResultList={studentResultList}/>
            </Box>
          </Box>
      ) : 
      showResultsDiv()
    )
  }

  const showResultsDiv = () => {
    
    return  !isOpenResult ?
        <LiveScreen currentStage={parentStage} parentStage={parentStage} showQuestion={showQuestion} isQuiz={isQuiz} stageType={parentStageType} handleMedia={handleMedia} updateHighlightToFirebase={updateHighlightToFirebase}/>
        : 
    renderResultBasedOnType() 
    
  }

  const renderResultBasedOnType = () => {
    switch(stageType){
      case ACTIONS.ADD_WORD_CLOUD:
        return (
          <Box className={classes.questionScreenWC}>            
            <Box className={classes.answerOptionWC}>
              <Box className={classes.questionHeading}>
                <Typography className={classes.showResult}>{showResults}</Typography>
              </Box>
              <Typography className={classes.wcTitle}>Word Cloud Question</Typography>
              <Box>
                {getStaticCanvas()}
                <Box
                  className={classes.wcQuestion}
                  dangerouslySetInnerHTML={{
                    __html: currentStage?.attributes.title,
                  }}
                />
              </Box>
              <Box className={classes.optionLayout} style={{marginTop:`${height}px`}}>
                <CustomWordCloud words={wordsAnswer} />
              </Box>
            </Box>
          </Box>
        )
    case ACTIONS.POLLING_Q:
    case ACTIONS.ADD_POLL:
      return (
        <>
        <Box className={classes.questionScreenPOLL}>
            <Box className={classes.questionHeading}>
              <Typography className={classes.showResult}>{showResults}</Typography>
            </Box>
            <Box className={classes.answerOptionOE}>
              <Typography className={classes.pollTitle}>Polling Question</Typography>
              <Box>
                {getStaticCanvas()}
                <Box
                  className={classes.wcQuestion}
                  dangerouslySetInnerHTML={{
                    __html: currentStage?.attributes?.questions[0]?.question?.question,
                  }}
                />
              </Box>
              <Box className={classes.optionLayout} style={{marginTop:`${height}px`}}>
                  {pollResult.map((poll:{
                    "options_id": number,
                    "option": string,
                    "percentage": number
                  })=>{
                    const pollPercentage = Math.round(poll.percentage)
                    return (
                        <Box className={classes.pollResultWrapper}>
                            <Box className="poll-result-left">
                                <Typography>{pollPercentage}%</Typography>
                            </Box>
                            <Box className="poll-result-right">
                                <Box className="progressbar-wrapper">
                                    <Typography>{poll.option}</Typography>
                                    <Box className={classes.LinearProgressMain}>
                                    <LinearProgress variant="determinate" value={pollPercentage} />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    )
                })}
              </Box>
            </Box>
          </Box>
        </>)
    case ACTIONS.OPEN_ENDED_QUESTION:
      return (
          <Box className={classes.questionScreenOE}>
            <Box className={classes.questionHeading}>
              <Typography className={classes.showResult}>{showResults}</Typography>
            </Box>
            <Box className={classes.answerOptionOE}>
              <OpenEndedQuestion currentStage={currentStage} showResult={true} studentResultList={studentResultList} />
            </Box>
          </Box>
      )
    }
  }
  const matchingStudentData = () => {
    return (
        isMatching ? 
          <Box className={`${classes.questionScreenMatch} mcq-show-result-box`}>
            <Box className={`${classes.questionHeading} mcq-result-title`}>
              <Typography className={classes.showResult}>{showResults}</Typography>
            </Box>
            <Box className={`${classes.answerOptionMatch} mcq-result-box-inner`}>
              <MatchingQuestion currentStage={currentStage} isMatching={isMatching} studentResultList={studentResultList} />
            </Box>
          </Box> :
          kwlStudentData()
      )
  };

  const getNamesDesign = () => {
    switch(stageType){
      case ACTIONS.OPEN_ENDED_QUESTION:
        return (
          <Box className={classes.mainBoxContainer} style={{marginTop:`${height}px`}}>
            <Box className={`${classes.NameAnswerGrid} heading`}>
              <Box className="image-name-block">
                  <Box className="name-image-inner">
                      <Typography className={classes.studentAnswer}>Student Name</Typography>
                  </Box>
              </Box>
              <Box className="answer-block">
                <Typography className={classes.studentAnswer}>Answer</Typography>
              </Box>
            </Box>
            <Box className={classes.studentNamesBox}> 
            {studentResultList?.attribute?.map((attributeItem: StudentAttributes) => {
              return (
                <Box className={classes.mainStudentContainer}>
                  <Box className={`${classes.NameAnswerGridRow}`}>
                    <Box className="image-name-block">
                        <Box className="name-image-inner">
                            <img className={classes.studentImage} src={attributeItem?.student_image !== null ? attributeItem?.student_image : studentProfile} />
                            <Typography className={classes.studentName}>{attributeItem?.student_name}</Typography>
                        </Box>
                    </Box>
                    <Box className="answer-block"  style={{display:"flex"}}>
                        {getAnswerForMCQ(attributeItem)}
                    </Box>
                  </Box>
                </Box>
              )
            })
            }
            </Box>
          </Box>
        )
      case ACTIONS.ADD_MCQ:
      case ACTIONS.MCQ:
      case ACTIONS.POLLING_Q:
      case ACTIONS.ADD_POLL:
        return (
          <Box className={`${classes.mainBoxContainer} mcq-main-box`} style={{marginTop:`${height}px`}}>
            {studentResultList?.attribute?.map((attributeItem: StudentAttributes) => {
              const showScore= isQuiz && stageType.includes("MCQ")
              return (
                <Box className={classes.mainStudentContainerMCQ}>
                  <img className={classes.studentImage} src={attributeItem?.student_image !== null ? attributeItem?.student_image : studentProfile} />
                  <Box className={classes.answerContainer}>
                    <Typography className={classes.studentName}>{attributeItem?.student_name}</Typography>
                    <Box className={classes.studentAnswerContainerMCQ} style={{display:"flex"}}>
                      {getAnswerHeading(attributeItem)}
                      {getAnswerForMCQ(attributeItem)}
                    </Box>
                   {showScore && <Box className={classes.studentAnswerContainerMCQ} style={{display:"flex"}}>
                      <Typography className={classes.studentAnswer}>Score: {attributeItem.current_stage_score}</Typography>
                    </Box>}
                  </Box>
                </Box>
              )
            })
            }
          </Box>
        )
    }
  }
  const getAnswerHeading = (attributeItem: StudentAttributes) => {
    let answerHeading:string = answer
    if(stageType==ACTIONS.POLLING_Q){
      if(isQuiz){
        const answerArray = JSON.parse(attributeItem?.answer)
        if(answerArray.length>1){
          answerHeading="Answers:"
        }
      }
      else if(Array.isArray(attributeItem?.answers)){
        const answerArray = attributeItem?.answers?.map(x=>x.answer)
        if(answerArray.length>1){
          answerHeading="Answers:"
        }
      }
    }
    return (
      <Typography className={classes.studentAnswer}>{answerHeading}</Typography>
    )
  }
  const getAnswerForMCQ = (attributeItem: StudentAttributes) => {
    if(stageType==ACTIONS.POLLING_Q){
      let answer:string = attributeItem?.answer;
      if(isQuiz){
        answer = JSON.parse(answer).join(", ")
      }
      else if(Array.isArray(attributeItem?.answers)){
        answer = attributeItem?.answers?.map(x=>x.answer).join(", ") ?? ""
      }
      return (
        <Typography className={classes.pollingAnswer}>{answer}</Typography>
      )
    }
    else if(stageType==ACTIONS.OPEN_ENDED_QUESTION){
      return (
        <Typography style={{display:"flex",alignItems:"center"}}>{attributeItem?.answer[0].answer}</Typography>
      )
    }
    else{
      return (
        <>
          <Typography className={attributeItem?.answer === attributeItem?.teacher_correct_answer ? classes.correctAnswer : classes.iNcorrectAnswer}>{attributeItem?.answer}<img className={classes.correctImage} src={attributeItem?.answer === attributeItem?.teacher_correct_answer ? trueTick : falseTick} /></Typography>
          
        </>
      )
    }
  }
  const getAnswerDisplay = (studentItem: StudentAttribute) => {
    const isWordC = ACTIONS.ADD_WORD_CLOUD == stageType
    const isMatchingQ = ACTIONS.ADD_MATCHING_Q == stageType
    if(isMatchingQ){
      return (
        studentItem?.answer?.map((itemData: StudentAnswer) => {
          return (
            <>
              <Box className="answer-block">
                <Typography className={classes.studentKwlResultMQ}>{itemData?.correct_answer === false ?
                    <img className={classes.correctsImage} src={falseTick} /> : <img className={classes.correctsImage} src={trueTick} />}
                  <br />{itemData?.answer}</Typography>
              </Box>
            </>
          )
        })        
      )
    }
    else if(isWordC){
      return (
        studentItem?.answers?.map((itemData: AnswerPlayload) => {
          return (
            <Box className="answer-blockWC" style={{display:"flex"}}>
              <Typography className={classes.studentKwlResult}>{(itemData?.answer ?? "").trim() == "" ?
                  <img className={classes.correctsImage} src={falseTick} /> :
                itemData?.answer}</Typography>
            </Box>
          )
        })
      )
    }
    else{
      return (
        studentItem?.student_answer?.map((itemData: AnswerPlayload) => {
          return (
            <Box className="answer-block" style={{display:"flex"}}>
              <Typography className={classes.studentKwlResult}>{(itemData?.answer ?? "").trim() == "" ?
                  <img className={classes.correctsImage} src={falseTick} /> :
                itemData?.answer}</Typography>
            </Box>
          )
        })
      )
    }
  }

  const getSecondBox=(isWordC:boolean)=>{
    return(
      <>
      <Box className={classes.mainKwlBoxContainer} style={{marginTop:`${height}px`}}>
        <Box className={`${classes.NameAnswerGrid} heading`}>
          <Box className="image-name-block">
                <Box className="name-image-inner">
                    <Typography className={classes.studentAnswer}>Student Name</Typography>
                </Box>
            </Box>             
              {isWordC?
              currentStage.attributes.description.map((value:{[key:string]:string},index:number)=>{
                return (
                <Box className="answer-blockWC"> 
                  <Typography className={classes.studentAnswer}>{`Answer${index+1}`}</Typography>
                </Box>
                )
              })
              :studentResultList?.questions?.map((questionItem: QuestionPayload,index:number) => {
                  let questionTitle = questionItem?.question;
                  if(!questionTitle || questionTitle==''){
                    questionTitle = `Answer${index+1}`
                  }
                  return (                          
                  <Box className="answer-block"> 
                    <Typography className={classes.studentAnswer} dangerouslySetInnerHTML={{
                    __html:  questionTitle
                      }}/>
                  </Box>
                  )
                })}
          </Box>
          <Box> 
              {studentResultList?.attribute?.map((attributeItem: StudentAttribute) => {

                let firstAnswer = attributeItem.answer
                let score:number = 0
                if(firstAnswer && firstAnswer.length>0){
                  score = firstAnswer[0].current_stage_score ?? 0
                  if(Array.isArray(score)){
                    score = 0
                  }
                }
                return (
                  <Box className={classes.mainStudentContainer}>
                    <Box className={classes.NameAnswerGridRow}>
                      <Box className="image-name-block">
                          <Box className="name-image-inner">
                              <img className={classes.studentImage} src={attributeItem?.student_image !== null ? attributeItem?.student_image : studentProfile} />
                              <Box>
                                <Typography className={classes.studentName}>{attributeItem?.student_name}</Typography>
                                {isQuiz && <Typography className={classes.studentAnswer}>Score: {score}</Typography>}
                              </Box>
                          </Box>
                      </Box>
                      {getAnswerDisplay(attributeItem)}
                    </Box>
                  </Box>
                )
              })
              }
          </Box>
      </Box> 
      </>
    )
  }
  const getModalContent = () => {
    if(!currentStage) return
    const isWordC = ACTIONS.ADD_WORD_CLOUD == stageType
    
    return (
      <>
      <Box className={classes.questionKwlContainer}>
        <Box className={classes.wordCloudHeading}>
          <Typography className={classes.studentQuestion}>{getQuestionType()}:</Typography>
          <Typography >Students answered: {studentResultList?.students_answered_count}/{studentResultList?.student_count}</Typography>
          {getStaticCanvas()}
        </Box>
        {isWordC?
        <Box className={classes.wordCloudHeading}>
          <Typography className={`${classes.studentQuestion} heading-bold`} 
        dangerouslySetInnerHTML={{
          __html: currentStage.attributes.title ,
        }}></Typography>
        <Typography className={classes.wordCloudEntries}>{`${currentStage.attributes.description.length} Entries per student`}</Typography>
        </Box>
        :
        <Typography className={`${classes.studentQuestion} heading-bold`} 
        dangerouslySetInnerHTML={{
          __html: currentStage.attributes.title ,
        }}></Typography>
      }
      </Box>
      
       {getSecondBox(isWordC)}
      </>
    )
  }
  
  const modifyFullScreen = () => {
    setFullScreen(!fullscreen)
  }
  
  const getStaticCanvas = () => {
    const hasCanvasData = currentStage?.attributes?.canvas_data;
    let jsonCanvas2: object | undefined;
    let canvasSize2: string | undefined;
    if (hasCanvasData) {      
      const parsedData = typeof hasCanvasData == "object"?hasCanvasData:JSON.parse(hasCanvasData);
      jsonCanvas2 = parsedData.canvas;
      canvasSize2 = parsedData.canvasSize      
    }
    return (
      jsonCanvas2 &&
        <Box style={{position:"absolute",width:"90%",height:"100%",}}>
            <StaticCanvas canvasData={jsonCanvas2} id="canvasId" canvasSize={canvasSize2?JSON.parse(canvasSize2):undefined}  callbackCanvas={setHeight}/>
        </Box>
    )
  }
  
  const getTitleContentWithCanvas = () => {
    if(!currentStage) return
    
    return(
    <Box>
      {getStaticCanvas()}
      <Typography className={classes.correctQuestion}
      dangerouslySetInnerHTML={{
        __html: stageType == ACTIONS.OPEN_ENDED_QUESTION ?studentResultList?.main_question :studentResultList?.question,
      }}></Typography>
    </Box>
    )
  }
  return (
    <Box>
      <Grid container style={{ overflowX: "hidden", height: "100%" }}>
        <Grid item xs={fullscreen?12:9} style={{ borderRight: "1px solid #F0F0F0" }}>
          {(!currentStage || showLoader) ? (
            <Loader loading={true} />
          ) : (
            <>
              <Grid item md={12}>
                <LiveSectionHeader lessonName={lessonName} className={className} role={role} leaveLesson={leaveLesson}
                  screen={screen} setScreen={setScreen} modifyFullScreen={modifyFullScreen} fullscreen={fullscreen} isQuiz={isQuiz}/>
              </Grid>

              <Grid item md={12} className={classes.liveScreen} 
                style={backgroundImage ? { 
                  backgroundImage: `url("${backgroundImage}")`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }:{}}
              >
              {screen == "Lesson" &&
                    <>
                      {isDescHide ? (
                        <Box className={`${classes.questionScreen} mcq-show-result-box`}>
                          <Box className={`${classes.questionHeading} mcq-result-title`}>
                            <Typography className={classes.showResult}>{showResults}</Typography>
                          </Box>
                          <Box className={`${classes.answerOption} mcq-result-box-inner`}>
                            <McqQuestion currentStage={currentStage} isDescHide={isDescHide} rightAnswerTick={rightAnswerTick} />
                          </Box>
                        </Box>) :
                        matchingStudentData()
                      }
                  </>}       
                {screen == "Whiteboard" && <WhiteboardCollaboration navigation={() => { }} id="" fullscreen={fullscreen} getCanvas={getLiveStreamCanvas} saveCurrentCanvas={saveCanvas}/>}
              </Grid>
              <Grid item md={12}>
                <LiveSectionFooter
                  data-test-id="live-footer"
                  stages={stages}
                  currentStage={currentStage}
                  nextStage={nextStage}
                  prevStage={prevStage}
                  room={room}
                  role={role}
                  handleShowResult={handleShowResult}
                  isOpenResult={isOpenResult}
                  handleStudentResult={handleStudentResult}
                  isStudentResult={isStudentResult}
                  isOpenName={isOpenName}
                  lessonStartTime={lessonStartTime}
                  isQuiz={isQuiz}
                  startQuiz={startQuiz}
                />
              </Grid>
            </>
          )}
        </Grid>
                      
        {!fullscreen && <Grid item xs={3} style={{ overflowY: "scroll", height: "100%" }}>
          <Grid item md={12}>
            <ChatSectionHeader
              data-test-id="chat-header"
              handleChatHeaderOption={handleChatHeaderOption}
              showCommentsIcon={showCommentsIcon}
              readComments={readComments}
            />
          </Grid>
          <Grid item md={12}>
            {renderChatContent()}
          </Grid>
        </Grid>}
      </Grid>
      <Box>
        <Modal
          className={classes.modalKwlName}
          open={commanModalOpen}
          closeAfterTransition
        >
          <Fade in={commanModalOpen}>
            <Box className={classes.paperKwlText}>
              <Box className={classes.showKwlNameStyle}>
                <Typography className={classes.showKwlNameText} >{showNames}</Typography>
                <CloseIcon className={classes.closeIcon} onClick={handleStudentResult} />
              </Box>
              <Box>
                
                {getModalContent()}
              </Box>
            </Box>
          </Fade>
        </Modal>
      </Box>
      <Box>
        <Modal
          className={classes.modalName}
          open={otherModalOpen}
          closeAfterTransition
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={otherModalOpen}>
            <Box className={`${classes.paperText} paper-text-new mcq-box`}>
              <Box className={classes.paperTextInner}>
                <Box className={classes.showNameStyle}>
                  <Typography className={classes.showNameText} >{showNames}</Typography>
                  <CloseIcon className={classes.closeIcon} onClick={handleStudentResult} />
                </Box>

                <Box>
                  <Box className={classes.questionContainer}>
                  <Box className={classes.wordCloudHeading}>
                  <Typography className={classes.studentQuestion}>{getQuestionType()}:</Typography>
                    <Typography >Students answered: {studentResultList?.students_answered_count}/{studentResultList?.student_count}</Typography>
                  </Box>
                  {getTitleContentWithCanvas()}
                  </Box>
                  {getNamesDesign()}
                </Box>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </Box>
    </Box>
  );
};

export default TeacherLiveStreaming;
