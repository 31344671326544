import React from "react";
import { Box, Grid } from "@material-ui/core";
import AppHeaderWeb from "../../../../components/src/AppHeader.web";
import Sidebar from "../../../../components/src/Sidebar.web";
import PeopleManagement2Controller, {
  Props,
} from "../PeopleManagement2Controller";
import PeopleManagement from "./PeopleManagement.web";
import ClassList from "./ClassList.web";
import {styled } from "@material-ui/core/styles";

const StyleGrid = styled(Grid)({
  overflowX: "hidden", 
  height: "100%",
  '@media (max-width: 600px)': {
      display:"block"
    }
});

export default class SchoolAdminPeopleManagement extends PeopleManagement2Controller {
  constructor(props: Props) {
    super(props);

    this.redirect = this.redirect.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSearchInput = this.handleSearchInput.bind(this);
    this.deleteProfile = this.deleteProfile.bind(this);
    this.deleteCleanup = this.deleteCleanup.bind(this);
    this.switchToTeacherView = this.switchToTeacherView.bind(this);
    this.switchToStudentView = this.switchToStudentView.bind(this);
  }

  renderSwitch(selectedOption: string) {
    if (selectedOption === "Classes") {
      return (
        <ClassList
          redirect={this.redirect}
          classesData={this.state.classesData}
          isLoading={this.state.isLoading}
        />
      );
    } else {
      return (
        <PeopleManagement
          redirect={this.redirect}
          students={this.state.students}
          teachers={this.state.teachers}
          getTableData={this.getTableData}
          handlePageChange={this.handlePageChange}
          pageLimit={this.state.pageLimit}
          handleSearchInput={this.handleSearchInput}
          searchInput={this.state.searchInput}
          deleteProfile={this.deleteProfile}
          isLoading={this.state.isLoading}
          isDeleteSuccess={this.state.isDeleteSuccess}
          deleteProfileError={this.state.deleteProfileError}
          deleteCleanup={this.deleteCleanup}
          pageNo={this.state.pageNo}
          switchToTeacherView={this.switchToTeacherView}
          switchToStudentView={this.switchToStudentView}
          tabSelected={this.state.tabSelected}
        />
      );
    }
  }

  render() {
    return (
      <Box>
        <StyleGrid container>
          <Grid item {...(!this.state.isSmallScreen && { xs: 2 })}>
            {!this.state.isSmallScreen && <Sidebar />}
          </Grid>
          <Grid item {...(!this.state.isSmallScreen && { xs: 10 })}>
            <Grid item md={12}>
              <AppHeaderWeb title={"People Management"} />
            </Grid>
            <Grid item md={12}>
              {this.renderSwitch(this.state.render)}
            </Grid>
          </Grid>
        </StyleGrid>
      </Box>
    );
  }
}
