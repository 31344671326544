import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Divider, Grid, TextField, Typography } from "@material-ui/core";
import AddBackgroundImageModal from "./AddBackgroundImageModal.web";
import { backgroundImageIcon, settingsIcon, timerIcon, backgroundImageActivePng, timerActivePng } from "../../assets";
const useStyles = makeStyles(() => ({
  editArea: {
    height: "740px",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    backgroundColor: "#FFFFFF",
    marginBottom: "50px",
    padding: "25px",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 400,
    overflow: "auto",
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
  },
  instructionBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    marginTop: "15px",
    justifyContent: "space-between"
  },
  answerBox: {
    "&:empty:before": {
      content: "attr(data-placeholder)",
      color: "#9FA1A4",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
    },
    color: "#2B3D7D",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    "&:focus-visible": {
      outline: "none",
    },
    border: "1px solid rgb(149, 157, 165)",
    padding: "8px",
    borderRadius: "4px",
    backgroundColor: "#fff",
    width: "100%"
  },
  enteriesCount: {
    color: "#282829",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    border: "1px solid rgb(149, 157, 165)",
    borderRadius: "4px",
    backgroundColor: "#fff",
    "& .MuiInputBase-input": {
      color: "#282829",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
      padding: "1px 5px"
    },
  },
  instructionTitle: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#0A033C",
    fontWeight: 600,
    marginRight: "12px",
    marginBottom: "4px"
  },
  settingMenu: {
    display: "flex",
    flexDirection: "column",
    width: "190px",
    border: "1px solid #979797",
    borderRadius: "8px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    position: "absolute",
    zIndex: 999,
    right: "5px",
    top: "27px",
    backgroundColor: "#fff"
  },
  action: {
    "&:hover": {
      backgroundImage:
        "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
      color: "#FFF",
    },
    display: "flex", padding: "8px", gap: "4px",
    color: "#2B3D7D",
    borderRadius: "4px",
    cursor: "pointer"
  },
  settingIcon: {
    borderRadius: "50%",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 0px 0px 3px",
    position: "relative",
    cursor: "pointer"
  },
  images: {
    width: "18px",
    height: "18px"
  }
}));



const AddWordCloud = (props: any) => {
  const { enteriesCount, setEnteriesCount, setAddWordCloudState, addWordCloudState, preview, setPreview, setIsSaveDisabled } = props;
  const [settingMenu, setSettingMenu] = useState(false);
  const [modalOpen, setModalOpen] = React.useState<any>();
  const [backgroundImage, setBackgroundImage] = React.useState<any>(null);
  const backgroundImageRef = React.useRef<any>(null)
  const timerImageRef = React.useRef<any>(null) 

  const backgroundStyle = {
    height: '100vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `linear-gradient(rgba(255,255,255,0.6), rgba(255,255,255,0.6)), url(${preview})`,
  };

  const classes = useStyles();
  const handleInput = (type: string, event: any) => {
    const value = event.target.value;
    if (type === "count") {
      if ((value === '' || (value > 0 && value <= 10)) && value.match(/^\d*$/)) {
        setEnteriesCount(event.target.value)
        value !== '' ?  setIsSaveDisabled(false) : setIsSaveDisabled(true) 
      }
    }
  };

  const createDescriptionArray = (value: any): any[] => {
    let answersArray: any[] = [];
    for (let i = 1; i <= Number(value); i++) {
      let answerObject = { [`answer${i}`]: "" };
      answersArray.push(answerObject);
    }
    return answersArray;
  };
  useEffect(() => {
      if (enteriesCount >= 0) {
        setAddWordCloudState({
          ...addWordCloudState,
          description: createDescriptionArray(enteriesCount),
        });
      }
      setIsSaveDisabled(enteriesCount == '' || enteriesCount==0)
  }, [enteriesCount])
  useEffect(() => {
    setAddWordCloudState({
      ...addWordCloudState,
      backgroundImage: backgroundImage
    })
  }, [backgroundImage])

  const handleModalOpen = () => {
    setModalOpen(true)
  }
  const handleCloseModal = () => {
    setModalOpen(false);
  }

  return (
    <Box className={classes.editArea} style={preview && backgroundStyle}>
      <Box className={classes.instructionBox}>
        <Box style={{ display: "flex", alignItems: "center", }}>
          <Typography className={classes.instructionTitle}>
            Enteries per Student:
          </Typography>
          <TextField
            className={classes.enteriesCount}
            style={{ width: enteriesCount ? "23px" : "114px" }}
            value={enteriesCount ? enteriesCount : ""}
            disabled={false}
            onChange={(e) => handleInput("count", e)}
            data-test-id="instruction"
            placeholder="Min 1 - Max 10"
            InputProps={{ disableUnderline: true }}
          />
        </Box>
        <Box className={classes.settingIcon}
          onClick={() => setSettingMenu(!settingMenu)} data-test-id="imageModal1">
          <img src={settingsIcon} />
          {settingMenu && (
            <Box className={classes.settingMenu}>
              <Box
                className={classes.action}
                onClick={handleModalOpen}
                data-test-id="imageModal"
                onMouseEnter={(e) => (backgroundImageRef.current.src = backgroundImageActivePng)}
                onMouseLeave={(e) => (backgroundImageRef.current.src = backgroundImageIcon)}
              >
                <img
                  ref={backgroundImageRef}
                  src={backgroundImageIcon}
                  className={classes.images}
                />
                <Typography style={{ fontSize: "12px", fontFamily: "Poppins" }}>Add Background Image</Typography>
              </Box>
              <Divider />
             
            </Box>
          )}
        </Box>
      </Box>

      {addWordCloudState?.description?.length > 0 && (<Box style={{ display: "flex", flexDirection: "column" }}>
        <Typography className={classes.instructionTitle}>Answers</Typography>
        <Grid container spacing={2}>
          {addWordCloudState?.description?.map((obj:any,index:any) =>
            <Grid item xs={12} md={6} key={index}>
              <input
                className={classes.answerBox}
                readOnly
                data-test-id="instruction2"
                placeholder={`Answer ${index+1} here`}
              />
            </Grid>
          )}
        </Grid>
      </Box>)}
      <AddBackgroundImageModal
        data-test-id="closeImageModal"
        open={modalOpen}
        onClose={handleCloseModal}
        backgroundImage={backgroundImage}
        setBackgroundImage={setBackgroundImage}
        setPreview={setPreview}
      />
    </Box>
  );
};

export default AddWordCloud;
