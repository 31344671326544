Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";

exports.teacherApiEndPoint = "account_block/add_users/create_teacher_account";
exports.studentApiEndPoint = "account_block/add_users/create_student_account";
exports.tasksAccountsApiEndPoint = "account_block/accounts";
exports.deleteAccountApiEndPoint = "account_block/add_users/delete_account?account_id=";
exports.editAccountApiEndPoint = "account_block/add_users/update_account?account_id="
exports.loggedInUserApiEndPoint = "account_block/accounts/logged_user"
exports.teachersListApiEndPoint = "/account_block/accounts/role_base_accounts"

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "* All fields are mandatory.";
exports.errorPasswordFieldRequired = "Password is required!";
exports.errorConfirmPasswordFieldRequired = "Confirm Password is required!";
exports.errorPasswordMismatch = "Password and confirm password do not match.";
exports.accountFilters = '/account_block/add_users/filter_account'
exports.textClose = "Cancel";
exports.textSave = "Save";
exports.textUpdate = "Update";
exports.textAddGroup = "Add Group";
exports.textShowGroup = "Show Group";
exports.textEdit = "Edit";
exports.textDeleteProfile = "Delete Profile";
exports.textDelete = "Delete";
exports.textAccounts = "Accounts";
exports.textAddAcc = "Add Acc.";
exports.textDeleteAcc = "Delete Acc.";
exports.textId = "Id";
exports.textStatus = "Status";
exports.textActions = "Actions";
exports.textAssign = "Assign";
exports.textAssignedTo = "Assigned To";
exports.textName = "Name";
exports.textCreatedAt = "Created At";
exports.textUpdatedAt = "Updated At";
exports.textSelectAccounts = "Select accounts";
exports.getMethod = "GET"
// Customizable Area End
