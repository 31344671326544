import React, { useEffect } from 'react'
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { withStyles, makeStyles, styled } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { Box, Typography, MenuItem, Menu, Tab, ListItemText, Tabs } from "@material-ui/core";
import moment from 'moment';
import { DeleteActive, DeleteInactive, EditActive, EditInactive } from '../../../blocks/dashboard/src/assets';
import Loader from '../Loader.web';
import BasicPagination from '../../../blocks/accountgroups/src/BasicPagination.web';
import { assignmentIcon, clockIcon, viewQuizzes } from '../../../blocks/tasks/src/assets';



const useStyles = makeStyles(() => ({
  table: {
    minWidth: 700,
  },
  assignmentName: {
    marginLeft: "10px"
  },
  assignmentContainer: {
    display: "flex",
    alignItems: 'center',
  },

  noRecords: {
    fontFamily: "Poppins",
    textAlign: "center",
    paddingTop: "35px",
  },
  tabs: {
    "&.MuiTabs-root": {
      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
      borderRadius: "8px",
      border: "0.2px solid #2B3D7D",
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "&.MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "center",
      marginTop: "20px",
    },
  },
  action: {
    borderRadius: "8px",
    border: "0.2px solid #2B3D7D",
    color: "#2B3D7D",
    padding: "5px",
    width: "fit-content"
  },
  link: {
    color: "#2B3D7D",
    textDecoration: "underline",
    textDecorationColor: "#2B3D7D",
  },
  tab: {
    "&.MuiTab-root": {
      color: "#2B3D7D",
      fontWeight: 500,
      fontSize: "20px",
      width: "218px",
      textTransform: "capitalize",
      borderRadius: "8px",
    },
    "&.MuiTab-root.Mui-selected": {
      fontWeight: 600,
      color: "#FFFFFF",
      textTransform: "capitalize",
      backgroundImage:
        "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
      fontSize: "20px",
      borderRadius: "8px",
      width: "218px",
    },
  },
}));

export const StyleBox = styled(Box)({
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
  border: "1px solid rgba(149, 157, 165, 0.2)",
  borderRadius: "10px",
  padding: "15px 30px",
  minHeight: "204px",
  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
  cursor: "pointer",
  maxWidth: "180px",
  flexDirection: "column" as "column",
  "&:hover": {
    background: "#ebf8fe",
    borderColor: "#3093DDFF",
    color: "#121212"
  }
});
export const StyledBox = styled(Box)({
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
  borderRadius: "10px",
  padding: "15px 30px",
  minHeight: "204px",
  cursor: "pointer",
  maxWidth: "180px",
  flexDirection: "column" as "column",
});

export const Card = styled(Box)({
  cursor: "pointer",
  display: "flex",
  width: "360px",
  height: "135px",
  borderRadius: "8px",
  justifyContent: "center",
  gap: "20px",
  alignItems: "center",
  position: "relative",
  border: "1px solid rgba(149, 157, 165, 0.2)",
  "&:hover": {
    background: "#ebf8fe",
    borderColor: "#3093DDFF",
    color: "#121212",
  }
})

export const StyledTabs = styled(Tabs)({

  "&.MuiTabs-root": {
    textTransform: "lowercase"
  },
  "& .MuiTabs-flexContainer": {
    display: "flex",
    // justifyContent: "center",
    marginTop: "20px",
    gap: "40px"
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#2B3D7D !important",
  },

})
export const StyledTab = styled(Tab)({
  "&.MuiTab-root": {
    color: "#3F526D",
    fontSize: "20px",
    fontFamily: "Poppins",
    textTransform: "capitalize"
  },
  "&.MuiTab-root.Mui-selected": {
    color: "#2B3D7D",
    fontSize: "20px",
  },
  "&.MuiButtonBase-root": {
    fontFamily: "Poppins"
  }

})



export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#FDF4DC",
    color: "#202020",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
  },
  body: {
    fontSize: "14px",
    color: "#3F526D",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
}))(TableCell);
export const StyledTableItem = withStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundImage:
        "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
        fontFamily: "Poppins",
        fontSize: "16px",
      },
    },
  },
}))(MenuItem);

export const StyledTable = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    borderRadius: "6px",
  },
})((props: any) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export const StyledTableRow = withStyles(() => ({}))(TableRow);


export const StyledTableHead = () => {
  return (
    <TableRow>
      <StyledTableCell align="left">Assignments</StyledTableCell>
      <StyledTableCell align="left">Class</StyledTableCell>
      <StyledTableCell align="left">Due Date</StyledTableCell>
      <StyledTableCell align="left">Completed</StyledTableCell>
      <StyledTableCell align="left">Action</StyledTableCell>
    </TableRow>
  );
};

export const GetDaysLeft = (targetDateString: any) => {
  if (targetDateString) {
    const targetDate = moment(targetDateString, 'YYYY-MM-DD');
    const currentDate = moment().startOf('day');
    const daysLeft = targetDate.diff(currentDate, 'days');
    return (isNaN(daysLeft) || daysLeft <= 0) ? 0 : daysLeft
  }
}

const tableHead = () => {
  return (
      <TableRow>
          <StyledTableCell align="left">Quizzes</StyledTableCell>
          <StyledTableCell align="left">Class</StyledTableCell>
          <StyledTableCell align="left">Due Date</StyledTableCell>
          <StyledTableCell align="left">Completed</StyledTableCell>
          <StyledTableCell align="left">Action</StyledTableCell>
      </TableRow>
  );
};

export const Assignments = (props: any) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [contextMenuListData, setContextMenuListData] = React.useState<any>({});
  const [openContextMenuList, setOpenContextMenuList] = React.useState(false);
  const deleteImageRef = React.useRef<any>();
  const previewImageRef = React.useRef<any>();
  const classes = useStyles();
  const {
    handlePageChange,
    getTaskLists,
    pageLimit,
    page,
    loading,
    taskLists,
    handleOpenEditModal,
    handleOpenDeleteConfirmationModal,
    viewMyLibraryAssignment
  } = props;

  useEffect(() => {
      getTaskLists()
  }, [])

  const handleOpenContextMenuList = (event: any, data: any) => {
    event.preventDefault( );
    setContextMenuListData({ ...data });
    setOpenContextMenuList(true);
    setAnchorEl(event.currentTarget);
  };

  const handleEditModalOpen = () => {
    setOpenContextMenuList(false);
    handleOpenEditModal();
    viewMyLibraryAssignment(contextMenuListData);
  }
  const handleDeleteModalOpen = () => {
    setOpenContextMenuList(false);
    handleOpenDeleteConfirmationModal();
    viewMyLibraryAssignment(contextMenuListData);
  }

  const tableHead = () => {
    return (
      <TableRow>
        <StyledTableCell align="left">Assignments</StyledTableCell>
        <StyledTableCell align="left">Class</StyledTableCell>
        <StyledTableCell align="left">Due Date</StyledTableCell>
        <StyledTableCell align="left">Completed</StyledTableCell>
        <StyledTableCell align="left">Action</StyledTableCell>
      </TableRow>
    );
  };

  const tableBody = () => {
    return taskLists && taskLists?.map((data: any, index: number) => {
      return (
        <React.Fragment key={data.id}>
          <StyledTableRow key={data.id}>
            <StyledTableCell align="left">
              <Box className={classes.assignmentContainer}>
                <img src={assignmentIcon} />
                <Typography className={classes.assignmentName}>
                  {data?.attributes?.assignment?.data?.attributes?.assignment_name}
                </Typography>
              </Box>
            </StyledTableCell>
            <StyledTableCell align="left">
              {data?.attributes?.assignment?.data?.attributes?.class_name}
            </StyledTableCell>
            <StyledTableCell align="left">
              {(data?.attributes?.days_left || 0) > 5 ? (
                <Box className={classes.assignmentContainer}>
                  <img src={clockIcon} />
                  <Typography className={classes.assignmentName}>{data?.attributes?.days_left} Days Left</Typography>
                </Box>
              ) : (
                <Box className={classes.assignmentContainer}>
                  <img src={clockIcon} />
                  <Typography className={classes.assignmentName} style={{ color: "#E02020" }}>{data?.attributes?.days_left} Days Left</Typography>
                </Box>
              )}
            </StyledTableCell>
            <StyledTableCell align="left">
              {data?.attributes?.assignment?.data?.attributes?.student_submit_count}
            </StyledTableCell>
            <StyledTableCell align="left">
              <MoreVertIcon
                data-test-id="action-icon"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  // e.stopPropagation();
                  handleOpenContextMenuList(e, data);
                }}
              />
            </StyledTableCell>
          </StyledTableRow>
          
          <StyledTable
            id="customized-menu"
            data-testid="customized-menu"
            anchorEl={anchorEl}
            open={openContextMenuList}
            onClose={() => setOpenContextMenuList(false)}
          >
            <React.Fragment>
              <StyledTableItem
                data-test-id="edit-option"
                onMouseEnter={(e) =>
                  previewImageRef.current && (previewImageRef.current.src = EditActive)
                }
                onMouseLeave={(e) => previewImageRef.current && (previewImageRef.current.src = EditInactive)}
                onClick={handleEditModalOpen}
              >
                <img src={EditInactive} ref={previewImageRef} style={{ marginRight: "10px" }} />
                <ListItemText primary="Edit" color="#48484A" />
              </StyledTableItem>
              <StyledTableItem
                data-test-id="delete-option"
                onMouseEnter={(e) => deleteImageRef.current && (deleteImageRef.current.src = DeleteActive)}
                onMouseLeave={
                  (e) => deleteImageRef.current && (deleteImageRef.current.src = DeleteInactive)
                }
                onClick={handleDeleteModalOpen}
              >
                <img src={DeleteInactive} ref={deleteImageRef} style={{ marginRight: "10px" }} />
                <ListItemText primary="Delete" color="#48484A" />
              </StyledTableItem>
            </React.Fragment>
          </StyledTable>
        </React.Fragment>
      );
    });
  };

  return (
    <React.Fragment>
      <Box sx={{ px: "35px" }}>
        {loading ? (
          <Loader loading />
        ) : (
          <Box>
            {taskLists?.length === 0 ? (
              <Typography className={classes.noRecords}>
                No records found
              </Typography>
            ) : props.tabValue === 0 && (
              <>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="customized table">
                    <TableHead>{tableHead()}</TableHead>
                    <TableBody>{tableBody()}</TableBody>
                  </Table>
                </TableContainer>
                <Box sx={{ px: "35px", py: "50px" }}>
                  <BasicPagination
                    handlePageChange={handlePageChange}
                    pageLimit={pageLimit}
                    pageNo={page}
                  />
                </Box>
              </>
            )}
          </Box>)}
      </Box>
    </React.Fragment>
  );
};


export const QuizzeList = (props: any) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openContextMenuList, setOpenContextMenuList] = React.useState(false);
  const [contextMenuListData, setContextMenuListData] = React.useState<any>({});
  const deleteImageRef = React.useRef<any>();
  const previewImageRef = React.useRef<any>();
  
  const classes = useStyles();
  const {
    loading,
    page,
    pageLimit,
    quizList,
    handlePageChange,
    handleOpenEditModal,
    handleOpenDeleteConfirmationModal,
    viewMyLibraryQuiz
  } = props;

  const handleOpenContextMenuList = (event: any, data: any) => {
    event.preventDefault();
    setContextMenuListData({ ...data });
    setOpenContextMenuList(true);
    setAnchorEl(event.currentTarget);
  };

  const handleEditModalOpen = () => {
    setOpenContextMenuList(false);
    handleOpenEditModal();
    viewMyLibraryQuiz(contextMenuListData);
  }
  const handleDeleteModalOpen = () => {
    setOpenContextMenuList(false);
    handleOpenDeleteConfirmationModal();
    viewMyLibraryQuiz(contextMenuListData);
  }

  const gettingData = (dummyData: any) => {
    return contextMenuListData;
  }
  const tableHead = () => {
    return (
      <TableRow>
        <StyledTableCell align="left">Quizzes</StyledTableCell>
        <StyledTableCell align="left">Class</StyledTableCell>
        <StyledTableCell align="left">Due Date</StyledTableCell>
        <StyledTableCell align="left">Completed</StyledTableCell>
        <StyledTableCell align="left">Action</StyledTableCell>
      </TableRow>
    );
  };

  const tableBody = () => {
    return quizList && quizList?.map((data: any, index: number) => {
      return (
        <React.Fragment key={data.id}>
          <StyledTableRow>
            <StyledTableCell align="left">
              <Box className={classes.assignmentContainer}>
                <img src={viewQuizzes} />
                <Typography className={classes.assignmentName}>
                  {data?.attributes?.quiz?.data?.attributes?.quiz_name}
                </Typography>
              </Box>
            </StyledTableCell>
            <StyledTableCell align="left">
              {data?.attributes?.quiz?.data?.attributes?.class_name}
            </StyledTableCell>
            <StyledTableCell align="left">
              {data?.attributes?.days_left > 5 ?
                <Box className={classes.assignmentContainer}>
                  <img src={clockIcon} />
                  <Typography className={classes.assignmentName}>{data?.attributes?.days_left} Days Left</Typography>
                </Box>
                : <Box className={classes.assignmentContainer}>
                  <img src={clockIcon} />
                  <Typography className={classes.assignmentName} style={{ color: "#E02020" }}>{data?.attributes?.days_left} Days Left</Typography>
                </Box>}
            </StyledTableCell>
            <StyledTableCell align="left">
              {data?.attributes?.quiz?.data?.attributes?.student_submit_count}
            </StyledTableCell>
            <StyledTableCell align="left">
              <MoreVertIcon
                data-test-id="action-icon"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  handleOpenContextMenuList(e, data);
                }}
              />
            </StyledTableCell>
          </StyledTableRow>

          <StyledTable
            id="customized-menu"
            data-testid="customized-menu"
            anchorEl={anchorEl}
            open={openContextMenuList}
            onClose={() => setOpenContextMenuList(false)}
          >
            <React.Fragment>
              <StyledTableItem
                data-test-id="edit-option"
                onMouseEnter={(e) =>
                  previewImageRef.current && (previewImageRef.current.src = EditActive)
                }
                onMouseLeave={(e) => previewImageRef.current && (previewImageRef.current.src = EditInactive)}
                onClick={handleEditModalOpen}
              >
                <img src={EditInactive} ref={previewImageRef} style={{ marginRight: "10px" }} />
                <ListItemText primary="Edit" color="#48484A" />
              </StyledTableItem>
              <StyledTableItem
                data-test-id="delete-option"
                onMouseEnter={(e) => deleteImageRef.current && (deleteImageRef.current.src = DeleteActive)}
                onMouseLeave={(e) => deleteImageRef.current && (deleteImageRef.current.src = DeleteInactive)}
                onClick={handleDeleteModalOpen}
              >
                <img src={DeleteInactive} ref={deleteImageRef} style={{ marginRight: "10px" }} />
                <ListItemText primary="Delete" color="#48484A" />
              </StyledTableItem>
            </React.Fragment>
          </StyledTable>
        </React.Fragment>
      );
    });
  };
  
  return (
    <React.Fragment>
      <Box sx={{ px: "35px" }}>
        {loading ? (
          <Loader loading />
        ) : (
          <Box>
            {quizList?.length === 0 ? (
              <Typography className={classes.noRecords}>
                No records found
              </Typography>
            ) : (
              <React.Fragment>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="customized table">
                    <TableHead>{tableHead()}</TableHead>
                    <TableBody>{tableBody()}</TableBody>
                  </Table>
                </TableContainer>
                <Box sx={{ px: "35px", py: "50px" }}>
                  <BasicPagination
                    handlePageChange={handlePageChange}
                    pageLimit={pageLimit}
                    pageNo={page}
                  />
                </Box>
              </React.Fragment>
            )}
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};


const TeacherAssignNewTask = () => {
  return (
    <div>TeacherAssignNewTask</div>
  )
}

export default TeacherAssignNewTask;
